import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Users } from '@voiply/shared/model';

@Component({
  selector: 'voiply-ringgroup-user',
  templateUrl: './ringgroup-user.component.html',
  styleUrls: ['./ringgroup-user.component.scss']
})
export class RinggroupUserComponent implements OnInit {
  @Input() users;
  @Input() ringGroupUser;
  @Input() RingGroupHelpText;
  @Output() userSelectionChange: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }
  isUserSelected(userId: string, index: number): boolean {
    if (this.ringGroupUser != undefined &&
      this.ringGroupUser[userId] != undefined && !this.isEmptyObject(this.ringGroupUser)) {
      return true;
    }
  }
  toggleUserSelection(userId) {
    this.userSelectionChange.emit(userId)
  }
  isEmptyObject(obj) {
    if (obj == undefined || obj == null)
      return true;
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }
    return true;
  }
}
