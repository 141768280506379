import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import uuid from 'uuid';

@Component({
  selector: 'voiply-question-model',
  templateUrl: './question-model.component.html',
  styleUrls: ['./question-model.component.scss']
})
export class QuestionModelComponent implements OnInit {

  @Input() selectedType = 'text';
  @Input() actionsAllowed = '';
  @Input() questionText = '';
  @Input() answers = [];
  @ViewChild('richTextBox') richTextBox: ElementRef;
  @Output() followUp = new EventEmitter<Object>();
  color: ThemePalette = 'primary';
  selectable = true;
  removable = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  tags = [
    'AgentName',
    'FirstName'
  ]
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  oninputChange(value) {
    this.questionText = value
  }

  removeAnswer(answer) {
    const index = this.answers.indexOf(answer);

    if (index >= 0) {
      this.answers.splice(index, 1);
    }
    // this.callForwardNumbersChange.emit(this.callForwardNumbers);
  }


  addAnswer(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.answers.push(value);
      // this.callForwardNumbersChange.emit(this.callForwardNumbers);
    }


    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  onFollowUpSave() {
    const options = this.answers.forEach(element => {
      return {
        optionKey: element,
        followUp: []
      }
    });
    this.followUp.emit({
      id: uuid(),
      input: this.selectedType,
      agentResponse: this.questionText,
      options: options,
      actionsAllowed: this.actionsAllowed
    })
  }
}
