import { Component, NgZone, OnInit } from '@angular/core';
import { IntroBase } from '../intro.base';

@Component({
  selector: 'voiply-intro-business',
  templateUrl: './intro-business.component.html',
  styleUrls: ['./../intro.base.css']
})
export class IntroBusinessComponent extends IntroBase implements OnInit {

  headerHeight = 0;
  observer;
  constructor(private zone: NgZone) {
    super();
  }

  ngOnInit() {
    this.observer = new (window as any).ResizeObserver(entries => {
      this.zone.run(() => {
        this.headerHeight = entries[0].contentRect.height;
      });
    });
    this.observer.observe(document.getElementById('head-bar'));
  }

  ngOnDestroy() {
    this.observer.unobserve(document.getElementById('head-bar'));
  }
}
