import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Address, EmergencyAddress } from '@voiply/shared/model';
import { COUNTRIES } from '@voiply/shared/common-ui';
import * as _ from 'lodash';
import { ValidationService } from 'libs/shared/common-ui/src/lib/services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'voiply-e911',
  templateUrl: './e911.component.html',
  styleUrls: ['./e911.component.css']
})
export class E911Component implements OnInit {

  allowE911Modal: boolean = false;
  addressForm: FormGroup;
  submitted: boolean = false;
  countries = COUNTRIES;
  filteredStates;
  // convenience getter for easy access to form fields
  get f() { return this.addressForm.controls; }


  @Output() updateEmergencyAddress = new EventEmitter<Address>();

  @Input() emergencyAddress: EmergencyAddress;

  @ViewChild('e911Modal') public e911Modal: ModalDirective;

  constructor(private formBuilder: FormBuilder, private validateService: ValidationService, private toastr: ToastrService) {
    this.addressForm = this.formBuilder.group({
      name: ['', Validators.required],
      addressLine1: ['', [Validators.required]],
      addressLine2: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      country: ['', [Validators.required]],
      zip: ['', [Validators.required]]
    });

    this.addressForm.controls['country'].valueChanges.subscribe((changes) => {
      this.filteredStates = (_.filter(this.countries, { 'countryShortCode': changes })[0] || {}).regions;
      if (!_.find(this.filteredStates, { shortCode: this.addressForm.controls['state'].value })) {
        this.addressForm.controls['state'].patchValue('');
      }
    });
  }

  ngOnInit() {
    this.filteredStates = (_.filter(this.countries, { 'countryShortCode': this.addressForm.controls['country'].value })[0] || {}).regions;
  }



  showE911Modal() {
    this.allowE911Modal = true;
    if (this.emergencyAddress && this.addressForm)
      this.addressForm.patchValue(this.emergencyAddress);
    this.e911Modal.show();
  }

  async onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.addressForm.invalid) {
      return;
    }
    // try {
    //   await this.validateService.validateEmergencyAddress(this.addressForm.value);
    // } catch (err) {
    //   if (err.error.error.length) {
    //     this.toastr.error(err.error.error[0].message)
    //   }
    //   return
    // }



    this.submitted = false;
    this.updateEmergencyAddress.emit(this.addressForm.value);

    this.e911Modal.hide();
    this.allowE911Modal = false;
  }

  hideE911Modal() {
    this.allowE911Modal = false;
    this.e911Modal.hide();
  }
}
