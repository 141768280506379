<div class="card">
  <div class="card-body">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <h5 class="text-secondary font-weight-bold m-0"><i class="fas fa-share-all pr-3"></i> CALL FORWARDING </h5>
      <i class="fas fa-question-circle fa-lg" *ngIf="callForwardHelpText" [popover]="callForwardHelpText"
        placement="auto" containerClass="popover-css" [outsideClick]="true"></i>
    </div>
    <div class="border-bottom my-3"></div>
    <mat-slide-toggle class="example-margin" [color]="color" [ngModel]="callForward"
      (ngModelChange)="callForwardChange.emit(!callForward)">
      Enable Call Forwarding
    </mat-slide-toggle>
    <div class="mt-3">
      <mat-chip-list #chipList1 aria-label="Email Selection" class="email-chip-list">
        <mat-chip *ngFor="let number of callForwardNumbers ;let i=index" [selectable]="removable"
          [removable]="removable" (removed)="remove(number)">
          <span class="text-truncate">{{number|mask:'(000)000-0000'}}</span>
          <i class="fas fa-times-circle text-primary d-flex align-items-center" matChipRemove *ngIf="removable"></i>
        </mat-chip>
        <input placeholder="Type Numbers Here" [matChipInputFor]="chipList1" mask="(000)000-0000"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="add($event)">
      </mat-chip-list>
    </div>
  </div>
</div>
