<div class="card h-100">
  <div class="card-body">
    <div
      class="d-flex flex-row justify-content-between align-items-center flex-wrap flex-sm-nowrap flex-md-wrap flex-xl-nowrap">
      <div class="order-1">
        <h5 class="text-secondary font-weight-bold m-0 text-nowrap "><i class="fas fa-clock pr-3"></i>
          BUSINESS HOURS </h5>
      </div>
      <div class="w-100 mx-sm-3 mx-md-0 mx-xl-3 mt-3 mt-sm-0 mt-md-3 mt-xl-0 order-3 order-sm-2 order-md-3 order-xl-2">
        <div class=" btn-group btn-group-toggle" data-toggle="buttons">
          <button class="btn" style="border-radius: 100px 0 0 100px; font-size: 12px;" [disabled]="!isEmpty"
            [ngClass]="{'bg-success-gradient text-primary': this.callFlow.ringCallFlow.opens24_7 ,'btn-outline-secondary': !this.callFlow.ringCallFlow.opens24_7}"
            (click)="onOpen24_7Change(true)">
            <div class="d-flex align-items-center justify-content-center h-100 font-weight-bold">
              <i class="fas fa-clock pr-1"></i>
              <input type="radio" name="planOption" autocomplete="off" [checked]="this.callFlow.ringCallFlow.opens24_7">
              <span class="pl-1">OPEN 24/7</span>
            </div>
          </button>
          <button class="btn" style="border-radius: 0 100px 100px 0; font-size: 12px;"
            [ngClass]="{'bg-primary-gradient text-primary': !this.callFlow.ringCallFlow.opens24_7 ,'btn-outline-secondary': this.callFlow.ringCallFlow.opens24_7}"
            (click)="onOpen24_7Change(false)">
            <div class="d-flex align-items-center justify-content-center font-weight-bold">
              <input type="radio" name="planOption" autocomplete="off"
                [checked]="!this.callFlow.ringCallFlow.opens24_7">
              <span class="pl-1"><span>SCHEDULE</span></span>
              <i class="fas fa-cog pl-1"></i>
            </div>
          </button>
        </div>
      </div>
      <i class="fas fa-question-circle fa-lg order-2 order-sm-3 order-md-2 order-xl-3" *ngIf="businessHoursHelpText"
        [popover]="businessHoursHelpText" placement="auto" containerClass="popover-css" [outsideClick]="true"></i>
    </div>
    <div class="border-bottom my-3"></div>
    <div class="d-flex flex-row flex-wrap">
      <div class="pr-0 pr-sm-2 pr-md-0 pr-xl-2 pb-xl-0 pb-2 flex-fill">
        <div class="select-input">
          <i class="fas fa-caret-down  dropdown-img d-flex align-items-center text-color"></i>
          <select class="select-custom text-color form-control" [(ngModel)]="selectedDay">
            <option [ngValue]="0">Sunday</option>
            <option [ngValue]="1" selected>Monday</option>
            <option [ngValue]="2">Tuesday</option>
            <option [ngValue]="3">Wednesday</option>
            <option [ngValue]="4">Thursday</option>
            <option [ngValue]="5">Friday</option>
            <option [ngValue]="6">Saturday</option>
          </select>
        </div>
      </div>
      <div class="d-flex flex-row">
        <div class="d-flex flex-row flex-wrap pr-2 pb-2 pb-md-0" style="position: relative">
          <div class="pr-1">
            <div class="select-input">
              <i class="fas fa-caret-down  dropdown-img d-flex align-items-center text-primary"></i>
              <select class="select-custom form-control bg-primary text-primary select-custom-edit"
                [(ngModel)]="selectedFromTime">
                <option value="00:00:00">12:00 AM</option>
                <option value="00:30:00">12:30 AM</option>
                <option value="01:00:00">1:00 AM</option>
                <option value="01:30:00">1:30 AM</option>
                <option value="02:00:00">2:00 AM</option>
                <option value="02:30:00">2:30 AM</option>
                <option value="03:00:00">3:00 AM</option>
                <option value="03:30:00">3:30 AM</option>
                <option value="04:00:00">4:00 AM</option>
                <option value="04:30:00">4:30 AM</option>
                <option value="05:00:00">5:00 AM</option>
                <option value="05:30:00">5:30 AM</option>
                <option value="06:00:00">6:00 AM</option>
                <option value="06:30:00">6:30 AM</option>
                <option value="07:00:00">7:00 AM</option>
                <option value="07:30:00">7:30 AM</option>
                <option value="08:00:00">8:00 AM</option>
                <option value="08:30:00">8:30 AM</option>
                <option value="09:00:00">9:00 AM</option>
                <option value="09:30:00">9:30 AM</option>
                <option value="10:00:00">10:00 AM</option>
                <option value="10:30:00">10:30 AM</option>
                <option value="11:00:00">11:00 AM</option>
                <option value="11:30:00">11:30 AM</option>
                <option value="12:00:00">12:00 PM</option>
                <option value="12:30:00">12:30 PM</option>
                <option value="13:00:00">1:00 PM</option>
                <option value="13:30:00">1:30 PM</option>
                <option value="14:00:00">2:00 PM</option>
                <option value="14:30:00">2:30 PM</option>
                <option value="15:00:00">3:00 PM</option>
                <option value="15:30:00">3:30 PM</option>
                <option value="16:00:00">4:00 PM</option>
                <option value="16:30:00">4:30 PM</option>
                <option value="17:00:00">5:00 PM</option>
                <option value="17:30:00">5:30 PM</option>
                <option value="18:00:00">6:00 PM</option>
                <option value="18:30:00">6:30 PM</option>
                <option value="19:00:00">7:00 PM</option>
                <option value="19:30:00">7:30 PM</option>
                <option value="20:00:00">8:00 PM</option>
                <option value="20:30:00">8:30 PM</option>
                <option value="21:00:00">9:00 PM</option>
                <option value="21:30:00">9:30 PM</option>
                <option value="22:00:00">10:00 PM</option>
                <option value="22:30:00">10:30 PM</option>
                <option value="23:00:00">11:00 PM</option>
                <option value="23:30:00">11:30 PM</option>
              </select>
            </div>
          </div>
          <i class="far fa-caret-circle-right bg-primary text-primary right-caret-circle d-none d-sm-block"></i>
          <div class="">
            <div class="select-input">
              <i class="fas fa-caret-down  dropdown-img d-flex align-items-center text-primary"></i>
              <select class="select-custom form-control bg-primary text-primary select-custom-edit"
                [(ngModel)]="selectedToTime">
                <option value="00:00:00">12:00 AM</option>
                <option value="00:30:00">12:30 AM</option>
                <option value="01:00:00">1:00 AM</option>
                <option value="01:30:00">1:30 AM</option>
                <option value="02:00:00">2:00 AM</option>
                <option value="02:30:00">2:30 AM</option>
                <option value="03:00:00">3:00 AM</option>
                <option value="03:30:00">3:30 AM</option>
                <option value="04:00:00">4:00 AM</option>
                <option value="04:30:00">4:30 AM</option>
                <option value="05:00:00">5:00 AM</option>
                <option value="05:30:00">5:30 AM</option>
                <option value="06:00:00">6:00 AM</option>
                <option value="06:30:00">6:30 AM</option>
                <option value="07:00:00">7:00 AM</option>
                <option value="07:30:00">7:30 AM</option>
                <option value="08:00:00">8:00 AM</option>
                <option value="08:30:00">8:30 AM</option>
                <option value="09:00:00">9:00 AM</option>
                <option value="09:30:00">9:30 AM</option>
                <option value="10:00:00">10:00 AM</option>
                <option value="10:30:00">10:30 AM</option>
                <option value="11:00:00">11:00 AM</option>
                <option value="11:30:00">11:30 AM</option>
                <option value="12:00:00">12:00 PM</option>
                <option value="12:30:00">12:30 PM</option>
                <option value="13:00:00">1:00 PM</option>
                <option value="13:30:00">1:30 PM</option>
                <option value="14:00:00">2:00 PM</option>
                <option value="14:30:00">2:30 PM</option>
                <option value="15:00:00">3:00 PM</option>
                <option value="15:30:00">3:30 PM</option>
                <option value="16:00:00">4:00 PM</option>
                <option value="16:30:00">4:30 PM</option>
                <option value="17:00:00">5:00 PM</option>
                <option value="17:30:00">5:30 PM</option>
                <option value="18:00:00">6:00 PM</option>
                <option value="18:30:00">6:30 PM</option>
                <option value="19:00:00">7:00 PM</option>
                <option value="19:30:00">7:30 PM</option>
                <option value="20:00:00">8:00 PM</option>
                <option value="20:30:00">8:30 PM</option>
                <option value="21:00:00">9:00 PM</option>
                <option value="21:30:00">9:30 PM</option>
                <option value="22:00:00">10:00 PM</option>
                <option value="22:30:00">10:30 PM</option>
                <option value="23:00:00">11:00 PM</option>
                <option value="23:30:00">11:30 PM</option>
              </select>
            </div>
          </div>
        </div>
        <div class="flex-fill">
          <button type="button" class="btn rounded-0 shadow bg-black text-primary" (click)="addBusinessHour()"><i
              class="fas fa-plus "></i></button>
        </div>
      </div>
    </div>
    <div class="border-bottom mt-3"></div>
    <div class="detail-form-bottoms">
      <!-- <div id="no-business-hours" *ngIf="isEmptyObject(this.callFlow.ringCallFlow.businessHours)" class="mt-3">Business
        Hours not
        selected.</div> -->
      <div *ngFor="let key of [1,2,3,4,5,6,0]">

        <div class="d-flex flex-row">
          <ng-container *ngIf="!isEmptyObject(this.callFlow.ringCallFlow.businessHours);else other_content">
            <ng-container
              *ngIf="this.callFlow.ringCallFlow.businessHours.hasOwnProperty(key) && this.callFlow.ringCallFlow.businessHours[key] !=null ;else other_content">
              <div class="d-flex flex-row flex-grow-1 justify-content-between align-items-center mt-3">
                <div class="px-3 py-2 text-primary bg-primary-gradient d-flex flex-row flex-wrap flex-grow-1 border">
                  <div class="flex-grow-1 pr-3">{{DAY_OF_WEEK[key]}}</div>
                  <div class="d-flex flex-row">
                    <div class="pr-3"><i class="far fa-clock"></i></div>
                    <div>{{this.callFlow.ringCallFlow.businessHours[key].fromHour | formatTimeTo12Hour}}
                      -
                      {{this.callFlow.ringCallFlow.businessHours[key].toHour | formatTimeTo12Hour}}</div>
                  </div>
                </div>
                <div id="remove-business-hour" (click)="deleteBusinessHour(key)" class="pl-3"><i
                    class="fas fa-times-circle fa-lg"></i>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #other_content>
            <div class="d-flex flex-row flex-grow-1 justify-content-between align-items-center mt-3">
              <div class="px-3 py-2 text-primary d-flex flex-row flex-wrap flex-grow-1 border">
                <div class="flex-grow-1 pr-3 text-color">{{DAY_OF_WEEK[key]}}</div>
                <div class="d-flex flex-row">
                  <div><span class="text-color">NOT SET</span></div>
                </div>
              </div>
              <div id="remove-business-hour" class="pl-3"><i class="fas fa-times-circle fa-lg"
                  style="color:rgba(0, 0, 0, 0.125);"></i>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>