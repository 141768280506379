import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CONTAINERS } from './container';
import { COMPONENTS, IntroModelComponent, LabelModelComponent, QuestionModelComponent } from './components';
import { CommonUiModule } from '@voiply/shared/common-ui';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { FaqModule } from '@voiply/faq';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  imports: [
    CommonModule,
    CommonUiModule,
    TabsModule.forRoot(),
    FaqModule,
    PopoverModule.forRoot(),
    RxReactiveFormsModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgxPaginationModule,
    MatMenuModule
  ],
  declarations: [
    ...CONTAINERS,
    ...COMPONENTS
  ],
  exports: [
    ...CONTAINERS,
    ...COMPONENTS
  ],
  entryComponents: [LabelModelComponent, IntroModelComponent, QuestionModelComponent]
})
export class ConfigureCallFlowModule { }