<div class="modal-header">
    <h4 class="modal-title">Update Label</h4>
</div>
<div class="modal-body">
    <input type="text" class="form-control" [(ngModel)]="name">

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark px-4 py-2" data-dismiss="modal"
        (click)="bsModalRef.hide()">Cancel</button>
    <button (click)="this.nameChange.emit(name);bsModalRef.hide()" class="btn bg-primary text-primary px-4 py-2">
        Update Name
    </button>
</div>