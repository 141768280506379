import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'common-ui-richtextbox-input',
  templateUrl: './richtextbox-input.component.html',
  styleUrls: ['./richtextbox-input.component.scss']
})
export class RichtextboxInputComponent implements OnInit {
  @Input() tags = [];
  @Input() value = ""
  @Output() change: EventEmitter<any> = new EventEmitter();
  @HostListener("keyup", ["$event"])
  element(e): void {
    // Space key pressed
    if (e.keyCode == 32) {
      this.changeInput(this.richTextBox.nativeElement)
    }
  }
  @ViewChild('richTextBox') richTextBox: ElementRef
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value.currentValue) {
      this.changeInput(this.richTextBox.nativeElement);
    }
  }

  changeInput(e, value = '') {
    var keywords = this.tags;


    // Loop through words
    var str = e.innerText;
    if (value) {
      str = str + ' ' + value;
    }
    if (str) {
      var chunks = str
        .split(new RegExp(
          keywords
            .map(w => `(${w})`)
            .join('|'), 'i'))
        .filter(Boolean),
        markup = chunks.reduce((acc, chunk) => {
          acc += keywords.includes(chunk) ?
            `<span style="color: #f63b0b;">${chunk}</span>` :
            `<span class='other'>${chunk}</span>`
          return acc
        }, '')
      e.innerHTML = markup;

      // Set cursor postion to end of text
      var child = e.children;
      var range = document.createRange();
      var sel = window.getSelection();
      range.setStart(child[child.length - 1], 1);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }


  updateOnBlur(value) {
    // this.changeInput(this.richTextBox.nativeElement)
    this.change.emit(value);
  }

  onAddTag(tag) {
    this.changeInput(this.richTextBox.nativeElement, tag)
  }

}
