import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'voiply-more-numbers',
  templateUrl: './more-numbers.component.html',
  styleUrls: ['./more-numbers.component.css']
})
export class MoreNumbersComponent implements OnInit {

  @Output() updateNumbers = new EventEmitter();
  @Output() addToCompanyNumber = new EventEmitter();
  @Input() updatingOrder: boolean;
  @Input() coolDownPeriodOn: boolean;
  @Input() showAddtoCompanyNumber: boolean;
  @Input() set additionalNumbers(value: any) {
    if (value) {
      this.additionalNumberArray = Object.keys(value).join('\n');
    }
  }
  additionalNumberArray = '';

  constructor() { }
  ngOnInit() {
  }

  updatePortingNumber(numberString: string, addtoCompanyNumber: boolean) {
    if (numberString) {
      const numbers = numberString.split('\n');
      const changes: any = {};
      this.additionalNumberArray.split('\n').forEach(n => changes[n] = null);
      numbers.forEach(n => changes[n] = { type: 'PORTED_NUMBER' });
      this.updateNumbers.emit({ additionalNumbers: changes });
      if (addtoCompanyNumber) {
        this.addToCompanyNumber.emit(changes);
      }
    }
    else {
      this.updateNumbers.emit({ additionalNumbers: null });
    }
  }

}
