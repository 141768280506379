import {
  OnDestroyCleanup,
  AppStateSelectors,
  ShippingSameAsBillingAction,
  CrispService,
  ShippingAddressChangeAction,
  BillingAddressChangeAction} from '@voiply/shared/common-ui';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CheckoutDetail, SystemType, Address } from '@voiply/shared/model';
import * as _ from 'lodash';
import * as LogRocket from 'logrocket';
/// Common class for both business and home Header.
export class CheckoutAfterCheckoutBase extends OnDestroyCleanup {

  checkoutDetail: CheckoutDetail;
  billingAddress: Address;
  shippingAddress: Address;
  orderId: string;

  @Select(AppStateSelectors.checkoutDetails) checkoutDetail$: Observable<CheckoutDetail>;
  @Select(AppStateSelectors.orderId) orderId$: Observable<string>;
  @Select(AppStateSelectors.userIsVoiplyMember) userIsVoiplyMember$: Observable<boolean>;

  @Dispatch() onShippingSameAsBilling = (isTrue) => new ShippingSameAsBillingAction(isTrue);
  @Dispatch() onShippingAddressChange = (shippingAddress) => new ShippingAddressChangeAction(shippingAddress);
  @Dispatch() onBillingAddressChange = (billingAddress) => new BillingAddressChangeAction(billingAddress);

  constructor(protected crispService: CrispService, private systemType: SystemType) {
    super();

    this.subscriptions$.add(this.checkoutDetail$.subscribe((checkoutDetail) => {
      this.checkoutDetail = checkoutDetail;
      this.billingAddress = { ...checkoutDetail.billingAddress };
      this.shippingAddress = { ...checkoutDetail.shippingAddress };
    }));
    this.subscriptions$.add(this.orderId$.subscribe((orderId) => this.orderId = orderId));
  }

  onShippingSameAsBillingChanged(isTrue: boolean) {
    this.onShippingSameAsBilling(isTrue);
  }

  shippingAddressChange(shippingAddress: Address) {
    this.onShippingAddressChange(shippingAddress);
  }

  billingAddressChange(billingAddress: Address) {
    this.onBillingAddressChange(billingAddress);

    if (billingAddress.name && billingAddress.email && billingAddress.city && billingAddress.address &&
      billingAddress.zip && billingAddress.state) {
      this.crispService.setSessionEvent('checkout:address', billingAddress);
    }

    if (billingAddress.name) {
      this.crispService.CrispUserName = billingAddress.name;
      LogRocket.identify(this.orderId, { name: billingAddress.name });
    }

    if (billingAddress.email) {
      this.crispService.CrispUserEmail = billingAddress.email;
      LogRocket.identify(this.orderId, { email: billingAddress.email });
      this.crispService.setSessionEvent(this.systemType === SystemType.Business ? "checkout:email" : "checkout:email:residential", { email: billingAddress.email });
      LogRocket.track(this.systemType == SystemType.Business ? "checkout:email" : "checkout:email:residential");
    }

    if (billingAddress.phone) {
      this.crispService.CrispUserPhone = billingAddress.phone;
      LogRocket.identify(this.orderId, { phone: billingAddress.phone });
    }

    // Crisp event once we receive all billing information
    if (billingAddress.name && billingAddress.email && billingAddress.phone && billingAddress.address &&
      billingAddress.zip && billingAddress.state && billingAddress.city) {
      this.crispService.setSessionEvent("checkout:billing:completed", billingAddress);
      LogRocket.track("checkout:billing:completed");
    }
  }
}