
<div class="" id="header" [style.marginTop.px]="headerHeight">
<div class="my-3 card py-5 px-lg-4" style="border-radius: 0% !important;">
    <div class="card-header bg-white border-0">
        <!-- * title -->
        <div class="d-flex justify-content-between align-items-center">
            <div class="text-capitalize heading-font" id="survey-header">
                <span class="h1 font-weight-bolder d-none d-lg-block">Why Voiply for Your Business?</span>
                <span class="h2 font-weight-bolder d-block d-lg-none">Why Voiply for Your Business?</span>
            </div>
            <div class="d-flex align-items-center justify-content-end">
                <!-- * banner (when passing 'banner' query parameter) the appropriate image will show up -->
                <div class="pr-2 position-relative" *ngIf="selectedBanner.name">
                    <div>
                        <!-- class="position-absolute" style="top: -56px; right: 12px" -->
                        <img src="assets/images/banners/{{selectedBanner.name}}-desktop.png" alt="" class="d-none d-lg-block" style="height: 100px">
                        <img src="assets/images/banners/{{selectedBanner.name}}-mobile.png" alt="" class="d-block d-lg-none m-auto" style="height: 100px">
                    </div>
                </div>
                <!-- * rating stars -->
                <div class="d-flex flex-column align-items-end">
                    <div class="d-none d-lg-block" style="z-index:1;position:relative;left:+36px;">
                        <span *ngFor="let star of [1, 2, 3, 4]">
                          <i class="fas fa-star fa-2x" style="color: #F8C600"></i>
                        </span>
                        <span>
                          <i class="fas fa-star-half fa-2x" style="color: #F8C600;"></i>
                          <i class="fas fa-star-half fa-2x" style="color: #E2E2E2;-webkit-transform: scaleX(-1);transform: scaleX(-1);z-index:1;position:relative;left:-37px;"></i>
                        </span>
                    </div>
                    <div class="d-none" style="z-index:1;position:relative;left:+18px;">
                        <span *ngFor="let star of [1, 2, 3, 4]">
                          <i class="fas fa-star" style="color: #F8C600"></i>
                        </span>
                        <span>
                          <i class="fas fa-star-half" style="color: #F8C600"></i>
                          <i class="fas fa-star-half " style="color: #E2E2E2;-webkit-transform: scaleX(-1);transform: scaleX(-1);z-index:1;position:relative;left:-18px;"></i>
                        </span>
                    </div>

                    <div class="mt-1 text-right text-sm-center d-none d-lg-block">
                        Based on <span class="font-weight-bolder"> 1,928 Reviews</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- * body -->
    <div class="card-body">
        <!-- * description about voiply  -->
        <div class="d-flex flex-column-reverse flex-lg-row pb-3 align-items-center" style="border-bottom: 1px solid #ececec;">
            <div class="column-width">
                <div class="pr-lg-3 pt-3 pt-lg-0">
                    <div class="h5 py-3 px-3" style="line-height: 1.75rem;background-color: #f5f5f5;border-radius: 9px;">
                      At Voiply, we provide <span style="color: #F63B0B;">reliable, high quality phone service anywhere</span> you have internet access.
                    </div>
                    <!-- <div class="pt-1 mt-2" style="background-image: linear-gradient(to right, #e4e4e4, #f9f9f9);">
                    </div> -->
                    <div class="mt-3 h6" style="line-height: 1.5rem;">
                      Simplify your business communications with a single product that does 
                      what you need, when you need it. You can keep your business number 
                      and get all the great features you need: <span class="font-weight-bold">caller-ID, call waiting, ring groups, 
                      auto attendants, business hours, conference calling and more.</span> Average 
                      business with 3 phone lines save 
                        <span class=" font-weight-bold">$1328 per year.</span>
                    </div>
                    <!-- * voiply adapter package contents -->
                    
                </div>
            </div>
            <!-- * survey header video -->
            <div class="column-width">
                <div *ngIf="systemFeatures">
                    <!-- <div class="rounded-lg" style="overflow: hidden;">
                        <voiply-wistia-player [videoId]=systemFeatures?.backgroundVideoId></voiply-wistia-player>
                    </div> -->
                    <div style="padding:56.25% 0 0 0;position:relative;">
                        <iframe src="https://player.vimeo.com/video/567057679?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;portrait=0&amp;byline=0&amp;title=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="BusinessPhoneSystem"></iframe>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>
</div>
