import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'voiply-timezone',
  templateUrl: './timezone.component.html',
  styleUrls: ['./timezone.component.scss']
})
export class TimezoneComponent implements OnInit {
  @Input()
  timeZone = 'est';
  @Input() timeZoneHelpText;
  @Output() timeZoneChange = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }
  toggleTimeZone(timeZone: string) {
    this.timeZoneChange.emit(timeZone);
  }
}
