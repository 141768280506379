<div class="card">
  <div class="card-body">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <h5 class="text-secondary font-weight-bold m-0">
        <i class="fas fa-clock pr-3"></i>TIMEOUT OPTIONS
      </h5>
      <i class="fas fa-question-circle fa-lg" [popover]="timeoutOptionHelpText" placement="auto"
        containerClass="popover-css" [outsideClick]="true"></i>
    </div>
    <div class="border-bottom my-3"></div>
    <div>
      <div>
        <label class="radio-container mr-3 mb-0">
          <input type="radio" [ngModel]="timeoutOptions.timeoutEnable" [value]="true"
            (ngModelChange)="timeoutEnableChange($event)" />
          <span class="checkmark"></span>
          <span>Enable</span>
        </label>
        <label class="radio-container mb-0">
          <input type="radio" [ngModel]="timeoutOptions.timeoutEnable" [value]="false"
            (ngModelChange)="timeoutEnableChange($event)" />
          <span class="checkmark"></span>
          <span>Disable</span>
        </label>
      </div>
    </div>
    <div class="d-flex align-items-center mt-2 flex-wrap" *ngIf="timeoutOptions.timeoutEnable">
      <span class="mr-2 font-weight-bold">All calls will route to
        timeout
        after</span>
      <div class="d-flex justify-content-center align-items-center">
        <div class="select-input" style="min-width: 65px">
          <i class="fas fa-caret-down dropdown-img d-flex align-items-center"></i>
          <select class="select-custom font-weight-bold text-secondary form-control"
            [ngModel]="timeoutOptions.timeoutValue" (change)="timeoutValue($event.target.value)">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
            <option value="30">30</option>
            <option value="35" selected>35</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="60">60</option>
            <option value="90">90</option>
            <option value="120">120</option>
            <option value="150">150</option>

          </select>
        </div>
        <span class="ml-2 font-weight-bold">Seconds</span>
      </div>
    </div>
  </div>
</div>