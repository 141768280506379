import { Component, OnInit, Input } from '@angular/core';
import { NavigationBarBase } from '../navigation-bar.base';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'voiply-navigation-bar-business',
  templateUrl: './navigation-bar-business.component.html',
  styleUrls: ['./../navigation-bar.base.css'], 
  animations: [
    trigger('slideInOut', [
      state('0', style({
        height: '0px',
        opacity: 0,
        overflow: 'hidden'
      })),
      state('1', style({
        height: '*',
        opacity: 1,
        overflow: 'hidden'
      })),
      transition('0 => 1', animate('400ms')),
      transition('1 => 0', animate('400ms'))
    ]),
  ]
})
export class NavigationBarBusinessComponent extends NavigationBarBase {

  @Input() helpNumber: string;
  @Input() logo: string;

  constructor() {
    super();
  }
  toggleAccordian = '0';
  toggleAccordianShow() {
    this.toggleAccordian === '0' ? this.toggleAccordian = '1' : this.toggleAccordian = '0';
  }

  goToResidential() {
    window.location.href = "https://build.voiply.house";
  }

}

