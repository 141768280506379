import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Users } from '@voiply/shared/model';

@Component({
  selector: 'voiply-ringone-user',
  templateUrl: './ringone-user.component.html',
  styleUrls: ['./ringone-user.component.scss']
})
export class RingoneUserComponent implements OnInit {
  @Input() ringOneUser: string;
  @Input() users: Users[];
  @Input() ringOneUserHelpText: string;
  @Output() userSelectionChange = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }
  toggleUserSelectionRingOne(userId: string) {
    if (userId != this.ringOneUser) {
      this.ringOneUser = userId;
      this.userSelectionChange.emit(userId);
    }
  }
}
