<button class="btn bg-orange text-primary w-100 px-3 px-sm-4 py-3 h-100" (click)="showE911Modal();">
  <i class="fas fa-ambulance d-none d-md-block"></i>
  <h5 class="m-0 button-heading">E911</h5>
</button>


<!-- Modal E911 -->
<div class="modal fade bsModal" bsModal #e911Modal="bs-modal" tabindex="-1" role="dialog"
  aria-labelledby="dialog-static-name">
  <div class="modal-dialog" *ngIf="allowE911Modal">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Edit Registered E911 Location</h4>
      </div>
      <div class="modal-body"> {{f.errors | json}}
        <form [formGroup]="addressForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label>Name</label>
            <input type="text" formControlName="name" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
              <div *ngIf="f.name.errors.required">Name is required.</div>
            </div>
          </div>



          <div class="form-group">
            <label>Address Line 1</label>
            <input type="text" formControlName="addressLine1" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.addressLine1.errors }" />
            <div *ngIf="submitted && f.addressLine1.errors" class="invalid-feedback">
              <div *ngIf="f.addressLine1.errors.required">Address is required.</div>
            </div>
          </div>

          <div class="form-group">
            <label>Address Line 2</label>
            <input type="text" formControlName="addressLine2" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.addressLine2.errors }" />

          </div>

          <div class="form-group">
            <label>City</label>
            <input type="text" formControlName="city" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.city.errors }" />
            <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
              <div *ngIf="f.city.errors.required">City is required.</div>
            </div>
          </div>

          <div class="form-group">
            <label>State</label>
            <div class="select-input">
              <i class="fas fa-caret-down dropdown-img d-flex align-items-center"></i>
              <select class="select-custom form-control" formControlName="state"
                [ngClass]="{ 'is-invalid': submitted && f.state.errors }">
                <option value="" selected>Select State</option>
                <option *ngFor="let state of filteredStates" [value]="state.shortCode">{{state.name}}</option>
              </select>
            </div>
            <div *ngIf="submitted && f.state.errors" class="dropdown-error">
              <div *ngIf="f.state.errors.required">State is required.</div>
            </div>
          </div>

          <div class="form-group">
            <label>Country</label>
            <div class="select-input">
              <i class="fas fa-caret-down dropdown-img d-flex align-items-center"></i>
              <select class="select-custom form-control" formControlName="country"
                [ngClass]="{ 'is-invalid': submitted && f.country.errors }">
                <option selected value="">Select Country</option>
                <option *ngFor="let country of countries" [value]="country.countryShortCode">{{country.countryName}}

                  <!-- <option value="US">U.S.A.</option>
                <option value="CA">Canada</option> -->
              </select>
            </div>
            <div *ngIf="submitted && f.country.errors" class="dropdown-error">
              <div *ngIf="f.country.errors.required">Country is required.</div>
            </div>
          </div>

          <div class="form-group">
            <label>Zip</label>
            <input type="text" formControlName="zip" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.zip.errors }" />
            <div *ngIf="submitted && f.zip.errors" class="invalid-feedback">
              <div *ngIf="f.zip.errors.required">Zip is required.</div>
            </div>
          </div>

        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="hideE911Modal()">Cancel</button>
        <button type="button" class="btn bg-primary text-primary" (click)="onSubmit()">Verify Changes</button>
      </div>
    </div>
  </div>
</div>