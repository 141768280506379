import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { ThemePalette } from '@angular/material/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'voiply-call-forward',
  templateUrl: './call-forward.component.html',
  styleUrls: ['./call-forward.component.scss']
})
export class CallForwardComponent implements OnInit {
  color: ThemePalette = 'primary';
  selectable = true;
  removable = true;
  addOnBlur = true;
  visible = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  @Input() callForward = false;
  @Input() callForwardNumbers = [];
  @Input() callForwardHelpText = '';
  @Output() callForwardChange = new EventEmitter<boolean>();
  @Output() callForwardNumbersChange = new EventEmitter<any>();
  constructor(private toastr: ToastrService) { }

  ngOnInit() {
  }
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      const number = _.join(value.match(/\d+/g), '');
      if (number.length < 10) {
        this.toastr.error('Please enter a valid 10 digit number')
      } else {
        this.callForwardNumbers.push(number);
        this.callForwardNumbersChange.emit(this.callForwardNumbers);
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(number): void {
    const index = this.callForwardNumbers.indexOf(number);

    if (index >= 0) {
      this.callForwardNumbers.splice(index, 1);
    }
    this.callForwardNumbersChange.emit(this.callForwardNumbers);
  }

}
