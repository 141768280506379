<div class="p-3 d-flex border-bottom align-items-center">
    <h5 class="text-secondary font-weight-bold m-0">ASK QUESTIONS</h5>
</div>
<div class="modal-body">
    Question Text
    <div class="mt-3">
        <common-ui-richtextbox-input [tags]="tags" [value]="questionText" (change)="oninputChange($event)">
        </common-ui-richtextbox-input>
    </div>
    <div class="mt-3 d-flex flex-row align-items-end">
        <div class="mr-3">
            Type
            <div class="select-input">
                <i class="fas fa-caret-down  dropdown-img d-flex align-items-center"></i>
                <select class="select-custom  form-control" [(ngModel)]="selectedType">
                    <option value="text">Text</option>
                    <option value="date">Date</option>
                    <option value="phone">Phone</option>
                </select>
            </div>
        </div>
        <div class="mr-3">
            Action Allowed
            <div class="select-input">
                <i class="fas fa-caret-down  dropdown-img d-flex align-items-center"></i>
                <select class="select-custom  form-control" [(ngModel)]="actionsAllowed">
                    <option value="" selected>None</option>
                    <option value="multiselect">Multiple Choice</option>
                    <option value="select">choice</option>
                </select>
            </div>
        </div>
        <div class="mr-3" *ngIf="actionsAllowed">Answer
            <mat-chip-list #answerChipList aria-label="Email Selection" class="answer-chip-list">
                <mat-chip *ngFor="let answer of answers ;let i=index" [selectable]="removable" [removable]="removable"
                    (removed)="removeAnswer(answer)">
                    <div class="parallelogram-text"> {{answer}}</div>
                    <!-- <span class="text-truncate"></span> -->
                    <i class="fas fa-times-circle  d-flex align-items-center parallelogram-text" matChipRemove
                        *ngIf="removable"></i>
                </mat-chip>
                <input placeholder="Type Answers Here" [matChipInputFor]="answerChipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="addAnswer($event)">
            </mat-chip-list>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark px-4 py-2" data-dismiss="modal"
        (click)="bsModalRef.hide()">Cancel</button>
    <button (click)="onFollowUpSave();bsModalRef.hide()" class="btn bg-primary text-primary px-4 py-2">
        Save
    </button>
</div>