import { Component, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {  BsModalService } from 'ngx-bootstrap/modal';
import { OrderTrackingBase } from '../order-tracking.base';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'voiply-order-tracking',
  templateUrl: './order-tracking.component.html',
  styleUrls: ['./order-tracking.component.css']
})
export class OrderTrackingComponent extends OrderTrackingBase{
  constructor(_renderer2: Renderer2, @Inject(DOCUMENT) _document,  modalService: BsModalService,  domSanitize: DomSanitizer){
    super(_renderer2,_document,modalService,domSanitize)
  }
 
}
