import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { Select } from '@ngxs/store';
import { AppStateSelectors, OnDestroyCleanup, RemoveCompanyNumberAction } from '@voiply/shared/common-ui';
import { Observable, Subscription } from 'rxjs';
import { PrimaryNumberService } from '../../../services';
import { NewNumberBase } from '../new-number.base';
import * as Model from '@voiply/shared/model';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';


@Component({
  selector: 'voiply-new-number-business',
  templateUrl: './new-number-business-new.component.html',
  styleUrls: ['./new-number-business.component.css']
})
export class NewNumberBusinessComponent extends NewNumberBase implements OnInit, OnDestroy {

  @Output() changedPrimaryNumber: EventEmitter<any> = new EventEmitter<any>();


  @Output() numberSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() internationalNumberSelected: EventEmitter<any> = new EventEmitter<any>();

  @Input() isNew: boolean;
  @Input() canAddCompanyNumbers = false;
  @Input() tollFreeNumberNotAllowed = false;
  @Input() primaryPhoneInfo;

  companyNumbers: Model.CompanyNumber[] = [];
  phoneNumberInfo:Model.PrimaryPhone;
  local: boolean = true;
  internationalTabSelected = false;
  internationalNumber: any = {};
  _selectedPrimaryNumber;

  toggle:boolean = true;
  flag:boolean=false;

  constructor() {
    super();
    
   }

  ngOnInit() {
    if(this.isNew){
      this._selectedPrimaryNumber=this.primaryPhoneInfo.number;
    }
    else{
      this._selectedPrimaryNumber=null;
    }
  }

  ngOnDestroy(){

  }

  onClick(btnId){
    if(btnId=='1' && this.toggle==false){
      this.toggle=!this.toggle;
      return;
    }
    if(btnId=='2' && this.toggle==true){
      this.toggle=!this.toggle;
      return;
    }
  }

  setPrimaryNumber(){
      this.changedPrimaryNumber.emit(this.phoneNumberInfo);
  }

  checkData(info){
    this.phoneNumberInfo=info;
    this._selectedPrimaryNumber=info.number;
  }
}
