import { Component, Inject } from '@angular/core';
import { ProductBase } from '../product.base';
import { ToastrService } from 'ngx-toastr';
import { ENVIRONMENT, IEnvironment, CrispService, AudioService } from '@voiply/shared/common-ui';

@Component({
  selector: 'voiply-product-business',
  templateUrl: './product-business.component.html',
  styleUrls: ['./product-business.component.scss']
})
export class ProductBusinessComponent extends ProductBase {
  isCollapsed = true;
  constructor(@Inject(ENVIRONMENT) environment: IEnvironment, toastr: ToastrService, crispService: CrispService, audioService: AudioService) {
    super(environment, toastr, crispService, audioService);
  }
}
