<div class="row">
  <div class="col-12 col-md-6" style="padding-right:12px; padding-left:12px;">
    <div class="card">
      <div class="card-body">
        <div class="d-flex flex-row justify-content-between align-items-center">
          <h5 class="text-secondary font-weight-bold m-0"><img src="assets/images/auto-attendant.png" class="pr-3"
              style="width:33px;">AUTO ATTENDANT</h5>
          <i class="fas fa-question-circle fa-lg" *ngIf="callFlowHelpText.autoAttendant"
            [popover]="callFlowHelpText.autoAttendant" placement="auto" [outsideClick]="true"
            containerClass="popover-css"></i>
        </div>
        <div class="border-bottom my-3"></div>
        <div *ngFor="let key of ['-','1','2','3','4','5','6','7','8','9','0','*'];let i = index">
          <div class="d-flex flex-row flex-grow-1 justify-content-between align-items-center mt-3">
            <div class="px-3 py-2 d-flex flex-row flex-wrap flex-grow-1 border"
              [ngClass]="{ 'bg-primary-gradient':editKey == key}">
              <div>
                <div *ngIf="(callFlow.autoAttendantOptions[key] == undefined)" class="d-flex flex-row flex-grow-1">
                  <div class="mr-2 d-flex flex-row align-items-center justify-content-center font-weight-bold "
                    style="width:24px;"
                    [ngClass]="{'bg-white text-secondary':(callFlow.autoAttendantOptions[key] == undefined && editKey == key),'bg-light text-primary':(callFlow.autoAttendantOptions[key] == undefined  && editKey !=key)}">
                    {{key}}
                  </div>
                  <span
                    [ngClass]="{'text-primary':(callFlow.autoAttendantOptions[key] == undefined && editKey == key),'text-color':(callFlow.autoAttendantOptions[key] == undefined  && editKey !=key)}">Not
                    Set</span>
                </div>
                <div *ngIf="(callFlow.autoAttendantOptions[key] != undefined)" class="d-flex flex-row flex-grow-1">
                  <div class="mr-2 d-flex flex-row align-items-center justify-content-center font-weight-bold "
                    style="width:24px;"
                    [ngClass]="{'bg-white text-secondary':(callFlow.autoAttendantOptions[key] != undefined && editKey == key),'bg-primary text-primary':(callFlow.autoAttendantOptions[key] != undefined  && editKey !=key)}">
                    {{key}}
                  </div>
                  <span class=""
                    [ngClass]="{'text-primary':(callFlow.autoAttendantOptions[key] != undefined && editKey == key),'text-color':(callFlow.autoAttendantOptions[key] != undefined  && editKey !=key)}">{{callFlow.autoAttendantOptions[key].type}}</span>
                </div>
              </div>
            </div>
            <div class="pl-4" *ngIf="(callFlow.autoAttendantOptions[key] != undefined)">
              <div class="text-primary rounded-circle d-flex align-items-center" style="width: 21px;"
                [ngClass]="{'bg-primary-gradient':(callFlow.autoAttendantOptions[key] != undefined && editKey == key),' bg-black':(callFlow.autoAttendantOptions[key] != undefined  && editKey !=key)}">
                <i class="fas fa-pencil-alt" (click)="toggleEdit(key)" style="font-size: 11px; padding:5px;"></i>
              </div>
            </div>
            <div class="pl-4" *ngIf="(callFlow.autoAttendantOptions[key] == undefined)">
              <div class="text-primary rounded-circle d-flex align-items-center" style="width: 21px;"
                [ngClass]="{'bg-primary-gradient':(callFlow.autoAttendantOptions[key] == undefined && editKey == key),' bg-light':(callFlow.autoAttendantOptions[key] == undefined  && editKey !=key)}">
                <i class="fas fa-pencil-alt" (click)="toggleEdit(key)" style="font-size: 11px; padding:5px;"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 pt-4 pt-md-0 d-flex flex-column" style="padding-left:12px; padding-right:12px;">
    <ng-container *ngIf="_rerenderAutoAttendant">
      <voiply-auto-attendant [users]="users" class="d-flex flex-column h-100" [callFlow]="callFlow" [apps]="apps"
        [callFlowHelpText]="callFlowHelpText" [editKey]="editKey" (configurationChanged)="updateConfiguration($event)">
      </voiply-auto-attendant>
      <voiply-auto-attendant [users]="users" class="d-flex flex-column h-100 mt-4" [callFlow]="callFlow" [apps]="apps"
        *ngIf="(this.callFlow.autoAttendantOptions['-'].timeoutOptions.timeoutEnable&&editKey==='-')"
        [callFlowHelpText]="callFlowHelpText" editKey="+" (configurationChanged)="updateConfiguration($event)">
      </voiply-auto-attendant>
    </ng-container>
  </div>
</div>


<!-- task:1001-humanattendant-start Outside Business Hours Section -->
<!-- <div>
  <div class="row mt-4">
    <div class="col-12 " style="padding-right:12px; padding-left:12px;">
      <div class="card h-100">
        <div class="card-body">
          <div class="d-flex flex-row justify-content-between align-items-center flex-wrap flex-md-nowrap">
            <div class="order-1">
              <h5 class="text-secondary font-weight-bold m-0 text-nowrap "><i class="fas fa-clock pr-3"></i>
                BUSINESS HOURS </h5>
            </div>
            <div class=" mx-md-3 mt-3 mt-md-0  order-md-3 order-4">
              <div class=" btn-group btn-group-toggle w-100" data-toggle="buttons">
                <button class="btn px-4" style="border-radius: 100px 0 0 100px; font-size: 12px;"
                  [ngClass]="{'bg-success-gradient text-primary': this.callFlow.autoAttendantRingCallFlow.opens24_7 ,'btn-outline-secondary': !this.callFlow.autoAttendantRingCallFlow.opens24_7}"
                  (click)="onOpen24_7Change(true)">
                  <div class="d-flex align-items-center justify-content-center h-100 font-weight-bold">
                    <i class="fas fa-clock pr-1"></i>
                    <input type="radio" name="planOption" autocomplete="off"
                      [checked]="this.callFlow.autoAttendantRingCallFlow.opens24_7">
                    <span class="pl-1 text-nowrap">OPEN 24/7</span>
                  </div>
                </button>
                <button class="btn px-4" style="border-radius: 0 100px 100px 0; font-size: 12px;"
                  [ngClass]="{'bg-primary-gradient text-primary': !this.callFlow.autoAttendantRingCallFlow.opens24_7 ,'btn-outline-secondary': this.callFlow.autoAttendantRingCallFlow.opens24_7}"
                  (click)="onOpen24_7Change(false)">
                  <div class="d-flex align-items-center justify-content-center font-weight-bold">
                    <input type="radio" name="planOption" autocomplete="off"
                      [checked]="!this.callFlow.autoAttendantRingCallFlow.opens24_7">
                    <span class="pl-1"><span>SCHEDULE</span></span>
                    <i class="fas fa-cog pl-1"></i>
                  </div>
                </button>
              </div>
            </div>
            <div class="select-input mt-3 mt-md-0  order-md-2 order-3 mx-md-3" style="max-width: 320px;">
              <i class="fas fa-caret-down  dropdown-img d-flex align-items-center"></i>
              <select class="select-custom form-control text-secondary font-weight-bold"
                (change)="toggleTimeZone($event.target.value)"
                [ngModel]="this.callFlow.autoAttendantRingCallFlow.timeZone">
                <option value="est">EST</option>
                <option value="mst">MST</option>
                <option value="cst">CST</option>
                <option value="pst">PST</option>
                <option value="hst">HST</option>
              </select>
            </div>
            <i class="fas fa-question-circle fa-lg order-2 order-md-4 " *ngIf="callFlowHelpText.businessHours"
              [popover]="callFlowHelpText.businessHours" placement="auto" containerClass="popover-css"
              [outsideClick]="true"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4" *ngIf="!this.callFlow.autoAttendantRingCallFlow.opens24_7">
    <div class="col-12 col-md-6" style="padding-right:12px; padding-left:12px;">
      <div class="card">
        <div class="card-body">
          <div class="d-flex flex-row flex-wrap">
            <div class="pr-0 pr-sm-2 pr-md-0 pr-xl-2 pb-xl-0 pb-2 flex-fill">
              <div class="select-input">
                <i class="fas fa-caret-down  dropdown-img d-flex align-items-center text-color"></i>
                <select class="select-custom text-color form-control" [(ngModel)]="selectedDay">
                  <option [ngValue]="0">Sunday</option>
                  <option [ngValue]="1" selected>Monday</option>
                  <option [ngValue]="2">Tuesday</option>
                  <option [ngValue]="3">Wednesday</option>
                  <option [ngValue]="4">Thursday</option>
                  <option [ngValue]="5">Friday</option>
                  <option [ngValue]="6">Saturday</option>
                </select>
              </div>
            </div>
            <div class="d-flex flex-row">
              <div class="d-flex flex-row flex-wrap pr-2 pb-2 pb-md-0" style="position: relative">
                <div class="pr-1">
                  <div class="select-input">
                    <i class="fas fa-caret-down  dropdown-img d-flex align-items-center text-primary"></i>
                    <select class="select-custom form-control bg-primary text-primary select-custom-edit"
                      [(ngModel)]="selectedFromTime">
                      <option value="00:00:00">12:00 AM</option>
                      <option value="00:30:00">12:30 AM</option>
                      <option value="01:00:00">1:00 AM</option>
                      <option value="01:30:00">1:30 AM</option>
                      <option value="02:00:00">2:00 AM</option>
                      <option value="02:30:00">2:30 AM</option>
                      <option value="03:00:00">3:00 AM</option>
                      <option value="03:30:00">3:30 AM</option>
                      <option value="04:00:00">4:00 AM</option>
                      <option value="04:30:00">4:30 AM</option>
                      <option value="05:00:00">5:00 AM</option>
                      <option value="05:30:00">5:30 AM</option>
                      <option value="06:00:00">6:00 AM</option>
                      <option value="06:30:00">6:30 AM</option>
                      <option value="07:00:00">7:00 AM</option>
                      <option value="07:30:00">7:30 AM</option>
                      <option value="08:00:00">8:00 AM</option>
                      <option value="08:30:00">8:30 AM</option>
                      <option value="09:00:00">9:00 AM</option>
                      <option value="09:30:00">9:30 AM</option>
                      <option value="10:00:00">10:00 AM</option>
                      <option value="10:30:00">10:30 AM</option>
                      <option value="11:00:00">11:00 AM</option>
                      <option value="11:30:00">11:30 AM</option>
                      <option value="12:00:00">12:00 PM</option>
                      <option value="12:30:00">12:30 PM</option>
                      <option value="13:00:00">1:00 PM</option>
                      <option value="13:30:00">1:30 PM</option>
                      <option value="14:00:00">2:00 PM</option>
                      <option value="14:30:00">2:30 PM</option>
                      <option value="15:00:00">3:00 PM</option>
                      <option value="15:30:00">3:30 PM</option>
                      <option value="16:00:00">4:00 PM</option>
                      <option value="16:30:00">4:30 PM</option>
                      <option value="17:00:00">5:00 PM</option>
                      <option value="17:30:00">5:30 PM</option>
                      <option value="18:00:00">6:00 PM</option>
                      <option value="18:30:00">6:30 PM</option>
                      <option value="19:00:00">7:00 PM</option>
                      <option value="19:30:00">7:30 PM</option>
                      <option value="20:00:00">8:00 PM</option>
                      <option value="20:30:00">8:30 PM</option>
                      <option value="21:00:00">9:00 PM</option>
                      <option value="21:30:00">9:30 PM</option>
                      <option value="22:00:00">10:00 PM</option>
                      <option value="22:30:00">10:30 PM</option>
                      <option value="23:00:00">11:00 PM</option>
                      <option value="23:30:00">11:30 PM</option>
                    </select>
                  </div>
                </div>
                <i class="far fa-caret-circle-right bg-primary text-primary right-caret-circle d-none d-sm-block"></i>
                <div class="">
                  <div class="select-input">
                    <i class="fas fa-caret-down  dropdown-img d-flex align-items-center text-primary"></i>
                    <select class="select-custom form-control bg-primary text-primary select-custom-edit"
                      [(ngModel)]="selectedToTime">
                      <option value="00:00:00">12:00 AM</option>
                      <option value="00:30:00">12:30 AM</option>
                      <option value="01:00:00">1:00 AM</option>
                      <option value="01:30:00">1:30 AM</option>
                      <option value="02:00:00">2:00 AM</option>
                      <option value="02:30:00">2:30 AM</option>
                      <option value="03:00:00">3:00 AM</option>
                      <option value="03:30:00">3:30 AM</option>
                      <option value="04:00:00">4:00 AM</option>
                      <option value="04:30:00">4:30 AM</option>
                      <option value="05:00:00">5:00 AM</option>
                      <option value="05:30:00">5:30 AM</option>
                      <option value="06:00:00">6:00 AM</option>
                      <option value="06:30:00">6:30 AM</option>
                      <option value="07:00:00">7:00 AM</option>
                      <option value="07:30:00">7:30 AM</option>
                      <option value="08:00:00">8:00 AM</option>
                      <option value="08:30:00">8:30 AM</option>
                      <option value="09:00:00">9:00 AM</option>
                      <option value="09:30:00">9:30 AM</option>
                      <option value="10:00:00">10:00 AM</option>
                      <option value="10:30:00">10:30 AM</option>
                      <option value="11:00:00">11:00 AM</option>
                      <option value="11:30:00">11:30 AM</option>
                      <option value="12:00:00">12:00 PM</option>
                      <option value="12:30:00">12:30 PM</option>
                      <option value="13:00:00">1:00 PM</option>
                      <option value="13:30:00">1:30 PM</option>
                      <option value="14:00:00">2:00 PM</option>
                      <option value="14:30:00">2:30 PM</option>
                      <option value="15:00:00">3:00 PM</option>
                      <option value="15:30:00">3:30 PM</option>
                      <option value="16:00:00">4:00 PM</option>
                      <option value="16:30:00">4:30 PM</option>
                      <option value="17:00:00">5:00 PM</option>
                      <option value="17:30:00">5:30 PM</option>
                      <option value="18:00:00">6:00 PM</option>
                      <option value="18:30:00">6:30 PM</option>
                      <option value="19:00:00">7:00 PM</option>
                      <option value="19:30:00">7:30 PM</option>
                      <option value="20:00:00">8:00 PM</option>
                      <option value="20:30:00">8:30 PM</option>
                      <option value="21:00:00">9:00 PM</option>
                      <option value="21:30:00">9:30 PM</option>
                      <option value="22:00:00">10:00 PM</option>
                      <option value="22:30:00">10:30 PM</option>
                      <option value="23:00:00">11:00 PM</option>
                      <option value="23:30:00">11:30 PM</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="flex-fill">
                <button type="button" class="btn rounded-0 shadow bg-black text-primary" (click)="addBusinessHour()"><i
                    class="fas fa-plus "></i></button>
              </div>
            </div>
          </div>
          <div class="border-bottom mt-3"></div>
          <div class="detail-form-bottoms">
           
            <div *ngFor="let key of [1,2,3,4,5,6,0]">

              <div class="d-flex flex-row">
                <ng-container
                  *ngIf="!isEmptyObject(this.callFlow.autoAttendantRingCallFlow.businessHours);else other_content">
                  <ng-container
                    *ngIf="this.callFlow.autoAttendantRingCallFlow.businessHours.hasOwnProperty(key) && this.callFlow.autoAttendantRingCallFlow.businessHours[key] !=null ;else other_content">
                    <div class="d-flex flex-row flex-grow-1 justify-content-between align-items-center mt-3">
                      <div
                        class="px-3 py-2 text-primary bg-primary-gradient d-flex flex-row flex-wrap flex-grow-1 border">
                        <div class="flex-grow-1 pr-3">{{DAY_OF_WEEK[key]}}</div>
                        <div class="d-flex flex-row">
                          <div class="pr-3"><i class="far fa-clock"></i></div>
                          <div>
                            {{this.callFlow.autoAttendantRingCallFlow.businessHours[key].fromHour | formatTimeTo12Hour}}
                            -
                            {{this.callFlow.autoAttendantRingCallFlow.businessHours[key].toHour | formatTimeTo12Hour}}
                          </div>
                        </div>
                      </div>
                      <div id="remove-business-hour" (click)="deleteBusinessHour(key)" class="pl-3"><i
                          class="fas fa-times-circle fa-lg"></i>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-template #other_content>
                  <div class="d-flex flex-row flex-grow-1 justify-content-between align-items-center mt-3">
                    <div class="px-3 py-2 text-primary d-flex flex-row flex-wrap flex-grow-1 border">
                      <div class="flex-grow-1 pr-3 text-color">{{DAY_OF_WEEK[key]}}</div>
                      <div class="d-flex flex-row">
                        <div><span class="text-color">NOT SET</span></div>
                      </div>
                    </div>
                    <div id="remove-business-hour" class="pl-3"><i class="fas fa-times-circle fa-lg"
                        style="color:rgba(0, 0, 0, 0.125);"></i>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 pt-4 pt-md-0" style="padding-right:12px; padding-left:12px;">
      <voiply-outside-business-hours [callFlowHelpText]="callFlowHelpText" [users]="users"
        [outsideBusinessHours]="this.callFlow.autoAttendantRingCallFlow.outsideBusinessHours" [apps]="apps"
        (outsideBusinessHoursChanged)="onOutsideBusinessHoursChanged($event)">
      </voiply-outside-business-hours>
    </div>
  </div>
</div> -->
<!-- task:1001-humanattendant-end Outside Business Hours Section -->