import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { Users, AutoAttendantType, RingType, CallFlow, Gender, AutoAttendantOption, AudioRecording, CallFlowsHelpText, App } from '@voiply/shared/model';
import * as _ from 'lodash';
import { VoicemailFormControlComponent, FormBase, AudioService } from '@voiply/shared/common-ui';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'voiply-auto-attendant',
  templateUrl: './auto-attendant.component.html',
  styleUrls: ['./auto-attendant.component.css']
})
export class AutoAttendantComponent extends FormBase implements OnInit, OnChanges {

  @ViewChild('voiceMailEmail') voiceMailEmail: any;
  @Input() users: Users[];
  @Input() callFlow: CallFlow;
  @Input() callFlowHelpText: CallFlowsHelpText;
  @Input() editKey: string;
  @Input() apps: App[] = [];
  @Output() configurationChanged = new EventEmitter<any>();

  AUTO_ATTENDANT_TYPE: typeof AutoAttendantType = AutoAttendantType;
  RING_TYPE: typeof RingType = RingType;
  selectedAutoAttendantOption: AutoAttendantOption = new AutoAttendantOption();
  audioTemplates: any[] = [];
  _callScenarioVoice: { showRecording: boolean; selectedGender: any; audioText: any; sendVoiceMailTo: any; blobFileName: any; fileName: any; uploadRecording: any; };


  ngOnChanges(changes: SimpleChanges): void {


    if (changes.callFlow) {
      this._callScenarioVoice = {
        showRecording: false,
        selectedGender: ((this.callFlow.autoAttendantOptions[this.editKey] || {}).recording || {}).selectedGender || Gender.Male,
        audioText: ((this.callFlow.autoAttendantOptions[this.editKey] || {}).recording || {}).audioText || '',
        sendVoiceMailTo: ((this.callFlow.autoAttendantOptions[this.editKey] || {}).recording || {}).sendVoiceMailTo || '',
        blobFileName: ((this.callFlow.autoAttendantOptions[this.editKey] || {}).recording || {}).blobFileName || '',
        fileName: ((this.callFlow.autoAttendantOptions[this.editKey] || {}).recording || {}).fileName || '',
        uploadRecording: ((this.callFlow.autoAttendantOptions[this.editKey] || {}).recording || {}).uploadRecording || ''
      };

      this.form.patchValue({ callScenarioVoice: this._callScenarioVoice })
    }
    if (changes.editKey) {
      if (this.editKey === '-' && (this.callFlow.autoAttendantOptions[this.editKey].timeoutOptions === undefined || this.callFlow.autoAttendantOptions[this.editKey].timeoutOptions === null)) {
        this.callFlow.autoAttendantOptions[this.editKey].timeoutOptions = {}
        this.callFlow.autoAttendantOptions[this.editKey].timeoutOptions.timeoutEnable = false;
        this.callFlow.autoAttendantOptions[this.editKey].timeoutOptions.timeoutValue = '35';
      }
      if (this.callFlow.autoAttendantOptions[this.editKey])
        this.selectedAutoAttendantOption = JSON.parse(JSON.stringify(this.callFlow.autoAttendantOptions[this.editKey]));
      else {
        if (this.editKey === '+') {
          this.selectedAutoAttendantOption = new AutoAttendantOption(AutoAttendantType.RingOne);
          this.selectedAutoAttendantOption.ringOneUser = this.users[0].id;
        }
        else
          this.selectedAutoAttendantOption = new AutoAttendantOption(AutoAttendantType.NoConfiguration);
      }
      if (this.selectedAutoAttendantOption.recording === undefined)
        this.selectedAutoAttendantOption.recording = new AudioRecording();

      // if (this.recordingManager)
      //   this.recordingManager.changeAudioTemplate(this.selectedAutoAttendantOption.type);

      //save in state
      if (this.selectedAutoAttendantOption.type !== AutoAttendantType.NoConfiguration)
        this.saveSelectedAutoAttendantOption(this.selectedAutoAttendantOption);
      if (this.callFlow.autoAttendantOptions[this.editKey]) {
        if (this.callFlow.autoAttendantOptions[this.editKey].type === AutoAttendantType.RingGroup && this.users.length === 1) {
          this.callFlow.autoAttendantOptions[this.editKey].users = {}
          this.callFlow.autoAttendantOptions[this.editKey].users[this.users[0].id] = true
        } else if (this.callFlow.autoAttendantOptions[this.editKey].type === AutoAttendantType.RingOne && this.users.length === 1) {
          this.callFlow.autoAttendantOptions[this.editKey].ringOneUser = this.users[0].id;
        }
      }
    }
    if (changes.users) {

      if (this.callFlow.autoAttendantOptions[this.editKey]) {
        if (this.callFlow.autoAttendantOptions[this.editKey].type === AutoAttendantType.RingGroup && changes.users.currentValue.length === 1) {
          this.callFlow.autoAttendantOptions[this.editKey].users = {}
          this.callFlow.autoAttendantOptions[this.editKey].users[changes.users.currentValue[0].id] = true
        } else if (this.callFlow.autoAttendantOptions[this.editKey].type === AutoAttendantType.RingOne && changes.users.currentValue.length === 1) {
          this.callFlow.autoAttendantOptions[this.editKey].ringOneUser = changes.users.currentValue[0].id;
        }
      }
    }

  }

  constructor(private service: AudioService) {

    super();


    this.form = new FormGroup({
      callScenarioVoice: new FormControl(''),
      voicemailAttachment: new FormControl('')
    });


    // this.initializeForm();

    this.subscriptions$.add(this.form.valueChanges.subscribe(changes => {
      if (this._callScenarioVoice != changes.callScenarioVoice) {
        // this.callFlow.autoAttendantOptions[this.editKey].recording.showRecording = changes.callScenarioVoice.showRecording
        this.callFlow.autoAttendantOptions[this.editKey].recording.audioText = changes.callScenarioVoice.audioText;
        this.callFlow.autoAttendantOptions[this.editKey].recording.blobFileName = changes.callScenarioVoice.blobFileName
        this.callFlow.autoAttendantOptions[this.editKey].recording.fileName = changes.callScenarioVoice.fileName
        this.callFlow.autoAttendantOptions[this.editKey].recording.selectedGender = changes.callScenarioVoice.selectedGender
        // this.callFlow.autoAttendantOptions[this.editKey].recording.sendVoiceMailTo = changes.callScenarioVoice.sendVoiceMailTo
        this.callFlow.autoAttendantOptions[this.editKey].recording.uploadRecording = changes.callScenarioVoice.uploadRecording
        this.updateConfiguration();
      }
    }));

  }

  public formStatusChanged(isValid: boolean) {
  }

  ngOnInit() {

    if (this.callFlow.autoAttendantOptions[this.editKey]) {
      this.form.controls['voicemailAttachment'].setValue({
        attachVoicemail: this.callFlow.autoAttendantOptions[this.editKey].attachVoicemail || true,
        ccEmail: this.callFlow.autoAttendantOptions[this.editKey].ccEmail || []
      })
      this.form.controls['voicemailAttachment'].valueChanges.subscribe((changes) => {
        this.callFlow.autoAttendantOptions[this.editKey].ccEmail = changes.ccEmail
        this.callFlow.autoAttendantOptions[this.editKey].attachVoicemail = changes.attachVoicemail
        this.updateConfiguration();
      })
    } else {
      this.form.controls['voicemailAttachment'].setValue({
        attachVoicemail: true,
        ccEmail: []
      });
    }

    this.service.getAudioTemplates().subscribe((value) => {
      this.audioTemplates = value;
    });
  }
  getTitle(key: string) {
    if (this.callFlow.autoAttendantOptions[key] === undefined ||
      this.callFlow.autoAttendantOptions[key] == null) {
      return "CONFIGURE EXTENSION";
    }
    else
      return `CONFIGURE ${this.callFlow.autoAttendantOptions[key].type.toUpperCase()}`;
  }

  getHelpText(key: string) {
    if (this.callFlow.autoAttendantOptions[key] === undefined ||
      this.callFlow.autoAttendantOptions[key] == null) {
      return this.callFlowHelpText.configureExtension;
    }
    else if (this.callFlow.autoAttendantOptions[key].type === this.AUTO_ATTENDANT_TYPE.Announcements)
      return this.callFlowHelpText.announcements;
    else if (this.callFlow.autoAttendantOptions[key].type === this.AUTO_ATTENDANT_TYPE.Greeting)
      return this.callFlowHelpText.greeting;
    else if (this.callFlow.autoAttendantOptions[key].type === this.AUTO_ATTENDANT_TYPE.RingGroup)
      return this.callFlowHelpText.configureRingGroup;
    else if (this.callFlow.autoAttendantOptions[key].type === this.AUTO_ATTENDANT_TYPE.RingOne)
      return this.callFlowHelpText.configureRingOne;
    else if (this.callFlow.autoAttendantOptions[key].type === this.AUTO_ATTENDANT_TYPE.VoiceMail)
      return this.callFlowHelpText.voicemail;


  }

  onFlowChange() {
    // debugger;
    const listOfTypesHavingRecordingOption = [AutoAttendantType.Announcements, AutoAttendantType.Greeting, AutoAttendantType.VoiceMail];
    if (_.some(listOfTypesHavingRecordingOption, (option) => this.selectedAutoAttendantOption.type === option)) {

      if (!this.selectedAutoAttendantOption.recording)
        this.selectedAutoAttendantOption.recording = new AudioRecording();

      // If there is no audio text, then insert some
      // if (!this.selectedAutoAttendantOption.recording.uploadRecording && this.selectedAutoAttendantOption.recording.audioText === '') { //commented this as we always want to replace audio text on flow change.

      const templates = _.filter(this.audioTemplates,
        (item) => _.includes(item.category.toLowerCase().split(","), this.selectedAutoAttendantOption.type.toLowerCase()));

      if (templates.length > 0)
        this.selectedAutoAttendantOption.recording.audioText = templates[0].text;
      // }
    }
    const changes: any = this.callFlow.autoAttendantOptions;
    changes[this.editKey] = {};
    changes[this.editKey] = this.selectedAutoAttendantOption;
    changes[this.editKey].voiceMailTo = null;
    changes[this.editKey].ringType = null;
    changes[this.editKey].voicemailRingTimeoutCount = null;
    changes[this.editKey].voiceMailUser = null;
    if (changes[this.editKey].type == AutoAttendantType.RingOne) {
      changes[this.editKey].recording = null;
      changes[this.editKey].users = null;
      if (this.users.length > 0)
        changes[this.editKey].ringOneUser = this.users[0].id;
    }
    if (changes[this.editKey].type == AutoAttendantType.RingGroup) {
      changes[this.editKey].recording = null;
      changes[this.editKey].ringType = this.RING_TYPE.Simultaneous;
      changes[this.editKey].users = {};
      if (this.users.length > 0) {
        changes[this.editKey].voiceMailUser = this.users[0].id;
        changes[this.editKey].users[this.users[0].id] = true;
      }
      changes[this.editKey].voicemailRingTimeoutCount = 4;
      changes[this.editKey].ringOneUser = null;
    }
    else if (changes[this.editKey].type == AutoAttendantType.Announcements)
      changes[this.editKey].users = null;
    else if (changes[this.editKey].type == AutoAttendantType.NoConfiguration)
      changes[this.editKey] = null;
    else if (changes[this.editKey].type == AutoAttendantType.App) {
      changes[this.editKey] = {
        type: this.selectedAutoAttendantOption.type,
        app: this.apps[0].title
      }
    }

    //save in state
    this.saveAutoAttendantOption(changes);
  }
  saveAutoAttendantOption(autoAttendantOption) {
    this.callFlow.autoAttendantOptions = autoAttendantOption;
    this.updateConfiguration();
  }
  saveSelectedAutoAttendantOption(selectedAutoAttendantOption) {
    // debugger;
    this.callFlow.autoAttendantOptions[this.editKey] = selectedAutoAttendantOption;
    this.updateConfiguration();
  }

  updateConfiguration() {
    this.configurationChanged.emit(this.callFlow);
  }

  changeRingTypeSelection(ringType) {
    this.selectedAutoAttendantOption.ringType = ringType;
    //On change of ringtype object was overriding instead of just saving the ringtype hence commented below line
    // this.callFlow.autoAttendantOptions[this.editKey] = { type: AutoAttendantType.RingGroup, ringType: ringType };
    this.callFlow.autoAttendantOptions[this.editKey].ringType = ringType
    this.updateConfiguration();
  }

  isUserSelectedRingOne(userId: string, index: number): boolean {
    if (this.callFlow.autoAttendantOptions == undefined || this.selectedAutoAttendantOption == undefined || this.isEmptyObject(this.selectedAutoAttendantOption)
      || this.callFlow.autoAttendantOptions[this.editKey].ringOneUser == undefined)
      return index == 0;
    else {
      return this.callFlow.autoAttendantOptions[this.editKey].ringOneUser == userId;
    }
  }

  isEmptyObject(obj) {
    if (obj == undefined || obj == null)
      return true;
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }
    return true;
  }

  timeoutOptionsChange(timeoutOptions) {
    this.callFlow.autoAttendantOptions[this.editKey].timeoutOptions = timeoutOptions;
    this.updateConfiguration();
  }
  toggleUserSelectionRingOne(userId: string) {
    this.callFlow.autoAttendantOptions[this.editKey] = { type: AutoAttendantType.RingOne, ringOneUser: userId, users: null };
    this.updateConfiguration();
  }

  toggleUserSelection(userId: string) {
    this.callFlow.autoAttendantOptions[this.editKey].type = AutoAttendantType.RingGroup
    if (this.callFlow.autoAttendantOptions[this.editKey].users == undefined) {
      this.callFlow.autoAttendantOptions[this.editKey].users = {};
      this.callFlow.autoAttendantOptions[this.editKey].users[userId] = true;
    }
    else if (!this.callFlow.autoAttendantOptions[this.editKey].users[userId])
      this.callFlow.autoAttendantOptions[this.editKey].users[userId] = true;
    else
      this.callFlow.autoAttendantOptions[this.editKey].users[userId] = null;

    this.updateConfiguration();
  }

  onVoiceMailUserChanged(userId) {
    this.callFlow.autoAttendantOptions[this.editKey].voiceMailUser = userId;
    this.updateConfiguration();
  }

  onvoicemailRingTimeoutCountChanged(ringCount) {
    this.callFlow.autoAttendantOptions[this.editKey].voicemailRingTimeoutCount = parseInt(ringCount);
    this.updateConfiguration();
  }

  voiceMailToEmailChanged(data) {
    if (!this.voiceMailEmail.errors) {
      this.callFlow.autoAttendantOptions[this.editKey].voiceMailTo = data.target.value;
      this.updateConfiguration();
    }
  }
  onAppSelectionChange(app: App) {
    this.callFlow.autoAttendantOptions[this.editKey].app = app;
    this.updateConfiguration();
  }

}
