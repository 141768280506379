import { Routes } from "@angular/router";
import { RootPageComponent } from './root-page/root-page.component';
import { AppInitializationGuard, AfterCheckoutResolver, ErrorPageComponent } from '@voiply/shared/common-ui';
import { CallBackComponent, AuthenticationGuard, LogoutComponent } from '@voiply/auth0';
import { AfterCheckoutPageComponent } from './after-checkout-page/after-checkout-page.component';
import { SystemType } from '@voiply/shared/model';

const routes: Routes = [
    { path: 'callback', component: CallBackComponent, data: { systemType: SystemType.Business } },
    { path: 'logout', component: LogoutComponent },
    { path: ':id/error', component: ErrorPageComponent },
    {
        path: ':id/aftercheckout', component: AfterCheckoutPageComponent, canActivate: [
            AuthenticationGuard
        ], resolve: {
            key: AfterCheckoutResolver
        }
    },
    { path: ':id', component: RootPageComponent, canActivate: [AppInitializationGuard], data: { systemType: SystemType.Business } },

    { path: '**', component: RootPageComponent, canActivate: [AppInitializationGuard], data: { systemType: SystemType.Business } },
];
export const AppRoutes = routes;