<!-- <div>
  <div class="d-flex py-2 align-items-center">
    <div>
      <h5 class="font-weight-bold my-2">Save your Quote</h5>
    </div>
    <div class="flex-grow-1 pl-4">
      <hr>
    </div>
  </div>

  <div class="d-flex pb-2 shadow">
    <div class="input-group">
      <input type="text" class="form-control" placeholder="(555) 555-5555" mask="(000) 000-0000"
        [(ngModel)]="mobileNumber">
      <div class="input-group-append">
        <button class="btn bg-primary text-primary font-weight-bold p-2 px-4 h-100" style="width:150px;"
          (click)="shareViaSMS()">Send SMS</button>
      </div>
    </div>
  </div>

  <div class="d-flex pb-2 shadow">
    <div class="input-group">
      <input type="text" class="form-control" placeholder="jamieappleseed@gmail.com" [(ngModel)]="email">
      <div class="input-group-append">
        <button class="btn bg-primary text-primary font-weight-bold p-2 px-4 h-100" (click)="shareViaEmail()"
          style="width:150px;">Send Email</button>
      </div>
    </div>
  </div>

  <div class="d-flex shadow">
    <div class="input-group">
      <input type="text" class="form-control" placeholder="Link Here" [value]="shareUrl" #sharableLink>
      <div class="input-group-append">
        <button class="btn bg-primary text-primary font-weight-bold p-2 px-4 h-100"
          (click)="copyInputMessage(sharableLink)" style="width:150px;">Copy Link</button>
      </div>
    </div>
  </div>

  <hr>

  <a class="btn bg-primary text-primary w-100 p-2 font-weight-bold text-center shadow"
    style="padding: 12px 0px!important;" [href]="printUrl.replace('{templateId}', 'BklW8YCarU')" target="_blank">
    Save Quote as PDF
  </a>
  <h6 class="text-secondary m-1 text-center">(quotes are valid for 30 days)</h6>
</div> -->

<div style="position: relative;">
  <i class="d-none d-lg-block fal fa-long-arrow-right section-arrow d-none d-lg-block" style="position: absolute; left: -18px; top: 26px; font-size: 60px; color:#D3D4D5;z-index:1;"></i>
</div>

<!-- MD or LG -->
<div class="d-none d-md-flex flex-column my-3 border p-4" style="background-color: white;">
  <div class="d-flex justify-content-between align-items-center m-2">
      <div>
          <strong><span class="quoteClass">Save Your Quote</span></strong>
      </div>

      <div class="">
          <a class="btn saveQuoteBtn" style="color: white;" role="button" aria-haspopup="true" aria-expanded="false"  [href]="printUrl.replace('{templateId}', 'BklW8YCarU')" target="_blank">
              Save Quote as PDF
            </a>
      </div>
      
  </div>

  <div class="d-flex flex-row flex-wrap justify-content-between">
      <div class="d-flex flex-row flex-fill m-2">
          <!-- <div class="d-flex flex-row justify-content-center dropdown shows flex-fill dataDiv" > -->
              <input [(ngModel)]="mobileNumber" class="dataInput align-self-center p-2 flex-fill" placeholder="(555)555-5555">
          <!-- </div> -->
          <div class="align-self-center d-flex flex-column btn pensilDiv">
              <button class="bg-transparent transbox flex-fill align-self-center d-flex buttonClass" (click)="shareViaSMS()">
                  Send SMS
              </button>
          </div>
      </div>

      <div class="d-flex flex-row flex-fill m-2">
          <!-- <div class="d-flex dropdown shows flex-fill justify-content-center dataDiv" > -->
              <input class="dataInput p-2 flex-fill" placeholder="jamieappleseed@gmail.com" [(ngModel)]="email">
          <!-- </div> -->
          <div class="align-self-center d-flex flex-column btn pensilDiv">
              <button class="bg-transparent transbox flex-fill align-self-center d-flex buttonClass" (click)="shareViaEmail()">
                  Send Email
              </button>
          </div>
      </div>
      
      <div class="d-flex flex-row flex-fill m-2">
          <!-- <div class="d-flex dropdown shows flex-fill justify-content-center dataDiv" > -->
              <input class="dataInput p-2 flex-fill" placeholder="Link Here" [value]="shareUrl" #sharableLink>
          <!-- </div> -->
          <div class="align-self-center d-flex flex-column btn pensilDiv">
              <button class="bg-transparent transbox flex-fill align-self-center d-flex buttonClass" (click)="copyInputMessage(sharableLink)">
                  Copy Link
              </button>
          </div>
      </div>
      
  </div>
</div>


<div class="d-flex  flex-column d-md-none my-3 border p-3" style="background-color: white;">
  <div class="d-flex justify-content-between mt-2">
      <div class="flex-fill align-self-center justify-content-center">
          <strong><h1 class="quoteClassSm">Save Your Quote</h1></strong>
      </div>

      <div>
          <a class="btn mr-2" style="background-color:#212121;color: white;" aria-haspopup="true" aria-expanded="false" [href]="printUrl.replace('{templateId}', 'BklW8YCarU')" target="_blank">
              <span class="p-1">Save as PDF</span>
            </a>
      </div>
      
  </div>

  <div class="d-flex flex-row justify-content-around flex-wrap">
      <div class="d-flex flex-row flex-fill m-2">
          <!-- <div class="d-flex flex-row pl-2 dropdown shows flex-fill dataDiv" > -->
              <input [(ngModel)]="mobileNumber" class="dataInput align-self-center flex-fill p-2" placeholder="(555)555-5555">
          <!-- </div> -->
          <div class="align-self-center d-flex flex-column btn pensilDiv">
              <button class="bg-transparent transbox flex-fill align-self-center d-flex buttonClass" (click)="shareViaSMS()">
                  Send SMS
              </button>
          </div>
      </div>

      <div class="d-flex flex-row flex-fill m-2">
          <!-- <div class="d-flex dropdown shows flex-fill pl-2 dataDiv" > -->
              <input class="dataInput flex-fill" placeholder="jamieappleseed@gmail.com" [(ngModel)]="email">
          <!-- </div> -->
          <div class="align-self-center d-flex flex-column btn pensilDiv">
              <button class="bg-transparent transbox flex-fill align-self-center d-flex buttonClass" (click)="shareViaEmail()">
                  Send Email
              </button>
          </div>
      </div>
      <div class="d-flex flex-row flex-fill m-2">
          <!-- <div class="d-flex dropdown shows flex-fill dataDiv pl-2 " > -->
              <input class="dataInput flex-fill" placeholder="Link Here" [value]="shareUrl" #sharableLink>
          <!-- </div> -->
          <div class="align-self-center d-flex flex-column btn pensilDiv">
              <button class="bg-transparent transbox flex-fill align-self-center d-flex buttonClass" (click)="copyInputMessage(sharableLink)">
                  Copy Link
              </button>
          </div>
      </div>
      
  </div>
</div>
