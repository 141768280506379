<div class="container bg-white wizardScroll">
    <div class="p-md-2 p-1">
        <h2 class="font-weight-bolder">Configure Your Voiply Phone System</h2>
    </div>
    <div class="d-lg-flex p-md-2 p-1">
        <!-- Horizontal Progressbar and Steps -->
        <div id="disp1" class="p-1">
            <div class="row p-1">
                <a *ngFor='let i of [1,2,3,4]' (click)="moveOnDifferentStep(i)" style="cursor: pointer;"
                    class="col-3 px-md-3 px-1 horizontal-step-div-height">
                    <div
                        class="horizontal-step-div px-md-2 py-md-1 p-1 d-flex justify-content-between flex-column h-100">
                        <h6 class="text-md-right text-center mb-0 not-active-steps-color">Step {{i}}</h6>
                        <div class="d-flex justify-content-md-start justify-content-center">
                            <img [src]="imgCurrent[i-1]" alt="img" class="progressimages ">
                        </div>
                        <h5 class="font-reduce mb-1 mb-md-2 font-weight-bolder"
                            [ngClass]="{'text-black':(activeStep==2||activeStep==3||activeStep==4),'not-active-steps-color':(activeStep==1)}">
                            {{stepText[i]}}</h5>
                    </div>
                </a>
            </div>
            <div class="mt-3 mb-2">
                <div class="horizontal-pgbar-container">
                    <div class="horizontal-pgbar"
                        [style.right]="(activeStep==1)?'100%':(activeStep==2)?'64%':(activeStep==3)?'39%':(activeStep==4)?'0%':'100%' ">
                    </div>
                    <div class="horizontal-pgbar-div1"
                        [ngClass]="{'active':(activeStep===2||activeStep===3||activeStep===4),'willactive':(activeStep===1)}">
                    </div>
                    <div class="horizontal-pgbar-div2"
                        [ngClass]="{'active':(activeStep===3||activeStep===4),'willactive':activeStep===2}">
                    </div>
                    <div class="horizontal-pgbar-div3"
                        [ngClass]="{'active':(activeStep===4),'willactive':(activeStep===3)}">
                    </div>
                    <div class="horizontal-pgbar-div4" [ngClass]="{'willactive':(activeStep===4)}">
                    </div>

                </div>
            </div>

        </div>
        <!-- Vertical ProgressBar and  Steps -->
        <div class="justify-content-md-evenly justify-content-center justify-content-around px-md-1 pt-md-1 pb-md-4 "
            id="disp2" style="width: 25%;">
            <div class="p-1 me-lg-4 ms-3">
                <a *ngFor='let i of [1,2,3,4]' (click)="moveOnDifferentStep(i)" style="cursor: pointer;">
                    <div class="border-bottom px-1 py-5 d-flex align-items-center">
                        <div class="px-2"><img [src]="imgCurrent[i-1]" alt="wizard image phones" class="progressimages">
                        </div>
                        <div class="px-2">
                            <h5 class="mb-0  not-active-steps-color">Step {{i}}</h5>
                            <h5 class="mb-0 font-weight-bolder steps-font-size "
                                [ngClass]="{'text-black':(activeStep==2||activeStep==3||activeStep==4),'not-active-steps-color':(activeStep==1)}">
                                {{stepText[i-1]}}
                            </h5>
                        </div>
                    </div>
                </a>
            </div>
            <div class="mx-lg-4">
                <div class="progressbarcontainer">
                    <div class="progressbar"
                        [style.bottom]="(activeStep==1)?'100%':(activeStep==2)?'64%':(activeStep==3)?'36%':(activeStep==4)?'0%':'100%' ">
                    </div>
                    <div class="progressbarcirclediv1 "
                        [ngClass]="{'active':(activeStep===2||activeStep===3||activeStep===4),'willactive':(activeStep===1)}">
                    </div>
                    <div class="progressbarcirclediv2"
                        [ngClass]="{'active':(activeStep===3||activeStep===4),'willactive':(activeStep===2)}">
                    </div>
                    <div class="progressbarcirclediv3"
                        [ngClass]="{'active':(activeStep===4),'willactive':(activeStep===3)}">
                    </div>
                    <div class="progressbarcirclediv4" [ngClass]="{'willactive':(activeStep===4)}">
                    </div>

                </div>
            </div>

        </div>
        <div class="col d-flex flex-column" style="overflow: auto;">


            <div class="flex-grow-1">
                <!-- Step Wise Container -->
                <div [formGroup]="wizardForm">
                    <voiply-select-phone-qty *ngIf="activeStep==1" formControlName="wizardData">
                    </voiply-select-phone-qty>
                </div>
                <voiply-phone-business *ngIf="activeStep==2"></voiply-phone-business>
                <voiply-primary-number-business *ngIf="activeStep==3"></voiply-primary-number-business>
                <voiply-apps-business *ngIf="activeStep==4"></voiply-apps-business>

            </div>
            <div class="my-2 d-flex  align-items-center"
                [ngClass]="{'justify-content-between':(activeStep==1||activeStep==2),'justify-content-end':(activeStep==3||activeStep==4)}">
                <h5 *ngIf="activeStep==1" class="not-active-steps-color m-0"><i
                        class="fas fa-exclamation-circle mr-2"></i>
                    15 or More?
                    <a href="javascript:void(0);" (click)="onClickContactUs()" class="text-black"><u>Contact Us!</u></a>
                    
                </h5>
                <h5 *ngIf="activeStep==2" class="not-active-steps-color m-0"><i
                        class="fas fa-exclamation-circle mr-2"></i>Your Business
                    Needs
                    <strong class="text-black">{{wizardService.survey?.numberOfEmployees}}
                        phones</strong>
                </h5>
                <button (click)='moveOnDifferentStep((activeStep+1))' class="btn btn-primary rounded px-5 py-2 btn-lg align-self-center"
                    id="#nxtbtn" #nxtbtn>{{(activeStep==4)?'SAVE':'NEXT'}}</button>
            </div>

        </div>
    </div>

</div>