import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { AppStateSelectors, CrispService, OnDestroyCleanup, SubmitSurveyAction } from '@voiply/shared/common-ui';
import { Observable } from 'rxjs';
import * as LogRocket from 'logrocket';
import * as _ from 'lodash';
import { Survey } from '@voiply/shared/model';

import { FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {  LayoutService, WizardService } from 'libs/shared/common-ui/src/lib/services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'voiply-wizard',
  templateUrl: './wizard-new.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent extends OnDestroyCleanup implements OnInit {
  private orderId = '';
  private phoneId = 6; //to add Wi-Fi Desk Phone when added items in cart by "Configure System" for Business
  wizardForm: FormGroup;
  img2 = "assets/images/voiplywizard-images/telephone_before.svg";
  img3 = "assets/images/voiplywizard-images/businesscall_before.svg";
  img4 = "assets/images/voiplywizard-images/switch_before.svg";

  imgBefore=["assets/images/voiplywizard-images/users.svg","assets/images/voiplywizard-images/telephone_before.svg","assets/images/voiplywizard-images/businesscall_before.svg","assets/images/voiplywizard-images/switch_before.svg"];
  imgAfter=["assets/images/voiplywizard-images/users.svg","assets/images/voiplywizard-images/telephone_after.svg","assets/images/voiplywizard-images/businesscall_after.svg","assets/images/voiplywizard-images/switch_after.svg"];
  imgCurrent=["assets/images/voiplywizard-images/users.svg","assets/images/voiplywizard-images/telephone_before.svg","assets/images/voiplywizard-images/businesscall_before.svg","assets/images/voiplywizard-images/switch_before.svg"];

  stepText=['Number of Team Members','Select Your Phones','Business Number','Activate Features']
  queryParams;
  @ViewChild('nxtbtn') nxtbtn: ElementRef;
  activeStep = 1;
  get nativeWindow(): any {
    return window;
  };
  @Input() paraZipCode;
  @Select(AppStateSelectors.orderId) orderId$: Observable<string>;
  @Select(AppStateSelectors.survey) survey$: Observable<Survey>;
  @Dispatch() submitSurveyDispatcher = (survey, phoneId) => new SubmitSurveyAction(survey, phoneId);
  constructor(private crispService: CrispService,
    public wizardService: WizardService, private toastr: ToastrService, private layoutService: LayoutService) {
    super();
    this.subscriptions$.add(this.orderId$.subscribe((orderId) => this.orderId = orderId));
    this.subscriptions$.add(this.survey$.subscribe((survey) => {
      this.wizardService.survey = survey;
    }));
    
  }

  ngOnInit(): void {
    this.wizardForm = new FormGroup({
      wizardData: new FormControl({
        surveyEmail: this.wizardService.survey.email || null,
        surveyZip: this.paraZipCode || this.wizardService.survey.zipcode 
      })
    })
    this.layoutService.onWizardSaveClick(false);
  }

  moveOnDifferentStep(currentStep){
    if(currentStep==5){ // Pressed on save Button 
      this.activeStep=4;
      setTimeout(() => {
        const section = this.nxtbtn.nativeElement;
        if (section !== undefined){
          this.layoutService.onWizardSaveClick(true);
          window.scrollTo({
            top: section.getBoundingClientRect().top + window.scrollY - 80,
            left: window.pageXOffset,
            behavior: 'smooth'
          });
        } 
      });
      return;
    }
    if(this.activeStep==currentStep) // Pressed on same stage so don't do anything 
      return;
    if(this.activeStep+1==currentStep){
      if(this.activeStep==1 || this.activeStep==2){
        if(this.onSurveyValueChange()){ //Check email address and zip code 
          this.activeStep=currentStep;
          this.onSurveyValueChange()
        }
        else {
          this.toastr.error("Please enter a valid email address.");
        }
      }
      else{
        this.activeStep=currentStep;
      }
    }
    else{
      if(this.activeStep>currentStep){ 
        this.activeStep=currentStep;
      }
      else{
        this.toastr.error("You can't skip any step");
      }   
    }
    this.setImg(); // Making highlighted or normal image on basis of active Step
  }

  setImg(){
    for( let i=1;i<=this.activeStep;i++){
      this.imgCurrent[i-1]=this.imgAfter[i-1];
    }
    for(let i=this.activeStep+1;i<=4;i++){
      this.imgCurrent[i-1]=this.imgBefore[i-1];
    }
  }

  onSurveyValueChange() {
    if (this.wizardForm.valid) {
      let survey = this.wizardService.survey;
      this.crispService.CrispUserEmail = survey.email;
      //this.crispService.setSessionEvent("checkout:survey", survey);
      LogRocket.track("checkout:survey");
      LogRocket.identify(this.orderId, { email: survey.email });

      this.submitSurveyDispatcher(survey, this.phoneId);
      return true;
    }
    else {
      return false;
    }


  }

  onClickContactUs(){
    this.nativeWindow.$crisp.push(["do", "chat:open"]);
  }
}




