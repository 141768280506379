<div class="card">
  <div class="card-body">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <h5 class="text-secondary font-weight-bold m-0"><i class="fas fa-user pr-3"></i> SELECT APP FOR ROUTING </h5>
      <i class="fas fa-question-circle fa-lg" *ngIf="appRoutingHelpText" [popover]="appRoutingHelpText" placement="auto"
        containerClass="popover-css" [outsideClick]="true"></i>
    </div>
    <div class="border-bottom my-3"></div>

    <div class="select-input">
      <i class="fas fa-caret-down  dropdown-img d-flex align-items-center"></i>
      <select class="select-custom form-control text-secondary font-weight-bold"
        (change)="toggleAppSelection($event.target.value)" [ngModel]="selectedApp">
        <option *ngFor="let app of apps;let i=index" [value]="app.title"> {{app.title}}
        </option>
        <option *ngIf="!selectedApp" value=""> Select App
        </option>
      </select>
    </div>

  </div>
</div>