import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Phone } from '@voiply/shared/model';
import * as _ from 'lodash';
import { __exportStar } from 'tslib';
@Component({
  selector: 'voiply-phone-wizard',
  templateUrl: './phone-wizard.component.html',
  styleUrls: ['./phone-wizard.component.scss']
})
export class PhoneWizardComponent implements OnChanges {
  @Input() phones;
  @Input() CartItems: any;

  constructor() { }

  @Output()
  addToCart = new EventEmitter<any>();

  @Output() removeCartItem = new EventEmitter<any>();

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.CartItems){
      if (changes.CartItems.currentValue) {
        this.phones.forEach((element, key) => {
          let qty = 0;
          _.each(changes.CartItems.currentValue, (value, key) => {

            if (value.heading == element.heading) {
              qty = qty + 1;
            }
          })
          element.qty = qty;
        });
      }
    }
  }

  addPhoneToCart(phone: Phone, quantity) {

    if (quantity === undefined) quantity = 1
    this.addToCart.emit({ phone, quantity });


  }
  onphoneselect(qty, phone: Phone, quantity, i) {
    if (qty == 0) {
      if (quantity === undefined) quantity = 1
      this.addToCart.emit({ phone, quantity });
    }


  }

  removeCartItems(heading: string, event: any) {
    _.each(this.CartItems, (val, key) => {
      if (val.heading == heading) {
        this.removeCartItem.emit(key);
        return false;
      }
    });
    event.stopPropagation();

  }

  infoClick(event) {
    event.stopPropagation();
  }

}
