import spacetime from 'spacetime';
import {
  OnDestroyCleanup,
  PerformValidationInAllForms,
  AppStateSelectors,
  ApplyPromoCodeAction,
  RemovePromoCodeAction,
  ChangeBillingPlanAction,
  ShippingSameAsBillingAction,
  CrispService,
  SelectShippingOptionAction,
  ShippingAddressChangeAction,
  CompanyNameChangeAction,
  BillingAddressChangeAction,
  StripeDataChangedAction,
  ShareOrderAction,
  CalculatingTotalAction,
  UpdateCartItemConfigurationAction,
  UpdateBYODStatusAction,
  updateEmergencyStatusAction,
  UpdateShippingOptions,
  difference,
  RemoveCompanyNumberAction,
  SetPrimaryPhoneNumber,
  ChangeProtectionPlanStatusAction,
  UpdateCartItemProtectionPlanAction,
  AddCodeForLoginAction,
  PaymentSuccessfulAction,
  SaveDefaultBasicCallFlowAction,
  VoiplySplashscreenComponent,
  APPSCONST,
  AddCompanyNumberAction
} from '@voiply/shared/common-ui';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { PromoCode, Phone, App, CheckoutDetail, ShippingOptions, SystemType, Address, Stripe, ShareOrder, TaxDetail, CallFlow, CompanyNumber, RingType, Users, User, CallFlowType } from '@voiply/shared/model';
import * as _ from 'lodash';

import { OnInit, ViewChild, Directive, Renderer2, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@voiply/auth0';
import * as LogRocket from 'logrocket';
import { ConversionService, OrderService, ShippingService, UserService, } from 'libs/shared/common-ui/src/lib/services';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import uuid from 'uuid';
import { ThankyouModalComponent } from '../components';
import { Console } from 'console';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

/// Common class for both business and home Header.
@Directive()
export abstract class CheckoutBase extends OnDestroyCleanup implements OnInit {


  promoCodeApplied: PromoCode;
  cartItems: any;
  users: Users[];
  shippingOptions: ShippingOptions[];
  taxDetail: TaxDetail;
  billingAddress: Address;
  shippingAddress: Address;
  cartHasHardware: boolean;
  orderId: string;
  hasCartItems: boolean;
  calculatingTotal: boolean;
  primaryPhoneNumber: string;
  checkAllFormsAreValid: boolean;
  checkCallFlowIsSelected: boolean;
  acceptTerms = false;
  primaryPhoneDetails;

  paymentLoading: boolean;
  payNowDisable = false;
  isCardProcessing: boolean;
  callFlow: CallFlow;
  hasBYODphone = false;
  companyNumbers: CompanyNumber[];
  payNowErrorMessage = ''
  loginLink = '';
  modalRef: BsModalRef;
  metadata: any;
  @ViewChild('stripe') stripeComponent;
  get nativeWindow(): any {
    return window;
  }
  @Select(AppStateSelectors.cartItems) cartItems$: Observable<any>;
  @Select(AppStateSelectors.promoCodeApplied) promoCodeApplied$: Observable<PromoCode>;
  @Select(AppStateSelectors.phones) phones$: Observable<Phone[]>;
  @Select(AppStateSelectors.apps) apps$: Observable<App[]>;
  @Select(AppStateSelectors.checkoutDetails) checkoutDetail$: Observable<CheckoutDetail>;
  @Select(AppStateSelectors.shippingAddress) shippingAddress$: Observable<Address>;
  @Select(AppStateSelectors.billingAddress) billingAddress$: Observable<Address>;
  @Select(AppStateSelectors.cartHasHardware) cartHasHardware$: Observable<boolean>;
  @Select(AppStateSelectors.orderId) orderId$: Observable<string>;
  @Select(AppStateSelectors.calculatingTotal) calculatingTotal$: Observable<boolean>;
  @Select(AppStateSelectors.primaryPhoneNumber) primaryPhoneNumber$: Observable<string>;
  @Select(AppStateSelectors.checkAllFormsAreValid) checkAllFormsAreValid$: Observable<boolean>;
  @Select(AppStateSelectors.checkCallFlowIsSelected) checkCallFlowIsSelected$: Observable<boolean>;
  @Select(AppStateSelectors.updateShipping) updateShipping$: Observable<object>;
  @Select(AppStateSelectors.cardProcess) cardProcess$: Observable<{ isCardProcessing: boolean }>;
  @Select(AppStateSelectors.companyNumbers) companyNumbers$: Observable<CompanyNumber[]>;
  @Select(AppStateSelectors.callFlow) callFlow$: Observable<CallFlow>;
  @Select(AppStateSelectors.metadata) metadata$: Observable<any>;
  @Select(AppStateSelectors.users) users$: Observable<Users[]>;
  @Select(AppStateSelectors.primaryPhone) primaryPhoneDetails$: Observable<any>;

  @Dispatch() applyPromoCode = (promoCode) => new ApplyPromoCodeAction(promoCode);
  @Dispatch() removePromo = () => new RemovePromoCodeAction();
  @Dispatch() performValidation = () => new PerformValidationInAllForms();
  @Dispatch() changeBillingPlan = (isAnnualBilling) => new ChangeBillingPlanAction(isAnnualBilling);
  @Dispatch() onShippingSameAsBilling = (isTrue) => new ShippingSameAsBillingAction(isTrue);
  @Dispatch() selectShippingOption = (shippingOption, eventName?) => new SelectShippingOptionAction(shippingOption, eventName);
  @Dispatch() onShippingAddressChange = (shippingAddress) => new ShippingAddressChangeAction(shippingAddress);
  @Dispatch() onBillingAddressChange = (billingAddress) => new BillingAddressChangeAction(billingAddress);
  @Dispatch() companyNameChange = (companyName) => new CompanyNameChangeAction(companyName);
  @Dispatch() onStripeDataChanged = (stripe) => new StripeDataChangedAction(stripe);
  @Dispatch() shareOrder = (sharing) => new ShareOrderAction(sharing);
  @Dispatch() recalculatingTotal = (isCalculating: boolean) => new CalculatingTotalAction(isCalculating);
  @Dispatch() updateCartItemConfigurationAction = (key, cartItemConfiguration) => new UpdateCartItemConfigurationAction(key, cartItemConfiguration);
  @Dispatch() updateBYODStatus = (hasBYODphone: boolean) => new UpdateBYODStatusAction(hasBYODphone);
  @Dispatch() updateEmergencyStatus = (hasBYODphone: boolean) => new updateEmergencyStatusAction(hasBYODphone);
  @Dispatch() updateShippingOptions = (fetchOptions: boolean) => new UpdateShippingOptions(fetchOptions);
  @Dispatch() removeCompanyNumber = (phoneNumber: CompanyNumber) => new RemoveCompanyNumberAction(phoneNumber);
  @Dispatch() setPrimaryPhoneNumber = (phoneNumber, updateInServer = true) => new SetPrimaryPhoneNumber(phoneNumber, updateInServer);
  @Dispatch() changeProtectionPlanStatus = (status) => new ChangeProtectionPlanStatusAction(status);
  @Dispatch() UpdateCartItemProtectionPlan = () => new UpdateCartItemProtectionPlanAction();
  @Dispatch() addCodeForLogin = (codeForLogin) => new AddCodeForLoginAction(codeForLogin);
  @Dispatch() paymentSuccessful = (subscriptionInfo) => new PaymentSuccessfulAction(subscriptionInfo)
  @Dispatch() saveDefaultBasicCallFlowAction = (callFlowSelection) => new SaveDefaultBasicCallFlowAction(callFlowSelection)
  @Dispatch() addCompanyNumber = (phoneNumber: CompanyNumber) => new AddCompanyNumberAction(phoneNumber);
  
  constructor(
    public crispService: CrispService,
    public shippingService: ShippingService,
    public systemType: SystemType,
    public tostr: ToastrService,
    public authService: AuthService,
    public modalService: BsModalService,
    public renderer: Renderer2,
    public userService: UserService,
    public conversionService: ConversionService,
    public orderService: OrderService,
    public router: Router,
    public route: ActivatedRoute,
    public location: Location
  ) {

    super();

    this.subscriptions$.add(this.cartItems$.subscribe((items) => {
      this.cartItems = items;
      this.hasCartItems = _.some(_.pickBy(items, (value, key) => !value.paid));
    }));
    this.subscriptions$.add(this.promoCodeApplied$.subscribe((promo) => this.promoCodeApplied = promo));
    this.subscriptions$.add(this.checkoutDetail$.subscribe((checkoutDetail) => {
      this.billingAddress = { ...checkoutDetail.billingAddress };
      this.shippingService.checkoutDetail = checkoutDetail;
      this.taxDetail = { ...checkoutDetail.taxDetail };
      if (checkoutDetail.orderTotal === 0) {
        this.payNowDisable = true
      } else {
        this.payNowDisable = false
      }
    }));
    this.subscriptions$.add(this.shippingAddress$.subscribe((shippingAddress) => {
      this.shippingAddress = shippingAddress
      this.shippingService.shippingAddress = shippingAddress;
    }));
    // this.subscriptions$.add(this.billingAddress$.subscribe((billingAddress) => this.billingAddress = billingAddress));
    this.subscriptions$.add(this.cartHasHardware$.subscribe((cartHasHardware) => this.cartHasHardware = cartHasHardware));
    this.subscriptions$.add(this.orderId$.subscribe((orderId) => this.orderId = orderId));
    this.subscriptions$.add(this.metadata$.subscribe((metadata) => this.metadata = metadata));
    this.subscriptions$.add(this.calculatingTotal$.subscribe((calculatingTotal) => this.calculatingTotal = calculatingTotal));
    this.subscriptions$.add(this.primaryPhoneNumber$.subscribe(primaryPhoneNumber => this.primaryPhoneNumber = primaryPhoneNumber));
    this.subscriptions$.add(this.checkCallFlowIsSelected$.subscribe(checkCallFlowIsSelected => this.checkCallFlowIsSelected = checkCallFlowIsSelected));
    this.subscriptions$.add(this.checkAllFormsAreValid$.subscribe(checkAllFormsAreValid => this.checkAllFormsAreValid = checkAllFormsAreValid));

    this.subscriptions$.add(this.callFlow$.subscribe((callFlow) => this.callFlow = callFlow));
    this.subscriptions$.add(this.companyNumbers$.subscribe((companyNumbers: CompanyNumber[]) => {
      this.companyNumbers = companyNumbers;
    }))
    this.subscriptions$.add(this.shippingService.selectShipping.subscribe(() => {
      this.selectShipping()
    }));
    this.subscriptions$.add(this.users$.subscribe(data => {
      this.users = data;
    }))
    this.primaryPhoneDetails$.subscribe( (data) =>{
      this.primaryPhoneDetails=data;
    })
  }

  ngOnInit(): void {
    // setTimeout(() => {
    //   this.shippingService.fetchShippingOptions(this.orderId);
    // }, 5000);
  }





  onShareOrder(sharing: ShareOrder) {
    this.shareOrder(sharing);
    if (sharing.mobiles) {
      this.orderService.shareOrderViaSMS({"from": "18444864759", "to": Object.keys(sharing.mobiles)[0] , "text": "Hey! Check out the phone system I built with Voiply. https://build.voiply.com/" }).subscribe();
      this.crispService.setSessionEvent("checkout:share-mobile", { mobile: Object.keys(sharing.mobiles)[0] });
      LogRocket.track("checkout:share-mobile");
    }
    if (sharing.emails) {
      let current_email;
      Object.keys(sharing.emails).forEach((key,value)=>{
        current_email=key;
      })
      let share_data={
        substitution_data: {
          data:{
            quote_id: this.orderId
          }
         
        },
        metadata: {},
        options: {},
        email: current_email
      }
      this.orderService.shareOrderViaEmail(share_data).subscribe();
      this.crispService.setSessionEvent("checkout:share-email", { email: Object.keys(sharing.emails)[0] });
      LogRocket.track("checkout:share-email");
    }
  }

  async validateOrderAndPay() {
    this.performValidation();
    setTimeout(() => {
      const section = document.getElementsByClassName("is-invalid");
      // console.log("section =", section[0].parentElement.scrollIntoView());
      if (section !== undefined && section.length > 0)
        window.scrollTo({
          top: section[0].getBoundingClientRect().top + window.scrollY - 200,
          left: window.pageXOffset,
          behavior: 'smooth'
        });
    }, 1000);

    if (this.canPayForOrder()) {
      this.PayOrderNow();
    }
  }

  async PayOrderNow() {
    const error = await this.stripeComponent.payNow();
    if (error) {
      this.tostr.error(error);
    }

    // Payment Loading 5 second timer
    this.paymentLoading = true;

  }

  applyPromo(promoCode) {
    this.crispService.setSessionData("promo", promoCode);
    this.applyPromoCode(promoCode);
  }

  removePromoCode() {
    this.removePromo();
  }

  shippingChange(shippingOption: ShippingOptions) {
    this.selectShippingOption(shippingOption);
    //loader while calcuating Total
    this.recalculatingTotal(true);
  }

  changePlan(isAnnualBilling: boolean) {
    if (isAnnualBilling) this.removePromoCode();
    this.changeBillingPlan(isAnnualBilling);
  }

  onShippingSameAsBillingChanged(isTrue: boolean) {
    this.onShippingSameAsBilling(isTrue);
    this.updateShippingOptions(true);

    // this.shippingService.fetchShippingOptions(this.orderId);
  }

  shippingAddressChange(shippingAddress: Address) {
    this.onShippingAddressChange(shippingAddress);
    //this.shippingService.fetchShippingOptions(this.orderId); //Handled in order.service
  }

  onCompanyNameChange(companyName) {
      this.companyNameChange(companyName);
      this.crispService.CrispUserCompany = companyName;
      LogRocket.identify(this.orderId, { companyName: companyName });
    

  }

  billingAddressChange(billingAddress: Address) {
      const diff = difference(billingAddress, this.shippingService.checkoutDetail.billingAddress);

      //Re-calculate total only if there is change in city, state or zip fields in billing address
      if (diff)
        if (diff.hasOwnProperty("city") || diff.hasOwnProperty("state") || diff.hasOwnProperty("zip") || diff.hasOwnProperty("country"))
          this.recalculatingTotal(true);
      this.onBillingAddressChange(billingAddress);
      if (this.shippingService.checkoutDetail.isShippingSameAsBillingAddress) {
        // this.shippingService.fetchShippingOptions(billingAddress, true); //Handled in order.service
      }
  
      if (billingAddress.name && billingAddress.email && billingAddress.city && billingAddress.address &&
        billingAddress.zip && billingAddress.state) {
        this.crispService.setSessionEvent('checkout:address', billingAddress);
      }
  
      if (billingAddress.name) {
        this.crispService.CrispUserName = billingAddress.name;
        LogRocket.identify(this.orderId, { name: billingAddress.name });
      }
  
      if (billingAddress.email) {
        this.crispService.CrispUserEmail = billingAddress.email;
        LogRocket.identify(this.orderId, { email: billingAddress.email });
        this.crispService.setSessionEvent(this.systemType === SystemType.Business ? "checkout:email" : "checkout:email:residential", { email: billingAddress.email });
        LogRocket.track(this.systemType === SystemType.Business ? "checkout:email" : "checkout:email:residential");
      }
  
      if (billingAddress.phone) {
        this.crispService.CrispUserPhone = billingAddress.phone;
        LogRocket.identify(this.orderId, { phone: billingAddress.phone });
      }
  
      // Crisp event once we receive all billing information
      if (billingAddress.name && billingAddress.email && billingAddress.phone && billingAddress.address &&
        billingAddress.zip && billingAddress.state && billingAddress.city) {
        this.crispService.setSessionEvent("checkout:billing:completed", billingAddress);
        LogRocket.track("checkout:billing:completed");
      }
    

  }

  stripeDataChanged(stripe: Stripe) {

    this.onStripeDataChanged(stripe);

    localStorage.setItem('paymentMethodId', stripe.paymentMethodId);   // Backup if stripe paymentMethodId is not saved in cosmos, we can use this to charge customer.
    LogRocket.track("checkout:payment-initiated");

    // Once saved to Cosmos, go for authentication, but make sure last call for SendMessage is complete, which is to save token in cosmos, otherwise it will faile.
    localStorage.setItem("OrderId", this.orderId);

  }
  public selectShipping() {

    if (_.some(this.shippingService.shippingOptions, option => option.id === this.shippingService.checkoutDetail.shippingOption)) {
      const shipping = _.filter(this.shippingService.shippingOptions, option => option.id === this.shippingService.checkoutDetail.shippingOption)[0];
      if (shipping.charges !== this.shippingService.checkoutDetail.shippingCharges
        || shipping.charges !== this.shippingService.checkoutDetail.discountedShippingCharges
        || shipping.label !== this.shippingService.checkoutDetail.shipperMethod)
        this.selectShippingOption(shipping);
    } else {
      const shipping = _.sortBy(this.shippingService.shippingOptions, (option) => option.charges)[0];
      this.selectShippingOption(shipping);
    }
  }

  onProtectionPlanStatusChanged(value) {
    this.changeProtectionPlanStatus(value);
  }
  getDefaultRingOneCallFlow() {
    //Update basic as default callscenario before checkout.
    const userId = (this.users['0'] || {}).id;
    const changes: any = {
      ringOneUser: (this.callFlow.ringCallFlow || {}).ringOneUser || userId,
      voiceMailUser: (this.callFlow.ringCallFlow || {}).voiceMailUser || userId,
      voicemailRingTimeoutCount: (this.callFlow.ringCallFlow || {}).voicemailRingTimeoutCount || 3,
      timeZone: ((this.callFlow || {}).ringCallFlow || {}).timeZone || 'est',
      callForward: ((this.callFlow || {}).ringCallFlow || {}).callForward || false,
      callForwardNumbers: ((this.callFlow || {}).ringCallFlow || {}).callForwardNumbers || [],
      opens24_7: typeof (((this.callFlow || {}).ringCallFlow || {}).opens24_7) === 'boolean' ? ((this.callFlow || {}).ringCallFlow || {}).opens24_7 : true,
      ring: {
        type: (((this.callFlow || {}).ringCallFlow || {}).ring || {}).type || RingType.Simultaneous,
        users: (((this.callFlow || {}).ringCallFlow || {}).ring || {}).users || {}
      },

    };

    let callFlow: CallFlow = {
      type: CallFlowType.RingOne,
      skipConfig: false,
      ringCallFlow: {...changes},
      autoAttendantOptions: {},
      autoAttendantRingCallFlow: { ... this.callFlow?.autoAttendantRingCallFlow } || null,
      humanAttendant: { ...this.callFlow?.humanAttendant } || null
    };


    
    this.saveDefaultBasicCallFlowAction(callFlow);
  }

  abstract canPayForOrder();


  paymentSuccess(data) {
    let subscriptionInfo = data.subscriptionInfo
    let paymentMethodId = data.paymentMethodId

    this.paymentSuccessful(subscriptionInfo)
    this.userService.createSubscription(this.orderId, paymentMethodId, subscriptionInfo).then(() => {

      // After payment call pixels only once. Thus showing on thank you popup
      // #region Execute pixels
      // Disable executing CJ pixel if customer comes from GetVoip or Whichvoip (referral field)
      if (!(this.metadata.fp_ref === "GetVoip" || this.metadata.fp_ref === "Whichvoip"))
        this.conversionService.CJExecution(this.orderId, this.metadata.systemType, this.cartItems, this.shippingService.checkoutDetail, this.metadata);

      // EXECUTE SALES FIRE CONVERSION ONLY ONCE LIKE WE DO FOR CJURL
      this.conversionService.salesFireConversion(this.orderId, this.shippingService.checkoutDetail.orderTotal, this.shippingService.checkoutDetail.discountedShippingCharges, this.shippingService.checkoutDetail.taxDetail.estimatedTotalTax);

      // EXECUTE GOOGLE TAG CONVERSION ONLY ONCE LIKE WE DO FOR CJURL
      this.conversionService.googleTagConversion(this.orderId, this.shippingService.checkoutDetail.orderTotal);
      this.conversionService.gaConversion(this.orderId, this.metadata.systemType, this.shippingService.checkoutDetail.orderTotal, this.shippingService.checkoutDetail.discountedShippingCharges, this.shippingService.checkoutDetail.taxDetail.estimatedTotalTax);

      // EXECUTE FACEBOOK PURCHASE TRACK
      this.conversionService.facebookpixel(this.orderId, this.metadata.systemType, this.shippingService.checkoutDetail.orderTotal);

      // EXECUTE MICROSOFT CONVERSION
      this.conversionService.microsoftConversion();

      // Profitwell
      this.nativeWindow.profitwell('auth_token', 'bbab77c0b63e61f5a0c44b0fd311072c');
      this.nativeWindow.profitwell('user_email', this.shippingService.checkoutDetail.billingAddress.email);

      //
      let [firstName,lastName] = this.shippingService?.checkoutDetail?.billingAddress?.name.split(" ");
     // this.conversionService.sendInBlueConversion(this.shippingService?.checkoutDetail?.billingAddress?.email,firstName,lastName,this.orderId,this.metadata?.systemType,this.shippingService?.checkoutDetail?.billingAddress?.city);
      //#endregion

      //#region Send mail to support team if fax app is present in the cart
      let hasFaxApp = _.some(this.cartItems, ['heading', APPSCONST.ONLINEFAX]);
      if (hasFaxApp) {
        let data = {
          substitution_data: {
            orderId: this.orderId,
            email: this.shippingService.checkoutDetail.billingAddress.email
          },
          metadata: {},
          options: {},
          email: 'support@voiply.com'
        }

        this.orderService.postNewFaxTemplate(data).subscribe();
      }
      //#endregion

      this.paymentLoading = false
      let codeForLogin = uuid()
      this.addCodeForLogin(codeForLogin);
      this.loginLink = `${location.origin}/${this.orderId}?code=${codeForLogin}`
      this.location.go(`/${this.orderId}?code=${codeForLogin}`);
      this.openThankYouModal();
    }).catch((err) => {
      console.log(err);
      console.log('Payment successful for one off invoice but failed to create a subscription');
      this.paymentLoading = false;
    })


  }


  paymentFailed() {
    this.paymentLoading = false;
  }

  openThankYouModal() {
    this.renderer.addClass(document.body, 'payment-successful-modal');
    this.modalRef = this.modalService.show(ThankyouModalComponent, {
      ignoreBackdropClick: true, initialState: {
        loginLink: this.loginLink
      }, class: 'h-100 m-auto d-flex align-items-center', keyboard: false
    });
  }

}
