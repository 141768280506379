import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { CountryCode } from '../country-codes';
import { InternationalPhoneNumberBase } from '../international-phone-number.base';

@Component({
    selector: 'intl-tel-input-new',
    templateUrl: './international-phone-number-input-new.component.html',
    styleUrls: ['./international-phone-number-input-new.component.css'],
    providers: [
        CountryCode,
        {
            provide: NG_VALUE_ACCESSOR,
            // tslint:disable-next-line:no-forward-ref
            useExisting: forwardRef(() => NgxIntlTelInputNewComponent),
            multi: true
        }
    ]
})
export class NgxIntlTelInputNewComponent extends InternationalPhoneNumberBase {
    @Input()
    background;
    constructor(countryCodeData: CountryCode) {
        super(countryCodeData);
    }

}

