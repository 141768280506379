import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'voiply-footer-business',
  templateUrl: './footer-business.component.html',
  styleUrls: ['./footer-business.component.scss']
})
export class FooterBusinessComponent {
  content = `Making the transition from a traditional landline to VoIP for your home phone is easier than ever. 65% of adults use broadband internet at home. Even compared with bundled packages, cable and phone companies can’t compete with the features and savings you’ll see by switching to Voiply.`;

  features = [
    "Unlimited Calling to 50+ Countries",
    "99.9999% Uptime",
    "HD Voice",
    "Voicemail to Email"
  ];

  imglogo = "assets/images/vp-res.png";
  constructor() {

  }
  navigatePage() {
    window.location.href = "https://build.voiply.house";
  }
}
