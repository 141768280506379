import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { AppStateSelectors } from '../+state/app.selectors';
import { Observable } from 'rxjs';
import { CheckoutDetail } from '@voiply/shared/model';

@Injectable({
    providedIn: 'root'
})
export class AudioTemplateService {
    
    private checkoutDetail: CheckoutDetail;
    private cartItems: any;

    @Select(AppStateSelectors.checkoutDetails) checkoutDetail$: Observable<CheckoutDetail>;
    @Select(AppStateSelectors.cartItems) cartItems$: Observable<any>;

    constructor() {
        this.checkoutDetail$.subscribe((checkoutDetail) => this.checkoutDetail = checkoutDetail);
        this.cartItems$.subscribe((items) => this.cartItems = items);
    }

    getBillingValues() {
        const empty: any = {};
        const billingAddress = ((this.checkoutDetail || empty).billingAddress || empty);
        return {
            'Company Name': (this.checkoutDetail || empty).companyName,
            'Name': billingAddress.name,
            'Email': billingAddress.email,
            'Phone': billingAddress.phone
        }
    }

    getCartItemConfigValues(cartItemId: string) {
        const empty: any = {};
        const cartItem = (this.cartItems || empty)[cartItemId];
        if (!cartItem)
            return {};

        const config = cartItem.configuration || empty;
        return {
            'Company Name': (this.checkoutDetail || empty).companyName,
            'Name': ((config.firstName || '') + ' ' + (config.lastName || '')).trim(),
            'First Name': config.firstName,
            'Last Name': config.lastName,
            'Email': config.email,
            'Number': ((config.phoneNumber || '') + ' ' + (config.extension || '')).trim(),
            'Phone': config.phoneNumber,
            'Extension': config.extension
        }
    }

    replacePlaceholders(template: string, valueType: string | "root"): string {
        const variables = valueType === "root" ? this.getBillingValues() : this.getCartItemConfigValues(valueType);
        let finalTemplate = template;
        Object.keys(variables).forEach(key => {
            if (variables[key])
                finalTemplate = finalTemplate.replace(`[${key}]`, variables[key]);
        })
        return finalTemplate;
    }
}