<div class="d-flex flex-column">
    <div>
        <h2 class="font-weight-bold px-1 py-0 mb-3">Numbers of Team Member, email address and Zip Code to receive the Quote</h2>
    </div>
    <div class="p-2 my-5">
        <form action="" [formGroup]="form" class="d-flex justify-content-center flex-wrap">

            <div class="mr-md-3 mb-3">
                <div style="position: relative;">
                    <input type="email" name="" id="" placeholder="Email Address"
                        class="py-3 pl-3 pr-5  rounded border-0" style="background-color: #eeeeee;"
                        formControlName="surveyEmail"
                        [ngClass]="{ 'is-invalid':((f.surveyEmail.touched) && (f.surveyEmail.errors)) }">
                    <img src="assets/images/wizardstep1validator.png" alt="" class="validation-rightmark-image">
                    <div *ngIf="((f.surveyEmail.touched) && f.surveyEmail.errors)"
                        style="position: absolute;top:21px;right:-10px;">
                        <span *ngIf="f.surveyEmail.errors.required">
                            <i class="far fa-exclamation-circle is-error" tooltip="Please enter Email"></i></span>
                        <span *ngIf="f.surveyEmail.errors.email">
                            <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid Email"></i></span>
                    </div>
                </div>
            </div>
            <div>
                <input type="text" name="" id="" placeholder="Zip Code" class="rounded mb-3 pl-3 pr-5  py-3 border-0"
                    formControlName="surveyZip" style="background-color: #eeeeee;">

            </div>
        </form>
    </div>

    <div class=" py-1 px-3 my-5">
        <div>
            <!-- Below Is Step1 Progress Bar -->
            <div class="range-wrap" style="width: 100%;">
                <input type="range" class="range temp" min="0" max="15" step="1" #range
                    [ngModel]="wizardService.survey.numberOfEmployees" (ngModelChange)="onPercentChange($event)">
                <div class="step1Progressbardiv1"></div>
                <div class="step1Progressbardiv2"></div>
                <div class="step1Progressbardiv3"></div>
                <div class="step1Progressbardiv4"></div>
                <div style="position: absolute;left: 1.25%;top: -35px;" class="text-secondary font-weight-bolder">0
                </div>
                <div style="position: absolute;left:97%;top: 25px" class="text-secondary font-weight-bolder">15</div>
                <div style="position: absolute;
            top:-70px;left:49.95%;width:35px;" class="dummy-person-of-pogressbar text-center" #dummy>
                    <img src="assets/images/teamwork.png" alt="Pr" style="height:40px;">
                </div>

                <output #bubble
                    class="bubble bg-primary d-flex justify-content-center align-items-center font-weight-bolder">{{wizardService.survey.numberOfEmployees}}</output>

            </div>
        </div>
    </div>




</div>