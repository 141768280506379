import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSiemaModule } from 'ngx-siema';
import { TimeAgoPipe } from 'time-ago-pipe';

import { CommonUiModule } from '@voiply/shared/common-ui';

import { CONTAINERS } from './container';
import { COMPONENTS } from './components';

@NgModule({
  imports: [
    CommonModule,
    CommonUiModule,
    NgxSiemaModule.forRoot(),
  ],
  declarations: [
    ...CONTAINERS,
    ...COMPONENTS,
    TimeAgoPipe
  ],
  exports: [
    ...CONTAINERS,
    ...COMPONENTS
  ]
})
export class TestimonialsModule { }
