import { Component, OnInit, Output, EventEmitter, Input, ViewChild, Injectable } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBase, UpdateCartItemConfigurationAction, OrderService, STATES, UpdatingPhoneSystemAction, COUNTRIES } from '@voiply/shared/common-ui';
import { CartItemType, Address, EmergencyAddress } from '@voiply/shared/model';
import * as _ from 'lodash';
import { FormGroup, FormControl } from '@angular/forms';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ToastrService } from 'ngx-toastr';
import { pairwise, startWith } from 'rxjs/operators';
import { ValidationService } from 'libs/shared/common-ui/src/lib/services';
import { BusinessConfigurationBase } from '../business-configuration.base';


@Component({
  selector: 'voiply-configuration-modal',
  templateUrl: './business-configuration-modal.component.html',
  styleUrls: ['./business-configuration-modal.component.scss']
})
export class BusinessConfigurationModalComponent extends BusinessConfigurationBase {
  constructor(private bsModalRef: BsModalRef, toastr: ToastrService, orderService: OrderService, validateService: ValidationService) {
    super(toastr, orderService, validateService);
  }

  async updateCartItemConfiguration(key, productConfig) {
    this.bsModalRef.hide()
    this.updateCartItemConfigurationAction(key, productConfig);
    if (this.value.paid) {
      this.updatingPhoneSystem(true);
      await this.orderService.updateOrder(this.orderId);
      this.updatingPhoneSystem(false);
    }
  }

  onClick(btnId){
    console.log(this.form)
    if(btnId=='1' && this.toggle==true){
      this.toggle=!this.toggle;
      this.form.controls[this.key].get("isVoicemailToEmailEnable").setValue(this.toggle);
      return;
    }
    if(btnId=='2' && this.toggle==false){
      this.toggle=!this.toggle;
      this.form.controls[this.key].get("isVoicemailToEmailEnable").setValue(this.toggle);
      return;
    }
  }

}