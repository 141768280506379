<div class="bg-primary-gradient p-3 text-white d-flex justify-content-center align-items-center">
  <img src="assets/images/voiply-human-attendant.png" class="mr-2" alt="" width="40px">
  <h5 class="font-weight-bold m-0"> HUMAN ATTENDANT </h5>
</div>


<!-- task:1001-humanattendant We have 3 sections question-answers,sales and representative. Sales and representative section is not implemented for now -->
<div class="row">
  <div class="col-12 col-md-6" style="padding-right:12px">
    <div class="mt-3">
      <div class="card">
        <div class="card-body">
          <div class="d-flex flex-row justify-content-between align-items-center">
            <h5 class="text-secondary font-weight-bold m-0"><i class="fas fa-building pr-3"></i> BUSINESS CATEGORY </h5>
            <i class="fas fa-question-circle fa-lg" *ngIf="businessCategoryHelpText"
              [popover]="businessCategoryHelpText" placement="auto" containerClass="popover-css"
              [outsideClick]="true"></i>
          </div>
          <div class="border-bottom my-3"></div>

          <div class="select-input">
            <i class="fas fa-caret-down  dropdown-img d-flex align-items-center"></i>
            <select class="select-custom form-control text-secondary font-weight-bold"
              (change)="toggleBusinessCategory($event.target.value)"
              [ngModel]="this.callFlow.humanAttendant.businessCategory">
              <option value="" disabled>Select Business Category</option>
              <option *ngFor="let businessCategory of businessCategories" [value]="businessCategory.title">
                {{businessCategory.title}}</option>
            </select>
          </div>

        </div>
      </div>
    </div>

  </div>
  <div class="col-12 col-md-6" style="padding-left:12px;">
    <div class="mt-3">
      <div class="d-flex flex-row">
        <button class="btn py-4 px-2 d-flex flex-column align-items-center justify-content-center mr-3"
          [ngClass]="this.callFlow.humanAttendant.selectedCategory==='QA'?'border-primary':'border-secondary'"
          style="min-width: 100px;width: 100%;height: 135px; " (click)="onUpdateSelectedCategory('QA')">
          <img src="assets/images/human-q&a.svg" class=""
            [ngClass]="this.callFlow.humanAttendant.selectedCategory==='QA'?'icon-primary':'icon-grey'"
            style="width:35px" alt="">
          <h6 class="mt-3 mb-0 font-weight-bold "
            [ngClass]="this.callFlow.humanAttendant.selectedCategory==='QA'?'text-secondary':'text-color'">Q&A
          </h6>
        </button>
        <button class=" btn py-4 px-2 
             d-flex flex-column align-items-center justify-content-center mr-3"
          [ngClass]="this.callFlow.humanAttendant.selectedCategory==='SALES'?'border-primary':'border-secondary'"
          style="min-width: 100px;width: 100%;height: 135px;" (click)="onUpdateSelectedCategory('SALES')">
          <img src="assets/images/human-sales.svg" class=""
            [ngClass]="this.callFlow.humanAttendant.selectedCategory==='SALES'?'icon-primary':'icon-grey'"
            style="width:35px" alt="">
          <h6 class="mt-3 mb-0 font-weight-bold"
            [ngClass]="this.callFlow.humanAttendant.selectedCategory==='SALES'?'text-secondary':'text-color'">
            SALES</h6>
        </button>
        <button class="btn py-4 px-2 
             d-flex flex-column align-items-center justify-content-center"
          [ngClass]="this.callFlow.humanAttendant.selectedCategory==='RECEPTIONIST'?'border-primary':'border-secondary'"
          style="min-width: 100px;width: 100%;height: 135px;" (click)="onUpdateSelectedCategory('RECEPTIONIST')">
          <img src="assets/images/human-receptionist.svg" class=""
            [ngClass]="this.callFlow.humanAttendant.selectedCategory==='RECEPTIONIST'?'icon-primary':'icon-grey'"
            style="width:35px" alt="">
          <h6 class="mt-3 mb-0 font-weight-bold"
            [ngClass]="this.callFlow.humanAttendant.selectedCategory==='RECEPTIONIST'?'text-secondary':'text-color'">
            RECEPTIONIST</h6>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- task:1001-humanattendant-start User Tips Section region -->
<div class="mt-3">
  <div class="card">
    <div class="card-body d-flex flex-column flex-md-row">
      <div class="border p-3 mr-md-2 " style="min-width:33%">
        <pagination-template #p="paginationApi" (pageChange)="onUserTipsChange($event,p.pages.length)" id="userTips"
          [maxSize]="200">

          <div class="d-flex flex-row justify-content-between">
            <div>
              <div class="font-weight-bold text-secondary" style="font-size: 12px;">User Tips</div>
              <div style="font-size: 12px;">Editing Questions and Answers</div>
            </div>
            <div>{{p.getCurrent()}}/{{p.pages.length}}</div>
          </div>
          <div class="border-bottom my-3"></div>
          <div class="d-flex flex-row">
            <div class="flex-grow-1"
              *ngFor="let userTip of userTips |paginate: { itemsPerPage: 1, currentPage: userTipPageNumber, id:'userTips'}">
              {{userTip}}
            </div>
            <button class="d-flex align-items-center p-3 btn py-0" (click)="p.next()"> <i
                class=" fas fa-chevron-right text-secondary"> </i></button>
          </div>
        </pagination-template>

      </div>
      <div class="border py-3 px-4 bg-primary-gradient make-test-call mt-3 mt-md-0" style="min-width:33%">
        <h5 class="font-weight-bold text-primary text-center">MAKE TEST CALL</h5>
        <div class="border-bottom my-3"></div>
        <input type="text" class="form-control" (mask)="'(000) 000-0000'" name="" id=""
          style="background:#E65B3C;border-color:white;color:white;" placeholder="(412) 346-4213">
        <button class="btn bg-white px-5 w-100 py-1 text-secondary font-weight-bold mt-2" style="font-size: 12px;">CALL
          ME</button>
      </div>
    </div>
  </div>
</div>
<!-- task:1001-humanattendant-end User Tips Section region -->

<!-- task:1001-humanattendant-start graph region -->
<div class="mt-3">
  <div class="d-flex flex-row align-items-center">
    <div class="h1 m-0 font-weight-bold text-secondary mr-5">TODAY</div>
    <div class="d-flex flex-row">
      <div class="">
        New Calls
        <div class="text-secondary">4</div>
      </div>
      <div class="border-right mx-5 my-3"></div>
      <div class="">
        Yesterday
        <div class="text-secondary">16</div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row ">
    <div class="mr-3 flex-grow-1">
      <common-ui-line-graph></common-ui-line-graph>
    </div>
    <div class="text-nowrap px-3 d-flex flex-column justify-content-center">
      <div class="d-flex flex-column ">
        <div>Total Cost</div>
        <div class="text-secondary font-weight-bold">$3.00</div>
      </div>
      <div class="border-top my-3 mx-2"></div>
      <div class="d-flex flex-column">
        <div>Avg. Cost</div>
        <div class="text-secondary font-weight-bold">$0.75</div>
      </div>
    </div>
  </div>
</div>
<!-- task:1001-humanattendant-end graph region -->

<!-- task:1001-humanattendant show components based on selectedcategory -->
<div class="mt-3">
  <voiply-questions-answers *ngIf="this.callFlow.humanAttendant.selectedCategory==='QA'"
    [questionsAnswers]="this.callFlow.humanAttendant.questionsAnswers"
    (questionsAnswersChanged)="onQuestionsAnswersChanged($event)"></voiply-questions-answers>
  <voiply-sales *ngIf="this.callFlow.humanAttendant.selectedCategory==='SALES'"></voiply-sales>
  <voiply-receptionist *ngIf="this.callFlow.humanAttendant.selectedCategory==='RECEPTIONIST'"></voiply-receptionist>
</div>

<!-- task:1001-humanattendant-start settings region -->
<div class="mt-3">
  <div class="bg-primary-gradient p-3 text-white d-flex justify-content-center align-items-center">
    <h5 class="font-weight-bold m-0"> SETTINGS </h5>
  </div>

  <div class="row ">
    <div class="col-12 col-md-6" style="padding-right:12px">
      <div class="mt-3">
        <voiply-budget-per-week [selectedBudget]="this.callFlow.humanAttendant.budgetPerWeek"
          (changeBudget)="changeBudget($event)"></voiply-budget-per-week>
      </div>
    </div>
    <div class="col-12 col-md-6" style="padding-left:12px;">
      <div class="mt-3">
        <voiply-max-price-per-week [maxPricePerCall]="this.callFlow.humanAttendant.maxPricePerCall"
          (changeMaxPricePerCall)="changeMaxPricePerCall($event)"></voiply-max-price-per-week>
      </div>
    </div>
  </div>
</div>
<!-- task:1001-humanattendant-end settings region -->