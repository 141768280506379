import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { App } from '@voiply/shared/model';

@Component({
  selector: 'voiply-app-routing',
  templateUrl: './app-routing.component.html',
  styleUrls: ['./app-routing.component.scss']
})
export class AppRoutingComponent implements OnInit {
  @Input() selectedApp = '';
  @Input() appRoutingHelpText = ''
  @Input() apps: App[] = []
  @Output() AppSelectionChange = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }
  toggleAppSelection(app: string) {
    this.AppSelectionChange.emit(app);
  }
}
