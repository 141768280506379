<div class="card border-0 mt-4" style="border-radius: 0% !important;">
    <div class="d-inline-flex flex-wrap p-3" style="gap: 1rem">
        <div class="responsive-card" *ngIf="numberPorting?.status != 'Completed'&&hasHardware">
            <div class="d-flex align-items-stretch h-100 pt-3 pt-sm-0">
                <div class="card bg-light-grey rounded-lg border-0 overflow-auto">
                    <div class="card-header bg-light-grey border-0">
                        <div class="d-flex flex-column justify-content-center align-items-center pt-4">
                            <svg xmlns="http://www.w3.org/2000/svg" height="80px"
                                xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 176.682 98.671">
                                <defs>
                                    <linearGradient id="linear-gradient" x2="1" y2="0.929"
                                        gradientUnits="objectBoundingBox">
                                        <stop offset="0" stop-color="#fb522c" />
                                        <stop offset="1" stop-color="#d22700" />
                                    </linearGradient>
                                </defs>
                                <g id="Group_417" data-name="Group 417" transform="translate(-702.103 -308.263)">
                                    <g id="telephone_6_" data-name="telephone (6)"
                                        transform="translate(829.835 406.934) rotate(-135)">
                                        <g id="Group_150" data-name="Group 150" transform="translate(0 0)">
                                            <path id="Path_1323" data-name="Path 1323"
                                                d="M58.219,43.306a5.365,5.365,0,0,0-8.111,0c-1.9,1.88-3.793,3.761-5.657,5.673a1.118,1.118,0,0,1-1.562.287c-1.227-.669-2.534-1.211-3.713-1.944A58.827,58.827,0,0,1,24.994,34.414a33.6,33.6,0,0,1-5.083-8.143,1.166,1.166,0,0,1,.287-1.5c1.9-1.833,3.745-3.713,5.609-5.593,2.6-2.613,2.6-5.673-.016-8.3-1.482-1.5-2.964-2.964-4.446-4.462-1.53-1.53-3.044-3.075-4.589-4.589a5.4,5.4,0,0,0-8.111.016C6.732,3.723,4.9,5.651,2.956,7.5a9.234,9.234,0,0,0-2.9,6.231A26.4,26.4,0,0,0,2.1,25.092,69.04,69.04,0,0,0,14.349,45.5,75.831,75.831,0,0,0,39.463,65.153,36.321,36.321,0,0,0,53.374,69.2a10.2,10.2,0,0,0,8.748-3.33c1.625-1.817,3.458-3.474,5.179-5.211,2.55-2.582,2.566-5.7.032-8.254Q62.792,47.839,58.219,43.306Z"
                                                fill="url(#linear-gradient)" />
                                        </g>
                                    </g>
                                    <g id="left-arrow_2_" data-name="left-arrow (2)"
                                        transform="translate(777.718 334.02)" opacity="0.23">
                                        <g id="Group_151" data-name="Group 151" transform="translate(0 0)">
                                            <path id="Path_1324" data-name="Path 1324"
                                                d="M23.965,50.228l.04.009H7.013l5.342-5.353a1.4,1.4,0,0,0,0-1.972l-.831-.832a1.39,1.39,0,0,0-1.962,0L.4,51.234A1.4,1.4,0,0,0,.4,53.2L9.561,62.36a1.392,1.392,0,0,0,1.962,0l.831-.832a1.375,1.375,0,0,0,.405-.981,1.334,1.334,0,0,0-.405-.963L6.953,54.2H23.984a1.439,1.439,0,0,0,1.408-1.424V51.6A1.411,1.411,0,0,0,23.965,50.228Z"
                                                transform="translate(0 -41.674)" />
                                        </g>
                                    </g>
                                    <g id="left-arrow_2_2" data-name="left-arrow (2)"
                                        transform="translate(803.111 380.242) rotate(180)">
                                        <g id="Group_151-2" data-name="Group 151" transform="translate(0 0)">
                                            <path id="Path_1324-2" data-name="Path 1324"
                                                d="M23.965,8.554l.04.009H7.013L12.355,3.21a1.4,1.4,0,0,0,0-1.972L11.524.406A1.39,1.39,0,0,0,9.561.4L.4,9.56a1.4,1.4,0,0,0,0,1.969l9.156,9.156a1.392,1.392,0,0,0,1.962,0l.831-.832a1.375,1.375,0,0,0,.405-.981,1.334,1.334,0,0,0-.405-.963l-5.4-5.383H23.984A1.439,1.439,0,0,0,25.393,11.1V9.927A1.411,1.411,0,0,0,23.965,8.554Z" />
                                        </g>
                                    </g>
                                    <g id="telephone_6_2" data-name="telephone (6)"
                                        transform="translate(751.053 308.263) rotate(45)">
                                        <g id="Group_150-2" data-name="Group 150" transform="translate(0 0)">
                                            <path id="Path_1323-2" data-name="Path 1323"
                                                d="M58.219,43.306a5.365,5.365,0,0,0-8.111,0c-1.9,1.88-3.793,3.761-5.657,5.673a1.118,1.118,0,0,1-1.562.287c-1.227-.669-2.534-1.211-3.713-1.944A58.827,58.827,0,0,1,24.994,34.414a33.6,33.6,0,0,1-5.083-8.143,1.166,1.166,0,0,1,.287-1.5c1.9-1.833,3.745-3.713,5.609-5.593,2.6-2.613,2.6-5.673-.016-8.3-1.482-1.5-2.964-2.964-4.446-4.462-1.53-1.53-3.044-3.075-4.589-4.589a5.4,5.4,0,0,0-8.111.016C6.732,3.723,4.9,5.651,2.956,7.5a9.234,9.234,0,0,0-2.9,6.231A26.4,26.4,0,0,0,2.1,25.092,69.04,69.04,0,0,0,14.349,45.5,75.831,75.831,0,0,0,39.463,65.153,36.321,36.321,0,0,0,53.374,69.2a10.2,10.2,0,0,0,8.748-3.33c1.625-1.817,3.458-3.474,5.179-5.211,2.55-2.582,2.566-5.7.032-8.254Q62.792,47.839,58.219,43.306Z"
                                                fill="url(#linear-gradient)" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <div class="font-weight-bold h5 text-uppercase pt-4">NUMBER PORTING</div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="text-center h-100 d-flex flex-column">
                            <!-- <h5>{{numberPorting.status || "Not Submitted"}}</h5> -->
                            <div style="background-color: #787878;"
                                class="text-primary py-2 px-1 mb-3 d-flex align-items-center justify-content-center w-100 rounded">
                                <p class="m-0 font-weight-bold">
                                    {{numberPorting?.status?.toUpperCase() || "Not Submitted".toUpperCase()}}</p>
                            </div>

                            <div class="border-top pt-3 d-flex flex-fill">
                                <span class="align-self-center">
                                    {{numberPorting?.text || "The porting process can take 7-10 business days to complete from the time of submission."}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer bg-light-grey border-0 p-0">
                        <div
                            *ngIf="!numberPorting.status || numberPorting.status ==='Not Submitted' || numberPorting.status ==='Rejected'">
                            <a href="https://voiply.typeform.com/to/R6MTB6?email={{email}}&company={{companyName}}&order_id={{orderId}}"
                                data-mode="popup" target="_blank">
                                <button class="btn w-100 py-2 bg-grey font-weight-bold text-muted"
                                    href="https://voiply.typeform.com/to/R6MTB6" target="_blank">
                                    Submit Port Request
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="responsive-card w-100" *ngIf="!hasOnlyFax">
            <div class="d-flex align-items-stretch h-100 pt-3 pt-sm-0 w-100">
                <div class="card bg-light-grey border-0 w-100">
                    <div class="card-header bg-light-grey border-0 rounded-lg">
                        <div
                            class="d-flex flex-column justify-content-center align-items-center position-relative pt-4">
                            <img src="/assets/images/{{isExtralineMobileSelected ? 'ExtraLineLogo' : 'logo'}}.png"
                                height=auto width=80px alt="">
                            <div class="font-weight-bold h5 text-uppercase pt-4">{{isExtralineMobileSelected ? 'Voiply Extra Line' : 'Desktop App'}}</div>
                        </div>
                        <!-- hardware dropdown when there is more than 1 hardware -->
                        <div *ngIf="dropDownItems().length > 1" class="btn-group position-absolute"
                            style="top: 10px; right: 10px" dropdown>
                            <button id="button-basic" dropdownToggle type="button"
                                class="btn w-100 bg-white font-weight-bold text-muted rounded dropdown-toggle px-1"
                                style="font-size: small;" aria-controls="dropdown-basic">
                                {{'EXT ' + this.selectedPhone?.configuration?.extension}} <span class="caret"></span>
                            </button>
                            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu w-100" role="menu"
                                aria-labelledby="button-basic">
                                <ng-container *ngFor="let item of dropDownItems()">
                                    <li role="menuitem" (click)="onPhoneSelect(item)" class="text-center cursor-pointer"
                                        style="font-size:small">{{'EXT ' + item.configuration.extension}}</li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="text-center d-flex flex-column h-100">
                            <!-- <h5>{{numberPorting.status || "Not Submitted"}}</h5> -->
                            <div class="d-flex justify-content-around mb-3" style="height: 40px;">
                                <ng-container *ngIf="isExtralineMobileSelected">
                                    <a href="https://play.google.com/store/apps/details?id=com.voiply.online.android"
                                        target="_blank">
                                        <img style="height: 40px;" src="/assets/images/app-store/google-play-light.png"
                                            alt="GooglePlayStore">
                                    </a>
                                    <a href="https://apps.apple.com/us/app/voiply-online/id1555541262" target="_blank">
                                        <img style="height: 40px;" src="/assets/images/app-store/apple-app-light.png"
                                            alt="AppleAppStore">
                                    </a>
                                </ng-container>
                                <ng-container *ngIf="!isExtralineMobileSelected">
                                    <a href="https://softphone.azureedge.net/apps/voiplyonline.exe" target="_blank">
                                        <img style="height: 40px;" src="/assets/images/app-store/windows-app.png"
                                            alt="WindowsAppStore">
                                    </a>
                                    <a href="https://softphone.azureedge.net/apps/voiplyonline.dmg" target="_blank">
                                        <img style="height: 40px;" src="/assets/images/app-store/mac-os-app.png"
                                            alt="MacOSApp">
                                    </a>
                                </ng-container>
                            </div>

                            <div class="d-flex flex-grow-1 align-items-center pt-3 w-100"
                                style="border-top: 1px dotted #dee2e6">
                                <div [hidden]="!isExtralineMobileSelected" class="w-100">
                                    <div class="d-none d-sm-flex">
                                        <span class="align-self-center">After downloading the app from store please scan
                                            the QR code to configure.</span>
                                        <span class="qrCode px-2" [hidden]="!isQrCodeLoaded"
                                            [innerHTML]="qrCodeData">loading</span>
                                    </div>
                                    <div>
                                    <a [href]="sanitizeUrl(generateUrlForExtralineApp())" class="btn btn-primary d-sm-none w-100 font-weight-bold py-2"
                                    [ngClass]="sanitizeUrl(generateUrlForExtralineApp())?'d-block':'d-none'">Launch App</a>
                                    </div>
                                </div>
                                <div [hidden]="isExtralineMobileSelected">
                                    <span>After you download the app from store please click on the button
                                        "configure".</span>
                                    <a [attr.href]="sanitizeUrl(generateUrl())"><span
                                            class="btn cursor-pointer bg-grey py-2 rounded w-100 text-uppercase font-weight-bold mt-4">Configure</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn-group w-100 rounded-bottom overflow-auto">
                        <div class="btn py-2 cursor-pointer"
                            [ngClass]="{'bg-dark text-white': isExtralineMobileSelected, 'bg-grey': !isExtralineMobileSelected}"
                            (click)="isExtralineMobileSelected = true">Mobile</div>
                        <div class="btn py-2 cursor-pointer"
                            [ngClass]="{'bg-dark text-white': !isExtralineMobileSelected, 'bg-grey': isExtralineMobileSelected}"
                            (click)="isExtralineMobileSelected = false">Desktop</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="d-flex flex-column flex-sm-row mr-lg-3">
      </div> -->
        <div class="d-flex flex-column responsive-card-2" style="gap: 1rem">
            <div *ngIf="hasHardware" class="d-flex flex-column">
                <div class="card bg-light-grey p-3 pr-4 border-0 rounded-lg" style="overflow: auto;">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex justify-content-start align-items-center">
                            <img src="/assets/images/fast-delivery.png" style="height: 25px; width: 40px;" class="pr-2"
                                alt="">
                            <div class="font-weight-bold m-0 h5">Order Status</div>
                        </div>
                        <div style="background-color: #787878;" class="text-primary text-uppercase rounded py-1 px-4">
                            {{shipment?.status?.toUpperCase() || "In Processing".toUpperCase()}}
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mt-3">
                        <span class="pr-5">{{shipment?.status_detail || ""}}</span>
                        <span [ngClass]='{"d-none": shipment?.shipperTrackingLink=="" }' class="rounded cursor-pointer" style="background-color: #EDEDED;">
                            <a [attr.href]="shipment?.shipperTrackingLink != '' ? shipment?.shipperTrackingLink : null"
                                target="_blank" *ngIf="shipment.status!=='Delivered'">
                                <i class="fal fa-chevron-right fa-2x text-muted px-4 py-2"></i>
                            </a>
                            <i (click)="openTroubleShooting()" *ngIf="shipment.status==='Delivered'"
                                class="fal fa-chevron-right fa-2x text-muted px-4 py-2"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column" *ngIf="!hasOnlyFax">
                <div class="card bg-light-grey p-3 pr-4 border-0 rounded-lg" style="overflow: auto;">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex justify-content-start align-items-center">
                            <img src="/assets/images/search.png" style="height: 25px; width: 40px;" class="pr-2" alt="">
                            <div class="font-weight-bold m-0 h5">Call Logs</div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mt-3">
                        <span class="pr-5">Access call logs to see your voicemail and incoming/outgoing calls.</span>
                        <span class="rounded cursor-pointer" style="background-color: #EDEDED;">
                            <a href="https://cdr.voiply.com/{{orderId}}" data-mode="popup" target="_blank">
                                <i class="fal fa-chevron-right fa-2x text-muted px-4 py-2"></i>
                            </a>
                        </span>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column">
                <div class="card bg-light-grey p-3 pr-4 border-0 rounded-lg" style="overflow: auto;">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex justify-content-start align-items-center">
                            <img src="/assets/images/money.png" style="height: 25px; width: 40px;" class="pr-2" alt="">
                            <div class="font-weight-bold m-0 h5">Get a Free Month</div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mt-3">
                        <span class="pr-5">
                            <div *ngIf="!firstPromoter.my_ref">When you refer a friend or business to VoiPLy you both
                                get a free month of VoIPLy! </div>
                            <div *ngIf="firstPromoter.my_ref">
                                <div>When you refer a friend or business to VoiPLy, and they subscribe with your
                                    <strong>{{firstPromoter.my_ref}}</strong> promo code, you both get a free month of
                                    VoIPLy!</div>
                            </div>
                        </span>
                        <span (click)="referButtonClick()" class="rounded cursor-pointer"
                            style="background-color: #EDEDED;"><i
                                class="fal fa-chevron-right fa-2x text-muted px-4 py-2"></i></span>
                    </div>
                    <div *ngIf="showRefer&&!showReferEmail" class="d-flex justify-content-center p-2 flex-wrap">
                        <!-- <div  style="margin-top: 32px;"> -->
                        <a class="cursor-pointer" shareButton="whatsapp" [url]="shareUrl"
                            title="Hey! I just ordered my Voiply phone system. Get yours and a month FREE with my code {{firstPromoter.my_ref}} at https://build.voiply.com">
                            <i class="share-btn text-secondary fab fa-2x fa-whatsapp-square"
                                style="font-size: 38px"></i>
                        </a>
                        <a class="cursor-pointer" shareButton="facebook" [url]="shareUrl"
                            title="Hey! I just ordered my Voiply phone system. Get yours and a month FREE with my code {{firstPromoter.my_ref}} at https://build.voiply.com">
                            <i class="share-btn text-secondary fab fa-2x ml-2 fa-facebook-square"
                                style="font-size: 38px"></i>
                        </a>
                        <a cla ss="cursor-pointer" shareButton="twitter" [url]="shareUrl"
                            title="Hey! I just ordered my Voiply phone system. Get yours and a month FREE with my code {{firstPromoter.my_ref}} at https://build.voiply.com">
                            <i class="share-btn text-secondary fab fa-2x ml-2 fa-twitter-square"
                                style="font-size: 38px"></i>
                        </a>
                        <a class="cursor-pointer" shareButton="linkedin" [url]="shareUrl"
                            title="Hey! I just ordered my Voiply phone system. Get yours and a month FREE with my code {{firstPromoter.my_ref}} at https://build.voiply.com">
                            <i class="share-btn text-secondary fab fa-2x ml-2 fa-linkedin" style="font-size: 38px"></i>
                        </a>
                        <div (click)="showReferEmail=true" class="cursor-pointer">
                            <i class="share-btn text-secondary fab fa-2x ml-2 fas fa-envelope-square"
                                style="font-size: 38px"></i>
                        </div>
                    </div>
                    <div class="position-relative pt-1" style="height: 42px;" *ngIf="showReferEmail">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-grey"><i class="fal fa-envelope"></i></span>
                            </div>
                            <input type="text" class="form-control bg-grey" style="height: 42px;"
                                placeholder="Type Email to Refer" [formControl]="referEmail">
                            <div>
                                <i style="right: 13px !important;" class="fal fa-times is-error text-color"
                                    (click)="showReferEmail=false"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>