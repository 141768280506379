<div class="d-flex flex-column bg-none mx-4 py-2 rounded-lg">
    <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex flex-column">
            <h4 class="m-0 font-weight-bolder">Total to Pay</h4>
            <h5 #savings class="cursor-pointer font-weight-bold"(click)="toggleAccordian()" [ngClass]="{'text-secondary': showSavings === '0' }" [ngStyle]="{'opacity': showSavings === '1' ? '0.4' : '1'}">
                Total Savings
                <i class="fas fa-caret-down"></i>
            </h5>
        </div>
        <div class="d-flex flex-column align-items-right">
            <ng-container *ngIf="calculatingTotal">
                <mat-spinner diameter="34" value="accent" class="float-right"></mat-spinner>
            </ng-container>

            <ng-container *ngIf="!calculatingTotal">
                <h4 class="m-0 font-weight-bold text-right">
                    {{checkoutDetail.orderTotal | formatMoneyValue}}
                </h4>
                <h5 class="text-right font-weight-bolder" style="opacity: 0.4;" *ngIf="checkoutDetail.orderTotal != checkoutDetail.withoutDiscountTotal">
                    -{{getTotalSaving() | formatMoneyValue}}
                </h5>

            </ng-container>

        </div>
    </div>
    <div [@slideInOut]="showSavings">
        <div class="d-flex flex-column pr-3">
            <div *ngIf="checkoutDetail.savings.shipping" class="d-flex justify-content-between">
                <span class="text-muted">Free Shipping:</span>
                <span class="text-success">{{checkoutDetail.savings.shipping | currency}}</span>
            </div>
            <div *ngIf="checkoutDetail.savings.freeporting" class="d-flex justify-content-between">
                <span class="text-muted">Free Number Transfer:</span>
                <span class="text-success">{{checkoutDetail.savings.freeporting | currency}}</span>
            </div>
            <div *ngIf="checkoutDetail.savings.numberactivation" class="d-flex justify-content-between">
                <span class="text-muted">Free Number Activation:</span>
                <span class="text-success">{{checkoutDetail.savings.numberactivation | currency}}</span>
            </div>
            <div *ngIf="checkoutDetail.savings.annual" class="d-flex justify-content-between">
                <span class="text-muted"> 20% off on Annual Subscription:</span>
                <span class="text-success">{{checkoutDetail.savings.annual | currency}}</span>
            </div>
            <div *ngIf="checkoutDetail.savings.hardware" class="d-flex justify-content-between">
                <span class="text-muted">Equipment Discount:</span>
                <span class="text-success">{{checkoutDetail.savings.hardware | currency}}</span>
            </div>
            <div *ngIf="checkoutDetail.savings.freemonth" class="d-flex justify-content-between">
                <span class="text-muted">Free month:</span>
                <span class="text-success">{{checkoutDetail.savings.freemonth | currency}}</span>
            </div>
        </div>
    </div>
    <div *ngIf="!checkoutDetail.payAnnually" class="border-top text-muted text-center">
        Includes first and last month's service with shipping and tax.
    </div>
</div>
