<div>
    <h3 class="px-1 py-0 mb-3">Select the Phones for your Team</h3>
    <div class="row scrolling-content py-2">

        <div class="col-lg-4 pt-3 col-md-6 light-grey-background-color main-content"
            *ngFor="let phones of phones;let i=index" style="cursor: pointer;">
            <div class="d-flex flex-column h-100">
                <span class="phone-val" [style.color]="(phones.qty>0)?'#F73A0A':'#CECECE' ">{{phones.featureText}}</span>
                <div class="p-3 p-lg-2 bg-light-grey inner-content  d-flex flex-column h-100"
                    (click)="onphoneselect(phones.qty,phones,1)"
                    [style.border-color]="(phones.qty>0)?'#F73A0A':'#f9f9f9' ">
                    <div class="mb-auto">
                        <div class="d-flex justify-content-between mb-2 flex-wrap"
                            [ngClass]="{'invisible':(phones.price=='INCLUDED')}" id="">
                            <div class="phone-price font-weight-bolder d-flex">
                                <span>{{phones.price}}</span>&nbsp;<span>{{phones.withoutDiscountPriceValue}}</span>
                            </div>
                            <div style="visibility: hidden;"><button
                                    class="btn py-0 btn-primary font-weight-bolder rounded px-2"
                                    (click)="infoClick($event)">Info</button>
                            </div>
                        </div>
                        <div class="text-center">
                            <img src={{phones.image}} alt="phones-image" class="phone-img">
                        </div>
                        <div class="phone-heading pt-2 px-1 pb-1">{{phones.heading}}</div>
                    </div>
                    <div class="d-flex justify-content-center align-items-center" id="counter-div"
                        [style.visibility]="(phones.qty>0)?'visible':'hidden' "><button
                            class="btn btn-sm p-0  btn-primary rounded-circle   font-weight-bolder counter-button d-flex justify-content-center align-items-center"
                            (click)="removeCartItems(phones.heading,$event)">-</button>&nbsp;<span
                            class="counter-text px-2">{{phones?.qty}}</span>&nbsp;<button
                            class="btn btn-sm p-0  btn-primary rounded-circle   font-weight-bolder p-0 counter-button d-flex justify-content-center align-items-center"
                            (click)="addPhoneToCart(phones,1)">+</button>
                    </div>
                </div>
            </div>
        </div>



    </div>

</div>