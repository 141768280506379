import { Component } from '@angular/core';
import { AppStateSelectors, SaveCallFlowSelectionAction, OnDestroyCleanup, UpdateCompanyNumberAction } from '@voiply/shared/common-ui';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { User, CallFlow, CallFlowList, Users, CallFlowType, HelpText, CallFlowsHelpText, CompanyNumber, App } from '@voiply/shared/model';
import * as _ from 'lodash';
import { takeWhile } from 'rxjs/operators';
import { MaskPipe, MaskApplierService } from 'ngx-mask';

@Component({
  selector: 'voiply-configure-call-flow',
  templateUrl: './configure-call-flow.component.html',
  styleUrls: ['./configure-call-flow.component.css']
})
export class ConfigureCallFlowComponent extends OnDestroyCleanup {

  public callFlowsList: CallFlowList[] = [];
  public callFlow: CallFlow = new CallFlow;
  public users: Users[];
  public callFlowHelpText: CallFlowsHelpText;
  CALL_FLOW_TYPE: typeof CallFlowType = CallFlowType;
  companyNumbers: CompanyNumber[] = [];
  selectedNumber = ''
  callScenariosTitle = 'Call Scenarios';
  apps = [];
  mask = new MaskPipe(this.maskService);

  @Select(AppStateSelectors.callFlow) callFlow$: Observable<CallFlow>;
  @Select(AppStateSelectors.callFlowsList) callFlowsList$: Observable<CallFlowList[]>;
  @Select(AppStateSelectors.users) users$: Observable<Users[]>;
  @Select(AppStateSelectors.CallFlowHelpText) callFlowHelpText$: Observable<CallFlowsHelpText>;
  @Select(AppStateSelectors.companyNumbers) companyNumbers$: Observable<CompanyNumber[]>;
  @Select(AppStateSelectors.apps) apps$: Observable<App[]>;

  @Dispatch() saveCallFlowSelection = (callFlowSelection: any, companyNumber: CompanyNumber) => new SaveCallFlowSelectionAction(callFlowSelection, companyNumber);
  @Dispatch() updateCompanyNumber = (phoneNumber: CompanyNumber) => new UpdateCompanyNumberAction(phoneNumber);


  constructor(private maskService: MaskApplierService) {
    super();

    this.subscriptions$.add(this.callFlowsList$.subscribe(data => {
      this.callFlowsList = data;
    }))

    this.subscriptions$.add(this.users$.subscribe(data => {
      this.users = data;
    }))
    this.subscriptions$.add(this.callFlowHelpText$.subscribe(data => {
      this.callFlowHelpText = data;
    }))

    this.subscriptions$.add(this.apps$.subscribe(apps => {
      if (apps) {
        this.apps.push(..._.filter(apps, (app: App) => app.ivrEnable));
        this.apps.push({ title: 'Company Directory' });
      }
    }));

    this.subscriptions$.add(this.companyNumbers$.subscribe((companyNumbers) => {
      if (companyNumbers.length) {
        this.companyNumbers = companyNumbers;
        const selectedCompanyNumber: CompanyNumber = _.filter(this.companyNumbers, (o: CompanyNumber) => o.isSelectedCallFlow)[0];
        this.selectedNumber = selectedCompanyNumber.number
        if (!selectedCompanyNumber.isInternational)
          this.callScenariosTitle = 'Call Scenarios for ' + selectedCompanyNumber.name + ' ' + this.mask.transform(selectedCompanyNumber.number, '(000)000-0000');
        else
          this.callScenariosTitle = 'Call Scenarios for ' + selectedCompanyNumber.name + ' ' + this.sanitizeInternationalNumber(selectedCompanyNumber);

        this.callFlow = { ...selectedCompanyNumber.callFlow }

      } else {
        //subscribe to callflow only when companynumber callflows are not available. This is done to subscribe to previous orders that were not having companyNumber Object
        this.subscriptions$.add(this.callFlow$.pipe(takeWhile(data => !companyNumbers.length)).subscribe(data => {
          this.callFlow = data
          this.callScenariosTitle = 'Call Scenarios';
        }))
      }
    }))

  }

  onCallConfigurationChanged(callFlow: CallFlow): void {
    console.log('onCallConfigurationChanged', callFlow);
    const companyNumber = _.filter(this.companyNumbers, (o) => this.selectedNumber === o.number);
    this.saveCallFlowSelection(callFlow, companyNumber[0]);

  }
  sanitizeInternationalNumber(companyNumber) {
    return companyNumber.countryCode + ' ' + companyNumber.areaCode + ' ' + companyNumber.dialCode.substring(0, 3) + '-' + companyNumber.dialCode.substring(3, 7);
  }
}
