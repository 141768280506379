import { FormGroup, FormControl } from '@angular/forms';
import { Select } from '@ngxs/store';
import { AppStateSelectors } from '../+state';
import { Observable } from 'rxjs';
import { OnDestroyCleanup } from './on-destroy-cleanup.class';
import { OnInit } from '@angular/core';

export abstract class FormBase extends OnDestroyCleanup implements OnInit {

	private formIsValid = true;
	public form: FormGroup;
	public formSubmitted = false;

	// convenience getter for easy access to form fields
	get f(): any { return this.form.controls; }

	@Select(AppStateSelectors.doValidate) performValidation$: Observable<object>;

	public abstract formStatusChanged(isValid: boolean);

	constructor() {
		super();

		this.subscriptions$.add(
			this.performValidation$.subscribe(newValue => {
				if (newValue) this.validateAllFormFields(this.form);
			})
		);
	}

	initializeForm() {
		this.subscriptions$.add(
			this.form.statusChanges.subscribe(status => {
				const formValid = status === "VALID";
				if (formValid !== this.formIsValid) {
					this.formIsValid = formValid;
					this.formStatusChanged(formValid);
				}
			})
		);
	}

	ngOnInit(): void {
		this.initializeForm();
	}

	validateAllFormFields(formGroup: FormGroup) {
		if (formGroup) {
			Object.keys(formGroup.controls).forEach(field => {
				const control = formGroup.get(field);
				if (control instanceof FormControl) {
					control.markAsTouched({ onlySelf: true });
				}
				else if (control instanceof FormGroup) {
					this.validateAllFormFields(control);
				}
			});
		}

		if (this.formSubmitted)
			this.formSubmitted = false;
		setTimeout(() => {
			this.formSubmitted = true;
		}, 1);
	}
}
