import { Component } from '@angular/core';
import { ProductConfigurationBase } from '../product-configuration.base';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CartItemType } from '@voiply/shared/model';

@Component({
  selector: 'voiply-product-configuration-business',
  templateUrl: './product-configuration-business-new.component.html',
  styleUrls: ['./../product-configuration.base.scss']
})
export class ProductConfigurationBusinessComponent extends ProductConfigurationBase {
  constructor(toastr: ToastrService, modalService: BsModalService) {
    super(toastr, modalService);
  }

  addAndUpdateProductConfiguration() {
    this.productConfigurations$.subscribe((data) => {
      this._productconfigutrations = data;
      this.phonesconfiguration={}; // Reset the phones configuration
      this.appsconfiguration={};
      this._productConfigurationLength = Object.keys(this._productconfigutrations).length;
      // loop through all cart items
      _.each(this._productconfigutrations, (value, key) => {
        if (value.type === CartItemType.Phone)
          this.phonesconfiguration[key] = value;
        else if (value.type === CartItemType.App)
          this.appsconfiguration[key] = value;
      });
      this._phonesconfigurationLength = Object.keys(this.phonesconfiguration).length;
      this._appsconfigurationLength = Object.keys(this.appsconfiguration).length;
    });
  }
}
