import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatChipInputEvent } from '@angular/material/chips/index';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CallTransfer, FollowUp, QuestionsAnswers } from 'libs/shared/model/src/lib/human-attendant.model';
import * as _ from 'lodash';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal/';
import { LabelModelComponent } from '../human-attendant-modals/label-model/label-model.component';
import { IntroModelComponent } from '../human-attendant-modals/intro-model/intro-model.component';
import { QuestionModelComponent } from '../human-attendant-modals/question-model/question-model.component';

@Component({
  selector: 'voiply-questions-answers',
  templateUrl: './questions-answers.component.html',
  styleUrls: ['./questions-answers.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        height: '0px',
        opacity: 0,
        overflow: 'hidden'
      })),
      state('out', style({
        height: '*',
        opacity: 1,
        overflow: 'hidden'
      })),
      transition('in => out', animate('400ms')),
      transition('out => in', animate('400ms'))
    ]),
    trigger('childAnimation1', [
      state('in', style({
        transform: 'rotate(0deg)'
      })),
      state('out', style({
        transform: 'rotate(180deg)'
      })),
      transition('in => out', animate('200ms', style({ transform: 'rotate(180deg)' }))),
      transition('out => in', animate('200ms', style({ transform: 'rotate(0deg)' })))
    ])
  ]
})
export class QuestionsAnswersComponent implements OnInit {

  @Input() questionsAnswersHelpText = ''
  @Input() questionsAnswers: QuestionsAnswers[];
  @Output() questionsAnswersChanged = new EventEmitter<any>();
  selectedCallTransferText = [];
  showInputFollowUp;
  expandQuestion = 'in';
  editquestionLabel = false;
  selectedTabIndex = 0;
  selectedFlowIndex = 0
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  modalRef: BsModalRef;


  constructor(public modalService: BsModalService) { }

  ngOnInit() {
  }

  // toggleQuestions() {
  //   if (this.expandQuestion === 'in') {
  //     this.expandQuestion = "out"
  //   }
  //   else {
  //     this.expandQuestion = "in"
  //   }
  // }
  // addFollowUpOption(event: MatChipInputEvent, questionsIndex, followUpIndex): void {
  //   const input = event.input;
  //   const value = event.value;

  //   if ((value || '').trim()) {
  //     this.questionsAnswers.questions[questionsIndex].followUp[followUpIndex].options.push({ text: value });
  //     this.questionsAnswersChanged.emit(this.questionsAnswers);
  //   }
  //   // Reset the input value
  //   if (input) {
  //     input.value = '';
  //   }

  // }

  // removeFollowUpOption(questionsIndex, followUpIndex, optionIndex): void {
  //   this.questionsAnswers.questions[questionsIndex].followUp[followUpIndex].options.splice(optionIndex, 1);
  //   this.questionsAnswersChanged.emit(this.questionsAnswers);
  // }

  // addFollowUp(questionsIndex) {
  //   this.questionsAnswers.questions[questionsIndex].followUp.push(new FollowUp);
  //   this.questionsAnswersChanged.emit(this.questionsAnswers);
  // }
  // removeFollowUp(questionsIndex, followUpIndex) {
  //   this.questionsAnswers.questions[questionsIndex].followUp.splice(followUpIndex, 1);
  //   this.questionsAnswersChanged.emit(this.questionsAnswers);
  // }

  onDeleteFlow(index) {
    this.questionsAnswers[this.selectedTabIndex].tabValue.questions.splice(index, 1)
    this.questionsAnswersChanged.emit(this.questionsAnswers);
  }

  editFollowUp(index) {
    if (this.showInputFollowUp === index) {
      this.showInputFollowUp = -1;
    } else {
      this.showInputFollowUp = index;
    }
  }

  // addCallTransfer(questionsIndex) {
  //   this.questionsAnswers.questions[questionsIndex].callTransfer.push(new CallTransfer);
  //   this.questionsAnswersChanged.emit(this.questionsAnswers);
  // }
  // removeCallTransfer(questionsIndex, callTransferIndex) {
  //   this.questionsAnswers.questions[questionsIndex].callTransfer.splice(callTransferIndex, 1);
  //   this.questionsAnswersChanged.emit(this.questionsAnswers);
  // }

  getAlphabets(index) {
    return String.fromCharCode(index + 65);
  }

  findFollowUpExist(options) {
    return options.some(element => {
      return element.followUp
    });
  }

  tabChanged(event) {
    this.selectedTabIndex = event.index
  }

  addtab() {

  }

  onEditLabel(labelName) {
    const initialState = {
      label: labelName
    };
    this.modalRef = this.modalService.show(LabelModelComponent, { initialState, backdrop: 'static' });
    this.modalRef.content.nameChange.subscribe(
      result => {
        this.questionsAnswers[this.selectedTabIndex].tab = result
        this.questionsAnswersChanged.emit(this.questionsAnswers);

        // phoneNumber.name = result
        // this.updateCompanyNumber(phoneNumber)
      }
    )
  }

  onEditInto(introText) {
    const initialState = {
      introText: introText
    };
    this.modalRef = this.modalService.show(IntroModelComponent, { initialState, backdrop: 'static', class: 'modal-lg' });
    this.modalRef.content.introChange.subscribe(
      result => {
        this.questionsAnswers[this.selectedTabIndex].tabValue.intro.agentResponse = result
        this.questionsAnswersChanged.emit(this.questionsAnswers);

        // phoneNumber.name = result
        // this.updateCompanyNumber(phoneNumber)
      }
    )
  }

  onAddNewQuestion(selectedFlowIndex) {
    this.selectedFlowIndex = selectedFlowIndex
    const initialState = {
      selectedType: 'text',
      questionText: ''
    };
    this.modalRef = this.modalService.show(QuestionModelComponent, { initialState, backdrop: 'static', class: 'modal-lg' });
    this.modalRef.content.followUp.subscribe(
      result => {
        this.questionsAnswers[this.selectedTabIndex].tabValue.questions[this.selectedFlowIndex].followUp.push({
          parentId: this.questionsAnswers[this.selectedTabIndex].tabValue.questions[this.selectedFlowIndex].id,
          id: result.id,
          agentResponse: result.agentResponse,
          options: result.options,
          input: result.input,
          actionsAllowed: '',
          isActive: true,

        })
        this.questionsAnswersChanged.emit(this.questionsAnswers);
      }
    )
  }


  addNewTab() {
    this.questionsAnswers.push(new QuestionsAnswers)
    this.questionsAnswersChanged.emit(this.questionsAnswers);
  }

  shiftHumanAttendantCallFlow(questionsIndex, direction) {
    if (direction === 'up') {
      this.questionsAnswers[this.selectedTabIndex].tabValue.questions = this.array_move(this.questionsAnswers[this.selectedTabIndex].tabValue.questions, questionsIndex, (questionsIndex - 1))

    } else {
      this.questionsAnswers[this.selectedTabIndex].tabValue.questions = this.array_move(this.questionsAnswers[this.selectedTabIndex].tabValue.questions, questionsIndex, (questionsIndex + 1))
    }
    this.questionsAnswersChanged.emit(this.questionsAnswers);

  }

  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };
}
