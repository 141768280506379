import { Component, OnInit } from '@angular/core';
import { TestimonialService } from '../../services/testimonial.service';
import { Testimonial } from '../../model/testimonial.model';
import { OnDestroyCleanup } from '@voiply/shared/common-ui';

@Component({
  selector: 'voiply-testimonial-new',
  templateUrl: './testimonial-new.component.html',
  styleUrls: ['./testimonial-new.component.scss']
})
export class TestimonialNewComponent extends OnDestroyCleanup implements OnInit {
  testimonials: Testimonial[];

  constructor(private service: TestimonialService) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions$.add(
      this.service.getTestimonials().subscribe(data => {
        this.testimonials = data.results;

        let dayToSubtract = 2;
        this.testimonials.forEach(testimony => {
          testimony.ts = ((Date.now().valueOf() - dayToSubtract * 24 * 60 * 60 * 1000)/1000)
          dayToSubtract += Math.round(Math.random() * 15);
        });
      })
    );
  }
}
