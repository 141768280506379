<!-- <div class="text-center pb-2" style="margin:0 20px;">
    <div class="btn-group btn-group-toggle w-100 rounded-lg bg-grey" data-toggle="buttons">
        <label class="py-4 cursor-pointer" [ngClass]="{'btn': true, 'active bg-secondary text-secondary': !payAnnually}" (click)="changePlan.emit(false)">
          <div class="d-flex align-items-center justify-content-center h-100 font-weight-bold">
            <svg id="refresh" xmlns="http://www.w3.org/2000/svg" width="25.465" height="25.465" viewBox="0 0 25.465 25.465" [attr.fill]="payAnnually? '#000000': '#f63b0b'">
              <path id="Path_1025" data-name="Path 1025" d="M3.058,13.907a10.075,10.075,0,0,1-.074-1.174A9.708,9.708,0,0,1,19.272,5.555l-.86.875a.763.763,0,0,0-.194.746.781.781,0,0,0,.567.507c.15.03,5.084,1.62,4.77,1.557a.754.754,0,0,0,.88-.88c-.03-.15-1.123-5.631-1.059-5.317a.739.739,0,0,0-1.253-.373l-.731.716A12.741,12.741,0,0,0,0,12.733v.03c0,.421.019.746.042.994a.746.746,0,0,0,.6.664l1.533.306a.747.747,0,0,0,.887-.821Zm0,0" transform="translate(0.001 0)"/>
              <path id="Path_1026" data-name="Path 1026" d="M44.259,215.75l-1.537-.313a.747.747,0,0,0-.89.821,10.157,10.157,0,0,1,.077,1.181A9.7,9.7,0,0,1,25.62,224.6l.86-.86a.763.763,0,0,0,.194-.746.78.78,0,0,0-.567-.507c-.15-.03-5.084-1.62-4.77-1.557a.731.731,0,0,0-.671.209.743.743,0,0,0-.209.671l1.06,5.267a.747.747,0,0,0,.522.567.717.717,0,0,0,.731-.194l.716-.716a12.713,12.713,0,0,0,21.407-9.3v-.045c0-.331-.012-.655-.04-.978a.746.746,0,0,0-.595-.667Zm0,0" transform="translate(-19.428 -204.707)"/>
            </svg>
            <input type="radio" name="planOption" autocomplete="off" [checked]="!payAnnually">
            <span style="font-size:18px;" class="pl-1 ">Monthly Billing</span>
          </div>
        </label>
        <label class="py-4 cursor-pointer" [ngClass]="{'btn': true, 'active bg-secondary text-secondary': payAnnually}" (click)="changePlan.emit(true)">
          <div class="d-flex align-items-center justify-content-center font-weight-bold">
            <svg id="calendar_2_" data-name="calendar (2)" xmlns="http://www.w3.org/2000/svg" width="26.452" height="26.452" viewBox="0 0 26.452 26.452" [attr.fill]="payAnnually? '#f63b0b': '#000000'">
              <g id="Group_465" data-name="Group 465">
                <g id="Group_464" data-name="Group 464">
                  <ellipse id="Ellipse_47" data-name="Ellipse 47" cx="1.122" cy="1.122" rx="1.122" ry="1.122" transform="translate(18.85 9.846)"/>
                  <path id="Path_1036" data-name="Path 1036" d="M22.319,2.067H20.976V1.033a1.033,1.033,0,1,0-2.067,0V2.067h-4.7V1.033a1.033,1.033,0,1,0-2.067,0V2.067H7.491V1.033a1.033,1.033,0,1,0-2.067,0V2.067H4.133A4.138,4.138,0,0,0,0,6.2V22.319a4.138,4.138,0,0,0,4.133,4.133h7.9a1.033,1.033,0,0,0,0-2.067h-7.9a2.069,2.069,0,0,1-2.067-2.067V6.2A2.069,2.069,0,0,1,4.133,4.133H5.425V5.166a1.033,1.033,0,1,0,2.067,0V4.133h4.65V5.166a1.033,1.033,0,1,0,2.067,0V4.133h4.7V5.166a1.033,1.033,0,1,0,2.067,0V4.133h1.343A2.069,2.069,0,0,1,24.386,6.2v5.89a1.033,1.033,0,1,0,2.067,0V6.2A4.138,4.138,0,0,0,22.319,2.067Z"/>
                  <ellipse id="Ellipse_48" data-name="Ellipse 48" cx="1.122" cy="1.122" rx="1.122" ry="1.122" transform="translate(14.362 9.846)"/>
                  <ellipse id="Ellipse_49" data-name="Ellipse 49" cx="1.122" cy="1.122" rx="1.122" ry="1.122" transform="translate(9.874 14.334)"/>
                  <ellipse id="Ellipse_50" data-name="Ellipse 50" cx="1.122" cy="1.122" rx="1.122" ry="1.122" transform="translate(5.386 9.846)"/>
                  <ellipse id="Ellipse_51" data-name="Ellipse 51" cx="1.122" cy="1.122" rx="1.122" ry="1.122" transform="translate(5.386 14.334)"/>
                  <ellipse id="Ellipse_52" data-name="Ellipse 52" cx="1.122" cy="1.122" rx="1.122" ry="1.122" transform="translate(5.386 18.822)"/>
                  <ellipse id="Ellipse_53" data-name="Ellipse 53" cx="1.122" cy="1.122" rx="1.122" ry="1.122" transform="translate(9.874 18.822)"/>
                  <ellipse id="Ellipse_54" data-name="Ellipse 54" cx="1.122" cy="1.122" rx="1.122" ry="1.122" transform="translate(9.874 9.846)"/>
                </g>
              </g>
            </svg>
            <input type="radio" name="planOption" autocomplete="off" [checked]="payAnnually">
            <span class="pl-1"><span style="font-size:18px; position: relative; top: 3px;">Annual Billing</span><br> <span class="m-0 text-primary text-uppercase" style=" font-size: 10px; position: absolute; top: -1px; right: -1px; border-top-right-radius: 5px; background-color: #515151; padding: 3px 12px;">Save 20%</span></span>
          </div>
        </label>
    </div>
</div> -->

<!-- New UI -->
<div class="text-center pb-2" style="margin:0 20px;">
  <div class="btn-group btn-group-toggle w-100 rounded-lg bg-grey" data-toggle="buttons">
      <label class="py-2 cursor-pointer" [ngClass]="{'btn': true, 'active bg-secondary text-secondary': !payAnnually}" (click)="changePlan.emit(false)">
        <div class="d-flex align-items-center justify-content-center h-100 p-1">
          <input type="radio" name="planOption" autocomplete="off" [checked]="!payAnnually">
          <span style="font-size:18px;" class="pl-1 ">Monthly Billing</span>
        </div>
      </label>
      <label class="py-2 cursor-pointer" [ngClass]="{'btn': true, 'active bg-secondary text-secondary': payAnnually}" (click)="changePlan.emit(true)">
        <div class="d-flex align-items-center justify-content-center p-1">
          <input type="radio" name="planOption" autocomplete="off" [checked]="payAnnually">
          <span class="pl-1"><span style="font-size:18px; position: relative; top: 3px;">Annual Billing</span><br> <span class="m-0 text-primary text-uppercase" style=" font-size: 10px; position: absolute; top: -10px; right: -1px; border-radius: 20px; background-color: #515151; padding: 3px 12px;">2 months free</span></span>
        </div>
      </label>
  </div>
</div>

