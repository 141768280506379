import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'voiply-product-configuration-phone-status',
  templateUrl: './product-configuration-phone-status.component.html',
  styleUrls: ['./product-configuration-phone-status.component.scss']
})
export class ProductConfigurationPhoneStatusComponent implements OnInit {
  @Input() phoneStatus: any;
  constructor() { }

  ngOnInit() {
  }

}
