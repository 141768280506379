import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'common-ui-line-graph',
  templateUrl: './line-graph.component.html',
  styleUrls: ['./line-graph.component.scss']
})
export class LineGraphComponent implements OnInit, AfterViewInit {

  @ViewChild('lineChart') lineChart: ElementRef;
  @Input() labels = []
  constructor() { }


  ngOnInit(): void {
    this.labels = this.getDaysInMonth(new Date().getMonth(), new Date().getFullYear())
  }

  ngAfterViewInit() {
    var myChart = new (window as any).Chart(this.lineChart.nativeElement.getContext('2d'), {
      type: 'line',
      data: {
        labels: this.labels,
        datasets: [{
          label: 'calls',
          data: [12, 19, 3, 17, 6, 3, 7],
          backgroundColor: "rgba(255,255,255,0)",
          borderColor: "rgb(246,59,11)",
          pointBackgroundColor: "rgb(246,59,11)",
          fill: true,
          lineTension: 0.2
        }]
      }
    });
  }

  getDaysInMonth(month, year) {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
      days.push((new Date(date)).toLocaleDateString("en-US", { month: 'short', day: 'numeric' }));
      date.setDate(date.getDate() + 1);
    }
    return days;
  }
}
