import { CallFlowType } from './enums';
import { RingCallFlow } from './ring-call-flow.model';
import { HumanAttendant } from './human-attendant.model';

export class CallFlow {
    type: CallFlowType = CallFlowType.None;
    ringCallFlow: RingCallFlow = null; //new RingCallFlow();
    autoAttendantOptions: any = null;
    autoAttendantRingCallFlow: any = null;
    skipConfig: boolean = false;
    humanAttendant: HumanAttendant = new HumanAttendant();
}