import { Component, Input, Renderer2, ViewChild } from '@angular/core';
import spacetime from 'spacetime';
import { CheckoutBase } from '../checkout.base';
import { CrispService, Validate, checkPhoneIsValid, ValidateCartItem, checkZipCodeIsValid, APPSCONST, } from '@voiply/shared/common-ui';
import { SystemType, CheckoutDetail, Address, CartItemType, ShippingAddress, CallFlowType, AutoAttendantOption, AutoAttendantType, CartItem, CompanyNumber } from '@voiply/shared/model';

import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@voiply/auth0';
import * as _ from 'lodash';
import { ConversionService, OrderService, ShippingService, UserService } from 'libs/shared/common-ui/src/lib/services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'voiply-checkout-business',
  templateUrl: './checkout-business.component.html',
  styleUrls: ['./../checkout.base.css']
})
export class CheckoutBusinessComponent extends CheckoutBase {

  @ViewChild('popError') popError;
  @Input()  isShowShareOrder:boolean;
  total_discount;
  isPrimaryPhoneNumberNew;

  saveNumberInDb=true;
  constructor(
    crispService: CrispService,
    shippingService: ShippingService,
    tostr: ToastrService,
    authService: AuthService,
    modalService: BsModalService,
    renderer: Renderer2,
    userService: UserService,
    conversionService: ConversionService,
    orderService: OrderService,
    router: Router,
    route: ActivatedRoute,
    location: Location
  ) {
    super(crispService, shippingService, SystemType.Business, tostr, authService, modalService, renderer, userService, conversionService, orderService, router, route, location
    );
    this.subscriptions$.add(this.cardProcess$.subscribe((cardProcess) => {
      this.isCardProcessing = cardProcess.isCardProcessing;
      if (cardProcess.isCardProcessing === false) {
        // this.authService.login();
      }
    }
    ));
    this.subscriptions$.add(this.updateShipping$.subscribe((fetchOptions) => {
      this.printTotalDiscount();
      if (fetchOptions) this.shippingService.fetchShippingOptionsBusiness(this.orderId)
    }));

    
  }

  ngOnInit(){
    if(this.isShowShareOrder==false && this.saveNumberInDb){
      this.saveNumberInDb=false;
      this.setPrimaryPhoneNumber({ number: '0000000000', isNew: false, systemSelected: false });
    }
    this.primaryPhoneDetails$.subscribe(
      (number)=>{
        this.isPrimaryPhoneNumberNew=number.isNew;
      }
      
    );
  }

  printTotalDiscount(){
    let ts = 0;
    // for(let savingItem of Object.keys(this.shippingService.checkoutDetail.savings || {})) {
    //   ts += this.shippingService.checkoutDetail.savings[savingItem];
    // }
    if(!this.shippingService.checkoutDetail.payAnnually){
      ts=(12*0.2*this.shippingService.checkoutDetail.monthlyTotal);
    }
    else{
      ts=this.shippingService.checkoutDetail.savings["annual"];
    }
    this.total_discount=ts;
  }

  canPayForOrder() {
    // validate check out form
    // validate phone number form

    // Validate call config options form
    // validate cart items form
    // if (!this.checkAllFormsAreValid) {
    //   return false;
    // }

    let hasOnlyFax = true;
    const phoneNumbers = [];
    let faxNumber = '';
    let isValidCartItem = true;
    this.payNowErrorMessage = '';

    //This check is so the user cannot checkout without a phone or onlinefax
    if (!_.find(this.cartItems, (cartItem: CartItem) => cartItem.type === CartItemType.Phone)) {
      if (!_.find(this.cartItems, (cartItem: CartItem) => cartItem.heading === APPSCONST.ONLINEFAX)) {
        this.tostr.warning(`Add a Phone or Online Fax in your cart to proceed`);
        return;
      }
      if (_.find(this.cartItems, (cartItem: CartItem) => cartItem.heading === APPSCONST.SPAMCALLBOT || cartItem.heading === APPSCONST.EMERGENCY || cartItem.heading === APPSCONST.CALLRECORDING)) {
        this.tostr.warning(`Add a Phone in your cart to proceed`);
        return;
      }
    }
    this.UpdateCartItemProtectionPlan();

    this.getDefaultRingOneCallFlow(); 


    _.each(this.cartItems, (item, key) => {
      if (item.type === CartItemType.Phone) {
        if (item.configuration.firstName === undefined || item.configuration.firstName === "" || item.configuration.firstName.includes("User"))
          if (this.billingAddress.name) {
            const a = this.billingAddress.name.lastIndexOf(' ')  // last occurence of space
            item.configuration.firstName = (a === -1) ? this.billingAddress.name : this.billingAddress.name.substring(0, a);
            item.configuration.lastName = (a === -1) ? '' : this.billingAddress.name.substring(a + 1);
          }
        if (item.configuration.email === undefined || item.configuration.email === "")
          item.configuration.email = this.billingAddress.email;
        this.updateCartItemConfigurationAction(key, item.configuration);
        if (item.heading == 'Bring Your Device') //update this status to true if atleast one BYOD phone is in cart. Otherwise false.
          this.hasBYODphone = true;
      }
      isValidCartItem = ValidateCartItem(item);
      if (!isValidCartItem) {
        this.tostr.error(`Some of your phones/apps need additional configuration.`);
        this.payNowErrorMessage = 'Some of your phones/apps need additional configuration. Please see above.'
        this.popError.show();
        return false;
      };

      const config = item.configuration || {};

      if (item.heading === APPSCONST.ONLINEFAX)
        faxNumber = config.mobile;

      if (item.heading !== APPSCONST.ONLINEFAX)
        hasOnlyFax = false;

      if (config.phoneNumber && config.phoneNumber !== '')
        phoneNumbers.push(config.phoneNumber);
      else if (config.mobile && config.mobile !== '')
        phoneNumbers.push(config.mobile);
    });

    this.updateBYODStatus(this.hasBYODphone);

    if (!isValidCartItem) {
      this.tostr.error(`Some of your phones/apps need additional configuration.`);
      this.payNowErrorMessage = 'Some of your phones/apps need additional configuration. Please see above.'
      this.popError.show();
      return false;
    }
    // Validate phone number
    
    if (!hasOnlyFax) {
      if (!checkPhoneIsValid(this.primaryPhoneNumber)) {
        this.tostr.error(`Please select phone number`);
        this.payNowErrorMessage = 'Please select phone number';
        this.popError.show();
        return false;
      }
    } else {
      _.each(this.companyNumbers, (value, key) => {
        this.removeCompanyNumber(value)
      })
    }



    //Checkout Validation


    if (this.billingAddress.country === 'US' || this.billingAddress.country === 'CA') {
      if (!checkZipCodeIsValid(this.billingAddress.zip)) {
        this.payNowErrorMessage = 'Your zip code in Billing Address is incorrect.';
        this.popError.show();
        return false;
      }
    }

    if (this.shippingAddress.country === 'US' || this.shippingAddress.country === 'CA') {
      if (!checkZipCodeIsValid(this.shippingAddress.zip)) {
        this.payNowErrorMessage = 'Your zip code in Shipping Address is incorrect.';
        this.popError.show();
        return false;
      }
    }

    const isValidCompanyName = Validate(CheckoutDetail, this.shippingService.checkoutDetail)
    if (!isValidCompanyName) {
      this.payNowErrorMessage = 'Your Company Name is missing or is incorrect.';
      this.popError.show();
      return false;
    }

    const isValidBillingAddress = Validate(Address, this.billingAddress);
    if (!isValidBillingAddress) {
      this.payNowErrorMessage = 'Your Billing Address has some missing value or is incorrect.';
      this.popError.show();
      return false;
    }
    if (!this.shippingService.checkoutDetail.isShippingSameAsBillingAddress) {
      const isValidShippingAddress = Validate(ShippingAddress, this.shippingAddress);
      if (!isValidShippingAddress) {
        this.payNowErrorMessage = 'Your Shipping Address has some missing value or is incorrect.';
        this.popError.show();
        return false;
      }
    }


    if (this.primaryPhoneNumber && this.primaryPhoneNumber === faxNumber) {
      this.payNowErrorMessage = `You cannot use number '${faxNumber}' as Fax number.`;
      this.popError.show();
      return false;
    }

    const numbers = [];
    let duplicates = [];
    phoneNumbers.forEach(n => {
      if (numbers.indexOf(n) >= 0)
        duplicates.push(n);
      else
        numbers.push(n);
    });

    if (duplicates.length > 0) {
      duplicates = _.unique(duplicates);
      this.payNowErrorMessage = `Following number${duplicates.length > 1 ? 's are' : ' is'} used multiple times in phone/app, ${_.unique(duplicates).join()}.`
      this.popError.show();
      return false;
    }

    // //Check if a call flow is selected
    // if (!this.checkCallFlowIsSelected) {
    //   this.payNowErrorMessage = 'Please select a Call Scenario.';
    //   this.popError.show();
    //   return false;
    // }
    let isValidVoiceMailEmail = true;
    if (this.callFlow.type === CallFlowType.AutoAttendant) {
      _.each(this.callFlow.autoAttendantOptions, (value: AutoAttendantOption, key) => {
        if (key === '+' && value.type === AutoAttendantType.VoiceMail) {
          if (!value.voiceMailTo) {
            this.tostr.error('Please enter an email address for your IVR timeout option.');
            this.payNowErrorMessage = 'Please enter an email address for your IVR timeout option.';
            this.popError.show();
            isValidVoiceMailEmail = false
            return false;
          }
        }
      });
      if (!isValidVoiceMailEmail) {
        return false;
      }
    }
    // Validate Terms & Condition
    // if (!this.acceptTerms) {
    //   this.payNowErrorMessage = "Please accept 'Voiply Terms of Service and Privacy Policy' first.";
    //   this.popError.show();
    //   return false;
    // }

    // Check for PO Box number
    if ((this.shippingService.checkoutDetail.billingAddress.address).toLowerCase().startsWith('po box')) {
      if (this.shippingService.checkoutDetail.isShippingSameAsBillingAddress) {
        this.tostr.error(`We can not deliver to a PO Box.`);
        return false;
      }
    }

    return true;
  }

}
