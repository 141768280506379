<div class="my-3">
  <div class="card bg-secondary">
    <div class="row paddingRow align-items-center">
      <div class="col-xs-12 col-md-8">
        <div class="row text-primary">
          <div class="col-12">
            <h3 class="font-weight-bold mb-3">
              Looking for <span class="text-secondary">{{ title }}</span>
            </h3>
          </div>
          <div class="col-10" style="color: #ffffff73">{{ content }}</div>
        </div>
      </div>
      <div class="col-sm-3 col-md-2 offset-md-1 d-none d-md-block text-center ">
        <img src="/assets/images/footerlogo.png" alt="" class="footerImg" />
      </div>
    </div>
  </div>
</div>