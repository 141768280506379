import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { CallFlow, Users, RingType, CallFlowType, CallFlowsHelpText } from '@voiply/shared/model';

@Component({
  selector: 'voiply-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {

  @Output() configurationChanged = new EventEmitter<any>();

  @Input() users: Users[];
  @Input() callFlow: CallFlow;
  @Input() callFlowHelpText: CallFlowsHelpText;
  RING_TYPE: typeof RingType = RingType;
  constructor() { }

  ngOnInit() {
  }

  updateConfiguration() {
    this.configurationChanged.emit(this.callFlow);
  }
  updateBusinessHours(changes) {
    this.callFlow.ringCallFlow = { ...changes };
    this.updateConfiguration();
  }
  toggleUserSelection(userId: string) {
    if (this.callFlow.ringCallFlow.ring.users[userId] == undefined) {
      this.callFlow.ringCallFlow.ring.users[userId] = true;
    } else {
      this.callFlow.ringCallFlow.ring.users[userId] = null;
    }
    this.updateConfiguration();
  }
  changeRingTypeSelection(value) {
    this.callFlow.ringCallFlow.ring.type = value;
    this.updateConfiguration();
  }
  onVoiceMailUserChanged(userId) {
    this.callFlow.ringCallFlow.voiceMailUser = userId;
    this.updateConfiguration();
  }
  onTimeZoneChange(timeZone) {
    this.callFlow.ringCallFlow.timeZone = timeZone;
    this.updateConfiguration();
  }
  onvoicemailRingTimeoutCountChanged(ringCount) {
    this.callFlow.ringCallFlow.voicemailRingTimeoutCount = parseInt(ringCount);
    this.updateConfiguration();
  }
}
