<voiply-share-order *ngIf="isShowShareOrder" id="share-order" [orderId]="orderId" (shareOrder)="onShareOrder($event)"
    [billingAddress]="billingAddress">
</voiply-share-order>
<!-- <common-ui-save-quote></common-ui-save-quote> -->

<!-- <div class=" mt-3" id="checkout"> -->

<!-- <common-ui-section-header imagePath="/assets/images/online-store-shopping-cart.png" title="Express Checkout" rightSideImagePath="/assets/images/secure-payment.png">
    </common-ui-section-header> -->

<div class="checkoutScroll my-3 card py-5 px-lg-4" style="border-radius: 0% !important; border: 1px solid #ececec;" id="checkout">
    <div class="card-header bg-white border-0">
        <div style="position: relative;">
            <i class="d-none d-lg-block fal fa-long-arrow-right section-arrow d-none d-lg-block"
                style="position: absolute; left: -60px; top: -10px; font-size: 60px; color:#D3D4D5"></i>
        </div>
        <div class="d-lg-flex justify-content-between">
            <div class="h2 font-weight-bolder text-capitalize heading-font">
                Review your order
            </div>
            <!-- <div class="d-flex flex-column">
                <span class="px-4 py-2 bg-light-grey cursor-pointer rounded" (click)="moneyBackGuaranteeModel.show()">
                    <span class="text-uppercase font-weight-bolder px-1 rounded">Hassle-free returns within <span
                            class="text-secondary">30 days</span> after delivery</span>
                </span>
                <div class="d-flex flex-row justify-content-center">
                    <span class="text-uppercase font-weight-bolder mx-1 my-auto">Secure payment</span>
                    <div class="d-flex flex-column flex-sm-row justify-content-center">
                        <img class="bg-black mx-1 img-fluid" src="/assets/images/stripe.png"
                            style="border-radius: 0.5rem; height:30px!important">
                        <img class="mx-1 img-fluid" style="height:30px!important" src="/assets/images/cards.png">
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <!-- </div> -->

    <div class="card-body">
        <div class="row bg-white">
            <!-- Billing Address, stripe and payment section -->
            <div class="col-md-12 col-lg-6 col-sm-12">
                <div class="mb-md-4 py-3 rounded-lg bg-light-grey">
                    <div *ngIf="shippingService.checkoutDetail">
                        <div class="billing info">
                            <checkout-billing-new [showCompanyNameField]=true [billingAddress]="billingAddress"
                                (billingAddressChange)="billingAddressChange($event)"
                                [companyName]="shippingService.checkoutDetail.companyName"
                                (companyNameChange)="onCompanyNameChange($event)">
                            </checkout-billing-new>
                            <checkout-stripe-new #stripe
                                [billingDetails]="shippingService.checkoutDetail.billingAddress"
                                [orderId]="this.orderId" [totalAmount]="shippingService.checkoutDetail.orderTotal || 0"
                                [shippingAmount]="shippingService.checkoutDetail.shippingCharges"
                                [shippingLabel]="'Shipping charges $' + shippingService.checkoutDetail.shippingCharges"
                                [shippingDetail]="'Shipping charges $' + shippingService.checkoutDetail.shippingCharges"
                                (stripeDataChanged)="stripeDataChanged($event)"
                                (paymentSuccess)="paymentSuccess($event)" (paymentFailed)="paymentFailed($event)">
                            </checkout-stripe-new>
                            <!-- Billing same as shipping -->
                            <div class="border-bottom mx-4 pb-1"></div>
                            <div class="mt-2 pt-1">
                                <div class="bg-grey d-flex justify-content-between align-items-center mx-4 mb-2 px-3 py-2 rounded"
                                    style="line-height: 2.4rem;">
                                    <label class="m-0 font-weight-bold checkbox-label w-100"> Same Address Shipping
                                        <input class="checkbox-input" type="checkbox" #shippingSameAsBillingCheckbox
                                            name="shippingSameAsBilling"
                                            [checked]="shippingService.checkoutDetail.isShippingSameAsBillingAddress"
                                            (change)="onShippingSameAsBillingChanged(shippingSameAsBillingCheckbox.checked)">
                                        <span class="checkbox-mark mt-1"></span>
                                    </label>
                                </div>
                                <checkout-shipping-address-new [shippingAddress]="shippingAddress"
                                    (shippingAddressChange)="shippingAddressChange($event)"
                                    *ngIf="!shippingService.checkoutDetail.isShippingSameAsBillingAddress">
                                </checkout-shipping-address-new>
                            </div>
                            <!-- <div class="mt-2 custom-checkbox">
                                <div class="pl-2 bg-primary">
                                    <label class="checkbox-container text-primary m-0 font-weight-bold inner-header"> I agree to the <a class="text-primary" style=" text-decoration: underline;" href="https://www.voiply.com/terms.html" target="_blank">Terms of Service</a> and <a class="text-primary" style="text-decoration: underline;" href="https://www.voiply.com/privacy-policy.html" target="_blank">Privacy Policy</a>.
                                    <input type="checkbox" [checked]="acceptTerms" (change)="acceptTerms=!acceptTerms">
                                    <span class=" checkmark mt-3"></span>
                                    </label>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12 col-lg-6 col-sm-12">
                <div class="p-3 rounded-lg bg-light-grey">
                    <!-- <div class="d-flex align-items-center">
                        <div class="flex-grow-1 inner-header">
                            <span class="font-weight-bolder text-uppercase text-secondary ml-4 h5">Cart Summary</span>
                        </div>
                    </div> -->
                    <!-- Cart Items -->
                    <checkout-cart-items-new [cartItems]="cartItems" [phones]="phones$ | async"
                    [apps]="apps$ | async" [payAnnually]="shippingService.checkoutDetail.payAnnually">
                    </checkout-cart-items-new>

                    <!-- Monthly Billing -->
                    <checkout-billing-plan-new (changePlan)="changePlan($event)"
                    [payAnnually]="shippingService.checkoutDetail.payAnnually">
                    </checkout-billing-plan-new>
                    
                    <ul class="list-group list-group-flush" style="border-top: 0 none;">
                        

                        <li class="list-group-item bg-light-grey" *ngIf="hasCartItems"
                                    style="padding: 0.75rem 0 !important;border-top: 0 none;">
                            <checkout-sub-total-new [calculatingTotal]="calculatingTotal"
                                [checkoutDetail]="shippingService.checkoutDetail">
                            </checkout-sub-total-new>
                        </li>

                        <!-- Taxes & Fees -->
                        <li class="list-group-item bg-light-grey"
                        *ngIf="hasCartItems && shippingService.checkoutDetail.billingAddress && shippingService.checkoutDetail.billingAddress.zip"
                        style="padding: 0.75rem 0 !important; border-top: 0 none;">
                        <checkout-tax-and-fees-new [taxDetail]="taxDetail"
                            [payAnnually]="shippingService.checkoutDetail.payAnnually"
                            [calculatingTotal]="calculatingTotal">
                        </checkout-tax-and-fees-new>
                        </li>

                        <li class="list-group-item bg-light-grey"
                        style="padding: 0.75rem 0 !important; border-top: 0 none;">
                        <div>
                            <h5 class="text-black font-weight-bolder mb-0" style="font-size: large;" *ngIf="!isPrimaryPhoneNumberNew">Transferring <span class="font-weight-light"> {{primaryPhoneNumber | mask:['(000) 000-0000']}} </span><h5 class="text-black font-weight-bolder float-right mb-0" style="font-size: large;">FREE</h5></h5>
                            <h5 class="text-black font-weight-bolder mb-0" style="font-size: large;" *ngIf="isPrimaryPhoneNumberNew">New Number <span class="font-weight-light"> {{primaryPhoneNumber | mask:['(000) 000-0000']}} </span>
                            <h5 class="text-black font-weight-bolder float-right mb-0" style="font-size: large;">FREE</h5></h5>

                        </div>
                        
                        </li>

                        <!-- Shipping Options -->
                        <li class="list-group-item bg-light-grey"
                            *ngIf="hasCartItems && cartHasHardware && shippingService.shippingOptions.length > 0"
                            style="padding: 0.75rem 0 !important; border-top: 0 none;">
                            <checkout-shipping-options-new [checkoutDetail]="shippingService.checkoutDetail"
                                [calculatingTotal]="calculatingTotal" (shippingChange)="shippingChange($event)">
                            </checkout-shipping-options-new>
                            <div class="mt-3 pt-3">
                                <checkout-protection-plan-new
                                    [hasProtectionPlan]="shippingService.checkoutDetail.hasProtectionPlan"
                                    (protectionPlanChanged)="onProtectionPlanStatusChanged($event)"
                                    [protectionPlanMonthlyCharge]="shippingService.checkoutDetail.protectionPlanTotal">
                                </checkout-protection-plan-new>
                            </div>
                        </li>
                    
                    </ul>
                    <!-- Total -->
                    <checkout-cart-total-new [calculatingTotal]="calculatingTotal"
                    [checkoutDetail]="shippingService.checkoutDetail">
                    </checkout-cart-total-new>

                    <!-- Saving Money  -->
                    <!-- <div class="border text-center ml-4 mr-4 p-2" style="border-radius: 15px;">
                        <span class="" style="font-size: medium; font-weight: bold;color:gray;" *ngIf="shippingService.checkoutDetail.payAnnually"> You are saving <span style="color: black;"> {{ (total_discount/12).toFixed(2) | formatMoneyValue }}/mo </span> with Annual Billing</span>
                        <span class="" style="font-size: medium; font-weight: bold; color:gray;" *ngIf="!shippingService.checkoutDetail.payAnnually"> Pay <span style="color: black;" (click)="changePlan(true)"> Annually </span> and save {{ (total_discount/12).toFixed(2) | formatMoneyValue }}/mo </span>
                    </div> -->
                    
                    <!-- Promo Code -->
                    <checkout-promo-code-new *ngIf="hasCartItems "
                    [checkoutDetail]="shippingService.checkoutDetail" [promoCodeApplied]="promoCodeApplied"
                    [cartHasHardware]="cartHasHardware" (applyPromoCode)="applyPromo($event)"
                    (removePromoCode)="removePromoCode()">
                    </checkout-promo-code-new>

                    
                    <div class="d-flex">
                        <button type="button" class="btn btn-primary mt-3 mx-4 rounded-lg flex-fill pay-now-btn"
                            (click)="validateOrderAndPay();"
                            [disabled]="calculatingTotal || payNowDisable||paymentLoading" [popover]="popErrorTemplate"
                            triggers="" placement="top" containerClass="popover-css-error" [outsideClick]="true"
                            #popError="bs-popover">
                            <h2
                                class="font-weight-bold text-center text-primary py-4 m-0 d-flex justify-content-center align-items-center text-uppercase">
                                place your order
                                <!-- <mat-spinner *ngIf="paymentLoading" class="white-spinner ml-3" diameter="25"></mat-spinner> -->
                            </h2>
                        </button>
                    </div>
                    <div class="mx-4 text-center p-2">
                        By placing your order, you agree to <a style=" text-decoration: underline; cursor: pointer;"
                            href="https://www.voiply.com/terms.html" target="_blank">Terms of Service</a>
                    </div>
                    
                    <div class="d-flex flex-column p-2">
                        <div class="d-flex flex-row justify-content-center">
                            <div class="d-flex flex-column flex-sm-row justify-content-center">
                                <img class="bg-black mx-1 img-fluid" src="/assets/images/stripe.png"
                                    style="border-radius: 0.5rem; height:30px!important">
                                <img class="mx-1 img-fluid" style="height:30px!important" src="/assets/images/cards.png">
                            </div>
                        </div>
                    </div>

                    <div class=" p-2 text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24.018" height="28.996" viewBox="0 0 24.018 28.996">
                            <g id="Group_865" data-name="Group 865" transform="translate(-149.412 -1191.027)">
                              <path id="computer-security-shield" d="M53.823,4.531C48.875,4.531,45.085,3.117,41.88,0c-3.2,3.117-7,4.531-11.943,4.531,0,8.116-1.68,19.744,11.943,24.465C55.5,24.274,53.823,12.647,53.823,4.531Z" transform="translate(119.541 1191.027)" fill="#979797"/>
                              <path id="check" d="M5.285,10.916a.7.7,0,0,1-1,0L.31,6.936a1.056,1.056,0,0,1,0-1.495l.5-.5a1.057,1.057,0,0,1,1.495,0L4.786,7.428,11.5.716a1.057,1.057,0,0,1,1.495,0l.5.5a1.056,1.056,0,0,1,0,1.495Zm0,0" transform="translate(154.762 1200.573)" fill="#fff"/>
                            </g>
                        </svg>
                        <span class="font-weight-bold" (click)="moneyBackGuaranteeModel.show()">
                            30 Day Money Back Guarantee
                        </span>
                    </div>

                </div>
                

            </div>

            <!-- cart summary -->
            <!-- <div class="col-md-12 col-lg-6 col-sm-12">
                <div class="py-3 rounded-lg bg-light-grey">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1 inner-header">
                            <span class="font-weight-bolder text-uppercase text-secondary ml-4 h5">Cart Summary</span>
                        </div>
                    </div>
                    <div class="card border-0 rounded-0 bg-light-grey">
                        <ul class="list-group list-group-flush"> -->
                            <!-- Cart Items -->
                            <!-- <checkout-cart-items-new [cartItems]="cartItems" [phones]="phones$ | async"
                                [apps]="apps$ | async" [payAnnually]="shippingService.checkoutDetail.payAnnually">
                            </checkout-cart-items-new> -->

                            <!-- Sub Total -->
                            <!-- <li class="list-group-item bg-light-grey" *ngIf="hasCartItems"
                                style="padding: 0.75rem 0 !important;">
                                <checkout-sub-total-new [calculatingTotal]="calculatingTotal"
                                    [checkoutDetail]="shippingService.checkoutDetail">
                                </checkout-sub-total-new>
                            </li> -->

                            <!-- Taxes & Fees -->
                            <!-- <li class="list-group-item bg-light-grey"
                                *ngIf="hasCartItems && shippingService.checkoutDetail.billingAddress && shippingService.checkoutDetail.billingAddress.zip"
                                style="padding: 0.75rem 0 !important;">
                                <checkout-tax-and-fees-new [taxDetail]="taxDetail"
                                    [payAnnually]="shippingService.checkoutDetail.payAnnually"
                                    [calculatingTotal]="calculatingTotal">
                                </checkout-tax-and-fees-new>
                            </li> -->

                            <!-- Shipping Options -->
                            <!-- <li class="list-group-item bg-light-grey"
                                *ngIf="hasCartItems && cartHasHardware && shippingService.shippingOptions.length > 0"
                                style="padding: 0.75rem 0 !important;">
                                <checkout-shipping-options-new [checkoutDetail]="shippingService.checkoutDetail"
                                    [calculatingTotal]="calculatingTotal" (shippingChange)="shippingChange($event)">
                                </checkout-shipping-options-new>
                                <div class="mt-3 border-top pt-3">
                                    <checkout-protection-plan-new
                                        [hasProtectionPlan]="shippingService.checkoutDetail.hasProtectionPlan"
                                        (protectionPlanChanged)="onProtectionPlanStatusChanged($event)"
                                        [protectionPlanMonthlyCharge]="shippingService.checkoutDetail.protectionPlanTotal">
                                    </checkout-protection-plan-new>
                                </div>
                            </li> -->
                        <!-- </ul> -->

                        <!-- Monthly Billing -->
                        <!-- <checkout-billing-plan-new (changePlan)="changePlan($event)"
                            [payAnnually]="shippingService.checkoutDetail.payAnnually">
                        </checkout-billing-plan-new> -->

                        <!-- Promo Code -->
                        <!-- <checkout-promo-code-new *ngIf="hasCartItems "
                            [checkoutDetail]="shippingService.checkoutDetail" [promoCodeApplied]="promoCodeApplied"
                            [cartHasHardware]="cartHasHardware" (applyPromoCode)="applyPromo($event)"
                            (removePromoCode)="removePromoCode()">
                        </checkout-promo-code-new> -->
                    <!-- </div> -->

                    <!-- Total -->
                    <!-- <checkout-cart-total-new [calculatingTotal]="calculatingTotal"
                        [checkoutDetail]="shippingService.checkoutDetail">
                    </checkout-cart-total-new> -->
                    <!-- Pay Now -->
                    <!-- <div class="d-flex">
                        <button type="button" class="btn btn-primary mt-3 mx-4 rounded-lg flex-fill pay-now-btn"
                            (click)="validateOrderAndPay();"
                            [disabled]="calculatingTotal || payNowDisable||paymentLoading" [popover]="popErrorTemplate"
                            triggers="" placement="top" containerClass="popover-css-error" [outsideClick]="true"
                            #popError="bs-popover">
                            <h2
                                class="font-weight-bold text-center text-primary py-4 m-0 d-flex justify-content-center align-items-center text-uppercase">
                                place your order -->
                                <!-- <mat-spinner *ngIf="paymentLoading" class="white-spinner ml-3" diameter="25"></mat-spinner> -->
                            <!-- </h2>
                        </button>
                    </div>
                    <div class="mx-4 text-center">
                        By placing your order, you agree to <a style=" text-decoration: underline; cursor: pointer;"
                            href="https://www.voiply.com/terms.html" target="_blank">Terms of Service</a>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>
<ng-template #popErrorTemplate>
    <div class="d-flex" *ngIf="payNowErrorMessage">
        <button type="button" class="p-0 btn mr-2 " (click)="popError.hide()">
            <i class="fas fa-times-circle fa-lg text-primary"></i>
        </button>
        <div>{{payNowErrorMessage}}</div>
    </div>
</ng-template>
<div *ngIf="paymentLoading">
    <common-ui-voiply-splashscreen loadingText="Payment Processing"></common-ui-voiply-splashscreen>
</div>

<!-- 30 days Guarantee -->

<div class="modal fade" bsModal #moneyBackGuaranteeModel="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
    role="dialog" aria-labelledby="dialog-static-name">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header" style="background-color: #fe330a; color: #ffffff;">
                <h1 class="text-center" style="width: 100%">
                    Money Back Guarantee
                    <br />
                    <small style="font-size: 50%" class="d-none d-md-block">Get peace of mind with our 30-Day Money-Back
                        Guarantee offer!</small>
                </h1>
                <button type="button" class="close pull-right" aria-label="Close"
                    (click)="moneyBackGuaranteeModel.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <p>We offer a 30-day Money Back Guarantee ('MBG') if you terminate your service within 30 days from the
                    date of your order. Your Order Date is the date you order service or the date we successfully
                    process your payment, whichever is later.
                    The MBG applies to the monthly service charge and taxes.
                </p>
                <br />
                <p>
                    Our MBG does not cover any charges for shipping or international calls.
                </p>
            </div>
        </div>
    </div>
</div>