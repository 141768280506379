import { State, Action, StateContext } from '@ngxs/store';
import {
    SetOrderIdAction, FillOrderAction,
    SubmitSurveyAction, SkipSurveyAction,
    SetPrimaryPhoneFormStatusAction,
    PerformValidationInAllForms,
    UserIsVoiplyTeamMemberAction,
    SetPrimaryPhoneNumber,
    GetCurrentLocation,
    GetPhonesAndApps,
    GetBusinessPhones,
    GetHomePhones,
    SaveCartItemAction,
    ApplyPromoCodeAction,
    RemovePromoCodeAction,
    SetInitialLoadOrderDataAction,
    SaveMetadataAction,
    ChangeBillingPlanAction,
    UpdateCartItemConfigurationAction,
    SaveCallFlowSelectionAction,
    SaveDefaultBasicCallFlowAction,
    RemoveCartItemAction,
    SelectInternetProviderAction,
    ShippingSameAsBillingAction,
    SelectShippingOptionAction,
    ShippingAddressChangeAction,
    BillingAddressChangeAction,
    CompanyNameChangeAction,
    StripeDataChangedAction,
    ShareOrderAction,
    CalculatingTotalAction,
    SetCrispSessionIdAction,
    SetBillingAddressFormStatusAction,
    SetShippingAddressFormStatusAction,
    SetProductConfigurationStatusAction,
    UnLockOrderAction,
    GetPhoneStatus,
    UpdateOrderAction,
    UpdateAdditionalNumbersAction,
    UpdateCartItemAction,
    UpdateShippingOptions,
    AddPhonesToCartAction,
    CardProcessAction,
    SetSurveyBannerAction,
    UpdateBYODStatusAction,
    updateEmergencyStatusAction,
    AddCompanyNumberAction,
    RemoveCompanyNumberAction,
    UpdateCompanyNumberAction,
    EmergencyAddressChangeAction,
    UpdatingPhoneSystemAction,
    AddAppToCartAction,
    AddMorePhonesAction,
    UpdatecartItemPaidStatusAction,
    OrderedMorePhonesAction,
    ChangeProtectionPlanStatusAction,
    UpdateCartItemProtectionPlanAction,
    AddCodeForLoginAction,
    RemoveCodeForLoginAction,
    PaymentSuccessfulAction,
    UpdateMetadataAction,
    ChangeBillingPlanAppAction,
    BillingAddressChangeExtralineSettingsAction,
    UpdatePrimaryNumberInCompanyNumberAction,
} from './app.actions';
import * as Model from '@voiply/shared/model'
import { v4 as uuid } from 'uuid';
import { OrderService, LocationService, ProductService, DeepMergeService, PromotionService, CrispService, AudioService } from '../services';
import { CallFlowType, CartItemType, AutoAttendantType, Gender, VoiceMail, Card, EventType, SystemType } from '@voiply/shared/model';
import * as LogRocket from 'logrocket';
import * as _ from 'lodash';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, checkPhoneIsValid } from '../constants';
import { IEnvironment } from '../environment.interface';
import { SignalRService } from '../services/signal-r.service';

export const APP_FEATURE_KEY = 'app';

export interface AppStateModel {
    orderId: string;
    primaryNumber: Model.PrimaryPhone;
    survey: Model.Survey;
    cartItems: any;
    checkoutDetail: Model.CheckoutDetail,
    user: Model.User;
    serverObjectInitialized: boolean;
    subscription: Model.Subscription;
    callFlow: Model.CallFlow;
    shipment: Model.Shippment;
    customer: Model.Customer;
    firstPromoter: Model.FirstPromoter;
    numberPorting: Model.NumberPorting;
    // Other than Order data
    doValidate: object;
    currentLocation: { stateCode: string, stateName: string, city: string, countryCode: string, zip: string };
    phones: Model.Phone[];
    apps: Model.App[];
    crispSessionId: string;
    promoCodeApplied: Model.PromoCode;
    callFlowsList: Model.CallFlowList[];
    systemFeatures: any;
    firstPromoter_ref: string;
    calculatingTotal: boolean;
    userIsVoiplyMember: boolean;
    formProductConfigurationValid: boolean;
    isOrderDisabled: boolean;
    helpText: Model.HelpText;
    additionalNumbers: any;
    card: Card;
    updateShipping: object;
    cardProcess: { isCardProcessing: boolean };
    surveyBanner: string;
    companyNumbers: Model.CompanyNumber[];
    updatingOrder: boolean;
    orderedMorePhones: boolean;
    metadata: any;
}

@Injectable()

@State<AppStateModel>({
    name: 'app',
    defaults: {
        orderId: null,
        primaryNumber: { number: null, isNew: false, systemSelected: false, isValid: false, selectedNewNumberInfo:null},
        survey: {
            email: null,
            numberOfEmployees: '1',
            responseSubmitted: null,
            zipcode: null
            //  surveySkipped: false
        },
        cartItems: {},
        checkoutDetail: {
            companyName: null,
            billingAddress: new Model.Address(),
            shippingAddress: new Model.Address(),
            emergencyAddress: new Model.EmergencyAddress(),
            isShippingSameAsBillingAddress: true,
            shippingCharges: 0,
            discountedShippingCharges: 0,
            payAnnually: false,
            promoCode: '',
            description: null,
            shippingOption: 'standard',
            shipperMethod: 'FedEx Ground',
            orderTotal: 0,
            monthlyTotal: 0,
            hasProtectionPlan: false,
            withoutDiscountTotal: 0,
            discountedTotalAmount: 0,
            taxDetail: {
                submissionId: null,
                taxBreakup: null,
                estimatedTotalTax: 0.00
            },
            internetProvider: { name: null },
            sharing: null,
            isValid: false,
            protectionPlanTotal: 0,
            savings: {
                hardware: 0.00,
                annual: 0.00,
                shipping: 0.00,
                freeporting: 0.00,
                numberactivation: 0.00,
                freemonth: 0.00
            }
        },
        subscription: null,
        shipment: null,
        customer: null,
        firstPromoter: null,
        numberPorting: null,
        serverObjectInitialized: false,
        user: Model.ModelDefaults.User,
        callFlow: { type: CallFlowType.None, ringCallFlow: null, autoAttendantOptions: null, skipConfig: false, humanAttendant: null, autoAttendantRingCallFlow: new Model.AutoAttendantRingCallFlow() },
        isOrderDisabled: false,

        doValidate: null,
        currentLocation: { stateCode: null, stateName: null, city: null, countryCode: null, zip: null },
        phones: [],
        apps: [],
        crispSessionId: null,
        promoCodeApplied: { status: false, message: '' },
        systemFeatures: null,
        callFlowsList: [],
        firstPromoter_ref: '',
        calculatingTotal: false,
        userIsVoiplyMember: false,
        formProductConfigurationValid: false,
        helpText: {
            callFlows: {
                description: null,
                businessHours: null,
                announcements: null,
                autoAttendant: null,
                configureExtension: null,
                configureRingGroup: null,
                configureRingOne: null,
                greeting: null,
                phoneRingOptions: null,
                ringGroupUser: null,
                ringOneUser: null,
                voicemail: null,
                timeoutOption: null,
                timeZone: null,
                callForward: null
            }
        },
        additionalNumbers: {},
        card: {
            exp_month: null,
            brand: null,
            exp_year: null,
            id: null,
            last4: null
        },
        updateShipping: null,
        cardProcess: { isCardProcessing: null },
        surveyBanner: null,
        companyNumbers: [],
        updatingOrder: false,
        orderedMorePhones: false,
        metadata: {}
    }
})
export class AppState {

    constructor(private orderService: OrderService, private locationService: LocationService, private productService: ProductService, private audioService: AudioService, private deepMergeService: DeepMergeService,
        private promotionService: PromotionService, private crispService: CrispService, @Inject(ENVIRONMENT) private environment: IEnvironment, private signalRService: SignalRService) { }

    // #region 'App Initialization'

    @Action(SetOrderIdAction)
    setOrderIdReducer({ patchState }: StateContext<AppStateModel>, { orderId }: SetOrderIdAction) {
        patchState({ orderId: orderId });
    }

    @Action(SetCrispSessionIdAction)
    setCrispSessionId({ patchState }: StateContext<AppStateModel>, { crispSessionId }: SetCrispSessionIdAction) {
        patchState({ crispSessionId: crispSessionId });
    }

    @Action(FillOrderAction)
    fillOrderReducer({ patchState, getState }: StateContext<AppStateModel>, { orderData }: FillOrderAction) {
        const appState = { ...getState() };
        this.deepMergeService.merge(appState, orderData);
        patchState({
            orderId: appState.orderId, primaryNumber: { ...appState.primaryNumber }, cartItems: { ...appState.cartItems },
            checkoutDetail: { ...appState.checkoutDetail }, subscription: { ...appState.subscription }, shipment: { ...appState.shipment },
            customer: { ...appState.customer }, firstPromoter: { ...appState.firstPromoter }, numberPorting: { ...appState.numberPorting },
            user: { ...appState.user }, callFlow: { ...appState.callFlow }, serverObjectInitialized: appState.serverObjectInitialized,
            isOrderDisabled: appState.isOrderDisabled, card: appState.card, companyNumbers: appState.companyNumbers, metadata: { ...appState.metadata }
        });
    }

    @Action(UpdateAdditionalNumbersAction)
    updateAdditionalNumbers({ patchState, getState }: StateContext<AppStateModel>, { additionalNumbers }: UpdateAdditionalNumbersAction) {

        const appState = { ...getState() };
        this.deepMergeService.merge(appState, additionalNumbers);

        patchState({
            additionalNumbers: { ...appState.additionalNumbers }
        })

        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: additionalNumbers,
            eventName: Model.EventType.NumberPorting,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }

    @Action(UpdatingPhoneSystemAction)
    updatingPhoneSystem({ patchState }: StateContext<AppStateModel>, { updatingOrder }: UpdatingPhoneSystemAction) {

        patchState({
            updatingOrder: updatingOrder
        })


    }
    @Action(UpdateOrderAction)
    updateOrderChanges({ patchState, getState }: StateContext<AppStateModel>, { orderData }: UpdateOrderAction) {
        const appState = { ...getState() };
        this.deepMergeService.merge(appState, orderData);

        if (orderData.primaryNumber)
            patchState({ primaryNumber: { ...appState.primaryNumber } });
        if (orderData.cartItems)
            patchState({ cartItems: { ...appState.cartItems } });
        if (orderData.callFlow)
            patchState({ callFlow: { ...appState.callFlow } });
        if (orderData.checkoutDetail) {
            if (orderData.checkoutDetail.billingAddress) {
                appState.checkoutDetail.billingAddress = { ...appState.checkoutDetail.billingAddress };
            }
            if (orderData.checkoutDetail.shippingAddress) {
                appState.checkoutDetail.shippingAddress = { ...appState.checkoutDetail.shippingAddress };
            }

            patchState({ checkoutDetail: { ...appState.checkoutDetail } });
        }
        if (orderData.subscription)
            patchState({ subscription: { ...appState.subscription } });
        if (orderData.customer)
            patchState({ customer: { ...appState.customer } })
        if (orderData.firstPromoter)
            patchState({ firstPromoter: { ...appState.firstPromoter } })
        if (orderData.numberPorting)
            patchState({ numberPorting: { ...appState.numberPorting } })
        if (orderData.user)
            patchState({ user: { ...appState.user } })
        if (orderData.companyNumbers)
            patchState({ companyNumbers: [...appState.companyNumbers] })
    }

    @Action(AddMorePhonesAction)
    addMorePhones({ getState }: StateContext<AppStateModel>) {
        const appState = { ...getState() };
        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: {},
            eventName: Model.EventType.AddMorePhones,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }

    @Action(UserIsVoiplyTeamMemberAction)
    userIsVoiplyTeamMember({ patchState }: StateContext<AppStateModel>, { isTrue }: UserIsVoiplyTeamMemberAction) {
        patchState({ userIsVoiplyMember: isTrue });
    }

    @Action(UnLockOrderAction)
    unLockOrder({ patchState, getState }: StateContext<AppStateModel>) {
        const appState = getState();

        patchState({ customer: { auth0Id: null, name: null, email: null, updatedSignUp: null, method: null } });

        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: { customer: { auth0Id: null, name: null, email: null, updatedSignUp: null, method: null } },
            eventName: Model.EventType.OrderLocked,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }



    //Show total calculation loader
    @Action(CalculatingTotalAction)
    calculatingTotal({ patchState }: StateContext<AppStateModel>, { isCalculating }: CalculatingTotalAction) {
        patchState({ calculatingTotal: isCalculating });
    }
    @Action(CardProcessAction)
    cardProcessing({ patchState }: StateContext<AppStateModel>, { cardProcess }: CardProcessAction) {
        patchState({ cardProcess: cardProcess })
    }

    @Action(GetCurrentLocation)
    async getCurrentLocation({ patchState, getState }: StateContext<AppStateModel>) {
        const appState = { ...getState() };
        const data = await this.locationService.getCurrentLocationData().toPromise();
        const location = { stateCode: data.region_code, stateName: data.region_name, city: data.city, countryCode: data.country_code, zip: data.zip };

        if (data.country_code) {
            const changes: any = {
                checkoutDetail: {
                    billingAddress: {}
                },
                survey: {},
            };

            const billingAddress = appState.checkoutDetail.billingAddress;
            if (!billingAddress.zip)
                changes.checkoutDetail.billingAddress["zip"] = data.zip;
            if (!billingAddress.state)
                changes.checkoutDetail.billingAddress["state"] = data.region_code;
            if (!billingAddress.country)
                changes.checkoutDetail.billingAddress["country"] = data.country_code;
            if (!appState.survey.zipcode)
                changes.survey["zipcode"] = data.zip;

            // check if shipping is same as billing address, then make the chages and save it in cosmos
            if (appState.checkoutDetail.isShippingSameAsBillingAddress && (changes.checkoutDetail || {}).billingAddress) {
                changes.checkoutDetail.shippingAddress = changes.checkoutDetail.billingAddress;
            }
            this.deepMergeService.merge(appState, changes);
            appState.checkoutDetail.billingAddress = { ...appState.checkoutDetail.billingAddress };
            patchState({ checkoutDetail: { ...appState.checkoutDetail }, survey: { ...appState.survey } });

            this.orderService.saveOrderChanges({
                orderId: appState.orderId,
                changes: changes,
                eventName: Model.EventType.BillingAddress,
                crispSessionId: appState.crispSessionId,
                signalRUserId: this.signalRService.userid
            });
        }
        patchState({ currentLocation: location });
    }

    @Action(GetPhonesAndApps)
    async getPhonesAndApps({ patchState, getState }: StateContext<AppStateModel>) {
        const appState = { ...getState() };
        if (appState.phones.length <= 0) { //fetch data in state, only once
            const features = await this.productService.getFeaturesAsync();
            patchState({ phones: features.features, apps: features.apps, callFlowsList: features.callFlows, systemFeatures: features.system_features, helpText: features.helpText });
        }
    }
    @Action(GetBusinessPhones)
    async getBusinessPhones({ patchState, getState }: StateContext<AppStateModel>) {
        const appState = { ...getState() };
        const features = await this.productService.getBusinessFeaturesAsync();
        patchState({ phones: (features || {}).features });

    }
    @Action(GetHomePhones)
    async getHomePhones({ patchState, getState }: StateContext<AppStateModel>) {
        const appState = { ...getState() };
        const features = await this.productService.getHomeFeaturesAsync();
        patchState({ phones: (features || {}).features });
    }
    @Action(GetPhoneStatus)
    async getPhoneStatus({ patchState, getState }: StateContext<AppStateModel>) {
        const appState = { ...getState() };
        const phoneStatuses: { extension: string, status: "Not registered" | "Registered" | string, ip: string, provisioning: string }[] = await this.productService.getPhoneStatus(appState.orderId);

        const phoneStatusChanges = { cartItems: {} };
        Object.keys(appState.cartItems).forEach(key => {
            const item = appState.cartItems[key];
            if (item.type === CartItemType.Phone) {
                let statuses = _.filter(phoneStatuses, (phoneStatus) => phoneStatus.mac == item.configuration.mac);
                if (statuses.length === 0) {
                    statuses = _.filter(phoneStatuses, (phoneStatus) => {
                        //phonestatus.extension is string and item.configuration.extension is number it should be == only.
                        return phoneStatus.extension == item.configuration.extension
                    });
                }
                console.log('MAC', item.configuration.mac, statuses);
                if (statuses.length > 0)
                    phoneStatusChanges.cartItems[key] = { phoneStatusDetail: statuses[0] };
            }
        });
        this.deepMergeService.merge(appState, phoneStatusChanges);
        patchState({ cartItems: { ...appState.cartItems } });
    }

    @Action(SetInitialLoadOrderDataAction)
    setInitialLoadOrderData({ patchState, getState }: StateContext<AppStateModel>, { promoCode, email, metaData }: SetInitialLoadOrderDataAction) {
        const appState = { ...getState() };

        const changes: any = {
            checkoutDetail:
            {
                payAnnually: appState.checkoutDetail.payAnnually,
                shippingCharges: appState.checkoutDetail.shippingCharges,
                discountedShippingCharges: appState.checkoutDetail.discountedShippingCharges,
                shippingOption: appState.checkoutDetail.shippingOption,
                shipperMethod: appState.checkoutDetail.shipperMethod,
                taxDetail: appState.checkoutDetail.taxDetail,
                promoCode: promoCode
            },
            metadata: metaData,
        };

        if (email) {
            changes.survey = { email: email };
            changes.checkoutDetail = { billingAddress: { email: email } };
        }

        this.deepMergeService.merge(appState, changes);

        patchState({ checkoutDetail: { ...appState.checkoutDetail }, survey: { ...appState.survey }, firstPromoter_ref: metaData.fp_ref });

        // check if shipping is same as billing address, then make the chages and save it in cosmos
        if (appState.checkoutDetail.isShippingSameAsBillingAddress && (changes.checkoutDetail || {}).billingAddress) {
            changes.checkoutDetail.shippingAddress = changes.checkoutDetail.billingAddress;
        }
        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: changes,
            eventName: Model.EventType.InitialLoad,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }

    @Action(SaveMetadataAction)
    saveMetadataReducer({ getState }: StateContext<AppStateModel>, { metaData }: SaveMetadataAction) {
        const appState = { ...getState() };

        const changes: any = { metadata: metaData };

        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: changes,
            eventName: Model.EventType.InitialLoad,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }

    @Action(UpdateMetadataAction)
    updateMetadata({ getState, patchState }: StateContext<AppStateModel>, { metaData }: UpdateMetadataAction) {
        const appState = { ...getState() };

        const changes: any = { metadata: metaData };
        this.deepMergeService.merge(appState, changes);
        patchState({ metadata: { ...appState.metadata } });

        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: changes,
            eventName: Model.EventType.Metadata,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });


    }

    @Action(AddCodeForLoginAction)
    addCodeForLogin({ getState }: StateContext<AppStateModel>, { code }: AddCodeForLoginAction) {
        const appState = { ...getState() };

        const changes: any = { metadata: { codeForLogin: code } };

        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: changes,
            eventName: Model.EventType.CodeForLogin,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }

    @Action(RemoveCodeForLoginAction)
    removeCodeForLogin({ getState }: StateContext<AppStateModel>) {
        const appState = { ...getState() };

        const changes: any = { metadata: { codeForLogin: '' } };

        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: changes,
            eventName: Model.EventType.CodeForLogin,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }

    @Action(PaymentSuccessfulAction)
    paymentSuccessful({ getState, patchState }: StateContext<AppStateModel>, { subscriptionInfo }: PaymentSuccessfulAction) {
        const appState = { ...getState() };
        const newChanges: any = {
            subscription: {
                paymentSuccessful: true,
                customerId: subscriptionInfo.customerId,
                invoiceId: subscriptionInfo.invoiceId,
                paymentMethodId: subscriptionInfo.paymentMethodId,
            },
            shipment: {
                trackingRef: '',
                estimatedDeliveryDate: '',
                delivered: false,
                deliveyDate: '',
                status: 'In Processing',
                status_detail: 'Thanks for your purchase, your Voiply order is being processed and will ship soon.',
                shipperTrackingLink: ''
            },
            firstPromoter: {
                my_ref: ''
            },
            numberPorting: {
                status: 'Not Submitted',
                text: 'The porting process can take 7-10 business days to complete from the time of submission.'
            }
        };

        newChanges.cartItems = {};
        Object.keys(appState.cartItems).forEach(key => {
            newChanges.cartItems[key] = {
                paid: true
            };
        });
        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: newChanges,
            eventName: Model.EventType.PaymentDoneAUTO,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }
    // #endregion

    // #region 'Survey'

    @Action(SetSurveyBannerAction)
    setSurveyBanner({ patchState }: StateContext<AppStateModel>, { surveyBanner }: SetSurveyBannerAction) {
        patchState({ surveyBanner: surveyBanner })
    }

    @Action(SubmitSurveyAction)
    submitSurvey({ patchState, getState }: StateContext<AppStateModel>, { surveyResponse, phoneId }: SubmitSurveyAction) {
        patchState({ survey: { ...surveyResponse } });

        const appState = getState();
        const changes: any = { survey: surveyResponse };
        if (surveyResponse.email) {
            if (appState.firstPromoter_ref) {
                this.promotionService.markFirstPromoterLeadAsync(surveyResponse.email, appState.firstPromoter_ref, appState.orderId);
            }

            console.log(`Found email: ${surveyResponse.email} and order: ${appState}`);

            const totalEmployees = +surveyResponse.numberOfEmployees;
            console.log('Number of employees', totalEmployees);

            changes.cartItems = {};
            const totalCartItems = appState.cartItems ? Object.keys(appState.cartItems).length : 0;
            console.log('Total cart items', totalCartItems);

            let lastUsedUserNumber = 0;
            _.each(appState.cartItems, (item) => {
                if (item.type === "phone") {
                    const extension = item.configuration.extension;
                    lastUsedUserNumber = Math.max(lastUsedUserNumber, extension);
                }
            });

            const phone: Model.Phone = appState.phones.find(function (item) {
                return item.featureId == phoneId;
            });
            for (let i = totalCartItems; i < totalEmployees; i++) {

                console.log('Processing ', i);
                const cartItem: any = {};

                //Add Wifi Desk phone when user configures system from business site.
                cartItem.type = "phone";
                cartItem.itemId = phone.featureId;
                cartItem.heading = phone.heading;
                cartItem.price = phone.priceValue;
                cartItem.discountedPrice = phone.priceValue;
                cartItem.qty = 1;
                cartItem.monthlyCharge = phone.monthlyCharge;
                cartItem.discountedMonthlyCharge = phone.monthlyCharge;
                cartItem.protectionCharge = phone.protectionCharge;


                lastUsedUserNumber += (lastUsedUserNumber > 0 ? 1 : 101);
                const blobId = uuid() + ".wav";
                cartItem.configuration = {
                    userId: uuid(),
                    firstName: "User " + (lastUsedUserNumber - 100),
                    lastName: '',
                    extension: lastUsedUserNumber,
                    showRecording: false,
                    selectedGender: Gender.Male,
                    audioText: "Hi, sorry I missed your call. I’m either away at the moment or on the phone, please leave your name and number along with a short message and I’ll be sure to get back to you. Thanks and have a great day!",
                    sendVoiceMailTo: VoiceMail.EmailAndPhone,
                    blobFileName: `${this.environment.blobStoragePath}${blobId}`,
                    fileName: '',
                    voicemailRingTimeoutCount: 4,
                    callForwarding: false,
                    phoneNumber:"",
                    email: surveyResponse.email,
                    isNewNumber:false,
                    uploadRecording:false,
                    includeinCompanyDirectory: true,
                    attachVoicemail: true,
                    ccEmail: [],
                    emergencyCallForwarding: false,
                    emergencyCallForwardingNumber: '',
                    isVoicemailToEmailEnable: appState.metadata.systemType==SystemType.Business ? true : false,
                    isSMSArchivingEnable: false,
                    archive_sms: ''
                };

                // if (order.phoneNumber)
                //     cartItem.configuration.phoneNumber = order.phoneNumber;
                changes.cartItems[uuid()] = cartItem;

                changes.callFlow = { ringCallFlow: { ring: { users: {} } } };
                changes.callFlow.ringCallFlow.ring.users[cartItem.configuration.userId] = true;

                let firstPhoneInCart = false;
                if (!appState.cartItems || appState.cartItems.length === 0)
                    firstPhoneInCart = true;

                if (firstPhoneInCart) {
                    console.log('Processing first phone in cart');
                    // First item in cart
                    changes.callFlow.ringCallFlow.ringOneUser = cartItem.configuration.userId;
                    changes.callFlow.ringCallFlow.voiceMailUser = cartItem.configuration.userId;
                    changes.callFlow.ringCallFlow.voicemailRingTimeoutCount = 3;
                }
            }

            console.log('Changes are', JSON.stringify(changes));

            if (appState.cartItems) {

                console.log('Fill cart item email');

                // Fill Cart Item email
                Object.keys(appState.cartItems).forEach(key => {
                    if (appState.cartItems[key].configuration) {
                        if (!appState.cartItems[key].configuration.email || appState.cartItems[key].configuration.email === '') {
                            changes.cartItems[key] = { configuration: { email: surveyResponse.email } };
                        }
                    }
                });

                // Fill billing email and zip
                console.log('Fill billing email');
                changes.checkoutDetail = { billingAddress: { email: surveyResponse.email, zip: surveyResponse.zipcode } };
            }


            this.deepMergeService.merge(appState, changes);
            patchState({ cartItems: { ...appState.cartItems }, callFlow: { ...appState.callFlow }, checkoutDetail: { ...appState.checkoutDetail } });


            // Recalculate totals
            console.log('Recalculate totals');
            this.orderService.saveOrderChanges({
                orderId: appState.orderId,
                changes: changes,
                eventName: Model.EventType.Survey,
                crispSessionId: appState.crispSessionId,
                signalRUserId: this.signalRService.userid
            });


        }
    }


    @Action(SkipSurveyAction)
    SkipSurvey({ patchState, getState }: StateContext<AppStateModel>) {
        const appState = getState();
        const changes: any = { survey: { surveySkipped: true } };
        patchState({ ...changes });
        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: changes,
            eventName: Model.EventType.Survey,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }

    // #endregion

    // #region 'Primary Phone'

    @Action(SetPrimaryPhoneFormStatusAction)
    setPrimaryPhoneValidationStatus({ patchState, getState }: StateContext<AppStateModel>, { isValid }: SetPrimaryPhoneFormStatusAction) {

        const appState = getState();
        patchState({ primaryNumber: { ...appState.primaryNumber, isValid } });
    }

    @Action(SetPrimaryPhoneNumber)
    setPrimaryPhoneNumber({ patchState, getState }: StateContext<AppStateModel>, { phoneNumber, updateInServer = true }: SetPrimaryPhoneNumber) {

        const appState = { ...getState() };
        let isValid;
        if (checkPhoneIsValid(phoneNumber.number))
            isValid = true;
        else
            isValid = false;
        patchState({ primaryNumber: { ...appState.primaryNumber, number: phoneNumber.number, isNew: phoneNumber.isNew, systemSelected: phoneNumber.systemSelected, isValid: isValid,selectedNewNumberInfo: phoneNumber.selectedNewNumberInfo } });

        if (updateInServer)
            this.orderService.saveOrderChanges({
                orderId: appState.orderId,
                changes: { primaryNumber: { number: phoneNumber.number, isNew: phoneNumber.isNew, systemSelected: phoneNumber.systemSelected, isValid: isValid } },
                eventName: Model.EventType.PhoneNumber,
                crispSessionId: appState.crispSessionId,
                signalRUserId: this.signalRService.userid
            });
    }


    @Action(AddCompanyNumberAction)
    addCompanyNumber({ patchState, getState }: StateContext<AppStateModel>, { phoneNumber }: AddCompanyNumberAction) {

        const appState = { ...getState() };
        const changes: any = {};
        const companyNumbers = appState.companyNumbers
        let isNumberSaved = false;
        // if (phoneNumber.number.length !== 10) {
        //     return
        // }

        _.each(companyNumbers, (value, key) => {
            if (value.number === phoneNumber.number) {
                isNumberSaved = true;
                return false;
            }
        })
        if (!isNumberSaved) {
            if (phoneNumber.isPrimaryNumber) {
                _.each(companyNumbers, (value, key) => {
                    companyNumbers[key].isPrimaryNumber = false;
                });
                changes.callFlow = phoneNumber.callFlow;
            }
            companyNumbers.push(phoneNumber);
            changes.companyNumbers = companyNumbers;
            patchState({ companyNumbers: [...companyNumbers] })
            if (changes.callFlow) {
                patchState({ callFlow: { ...changes.callFlow } })
            }
            this.orderService.saveOrderChanges({
                orderId: appState.orderId,
                changes: changes,
                eventName: Model.EventType.CompanyNumber,
                crispSessionId: appState.crispSessionId,
                signalRUserId: this.signalRService.userid
            });
        }
    }

    @Action(UpdatePrimaryNumberInCompanyNumberAction)
    updatePrimaryNumberInCompanyNumber({ patchState, getState }: StateContext<AppStateModel>, { phoneNumber, isNew }: UpdatePrimaryNumberInCompanyNumberAction) {

        const appState = { ...getState() };
        const companyNumbers = appState.companyNumbers;
        for (let i = 0; i < companyNumbers.length; i++) {
            if (companyNumbers[i].isPrimaryNumber == true) {
                companyNumbers[i].number = phoneNumber;
                companyNumbers[i].isNew = isNew;
                patchState({ companyNumbers: [...appState.companyNumbers] })
            }
        }


        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: { companyNumbers: appState.companyNumbers },
            eventName: Model.EventType.CompanyNumber,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });

    }

    @Action(UpdateCompanyNumberAction)
    updateCompanyNumber({ patchState, getState }: StateContext<AppStateModel>, { phoneNumber }: UpdateCompanyNumberAction) {

        const appState = { ...getState() };
        const changes: any = {}
        const companyNumbers = appState.companyNumbers;
        // Find item index using _.findIndex
        const index = _.findIndex(companyNumbers, { number: phoneNumber.number });
        // Replace item at index using native splice
        companyNumbers.splice(index, 1, phoneNumber);
        //If phonenumber is primary number then make other other number status false and update callflows
        if (phoneNumber.isPrimaryNumber) {
            _.each(companyNumbers, (value, key) => {
                if (phoneNumber.number !== value.number) {
                    companyNumbers[key].isPrimaryNumber = false;
                } else {
                    changes.callFlow = value.callFlow;
                    patchState({ callFlow: { ...changes.callFlow } })
                }
            });
        }
        //set selectedCallFlowStatus
        if (phoneNumber.isSelectedCallFlow) {
            _.each(companyNumbers, (value, key) => {
                if (phoneNumber.number !== value.number) {
                    companyNumbers[key].isSelectedCallFlow = false;
                }
            });
        }
        changes.companyNumbers = companyNumbers;
        patchState({ companyNumbers: [...companyNumbers] })

        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: changes,
            eventName: Model.EventType.PhoneNumber, //We dont need Processing so assigning phonenumber instead of companyNumber Event
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }

    @Action(RemoveCompanyNumberAction)
    removeCompanyNumber({ patchState, getState }: StateContext<AppStateModel>, { phoneNumber }: RemoveCompanyNumberAction) {
        const appState = { ...getState() };
        const changes: any = {}
        const companyNumbers = appState.companyNumbers;

        _.remove(companyNumbers, function (el) {
            return phoneNumber.number === el.number
        });
        // If user removes the selected CallFlow Number then assign 1st number as selected callFlow
        if (phoneNumber.isSelectedCallFlow) {
            if (companyNumbers.length > 0)
                companyNumbers[0].isSelectedCallFlow = true
        }
        //Made changes to remove primary Number object when deleting primary Number from companyNumber.
        if (phoneNumber.isPrimaryNumber) {
            //Assign 1st non International company Number as PrimaryNumber
            const filteredNonInternationalCompanyNumbers = _.filter(companyNumbers, (companyNumber) => !companyNumber.isInternational);
            if (filteredNonInternationalCompanyNumbers.length > 0) {
                _.each(companyNumbers, (value, key) => {
                    if (value.number === filteredNonInternationalCompanyNumbers[0].number) {
                        value.isPrimaryNumber = true
                    }
                })
                changes.callFlow = filteredNonInternationalCompanyNumbers[0].callFlow;
                changes.primaryNumber = {
                    number: filteredNonInternationalCompanyNumbers[0].number, isNew: filteredNonInternationalCompanyNumbers[0].isNew, systemSelected: filteredNonInternationalCompanyNumbers[0].systemSelected, isValid: true
                }
            } else {
                changes.callFlow = new Model.CallFlow();
                changes.primaryNumber = {
                    number: '0000000000',
                    isNew: false,
                    systemSelected: false,
                    isValid: false
                }
            }
            patchState({ primaryNumber: { ...changes.primaryNumber } });
        }

        changes.companyNumbers = companyNumbers;
        patchState({ companyNumbers: [...companyNumbers] });
        patchState({ callFlow: { ...changes.callFlow } });
        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: changes,
            eventName: Model.EventType.CompanyNumber,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }
    // #endregion

    // #region 'Generic Form/App'

    @Action(SetProductConfigurationStatusAction)
    setProductConfigurationStatus({ patchState }: StateContext<AppStateModel>, { isValid }: SetProductConfigurationStatusAction) {
        patchState({ formProductConfigurationValid: isValid })
    }

    @Action(PerformValidationInAllForms)
    performValidationInAllForms({ patchState }: StateContext<AppStateModel>) {
        const value = new Object({ datetime: new Date() });
        patchState({ doValidate: value });
    }

    @Action(UpdateShippingOptions)
    UpdateShippingOptions({ patchState }: StateContext<AppStateModel>, { fetchOptions }: UpdateShippingOptions) {
        const updateShipping = fetchOptions;
        patchState({ updateShipping: { updateShipping } });
    }

    //#region 'Products'
    @Action(SaveCartItemAction)
    saveCartItem({ patchState, getState }: StateContext<AppStateModel>, { cartItem }: SaveCartItemAction) {
        const changes: any = {};
        changes.cartItems = {};
        changes.cartItems[uuid()] = cartItem;

        const appState = getState();
        const cartItems = appState.cartItems;
        this.deepMergeService.merge(cartItems, changes.cartItems);
        patchState({ cartItems: { ...cartItems } });

        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: changes,
            eventName: (cartItem.type === Model.CartItemType.Phone) ? Model.EventType.CartItemPhone : Model.EventType.CartItemApp,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }

    @Action(AddAppToCartAction)
    addAppToCart({ patchState, getState }: StateContext<AppStateModel>, { app }: AddAppToCartAction) {
        const appState = { ...getState() };
        const cartItems = appState.cartItems;
        const changes: any = {};
        changes.cartItems = {};

        const appItem = new Model.CartItem();
        appItem.itemId = app.appId;
        appItem.heading = app.title;
        appItem.price = app.price;
        appItem.discountedPrice = app.price;
        appItem.qty = 1;
        appItem.type = CartItemType.App;
        appItem.monthlyCharge = app.monthlyCharge;
        appItem.discountedMonthlyCharge = app.monthlyCharge;
        appItem.configuration = new Model.PhoneConfiguration();

        if (app.configureDefaultAudioRecording) {
            const blobId = uuid() + ".wav";
            appItem.configuration = new Model.PhoneConfiguration();
            appItem.configuration.voicemailRingTimeoutCount = 4;
            appItem.configuration.selectedGender = Gender.Male;
            appItem.configuration.audioText = 'We care about your experience, so we may record this call.';
            appItem.configuration.blobFileName = `${this.environment.blobStoragePath}${blobId}`;
            appItem.configuration.fileName = '';
            appItem.configuration.callForwarding = false;
            appItem.configuration.showRecording = false;

            // Generate file for blob.
            this.audioService.generateAudioFileFromText(appItem.configuration.audioText, Gender.Male, blobId).subscribe();
        }

        // Find last used extension and assign to app extension if app has one
        if (app.hasExtension) {
            let lastUsedAppExtension = 500;

            _.each(cartItems, (item) => {
                if (item.type === CartItemType.App && item.configuration.extension) {
                    lastUsedAppExtension = Math.max(+item.configuration.extension, lastUsedAppExtension);
                }
            });

            // Increment last used app extension and assign
            appItem.configuration.extension = ++lastUsedAppExtension;
        }

        changes.cartItems[uuid()] = appItem;

        this.deepMergeService.merge(cartItems, changes.cartItems);
        patchState({ cartItems: { ...cartItems } });

        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: changes,
            eventName: Model.EventType.CartItemApp,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }

    @Action(AddPhonesToCartAction)
    addPhonesToCart({ patchState, getState }: StateContext<AppStateModel>, { phoneFeatureId, quantity, phoneConfiguration, eventName }: AddPhonesToCartAction) {
        const appState = { ...getState() };
        const cartItems = appState.cartItems;
        const phones = appState.phones;
        const phone = _.filter(phones, (item: Model.Phone) => item.featureId === phoneFeatureId)[0];
        const changes: any = {};
        changes.cartItems = {};
        let lastUsedUserNumber = 100;
        _.each(cartItems, (item) => {
            if (item.type === CartItemType.Phone) {
                const extension = +item.configuration.extension;
                lastUsedUserNumber = Math.max(lastUsedUserNumber, extension);
            }
        });
        if (!phoneConfiguration) {
            phoneConfiguration = new Model.PhoneConfiguration()
        }
        if (phoneConfiguration.model)
            phoneConfiguration.manufacturer = 'grandstream'

        for (let i = 0; i < quantity; i++) {
            const cartItem = new Model.CartItem();
            cartItem.itemId = phone.featureId;
            cartItem.heading = phone.heading;
            cartItem.price = phone.withoutDiscountPriceValue;
            cartItem.discountedPrice = phone.priceValue;
            cartItem.qty = 1;
            cartItem.type = CartItemType.Phone;
            cartItem.monthlyCharge = phone.monthlyCharge;
            cartItem.discountedMonthlyCharge = phone.monthlyCharge;
            cartItem.protectionCharge = phone.protectionCharge;
            lastUsedUserNumber = lastUsedUserNumber + 1;

            const blobId = uuid() + ".wav";
            cartItem.configuration = new Model.PhoneConfiguration();
            cartItem.configuration.userId = uuid();
            cartItem.configuration.firstName = phoneConfiguration.firstName || "User " + (lastUsedUserNumber - 100);
            cartItem.configuration.lastName = phoneConfiguration.lastName || '';
            cartItem.configuration.extension = lastUsedUserNumber;
            cartItem.configuration.showRecording = false;
            cartItem.configuration.selectedGender = Gender.Male;
            cartItem.configuration.audioText = "Hi, sorry I missed your call. I’m either away at the moment or on the phone, please leave your name and number along with a short message and I’ll be sure to get back to you. Thanks and have a great day!";
            cartItem.configuration.sendVoiceMailTo = VoiceMail.EmailAndPhone;
            cartItem.configuration.blobFileName = `${this.environment.blobStoragePath}${blobId}`;
            cartItem.configuration.fileName = '';
            cartItem.configuration.voicemailRingTimeoutCount = 10;
            cartItem.configuration.callForwarding = false;
            cartItem.configuration.phoneNumber = '';
            cartItem.configuration.email = phoneConfiguration.email || '';
            cartItem.configuration.isNewNumber = false;
            cartItem.configuration.uploadRecording = false;
            cartItem.configuration.includeinCompanyDirectory = true;
            cartItem.configuration.attachVoicemail = true;
            cartItem.configuration.ccEmail = [];
            cartItem.configuration.emergencyCallForwarding = false;
            cartItem.configuration.emergencyCallForwardingNumber = '';
            cartItem.configuration.isSMSArchivingEnable= false;
            cartItem.configuration.archive_sms= '';
            cartItem.configuration.isVoicemailToEmailEnable=appState.metadata.systemType==SystemType.Business ? true : false;
            if (phone.featureId === 11) {
                cartItem.configuration.mac = phoneConfiguration.mac || '';
                cartItem.configuration.serial = phoneConfiguration.serial || '';
                cartItem.configuration.manufacturer = phoneConfiguration.manufacturer || '';
                cartItem.configuration.model = phoneConfiguration.model || '';
            }
            // Generate file for blob.
            this.audioService.generateAudioFileFromText(cartItem.configuration.audioText, Gender.Male, blobId).subscribe();
            changes.cartItems[uuid()] = cartItem;
        }

        this.deepMergeService.merge(cartItems, changes.cartItems);
        patchState({ cartItems: { ...cartItems } });

        switch (eventName) {
            case EventType.UpgradeToBusiness:
                this.orderService.saveOrderChanges({
                    orderId: appState.orderId,
                    changes: changes,
                    eventName: Model.EventType.UpgradeToBusiness,
                    crispSessionId: appState.crispSessionId,
                    signalRUserId: this.signalRService.userid
                });
                break;
            case EventType.UpgradeToHome:
                this.orderService.saveOrderChanges({
                    orderId: appState.orderId,
                    changes: changes,
                    eventName: Model.EventType.UpgradeToHome,
                    crispSessionId: appState.crispSessionId,
                    signalRUserId: this.signalRService.userid
                });
                break;
            case EventType.CartItemPhone:
                this.orderService.saveOrderChanges({
                    orderId: appState.orderId,
                    changes: changes,
                    eventName: Model.EventType.CartItemPhone,
                    crispSessionId: appState.crispSessionId,
                    signalRUserId: this.signalRService.userid
                });
                break;
            default:
                this.orderService.saveOrderChanges({
                    orderId: appState.orderId,
                    changes: changes,
                    eventName: Model.EventType.CartItemPhone,
                    crispSessionId: appState.crispSessionId,
                    signalRUserId: this.signalRService.userid
                });
        }

    }

    @Action(UpdateCartItemAction)
    updateCartItem({ patchState, getState }: StateContext<AppStateModel>, { key, updateFeatureId, eventName }: UpdateCartItemAction) {
        const appState = { ...getState() };
        const cartItems = appState.cartItems;
        const phones = appState.phones;
        const feature = _.filter(phones, (phone) => phone.featureId === updateFeatureId)[0];

        const changes: any = {};
        changes.cartItems = {};

        changes.cartItems[key] = {
            heading: feature.heading,
            itemId: feature.featureId,
            price: feature.withoutDiscountPriceValue,
            discountedPrice: feature.priceValue,
            discountedMonthlyCharge: feature.monthlyCharge,
            monthlyCharge: feature.monthlyCharge,
            protectionCharge: feature.protectionCharge
        };

        this.deepMergeService.merge(cartItems, changes.cartItems);
        patchState({ cartItems: { ...cartItems } });

        switch (eventName) {
            case EventType.UpgradeToBusiness:
                this.orderService.saveOrderChanges({
                    orderId: appState.orderId,
                    changes: changes,
                    eventName: Model.EventType.UpgradeToBusiness,
                    crispSessionId: appState.crispSessionId,
                    signalRUserId: this.signalRService.userid
                });
                break;
            case EventType.UpgradeToHome:
                this.orderService.saveOrderChanges({
                    orderId: appState.orderId,
                    changes: changes,
                    eventName: Model.EventType.UpgradeToHome,
                    crispSessionId: appState.crispSessionId,
                    signalRUserId: this.signalRService.userid
                });
                break;
            case EventType.CartItemPhone:
                this.orderService.saveOrderChanges({
                    orderId: appState.orderId,
                    changes: changes,
                    eventName: Model.EventType.CartItemPhone,
                    crispSessionId: appState.crispSessionId,
                    signalRUserId: this.signalRService.userid
                });
                break;
            default:
                this.orderService.saveOrderChanges({
                    orderId: appState.orderId,
                    changes: changes,
                    eventName: Model.EventType.CartItemPhone,
                    crispSessionId: appState.crispSessionId,
                    signalRUserId: this.signalRService.userid
                });
        }

    }
    //#endregion
    @Action(UpdatecartItemPaidStatusAction)
    updatecartItemPaidStatus({ patchState, getState }: StateContext<AppStateModel>, { cartItems }: UpdatecartItemPaidStatusAction) {
        const appState = { ...getState() };

        _.each(cartItems, (value, key) => {
            value.paid = true;
        });
        this.deepMergeService.merge(appState.cartItems, cartItems);
        patchState({ cartItems: { ...appState.cartItems } });

        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: { cartItems: cartItems },
            eventName: Model.EventType.CartItemPhone,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }

    //#region 'Check Out'
    @Action(UpdateCartItemConfigurationAction)
    updateCartItemConfiguration({ patchState, getState }: StateContext<AppStateModel>, { key, cartItemConfiguration }: UpdateCartItemConfigurationAction) {
        const appState = { ...getState() };
        const item = { ...appState.cartItems[key] };
        item.configuration = cartItemConfiguration;
        const cartItems = {};
        cartItems[key] = item;
        this.deepMergeService.merge(appState.cartItems, cartItems);

        patchState({ cartItems: { ...appState.cartItems } });

        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: { cartItems: cartItems },
            eventName: Model.EventType.CartItemConfigurationChange,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }

    @Action(RemoveCartItemAction)
    removeCartItem({ patchState, getState }: StateContext<AppStateModel>, { cartItemKey, eventName }: RemoveCartItemAction) {
        const appState = { ...getState() };
        const cartItem: Model.CartItem = appState.cartItems[cartItemKey];
        const callFlow: Model.CallFlow = appState.callFlow;
        const userId = cartItem.configuration.userId;

        const changes: any = { cartItems: {}, callFlow: { ringCallFlow: { ring: { users: {} } } } };
        changes.cartItems[cartItemKey] = null;
        if (cartItem.type === CartItemType.Phone) {
            changes.callFlow.ringCallFlow.ring.users[userId] = null;

            const cartItemArray = _.values(appState.cartItems);
            const anyCartItems = _.filter(cartItemArray, (item) => item.type === CartItemType.Phone && item.configuration.userId !== userId);
            let secondaryUserId = null;
            if (_.some(anyCartItems))
                secondaryUserId = anyCartItems[0].configuration.userId || null;

            if (callFlow) {
                if (callFlow.ringCallFlow) {
                    if (callFlow.ringCallFlow.ringOneUser === userId)
                        changes.callFlow.ringCallFlow.ringOneUser = secondaryUserId;

                    if (callFlow.ringCallFlow.voiceMailUser === userId)
                        changes.callFlow.ringCallFlow.voiceMailUser = secondaryUserId;
                }

                if (callFlow.autoAttendantOptions) {
                    // Remove user from call flow - Auto Attendant
                    Object.keys(callFlow.autoAttendantOptions).forEach(key => {
                        const option = callFlow.autoAttendantOptions[key];

                        if (option.type === AutoAttendantType.RingOne && option.ringOneUser === userId) {
                            changes.callFlow.autoAttendantOptions = {};
                            changes.callFlow.autoAttendantOptions[key] = { ringOneUser: secondaryUserId };

                        } else if (option.type === AutoAttendantType.RingGroup) {
                            // Check this user id is in list
                            Object.keys(option.users).forEach(userKey => {
                                if (userKey === userId) {
                                    changes.callFlow.autoAttendantOptions = {};
                                    changes.callFlow.autoAttendantOptions[key] = { users: {} };
                                    changes.callFlow.autoAttendantOptions[key].users[userKey] = null;
                                }
                            });

                            if (option.voiceMailTo === userId) {
                                if (!changes.callFlow.autoAttendantOptions)
                                    changes.callFlow.autoAttendantOptions = {};
                                changes.callFlow.autoAttendantOptions[key] = { voiceMailTo: secondaryUserId };
                            }
                        }
                    });
                }
            }
        }

        this.deepMergeService.merge(appState, changes);

        patchState({ cartItems: { ...appState.cartItems }, callFlow: { ...appState.callFlow } });

        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: changes,
            eventName: (cartItem.type === Model.CartItemType.Phone) ? Model.EventType.CartItemPhone : Model.EventType.CartItemApp,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });

        switch (eventName) {
            case EventType.UpgradeToBusiness:
                this.orderService.saveOrderChanges({
                    orderId: appState.orderId,
                    changes: changes,
                    eventName: Model.EventType.UpgradeToBusiness,
                    crispSessionId: appState.crispSessionId,
                    signalRUserId: this.signalRService.userid
                });
                break;
            case EventType.UpgradeToHome:
                this.orderService.saveOrderChanges({
                    orderId: appState.orderId,
                    changes: changes,
                    eventName: Model.EventType.UpgradeToHome,
                    crispSessionId: appState.crispSessionId,
                    signalRUserId: this.signalRService.userid
                });
                break;
            case EventType.CartItemPhone:
                this.orderService.saveOrderChanges({
                    orderId: appState.orderId,
                    changes: changes,
                    eventName: Model.EventType.CartItemPhone,
                    crispSessionId: appState.crispSessionId,
                    signalRUserId: this.signalRService.userid
                });
                break;
            case EventType.CartItemApp:
                this.orderService.saveOrderChanges({
                    orderId: appState.orderId,
                    changes: changes,
                    eventName: Model.EventType.CartItemApp,
                    crispSessionId: appState.crispSessionId,
                    signalRUserId: this.signalRService.userid
                });
                break;
            default:
                this.orderService.saveOrderChanges({
                    orderId: appState.orderId,
                    changes: changes,
                    eventName: (cartItem.type === Model.CartItemType.Phone) ? Model.EventType.CartItemPhone : Model.EventType.CartItemApp,
                    crispSessionId: appState.crispSessionId,
                    signalRUserId: this.signalRService.userid
                });
        }
    }

    @Action(UpdateBYODStatusAction)
    updateBYODStatus({ getState }: StateContext<AppStateModel>, { hasBYODphone }: UpdateBYODStatusAction) {
        const appState = getState();
        let changes: any = { hasBYOD: hasBYODphone };

        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: changes,
            eventName: Model.EventType.BYODupate,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }
    @Action(updateEmergencyStatusAction)
    updateEmergencyStatus({ getState }: StateContext<AppStateModel>, { hasEmergency999 }: updateEmergencyStatusAction) {
        const appState = getState();
        let changes: any = { hasEmergency999: hasEmergency999 };

        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: changes,
            eventName: Model.EventType.Emergency999Update,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }

    @Action(OrderedMorePhonesAction)
    orderedMorePhones({ patchState }: StateContext<AppStateModel>, { orderedMorePhones }: OrderedMorePhonesAction) {
        if (orderedMorePhones) {
            patchState({ orderedMorePhones: orderedMorePhones });
        }
    }

    @Action(ApplyPromoCodeAction)
    async applyPromoCode({ patchState, getState, dispatch, setState }: StateContext<AppStateModel>, { promoCode }: ApplyPromoCodeAction) {
        const appState = { ...getState() };

        if (appState.promoCodeApplied.status) {
            this.removePromoCode({ patchState, getState, dispatch, setState });
        }

        const res: any = await this.promotionService.applyPromoCodeAsync(appState.orderId, promoCode);

        let promoCodeApplied: Model.PromoCode;

        if (res.status === 200) {
            promoCodeApplied = { status: true, message: '' };
            let changes: any = { checkoutDetail: { promoCode: promoCode } };
            this.deepMergeService.merge(appState, changes);

            patchState({ checkoutDetail: { ...appState.checkoutDetail } });

            if (res.firstPormoterPromotion) {
                changes.metadata = { fp_ref: promoCode };
            }

            this.orderService.saveOrderChanges({
                orderId: appState.orderId,
                changes: changes,
                eventName: Model.EventType.ApplyPromoCode,
                crispSessionId: appState.crispSessionId,
                signalRUserId: this.signalRService.userid
            });
            this.crispService.setSessionEvent("checkout:promo-code", { promoCode: promoCode });
            LogRocket.track("checkout:promo-code");
        } else {
            promoCodeApplied = { status: false, message: res.result.message };
        }

        patchState({ promoCodeApplied: promoCodeApplied });
    }

    @Action(RemovePromoCodeAction)
    removePromoCode({ patchState, getState }: StateContext<AppStateModel>) {
        const appState = { ...getState() };

        const phones = appState.phones;
        const apps = appState.apps;

        const cartItems = {};
        // tslint:disable-next-line: forin
        for (const key in appState.cartItems) {
            let selectedCartItem;
            if (appState.cartItems[key].type == CartItemType.Phone) {
                selectedCartItem = _.filter(phones, phone => phone.featureId === appState.cartItems[key].itemId)[0];
                cartItems[key] = { discountedPrice: selectedCartItem.priceValue, discountedMonthlyCharge: selectedCartItem.monthlyCharge }
            }
            if (appState.cartItems[key].type == CartItemType.App) {
                selectedCartItem = _.filter(apps, app => app.appId === appState.cartItems[key].itemId)[0];
                cartItems[key] = { discountedPrice: selectedCartItem.price, discountedMonthlyCharge: selectedCartItem.monthlyCharge }
            }
        }

        const promoCodeApplied = appState.promoCodeApplied;
        promoCodeApplied.status = false;

        const changes: any = {
            checkoutDetail: { promoCode: null, discountedShippingCharges: appState.checkoutDetail.shippingCharges, discountedTotalAmount: 0 },
            cartItems: cartItems,
            promoCodeApplied: { status: false }
        };
        this.deepMergeService.merge(appState, changes);

        patchState({ checkoutDetail: appState.checkoutDetail, cartItems: appState.cartItems, promoCodeApplied: appState.promoCodeApplied });

        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: changes,
            eventName: Model.EventType.RemovePromoCode,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }

    @Action(ChangeBillingPlanAction)
    changeBillingPlan({ patchState, getState }: StateContext<AppStateModel>, { isAnnualBilling }: ChangeBillingPlanAction) {
        const appState = { ...getState() };
        if (isAnnualBilling !== appState.checkoutDetail.payAnnually) {
            const changes = { checkoutDetail: { payAnnually: isAnnualBilling } };
            this.deepMergeService.merge(appState, changes);
            patchState({ checkoutDetail: appState.checkoutDetail });

            this.orderService.saveOrderChanges({
                orderId: appState.orderId,
                changes: changes,
                eventName: Model.EventType.PaymentDuration,
                crispSessionId: appState.crispSessionId,
                signalRUserId: this.signalRService.userid
            });
        }
    }

    @Action(ChangeBillingPlanAppAction)
    changeBillingPlanApp({ patchState, getState }: StateContext<AppStateModel>, { isAnnualBilling }: ChangeBillingPlanAction) {
        const appState = { ...getState() };
        if (isAnnualBilling !== appState.checkoutDetail.payAnnually) {
            const changes = { checkoutDetail: { payAnnually: isAnnualBilling } };
            this.deepMergeService.merge(appState, changes);
            patchState({ checkoutDetail: appState.checkoutDetail });

            this.orderService.saveOrderChanges({
                orderId: appState.orderId,
                changes: changes,
                eventName: Model.EventType.ChangeBillingApp,
                crispSessionId: appState.crispSessionId,
                signalRUserId: this.signalRService.userid
            });
        }
    }

    @Action(ChangeProtectionPlanStatusAction)
    changeProtectionPlanStatus({ patchState, getState }: StateContext<AppStateModel>, { status }: ChangeProtectionPlanStatusAction) {
        const appState = { ...getState() };
        if (status !== appState.checkoutDetail.hasProtectionPlan) {
            const changes = { checkoutDetail: { hasProtectionPlan: status } };
            this.deepMergeService.merge(appState, changes);
            patchState({ checkoutDetail: appState.checkoutDetail });

            this.orderService.saveOrderChanges({
                orderId: appState.orderId,
                changes: changes,
                eventName: Model.EventType.ProtectionPlanStatus,
                crispSessionId: appState.crispSessionId,
                signalRUserId: this.signalRService.userid
            });
        }
    }


    @Action(UpdateCartItemProtectionPlanAction)
    updateCartItemProtectionPlanAction({ patchState, getState }: StateContext<AppStateModel>) {
        const appState = { ...getState() };
        const cartItems = appState.cartItems;
        const payAnnually = appState.checkoutDetail.payAnnually;
        const hasProtectionPlan = appState.checkoutDetail.hasProtectionPlan;
        _.each(cartItems, (value, key) => {
            if (value.type === 'phone') {
                if (hasProtectionPlan)
                    if (!payAnnually) {
                        switch (value.protectionCharge) {
                            case 0: value.protectionPlan = ''; break;
                            case 0.99: value.protectionPlan = 'monthly1'; break;
                            case 1.99: value.protectionPlan = 'monthly2'; break;
                            case 2.99: value.protectionPlan = 'monthly3'; break;
                            default: value.protectionPlan = 'monthly3'; break;
                        }
                    } else {
                        switch (value.protectionCharge) {
                            case 0: value.protectionPlan = ''; break;
                            case 0.99: value.protectionPlan = 'yearly1'; break;
                            case 1.99: value.protectionPlan = 'yearly2'; break;
                            case 2.99: value.protectionPlan = 'yearly3'; break;
                            default: value.protectionPlan = 'yearly1'; break;
                        }
                    } else {
                    value.protectionPlan = ''
                }
            }
        });
        const changes = { cartItems };
        this.deepMergeService.merge(appState, changes);
        patchState({ cartItems: appState.cartItems });

        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: changes,
            eventName: Model.EventType.CartItemProtectionPlan,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }
    // #endregion

    //#region call flow scenerios

    @Action(SaveCallFlowSelectionAction)
    saveCallFlowSelection({ patchState, getState }: StateContext<AppStateModel>, { callFlowSelection, companyNumber }: SaveCallFlowSelectionAction) {
        const appState = { ...getState() };
        const companyNumbers = [...appState.companyNumbers]
        let changes: any = {};
        changes.companyNumbers = [...companyNumbers]
        //This check is for new orders. In previous orders we didn't had companyNumber argument
        if (companyNumber) {
            _.each(changes.companyNumbers, (value, key) => {
                if (companyNumber.number === value.number) {
                    value.callFlow = callFlowSelection
                    if (value.isPrimaryNumber) {
                        changes.callFlow = { ...callFlowSelection }
                        patchState({ callFlow: { ...callFlowSelection } });
                        patchState({ companyNumbers: [...changes.companyNumbers] });
                    }
                    else {
                        patchState({ companyNumbers: [...changes.companyNumbers] })
                    }
                }

            });
        } else {
            if(companyNumber==undefined || companyNumber==null){
                _.each(changes.companyNumbers,(value,keys)=>{
                    value.callFlow={...callFlowSelection};
                    changes.callFlow={...callFlowSelection};
                    patchState({ callFlow: { ...callFlowSelection } });
                    patchState({ companyNumbers: [...changes.companyNumbers] });
                })
            }
            else{
                changes.callFlow = { ...callFlowSelection }
                patchState({ callFlow: { ...callFlowSelection } });
            }
            
        }
        this.deepMergeService.merge(appState, changes);
        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: changes,
            eventName: Model.EventType.CallFlow,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }

    @Action(SaveDefaultBasicCallFlowAction)
    saveDefaultBasicCallFlow({ patchState, getState }: StateContext<AppStateModel>, { callFlowSelection }: SaveDefaultBasicCallFlowAction) {
        const appState = { ...getState() };
        const companyNumbers = [...appState.companyNumbers]
        const primaryNumber = { ...appState.primaryNumber };
        let changes: any = {};
        changes.companyNumbers = [...companyNumbers]

        _.each(changes.companyNumbers, (value, key) => {
            if (primaryNumber.number === value.number) {
                value.callFlow = callFlowSelection
                if (value.isPrimaryNumber) {
                    changes.callFlow = { ...callFlowSelection }
                    patchState({ callFlow: { ...callFlowSelection } });
                    patchState({ companyNumbers: [...changes.companyNumbers] });
                }
            }

        });
        changes.callFlow = { ...callFlowSelection }
        patchState({ callFlow: { ...callFlowSelection } });

        this.deepMergeService.merge(appState, changes);
        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: changes,
            eventName: Model.EventType.CallFlow,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }
    //#endregion

    //#region Internet Providers

    @Action(SelectInternetProviderAction)
    selectInternetProvider({ patchState, getState }: StateContext<AppStateModel>, { selectInternetProviderName }: SelectInternetProviderAction) {
        const temp: any = {};
        const appState = { ...getState() };
        const providerNames: string = (appState.checkoutDetail.internetProvider || temp).name || '';

        const selectedProviders: string[] = providerNames.split("|");

        let elementExists = false;
        for (let index = 0; index < selectedProviders.length; index++) {
            const element = selectedProviders[index];
            if (element === selectInternetProviderName) {
                selectedProviders.splice(index, 1);
                elementExists = true;
                break;
            }
        }

        if (!elementExists)
            selectedProviders.push(selectInternetProviderName);

        const checkoutDetail = { internetProvider: { name: selectedProviders.join('|') } };

        this.deepMergeService.merge(appState.checkoutDetail, checkoutDetail);

        patchState({ checkoutDetail: { ...appState.checkoutDetail } });

        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: { checkoutDetail: checkoutDetail },
            eventName: Model.EventType.InternetProvider,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }
    //#endregion

    @Action(ShippingSameAsBillingAction)
    shippingSameAsBilling({ patchState, getState }: StateContext<AppStateModel>, { isTrue }: ShippingSameAsBillingAction) {
        const appState = { ...getState() };

        const changes = { checkoutDetail: { shippingAddress: appState.checkoutDetail.shippingAddress, isShippingSameAsBillingAddress: isTrue } };

        if (isTrue) {
            //if same, then fill the shipping address by billing address values
            const billingAddress = appState.checkoutDetail.billingAddress;
            changes.checkoutDetail.shippingAddress = {
                name: billingAddress.name,
                phone: billingAddress.phone,
                address: billingAddress.address,
                address2: billingAddress.address2,
                city: billingAddress.city,
                state: billingAddress.state,
                country: billingAddress.country,
                zip: billingAddress.zip,
                email: billingAddress.email
            }
        }
        this.deepMergeService.merge(appState, changes);

        appState.checkoutDetail.shippingAddress = { ...appState.checkoutDetail.shippingAddress };
        patchState({ checkoutDetail: appState.checkoutDetail });

        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: changes,
            eventName: Model.EventType.ShippingAddress,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }

    @Action(SelectShippingOptionAction)
    selectShippingOption({ patchState, getState }: StateContext<AppStateModel>, { shippingOption, eventName }: SelectShippingOptionAction) {
        const appState = getState();
        if (shippingOption) {
            const checkoutDetail = {
                shippingCharges: shippingOption.charges,
                discountedShippingCharges: shippingOption.charges,
                shipperMethod: shippingOption.label,
                shippingOption: shippingOption.id
            };
            this.deepMergeService.merge(appState.checkoutDetail, checkoutDetail);
            patchState({ checkoutDetail: { ...appState.checkoutDetail } });


            switch (eventName) {
                case EventType.UpgradeToBusiness:
                    this.orderService.saveOrderChanges({
                        orderId: appState.orderId,
                        changes: { checkoutDetail: checkoutDetail },
                        eventName: Model.EventType.UpgradeToBusiness,
                        crispSessionId: appState.crispSessionId,
                        signalRUserId: this.signalRService.userid
                    });
                    break;
                case EventType.UpgradeToHome:
                    this.orderService.saveOrderChanges({
                        orderId: appState.orderId,
                        changes: { checkoutDetail: checkoutDetail },
                        eventName: Model.EventType.UpgradeToHome,
                        crispSessionId: appState.crispSessionId,
                        signalRUserId: this.signalRService.userid
                    });
                    break;
                case EventType.Shipping:
                    this.orderService.saveOrderChanges({
                        orderId: appState.orderId,
                        changes: { checkoutDetail: checkoutDetail },
                        eventName: Model.EventType.Shipping,
                        crispSessionId: appState.crispSessionId,
                        signalRUserId: this.signalRService.userid
                    });
                    break;
                default:
                    this.orderService.saveOrderChanges({
                        orderId: appState.orderId,
                        changes: { checkoutDetail: checkoutDetail },
                        eventName: Model.EventType.Shipping,
                        crispSessionId: appState.crispSessionId,
                        signalRUserId: this.signalRService.userid
                    });
                    break;
            }
        }
    }

    @Action(ShippingAddressChangeAction)
    shippingAddressChange({ patchState, getState }: StateContext<AppStateModel>, { shippingAddress }: ShippingAddressChangeAction) {
        const appState = getState();
        const checkoutDetail = { shippingAddress: shippingAddress };

        this.deepMergeService.merge(appState.checkoutDetail, checkoutDetail);
        patchState({ checkoutDetail: { ...appState.checkoutDetail } });

        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: { checkoutDetail: checkoutDetail },
            eventName: Model.EventType.ShippingAddress,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }

    @Action(EmergencyAddressChangeAction)
    emergencyAddressChange({ patchState, getState }: StateContext<AppStateModel>, { emergencyAddress }: EmergencyAddressChangeAction) {
        const appState = { ...getState() };
        const checkoutDetail = { emergencyAddress: emergencyAddress };

        this.deepMergeService.merge(appState.checkoutDetail, checkoutDetail);
        patchState({ checkoutDetail: { ...appState.checkoutDetail } });

        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: { checkoutDetail: checkoutDetail },
            eventName: Model.EventType.EmergencyAddress,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }

    @Action(BillingAddressChangeAction)
    billingAddressChange({ patchState, getState }: StateContext<AppStateModel>, { billingAddress }: BillingAddressChangeAction) {
        const appState = getState();
        const checkoutDetail = { billingAddress: billingAddress };
        if (appState.checkoutDetail.isShippingSameAsBillingAddress) {
            checkoutDetail['shippingAddress'] = billingAddress;
        }
        this.deepMergeService.merge(appState.checkoutDetail, checkoutDetail);
        patchState({ checkoutDetail: { ...appState.checkoutDetail } });

        const changes = { checkoutDetail: checkoutDetail };
        if (checkoutDetail.billingAddress.email) {
            changes['email'] = checkoutDetail.billingAddress.email;
        }
        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: changes,
            eventName: Model.EventType.BillingAddress,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });

        if (billingAddress.email && appState.firstPromoter_ref) {
            this.promotionService.markFirstPromoterLeadAsync(billingAddress.email, appState.firstPromoter_ref, appState.orderId);
        }
    }

    @Action(BillingAddressChangeExtralineSettingsAction)
    billingAddressChangeExtralineSettings({ patchState, getState }: StateContext<AppStateModel>, { billingAddress }: BillingAddressChangeAction) {
        const appState = { ...getState() };
        const checkoutDetail = { billingAddress: billingAddress, isShippingSameAsBillingAddress: false };
        this.deepMergeService.merge(appState.checkoutDetail, checkoutDetail);
        patchState({ checkoutDetail: { ...appState.checkoutDetail } });

        const changes = { checkoutDetail: checkoutDetail };
        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: changes,
            eventName: Model.EventType.BillingAddressExtraline,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }

    @Action(CompanyNameChangeAction)
    companyNameChange({ patchState, getState }: StateContext<AppStateModel>, { companyName }: CompanyNameChangeAction) {
        const appState = { ...getState() };

        const checkoutDetail = { companyName: companyName };
        this.deepMergeService.merge(appState.checkoutDetail, checkoutDetail);
        patchState({ checkoutDetail: appState.checkoutDetail });

        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: { checkoutDetail: checkoutDetail },
            eventName: Model.EventType.CompanyName,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }

    @Action(StripeDataChangedAction)
    stripeDataChanged({ patchState, getState }: StateContext<AppStateModel>, { stripe }: StripeDataChangedAction) {
        const appState = { ...getState() };
        const stripeData = { ...stripe };
        this.deepMergeService.merge(appState.card, stripeData.card);
        patchState({ card: appState.card });

        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: { card: { ...stripe.card } },
            eventName: Model.EventType.Payment,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }

    @Action(ShareOrderAction)
    shareOrder({ patchState, getState }: StateContext<AppStateModel>, { sharing }: ShareOrderAction) {
        const appState = { ...getState() };

        const checkoutDetail = { sharing: sharing };
        this.deepMergeService.merge(appState.checkoutDetail, checkoutDetail);
        patchState({ checkoutDetail: appState.checkoutDetail });

        this.orderService.saveOrderChanges({
            orderId: appState.orderId,
            changes: { checkoutDetail: checkoutDetail },
            eventName: Model.EventType.ShareOrder,
            crispSessionId: appState.crispSessionId,
            signalRUserId: this.signalRService.userid
        });
    }

    @Action(SetBillingAddressFormStatusAction)
    setBillingAddressFormStatus({ patchState, getState }: StateContext<AppStateModel>, { isValid }: SetBillingAddressFormStatusAction) {
        const appState = getState();
        patchState({ checkoutDetail: { ...appState.checkoutDetail, isValid } });
    }

    @Action(SetShippingAddressFormStatusAction)
    setShippingAddressFormStatus({ patchState, getState }: StateContext<AppStateModel>, { isValid }: SetShippingAddressFormStatusAction) {
        const appState = getState();
        patchState({ checkoutDetail: { ...appState.checkoutDetail, isValid } });
    }
    //#endregion
}
