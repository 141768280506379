<div class="card ">
  <div class="card-body">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <h5 class="text-secondary font-weight-bold m-0 d-flex"><img src="assets/images/dollar-bag.svg"
          class="icon-primary mr-3" style="width:20px" alt=""> BUDGET PER WEEK </h5>
      <i class="fas fa-question-circle fa-lg" *ngIf="budgetHelpText" [popover]="budgetHelpText" placement="auto"
        containerClass="popover-css" [outsideClick]="true"></i>
    </div>
    <div class="border-bottom my-3"></div>

    <div class="d-flex flex-row align-items-center">
      <h5 class="font-weight-bold m-0">$10</h5>
      <form [ngFormOptions]="{updateOn: 'blur'}" class="flex-fill">
        <div class="range-wrap mx-3 change-budget">
          <input type="range" class="range range-slider range-budget" min="10" max="500" name="range" step="5"
            [ngModel]="selectedBudget" (ngModelChange)="changeBudget.emit($event)">
          <div class="position-absolute" style="left: 40%;">
            <div class="position-relative">
              <div class="tickmarks"></div>
              <div class="position-absolute " style="font-size:10px;top:-15px;right:-40px;">RECOMMENDED
              </div>
            </div>
          </div>
          <output class="bubble bubble-budget"></output>
        </div>
      </form>
      <h5 class="font-weight-bold m-0">$500</h5>

    </div>
  </div>
</div>