import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { App } from '@voiply/shared/model';
import { ModalDirective, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { OnDestroyCleanup } from '@voiply/shared/common-ui';
import { ShowAppDetailsModalComponent } from '../show-app-details-modal/show-app-details-modal.component';
import { take } from 'rxjs/operators';
import * as _ from 'lodash';
@Component({
  selector: 'voiply-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends OnDestroyCleanup implements OnInit {
  @Input()
  apps$: Observable<any>;
  @Input()
  selectedCountry = 'US';
  @Output()
  addToCart = new EventEmitter<App>();
  modalRef: BsModalRef;
  showapps = 4;
  apps: App[]
  // @ViewChild('appModal', { static: true }) appModal: ModalDirective;

  constructor(private modalService: BsModalService) {
    super()
  }

  ngOnInit() {
    this.subscriptions$.add(this.apps$.subscribe((apps) => this.apps = _.filter(apps, (app) => app.isActive)));
  }

  onAddToCart(app: App) {
    this.addToCart.emit(app);
  }
  onOpenModalDialog(index: number) {
    const initialState = {
      app: this.apps[index],
    };
    this.modalRef = this.modalService.show(ShowAppDetailsModalComponent, { initialState, class: 'modal-lg' });
    this.modalRef.content.addToCart.pipe(take(1)).subscribe((app) => {
      this.onAddToCart(app)
    });

  }
  showMoreApps() {
    this.showapps = this.showapps + 4;
  }
  appAvailableInCountry(app) {
    if (app.onlyAvailableInCountry.length) {
      if (_.includes(app.onlyAvailableInCountry, this.selectedCountry)) {
        return true
      } else return false
    } else return true
  }
}
