import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppStateSelectors } from '@voiply/shared/common-ui';
import { Select } from '@ngxs/store';
import { Observable, identity } from 'rxjs';
import { User, CallFlow, Users, CallFlowsHelpText } from '@voiply/shared/model';

@Component({
  selector: 'voiply-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.css']
})
export class BasicComponent implements OnInit {

  @Output() configurationChanged = new EventEmitter<any>();

  @Input() users: Users[];
  @Input() callFlow: CallFlow;
  @Input() callFlowHelpText: CallFlowsHelpText
  constructor() { }

  ngOnInit() {
  }
  isUserSelectedRingOne(userId: string, index: number): boolean {
    if (this.callFlow.ringCallFlow == undefined || this.callFlow.ringCallFlow.ringOneUser == undefined || this.callFlow.ringCallFlow.ringOneUser == '')
      return index == 0;
    else
      return userId == this.callFlow.ringCallFlow.ringOneUser;
  }
  toggleUserSelectionRingOne(userId: string) {
    if (userId != this.callFlow.ringCallFlow.ringOneUser) {
      this.callFlow.ringCallFlow.ringOneUser = userId;
      this.updateConfiguration();
    }
  }
  updateBusinessHours(changes) {
    this.callFlow.ringCallFlow = { ...changes };
    this.updateConfiguration();
  }

  updateConfiguration() {
    this.configurationChanged.emit(this.callFlow);
  }
  onVoiceMailUserChanged(userId) {
    this.callFlow.ringCallFlow.voiceMailUser = userId;
    this.updateConfiguration();
  }
  onTimeZoneChange(timeZone) {
    this.callFlow.ringCallFlow.timeZone = timeZone;
    this.updateConfiguration();
  }
  onvoicemailRingTimeoutCountChanged(ringCount) {
    this.callFlow.ringCallFlow.voicemailRingTimeoutCount = parseInt(ringCount);
    this.updateConfiguration();
  }
  onCallForwardChange(callForward) {
    this.callFlow.ringCallFlow.callForward = callForward;
    this.updateConfiguration();
  }
  onCallForwardNumbersChange(callForwardNumbers) {
    this.callFlow.ringCallFlow.callForwardNumbers = [...callForwardNumbers];
    this.updateConfiguration();
  }

}
