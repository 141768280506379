<div class="p-3 d-flex border-bottom align-items-center">
    <i class="fas fa-bookmark text-secondary mr-3"></i>
    <h5 class="text-secondary font-weight-bold m-0">EDIT INTRO</h5>
</div>
<div class="modal-body">
    Intro Text
    <textarea type="text" class="form-control mt-3" [(ngModel)]="introText"></textarea>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark px-4 py-2" data-dismiss="modal"
        (click)="bsModalRef.hide()">Cancel</button>
    <button (click)="this.introChange.emit(introText);bsModalRef.hide()" class="btn bg-primary text-primary px-4 py-2">
        Save
    </button>
</div>