import { Injectable } from "@angular/core";
import { CartItemType, EventType, SystemType } from "@voiply/shared/model";
import { BaseService } from "./base.service";
import * as _ from 'lodash';
import { OrderService } from "./order.service";
import * as LogRocket from 'logrocket';
import { HttpClient } from "@angular/common/http";

declare let ga: Function;

@Injectable({
    providedIn: 'root'
})
export class ConversionService extends BaseService {

    get nativeWindow(): any {
        return window;
    }
    constructor(private orderService: OrderService,
        private httpClient: HttpClient) {
        super()
    }

    async CJExecution(orderId, systemType, cartItems, checkoutDetail, metadata) {
        let paymentAnually: boolean;
        let containerId
        let actionId;
        let cid = 1557435; //curently it is same for all scenarios
        let appendString = '';


        paymentAnually = checkoutDetail.payAnnually;

        let index = 1;
        let heading = [];

        //distinct products
        _.each(cartItems, (value, key) => {
            if (!heading.includes(value.heading))
                heading.push(value.heading)
        })


        _.each(heading, (feature) => {
            const phones = _.filter(cartItems, (item) => item.heading == feature && item.type == CartItemType.Phone);
            const apps = _.filter(cartItems, (item) => item.heading == feature && item.type == CartItemType.App);

            //append item, amount, quantity after grouping products together
            if (phones.length > 0) {
                let amount: number = 0;
                if (phones[0].discountedMonthlyCharge)
                    amount = phones[0].discountedMonthlyCharge; //As per CJ document: If you offer discounts and coupons, but are not using the DISCOUNT parameter, this value must be the post-discount unit price.
                if (paymentAnually)
                    amount = amount * 11;
                appendString += `&ITEM${index}=phone_${phones[0].itemId}&AMT${index}=${amount.toFixed(2)}&QTY${index}=${phones.length}`
                index++;
            }

            //append item, amount, quantity after grouping apps together
            if (apps.length > 0) {
                let amount: number = 0;
                if (apps[0].discountedMonthlyCharge)
                    amount = apps[0].discountedMonthlyCharge;
                if (paymentAnually)
                    amount = amount * 11;
                appendString += `&ITEM${index}=app_${apps[0].itemId}&AMT${index}=${amount.toFixed(2)}&QTY${index}=${apps.length}`
                index++;
            }
        });


        //When a CJ Event is known at the time of conversion, the tag URL must include this value.
        //If the CJ event value is blank, still need to pass null in the parameter.
        let CJEVENT = metadata.CJEVENT; //value from querystring
        if (CJEVENT == '' || CJEVENT == undefined)
            CJEVENT = null;
        //Add Promocodo to CjURl
        appendString += `&COUPON=${checkoutDetail.promoCode || ""}`
        if (systemType == SystemType.Business) {
            if (paymentAnually) {
                containerId = 34732;
                actionId = 416990;
            }
            else {
                containerId = 34648;
                actionId = 416872;
            }
        }
        else {
            if (paymentAnually) {
                containerId = 34829;
                actionId = 417119;
            }
            else {
                containerId = 34828;
                actionId = 417118;
            }
        }
        let url = `https://www.emjcd.com/tags/c?containerTagId=${containerId}&OID=${orderId}&TYPE=${actionId}&CID=${cid}&CURRENCY=USD&CJEVENT=${CJEVENT}${appendString}`;
        console.log("CJ url generated: ", url);
        LogRocket.track("Pixel:CJ");
        localStorage.setItem('cjUrl', url);

        this.orderService.saveOrderChanges({
            orderId: orderId,
            changes: { isCJExecuted: true },
            eventName: EventType.OrderLocked,
            crispSessionId: null,
            signalRUserId: null
        });
    }

    salesFireConversion(orderId, orderTotal, shippingCharges, tax) {
        const script = document.createElement('script');
        script.type = 'application/javascript',
            script.text = `
            window.sfDataLayer = window.sfDataLayer || [];
            window.sfDataLayer.push({
              "ecommerce" : {
                  "purchase": {
                      "id": "${orderId}",
                      "revenue": "${orderTotal}",
                      "shipping": "${shippingCharges}",
                      "tax": "${tax}",
                      "currency": "USD"
                   }
                }
            });`;
        document.getElementsByTagName('head')[0].appendChild(script);
        console.log("Added SalesFire Conversion Tag");
    }

    microsoftConversion() {
        const script = document.createElement('script');
        script.type = 'application/javascript',
            script.text = `
          (function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"12097390"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`;
        document.getElementsByTagName('head')[0].appendChild(script);
        this.nativeWindow.uetq = [];
        this.nativeWindow.uetq.push('event', 'purchase', { 'event_category': 'purchase', 'event_label': 'purchase', 'event_value': '1' });
        //this.nativeWindow.uetq.push({ 'gv': orderTotal.toFixed(2), 'gc': 'USD' });
        console.log("Added Microsoft Conversion Tag");
    }

    googleTagConversion(orderId, orderTotal) {
        const gtagscript = document.createElement('script');
        gtagscript.type = 'application/javascript',
            gtagscript.text = `
            gtag('event', 'conversion', {
              'send_to': 'AW-956034716/QJj7CN6e78gBEJzd78cD',
              'value': "${orderTotal}",
              'currency': 'USD',
              'transaction_id': "${orderId}"
            }); 
            gtag({'event':'Checkout_Purchase','conversionValue':1});
          `;
        document.getElementsByTagName('head')[0].appendChild(gtagscript);
        console.log("Added Tag");
    }

    gaConversion(orderId, systemType, orderTotal, shippingCharges, tax) {
            ga('require', 'ecommerce');
    
            ga('ecommerce:addTransaction', {
                'id': orderId,                     // Transaction ID. Required.
                'affiliation': systemType,   // Affiliation or store name.
                'revenue': orderTotal,               // Grand Total.
                'shipping': shippingCharges,                  // Shipping.
                'tax': tax                     // Tax.
            });
    
            ga('ecommerce:send');
    }

    facebookpixel(orderId, systemType, orderTotal) {
        this.nativeWindow.fbq('track', 'Purchase', { value: orderTotal, currency: 'USD' }, { eventID: orderId });

        let token = "";
        let pixelID = "";
        let data = [
            {
                "event_name": "Purchase",
                "event_time": new Date(),
                "action_source": "email",
                "user_data": { "em": "7b17fb0bd173f625b58636fb796407c22b3d16fc78302d79f0fd30c2fc2fc068", "ph": null },
                "custom_data": { "currency": "USD", "value": orderTotal }
            }];
        if (systemType == SystemType.Home) {
            token = "EAA7RMK15XdIBAAc6bK9BsH5YeI5yLEqlciSfFOjPJKoWoLfO2xOJoDB1ZCG9LyutoeDiZCY2AwmzHw2E6jQ7vYZAhYZAFBBONvknLnZC9VG6kFWWz4sq00Cw8fGFfCZALVt18fcGuSZB20ZBjN9ZCZCW3j9S2O0vwYvw6CnzIZAzaZARLy3BeBZCUPF7t";
            pixelID = "319948005357022";
        }
        else {
            token = "EAA7RMK15XdIBANHw0NepSNgZAkQZBnuk7EBNT1oCUeNtvSZBDZBTkaH4pZC2SosnL5rlzUz3uQ5TXy0HydE9w9x9ny7IhN4pOGINZC0me8T6fQFqZBG4I5JX5rYbuN1CYZBwEcWVnahSWKEq6r2HKIws9sgwI3vRJRI4HsN8MbYPWpOXcLHOprqJ";
            pixelID = "321501911777330";
        }
        this.httpClient.post(`https://graph.facebook.com/v6.0/${pixelID}/events?access_token=${token}`, { "data": data })
    }

    sendInBlueConversion(email,firstName,lastName,orderId,systemType,location){
        const script = document.createElement('script');
        script.type = 'application/javascript',
            script.text = `
            (function() {
                window.sib = {
                    equeue: [],
                    client_key: "abmmqbdzjyu6z9nef85ul86p"
                };
                /* OPTIONAL: email for identify request*/
                // window.sib.email_id = 'example@domain.com';
                window.sendinblue = {};
                for (var j = ['track', 'identify', 'trackLink', 'page'], i = 0; i < j.length; i++) {
                (function(k) {
                    window.sendinblue[k] = function() {
                        var arg = Array.prototype.slice.call(arguments);
                        (window.sib[k] || function() {
                                var t = {};
                                t[k] = arg;
                                window.sib.equeue.push(t);
                            })(arg[0], arg[1], arg[2]);
                        };
                    })(j[i]);
                }
                var n = document.createElement("script"),
                    i = document.getElementsByTagName("script")[0];
                n.type = "text/javascript", n.id = "sendinblue-js", n.async = !0, n.src = "https://sibautomation.com/sa.js?key=" + window.sib.client_key, i.parentNode.insertBefore(n, i), window.sendinblue.page();
            })();
            
            `;
        document.getElementsByTagName('head')[0].appendChild(script);
        let plan;
        if(systemType==SystemType.Home){
            plan='residential'
        }
        else if(systemType==SystemType.Business){
            plan='business'
        }
        else if(systemType==SystemType.Fax){
            plan='fax'
        }
        else{
            plan='app';
        }


        this.nativeWindow.sendinblue.identify(email, {
            'FIRSTNAME': firstName,
            'LASTNAME' : lastName,
            'id': orderId,
            'plan' : plan,
            'location' : location
        });

        if(plan=='residential'){
            this.nativeWindow.sendinblue.track( 'order_residential' );
        }
        else if(plan=='business' || plan=="fax"){
            this.nativeWindow.sendinblue.track( 'order_business' );
        }
        else{
            this.nativeWindow.sendinblue.track( 'order_app' );
        }
        
    }
}