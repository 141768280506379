<div class="row">
  <div class="col-12 col-md-6" style="padding-right:12px;padding-left:12px;">
    <div class="position-relative">
      <div *ngIf="callFlow.ringCallFlow.callForward" class="position-absolute h-100 w-100 bg-black"
        style="opacity: 0.1; z-index: 2;"></div>
      <voiply-business-hours [callFlow]="callFlow" (configurationChanged)="updateBusinessHours($event)"
        [businessHoursHelpText]="callFlowHelpText.businessHours">
      </voiply-business-hours>
    </div>
  </div>
  <div class="col-12 col-md-6 pt-4 pt-md-0" style="padding-left:12px;padding-right:12px;">
    <div class="position-relative">
      <div *ngIf="callFlow.ringCallFlow.callForward" class="position-absolute h-100 w-100 bg-black"
        style="opacity: 0.1; z-index: 1;"></div>
      <voiply-timezone [timeZone]="this.callFlow.ringCallFlow.timeZone"
        [timeZoneHelpText]="this.callFlowHelpText.timeZone" (timeZoneChange)=onTimeZoneChange($event)>
      </voiply-timezone>
    </div>
    <div class="mt-4 position-relative">
      <div *ngIf="callFlow.ringCallFlow.callForward" class="position-absolute h-100 w-100 bg-black"
        style="opacity: 0.1; z-index: 1;"></div>
      <voiply-ringone-user [users]="users" [ringOneUser]="callFlow.ringCallFlow.ringOneUser"
        (userSelectionChange)="toggleUserSelectionRingOne($event)" [ringOneUserHelpText]="callFlowHelpText.ringOneUser">
      </voiply-ringone-user>
    </div>

    <div class="mt-4 position-relative">
      <div *ngIf="callFlow.ringCallFlow.callForward" class="position-absolute h-100 w-100 bg-black"
        style="opacity: 0.1; z-index: 1;"></div>
      <voiply-missedcalls-voicemail [voiceMailUser]="callFlow.ringCallFlow.voiceMailUser"
        [voicemailRingTimeoutCount]="callFlow.ringCallFlow.voicemailRingTimeoutCount" [users]="users"
        [voicemailHelpText]="callFlowHelpText.voicemail" (updateVoiceMailUser)="onVoiceMailUserChanged($event)"
        (updateVoicemailRingTimeoutCount)="onvoicemailRingTimeoutCountChanged($event)"></voiply-missedcalls-voicemail>
    </div>
    <div class="mt-4">
      <voiply-call-forward [callForward]="callFlow.ringCallFlow.callForward"
        [callForwardNumbers]="callFlow.ringCallFlow.callForwardNumbers"
        [callForwardHelpText]="callFlowHelpText.callForward" (callForwardChange)="onCallForwardChange($event)"
        (callForwardNumbersChange)="onCallForwardNumbersChange($event)">
      </voiply-call-forward>
    </div>
  </div>
</div>