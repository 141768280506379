<div class="card d-flex flex-column"
  [ngClass]="{'h-100':(getTitle(editKey)==='CONFIGURE GREETING'||getTitle(editKey)==='CONFIGURE VOICEMAIL'||getTitle(editKey)==='CONFIGURE ANNOUNCEMENTS')}">
  <div class="card-body d-flex flex-column">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <h5 class="text-secondary font-weight-bold m-0"><i class="fas fa-cog pr-3"></i>{{getTitle(editKey)}}</h5>
      <i class="fas fa-question-circle fa-lg" *ngIf="getHelpText(editKey)" [popover]="getHelpText(editKey)"
        placement="auto" [outsideClick]="true" containerClass="popover-css"></i>
    </div>
    <div class="border-bottom my-3"></div>
    <!--If Greeting is not selected than show select autoattendant Type Dropdown-->
    <div class="" *ngIf="selectedAutoAttendantOption.type != AUTO_ATTENDANT_TYPE.Greeting"
      [ngClass]="{'mb-3':getTitle(editKey)==='CONFIGURE ANNOUNCEMENTS'}">
      <div class="select-input">
        <i class="fas fa-caret-down  dropdown-img d-flex align-items-center"></i>
        <select class="select-custom form-control text-secondary font-weight-bold" (change)="onFlowChange()"
          [(ngModel)]="selectedAutoAttendantOption.type">
          <option [value]="AUTO_ATTENDANT_TYPE.NoConfiguration" *ngIf="this.editKey!='+'"
            [selected]="selectedAutoAttendantOption.type == AUTO_ATTENDANT_TYPE.NoConfiguration">
            {{AUTO_ATTENDANT_TYPE.NoConfiguration}}</option>
          <option [value]="AUTO_ATTENDANT_TYPE.RingOne"
            [selected]="selectedAutoAttendantOption.type == AUTO_ATTENDANT_TYPE.RingOne">
            {{AUTO_ATTENDANT_TYPE.RingOne}}
          </option>
          <option [value]="AUTO_ATTENDANT_TYPE.RingGroup"
            [selected]="selectedAutoAttendantOption.type == AUTO_ATTENDANT_TYPE.RingGroup">
            {{AUTO_ATTENDANT_TYPE.RingGroup}}</option>
          <option [value]="AUTO_ATTENDANT_TYPE.Announcements"
            [selected]="selectedAutoAttendantOption.type == AUTO_ATTENDANT_TYPE.Announcements">
            {{AUTO_ATTENDANT_TYPE.Announcements}}</option>
          <option [value]="AUTO_ATTENDANT_TYPE.VoiceMail"
            [selected]="selectedAutoAttendantOption.type == AUTO_ATTENDANT_TYPE.VoiceMail">
            {{AUTO_ATTENDANT_TYPE.VoiceMail}}</option>
          <option [value]="AUTO_ATTENDANT_TYPE.App"
            [selected]="selectedAutoAttendantOption.type == AUTO_ATTENDANT_TYPE.App">
            {{AUTO_ATTENDANT_TYPE.App}}
          </option>
        </select>
      </div>
    </div>
    <div *ngIf="selectedAutoAttendantOption.type != AUTO_ATTENDANT_TYPE.NoConfiguration"
      class="d-flex flex-column flex-grow-1">
      <!--used *ngif to rerender voicemail formcontrol donot MODIFY-->
      <div class=" flex-grow-1" *ngIf="selectedAutoAttendantOption.type === AUTO_ATTENDANT_TYPE.Greeting">
        <form [formGroup]="form" class="h-100">
          <voiply-voicemail-form-control formControlName="callScenarioVoice" insertTemplateVariableFrom="root"
            class="d-flex flex-column h-100" [audioCategory]="selectedAutoAttendantOption.type"
            [recordingData]="selectedAutoAttendantOption.recording">
          </voiply-voicemail-form-control>
        </form>
      </div>
      <div class=" flex-grow-1" *ngIf="selectedAutoAttendantOption.type === AUTO_ATTENDANT_TYPE.Announcements">
        <form [formGroup]="form" class="h-100">
          <voiply-voicemail-form-control formControlName="callScenarioVoice" insertTemplateVariableFrom="root"
            class="d-flex flex-column h-100" [audioCategory]="selectedAutoAttendantOption.type"
            [recordingData]="selectedAutoAttendantOption.recording">
          </voiply-voicemail-form-control>
        </form>
      </div>
      <!--email to-->
      <div *ngIf="selectedAutoAttendantOption.type == AUTO_ATTENDANT_TYPE.VoiceMail"
        class="flex-grow-1 d-flex flex-column">
        <div class="mt-3 form-group">
          <div *ngIf="this.editKey==='+'">
            <label for="exampleInputEmail"
              [ngClass]="{ 'is-invalid': ((formSubmitted && voiceMailEmail.errors)&&this.editKey==='+') }">Send
              Voice mail to</label>
            <div class="position-relative">
              <input type="email" class="form-control" placeholder="Email" email required #voiceMailEmail="ngModel"
                [ngModel]="selectedAutoAttendantOption.voiceMailTo" (focusout)="voiceMailToEmailChanged($event)"
                [ngClass]="{ 'is-invalid': (((formSubmitted) && voiceMailEmail.errors)&&this.editKey==='+') }">
              <div *ngIf="((voiceMailEmail.touched ||formSubmitted) && voiceMailEmail.errors)">
                <span *ngIf="voiceMailEmail.errors.email">
                  <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid Email"> </i></span>
                <span *ngIf="voiceMailEmail.errors.required">
                  <i class="far fa-exclamation-circle is-error" tooltip="Please enter Email"> </i></span>
              </div>
            </div>
          </div>
          <div *ngIf="this.editKey!=='+'">
            <label for="exampleInputEmail">Send
              Voice mail to</label>
            <div class="position-relative">
              <input type="email" class="form-control" placeholder="Email" #voiceMailEmail="ngModel" email
                [ngModel]="selectedAutoAttendantOption.voiceMailTo" (focusout)="voiceMailToEmailChanged($event)">
              <div *ngIf="((voiceMailEmail.touched) && voiceMailEmail.errors)">
                <span *ngIf="voiceMailEmail.errors.email">
                  <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid Email"> </i></span>
              </div>
            </div>
          </div>
        </div>
        <div class=" flex-grow-1" *ngIf="selectedAutoAttendantOption.type === AUTO_ATTENDANT_TYPE.VoiceMail">
          <form [formGroup]="form" class="d-flex flex-column h-100">
            <voiply-voicemail-attachment-form-control formControlName="voicemailAttachment">
            </voiply-voicemail-attachment-form-control>
            <voiply-voicemail-form-control formControlName="callScenarioVoice" insertTemplateVariableFrom="root"
              class="d-flex flex-column h-100" [audioCategory]="selectedAutoAttendantOption.type"
              [recordingData]="selectedAutoAttendantOption.recording">
            </voiply-voicemail-form-control>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="selectedAutoAttendantOption.type==AUTO_ATTENDANT_TYPE.Greeting">
  <div class="mt-4">
    <voiply-timeout-options (timeoutOptionsChange)="timeoutOptionsChange($event)"
      [timeoutOptionHelpText]="callFlowHelpText.timeoutOption"
      [timeoutOptions]="callFlow.autoAttendantOptions[this.editKey].timeoutOptions"></voiply-timeout-options>
  </div>
</div>
<div *ngIf="selectedAutoAttendantOption.type == AUTO_ATTENDANT_TYPE.RingOne">
  <div class="mt-4">
    <voiply-ringone-user [ringOneUser]="callFlow.autoAttendantOptions[this.editKey].ringOneUser" [users]="users"
      [ringOneUserHelpText]="callFlowHelpText.ringOneUser" (userSelectionChange)="toggleUserSelectionRingOne($event)">
    </voiply-ringone-user>
  </div>
</div>
<div *ngIf="selectedAutoAttendantOption.type == AUTO_ATTENDANT_TYPE.RingGroup">

  <div class="mt-4">
    <voiply-ringgroup-user [users]="users" [ringGroupUser]="callFlow.autoAttendantOptions[this.editKey].users"
      [RingGroupHelpText]="callFlowHelpText.ringGroupUser" (userSelectionChange)="toggleUserSelection($event)">
    </voiply-ringgroup-user>
  </div>
  <!-- <div class="mt-4">
    <voiply-sequential-ring [ringType]="selectedAutoAttendantOption.ringType"
      [phoneRingOptionsHelpText]="callFlowHelpText.phoneRingOptions" (ringTypeChange)="changeRingTypeSelection($event)">
    </voiply-sequential-ring>
  </div> -->
  <div class="mt-4">
    <voiply-missedcalls-voicemail [voiceMailUser]="selectedAutoAttendantOption.voiceMailUser"
      [voicemailRingTimeoutCount]="selectedAutoAttendantOption.voicemailRingTimeoutCount" [users]="users"
      [voicemailHelpText]="callFlowHelpText.voicemail" (updateVoiceMailUser)="onVoiceMailUserChanged($event)"
      (updateVoicemailRingTimeoutCount)="onvoicemailRingTimeoutCountChanged($event)"
      *ngIf="selectedAutoAttendantOption.type == AUTO_ATTENDANT_TYPE.RingGroup"></voiply-missedcalls-voicemail>
  </div>

  <!--Ring group component-->

</div>
<div *ngIf="selectedAutoAttendantOption.type == AUTO_ATTENDANT_TYPE.App">
  <div class="mt-4">
    <voiply-app-routing [apps]="apps" [selectedApp]="callFlow.autoAttendantOptions[this.editKey].app"
      (AppSelectionChange)="onAppSelectionChange($event)"></voiply-app-routing>
  </div>
</div>