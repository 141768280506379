<div id="footer" class="card py-5 px-4" style="border-radius: 0% !important; border: 1px solid #ececec;">
    <div class="card-header bg-white border-0 d-flex justify-content-between">
        <h2 class="font-weight-bolder text-capitalize heading-font">
            {{title}}
        </h2>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-md-8 col-12">
                <div class="d-flex h-100">
                    <div class="d-flex flex-column h-100" style="line-height: 1.75rem;">
                        <div class="flex-fill text-justify" [innerHTML]="content">

                        </div>
                        <div class="mb-3 mb-md-0 text-muted row font-weight-bold">
                            <div *ngFor="let feature of features" class="col-md-6"><i
                                    class="fas fa-circle text-secondary fa-xs pr-1"></i>{{feature}}</div>
                            <!-- <div class="col-md-5"><i class="fas fa-circle text-secondary fa-xs pr-1"></i></div> -->
                        </div>
                    </div>
                    <div class="d-none d-lg-block px-5">
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="d-flex flex-column align-items-center rounded-lg">
                    <img *ngIf="imglogo" src='/assets/images/vp-res.png' class="img-fluid px-5 px-md-0">
                    <div class="btn bg-grey text-black d-flex align-self-stretch justify-content-between align-items-center mt-3 cursor-pointer py-2 px-4"
                        (click)="navigatePage.emit()">
                        <div class="font-weight-bold">{{navigateButtonText}}</div>
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 23.333 23.333">
                            <path id="Select_Dropdown" data-name="Select Dropdown"
                                d="M11.257.345c.8-.8,2.342-.129,2.583,1.113L16.47,15A1.206,1.206,0,0,1,15,16.47L1.458,13.84C.216,13.6-.45,12.053.345,11.257Z"
                                transform="translate(0 11.666) rotate(-45)" />
                        </svg>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>