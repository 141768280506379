<div class=" my-3 card py-5 px-lg-4" style="border-radius: 0% !important; border: 1px solid #ececec;" *ngIf="testimonials" id="testimonials">
    <div class="card-header bg-white border-0">
        <div style="position: relative;">
            <i class="d-none d-lg-block fal fa-long-arrow-right section-arrow d-none d-lg-block" style="position: absolute; left: -60px; top: -10px; font-size: 60px; color:#D3D4D5"></i>
        </div>
        <div class="h2 font-weight-bolder text-capitalize heading-font">
            Checkout our customers reviews
        </div>
    </div>
    <div class="card-body">
        <div style="position: relative; margin-left:20px; margin-right:20px;" *ngIf="testimonials">
            <ngx-siema [options]="options">
                <ngx-siema-slide *ngFor="let item of testimonials">
                    <div class="card-group">
                        <div class="card w-100 m-3 border">
                            <div class="d-flex flex-row justify-content-between align-items-center p-3 ">
                                <h4 class="font-weight-bold  m-0">
                                    {{ item.name }}
                                </h4>
                                <p class="text-muted m-0">{{ item.location }}</p>
                            </div>
                            <div class="card-body overflow-auto" style="height: 9rem;">
                                <p class="card-text flex-fill">{{ item.review }}</p>
                            </div>
                            <div class="card-footer d-flex justify-content-between  border-0 bg-white">
                                <p class="m-0 text-muted">{{ item.ts | epochToDate | timeAgo }}</p>
                                <div class="d-flex flex-row">
                                    <div *ngFor="let rate of [1, 2, 3, 4, 5]">
                                        <i *ngIf="rate <= item.stars" class="fas fa-star mr-1" style="color: #F8C600;"></i>
                                        <i *ngIf="rate > item.stars" class="fas fa-star mr-1" style="color: #E8E8E8;"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-siema-slide>
            </ngx-siema>

            <a (click)="next()" (mouseenter)="next()" class="carouselButtonsRight d-flex align-items-center justify-content-end px-2">
                <span class="text-secondary"><i class="fas fa-caret-right fa-2x"></i></span>
            </a>
            <a (click)="prev()" (mouseenter)="prev()" class="carouselButtonsLeft d-flex align-items-center justify-content-start px-2"><span class="text-secondary"
          ><i class="fas fa-caret-left fa-2x"></i></span
      ></a>
        </div>
    </div>
</div>
