<div class="d-flex flex-row align-items-center justify-content-between py-2 cursor-pointer" (click)="toggleFaqShow()">
  <div>
    <div class="d-flex flex-row align-items-center">
      <i class="fas fa-circle pr-3" style="color:#FBA08C; font-size: 12px;"></i>
      <h6 class="m-0 " [ngClass]="{'text-secondary':faqShow==='out','text-color':faqShow==='in'}">{{ faq.title }}</h6>
    </div>
  </div>
  <div>
    <i class="fas fa-chevron-right " style="color:#FBA08C;" [@childAnimation1]="faqShow"> </i>
  </div>
</div>
<div [@slideInOut]="faqShow">
  <p class="mb-0" style="padding-left: 28px;">{{ faq.content }}</p>
</div>