import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Users, CallFlow, CallFlowsHelpText, AutoAttendantType, App, DayOfWeek } from '@voiply/shared/model';
import * as _ from 'lodash';

@Component({
  selector: 'voiply-auto-attendant-master',
  templateUrl: './auto-attendant-master.component.html',
  styleUrls: ['./auto-attendant-master.component.scss']
})
export class AutoAttendantMasterComponent implements OnInit, OnChanges {

  @Input() users: Users[];
  @Input() callFlow: CallFlow;
  @Input() callFlowHelpText: CallFlowsHelpText;
  @Input() selectedNumber = '';
  @Input() apps: App[] = [];
  @Output() configurationChanged = new EventEmitter<any>();
  AUTO_ATTENDANT_TYPE: typeof AutoAttendantType = AutoAttendantType;

  editKey = '-';
  _rerenderAutoAttendant = true;
  public selectedDay = 1;
  public selectedFromTime = '09:00:00';
  public selectedToTime = '17:00:00';
  DAY_OF_WEEK: typeof DayOfWeek = DayOfWeek;
  isEmpty = true;
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedNumber) {
      this.editKey = "-"
      //on Selected CallFlow Change rerender the AutoAttendant component
      setTimeout(() => this._rerenderAutoAttendant = false);
      setTimeout(() => this._rerenderAutoAttendant = true);
    }
  }

  ngOnInit() {

  }

  toggleEdit(key: string) {
    this.editKey = key;
    //This is done to force rerender the AutoAttendant component on edit key changed
    setTimeout(() => this._rerenderAutoAttendant = false);
    setTimeout(() => this._rerenderAutoAttendant = true);
  }


  updateConfiguration(callFlow: CallFlow) {
    this.configurationChanged.emit(callFlow);
  }

  updateBusinessHours(changes) {
    this.callFlow.autoAttendantRingCallFlow = { ...changes };
    this.updateConfiguration(this.callFlow)
  }

  onOpen24_7Change(value) {
    this.callFlow.autoAttendantRingCallFlow.opens24_7 = value;
    this.updateConfiguration(this.callFlow);
  }

  toggleTimeZone(timeZone: string) {
    this.callFlow.autoAttendantRingCallFlow.timeZone = timeZone;
    this.updateConfiguration(this.callFlow);
  }

  isEmptyObject(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop) && obj[prop] != null) {
        return false;
      }
    }
    return true;
  }
  addBusinessHour() {
    this.isEmpty = false;
    let changes = this.callFlow.autoAttendantRingCallFlow;
    if (!changes.businessHours)
      changes.businessHours = {};
    changes.opens24_7 = false;
    changes.businessHours[this.selectedDay] = { fromHour: this.selectedFromTime, toHour: this.selectedToTime };
    this.callFlow.autoAttendantRingCallFlow = changes;
    this.updateConfiguration(this.callFlow);
    //}
    this.selectedDay = this.selectedDay + 1;
    if (this.selectedDay > 6)
      this.selectedDay = 0;

  }

  deleteBusinessHour(day) {
    const changes = this.callFlow.autoAttendantRingCallFlow
    const businessHours = changes.businessHours;
    businessHours[day] = null;
    this.isEmpty = true;
    _.each(changes.businessHours, (value) => {
      if (value) {
        this.isEmpty = false;
        return false
      }
    });
    if (this.isEmpty)
      changes.opens24_7 = true;
    this.callFlow.autoAttendantRingCallFlow = changes;
    this.updateConfiguration(this.callFlow);
  }

  timeoutOptionsChange(timeoutOptions) {
    this.callFlow.autoAttendantRingCallFlow.timeoutOptions = timeoutOptions;
    this.updateConfiguration(this.callFlow);
  }

  onOutsideBusinessHoursChanged(outsideBusinessHours) {
    this.callFlow.autoAttendantRingCallFlow.outsideBusinessHours = outsideBusinessHours;
    this.updateConfiguration(this.callFlow);
  }
}
