<!-- <div class=" mt-3  mb-3" style="width: 100%;"> -->
<!-- <voiply-footer-top class="mt-3 "title="Residential Phone Service?"
        content="Making the transition from a traditional landline to VoIP for your home phone is easier than ever. 65% of adults use broadband internet at home. Even compared with bundled packages, cable and phone companies can’t compete with the features and savings you’ll see by switching to Voiply" buttonText="Switch To Residential" (navigatePage)="navigatePage()" logo="business-logo.png"
        >
        
        
    </voiply-footer-top>
    <!-- <voiply-footer  buttonText="Switch To Residential" (navigatePage)="navigatePage()" logo="business-logo.png">
    </voiply-footer> -->
<!-- <voiply-footer class="mt-2" buttonText="Switch To Residential" (navigatePage)="navigatePage()" logo="business-logo.png">
    </voiply-footer> -->
<!-- </div> -->

<div class="container bg-light-grey py-3">
    <voiply-footer-top-new [imglogo]="imglogo" [content]="content" title="Residential Phone Service?"
        [features]="features" navigateButtonText="Switch To Residential" (navigatePage)="navigatePage()">
    </voiply-footer-top-new>
</div>
<voiply-footer-new buttonText="Go to Voiply Business" logo="business-logo.png">
</voiply-footer-new>