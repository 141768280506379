<div class="container">
  <div class="d-flex flex-column justify-content-center">
    
    <h3 class="mb-0">
      Add amazing Voiply Features to your Business system?
    </h3>
    <div class="row pt-3 d-flex flex-wrap justify-content-center">
      <!-- <div class="col-sm-8 col-md-4 order-1 order-md-1 mt-2" >
                <img src='../../../../../../assets/images/mic_tmp.png' style="width: 100%;">       
            </div>
            <div class="col-sm-8 col-md-4 order-3 order-md-2 mt-2">
                <img src='../../../../../../assets/images/mic_tmp.png' style="width: 100%;">        
            </div>
            <div class="col-sm-8 col-md-4 order-5 order-md-3 mt-2">
                <img src='../../../../../../assets/images/mic_tmp.png' style="width: 100%;">        
            </div> -->
    </div>

  </div>
</div>

<div class="row pt-3 d-flex flex-wrap justify-content-center" *ngIf="apps">
  <div class="col-sm-8 col-md-4 pt-3 order-2 order-md-4" *ngFor="let app of apps; index as i;">
    <ng-container *ngIf="i<showapps && (appAvailableInCountry(app))">
      <!-- <div class="col-sm-8 col-md-4 pt-3 order-2 order-md-4"> -->
      <div class="d-flex flex-column"
        style="background-color: #F9F9F9;border-radius:10px;border-width:1px;border-color:#F73A0A;height:258px;"
        [ngStyle]="{'border-style': selectedAppsInCart[app.appId-1].isSelected ? 'solid' : 'none' }">
        <div class="d-flex flex-row justify-content-between p-2">
          <div class="d-flex flex-row">
            <span style="color: #F73A0A;" *ngIf='app.price == 0 && app.monthlyCharge==0'>FREE <s
                style="font-size: 15px; color:#CECECE;font-weight:bold;">$5/mo</s> </span>
            <span style="font-size: medium;" *ngIf='app.monthlyCharge!==0'>${{app.monthlyCharge}}/mo</span>
          </div>

          <div class="d-flex flex-row">
            <label class="switch">
              <input type="checkbox" [checked]="selectedAppsInCart[app.appId-1].isSelected"
                (click)="clickedToggle(i,app)">
              <span class="slider round"> </span>
            </label>
          </div>
        </div>

        <div class="align-self-center p-2">
          <img [src]="app.image" class="mb-2 p-2">
        </div>

        <div class="p-4 align-self-center">
          <span style="color:#F73A0A; font-size:large;font-weight:bold;">{{app.title.toUpperCase()}}</span>
        </div>
      </div>
      <!-- </div> -->
      <!-- <div class="card mt-4">
          <div style="position: absolute; right:8px; top:8px;">
            <button type="button" class="btn rounded-0 shadow-1 bg-white text-secondary" (click)="onAddToCart(app)"><i
                class="fas fa-plus"></i></button>
          </div>
          <div
            class="d-flex flex-column justify-content-between align-items-center px-4 pt-2 pb-4 bg-primary-gradient cursor-pointer"
            (click)="onOpenModalDialog(i) ">
            <img [src]="app.image" style="height: 90px; width: 90px;" class="app-image-color">
            <h6 class="text-primary m-0 font-weight-bold">{{app.title.toUpperCase()}}</h6>
          </div>
        </div>
  
        <div class="bg-black py-1 border"></div> -->
    </ng-container>
  </div>
</div>




<div class="modal fade" bsModal #moreAppsModal="bs-modal" tabindex="-1" role="dialog"
  aria-labelledby="dialog-static-name">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex flex-row align-items-center">
          <h4 class="flex-grow-1" class="modal-title text-primary pl-3">Apps</h4>
        </div>
        <button type="button" class="close pull-right" aria-label="Close" (click)="moreAppsModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-4 col-sm-6 col-12" *ngFor="let app of apps; index as i;">
            <div class="card ">
              <div style="position: absolute; right:8px; top:8px;">
                <button type="button" class="btn rounded-0 shadow-1 bg-white text-secondary"
                  (click)="onAddToCart(app)"><i class="fas fa-plus"></i></button>
              </div>
              <div
                class="d-flex flex-column justify-content-between align-items-center px-4 pt-2 pb-4 bg-primary-gradient">
                <img [src]="app.image" style="height: 70px;" class="mb-2" class="app-image-color">
                <h6 class="text-primary m-0 font-weight-bold">{{app.title.toUpperCase()}}</h6>
              </div>
            </div>

            <div class="bg-black py-1 border"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>