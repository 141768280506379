import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'voiply-apply-changes',
  templateUrl: './apply-changes.component.html',
  styleUrls: ['./apply-changes.component.css']
})
export class ApplyChangesComponent implements OnInit {

  @Input() updatingOrder: boolean;
  @Input() coolDownPeriodOn: boolean;
  @Output() updateOrder = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  updateOrderDetails() {
    this.updateOrder.emit();
  }
}
