import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

isClickedWizardSaveBtn: BehaviorSubject<boolean>=new BehaviorSubject(false);
  
constructor() { }

onWizardSaveClick(parameter:boolean){
    this.isClickedWizardSaveBtn.next(parameter);
}


}
