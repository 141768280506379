import { AutoAttendantType } from './enums';
import { AudioRecording } from './audio-recording.model';

export class AutoAttendantOption {
    type: AutoAttendantType = AutoAttendantType.NoConfiguration;
    users: any = {};
    recording: AudioRecording = new AudioRecording();
    voiceMailTo = '';

    ringType = '';
    voicemailRingTimeoutCount = 4;
    voiceMailUser = '';
    ringOneUser = ''
    constructor(type?: AutoAttendantType) {
        if (type)
            this.type = type;
    }
}