import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, AfterViewChecked, OnChanges, OnDestroy } from '@angular/core';
import { CallFlow, CallFlowsHelpText } from '@voiply/shared/model';
import { HumanAttendantConfigService } from '@voiply/shared/common-ui';
@Component({
  selector: 'voiply-human-attendant',
  templateUrl: './human-attendant.component.html',
  styleUrls: ['./human-attendant.component.scss']
})
export class HumanAttendantComponent implements OnInit {

  @Input() businessCategoryHelpText = '';
  @Input() callFlow: CallFlow;
  @Input() callFlowHelpText: CallFlowsHelpText;
  @Output() configurationChanged = new EventEmitter<any>();
  businessCategories = [];
  userTips = ['Hi this is a dummy Tip 1', "It's easy to edit answer and questions, all you have to doit mouse over anything."];
  userTipPageNumber = 1;
  selectedCateogry = 'QA'
  constructor(private humanAttendantConfigService: HumanAttendantConfigService) { }

  ngOnInit() {

    this.humanAttendantConfigService.getBusinessCategories().then((businessCategories) => {
      // Default business category is selected if no businessCategory is found to load the default human attendant data
      this.businessCategories = businessCategories || [];
      if (!this.callFlow.humanAttendant.businessCategory)
        this.toggleBusinessCategory(this.businessCategories[0].title)
    })
  }

  updateConfiguration() {
    this.configurationChanged.emit(this.callFlow);
  }

  toggleBusinessCategory(value) {
    this.callFlow.humanAttendant.businessCategory = value;
    this.callFlow.humanAttendant.questionsAnswers = this.businessCategories.filter((businessCategory) => businessCategory.title === value)[0].config
    this.updateConfiguration();
  }

  changeBudget(value) {
    this.callFlow.humanAttendant.budgetPerWeek = value;
    this.updateConfiguration();
  }

  changeMaxPricePerCall(value) {
    this.callFlow.humanAttendant.maxPricePerCall = parseFloat((value / 100).toFixed(2));
    this.updateConfiguration();
  }

  onUpdateSelectedCategory(selectedCategory) {
    this.callFlow.humanAttendant.selectedCategory = selectedCategory;
    this.updateConfiguration();
  }

  onQuestionsAnswersChanged(questionsAnswers) {
    this.callFlow.humanAttendant.questionsAnswers = questionsAnswers;
    this.updateConfiguration();
  }

  onUserTipsChange(number, lastPage) {
    this.userTipPageNumber = number;
    if (number > lastPage) {
      this.userTipPageNumber = 1
    }
  }

}
