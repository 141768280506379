import { Component, OnInit, Input, Directive } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ShippingAddressChangeAction, OrderService, AppStateSelectors, OnDestroyCleanup, SetPrimaryPhoneNumber, UpdateAdditionalNumbersAction, CompanyNumberNameChangeModalComponent, UpdateCompanyNumberAction, RemoveCompanyNumberAction, EmergencyAddressChangeAction, AddMorePhonesAction, CalculatingTotalAction, AddCompanyNumberAction } from '@voiply/shared/common-ui';
import { Address, PrimaryPhone, CompanyNumber, EmergencyAddress, CheckoutDetail, CallFlow, Users, CartItemType } from '@voiply/shared/model';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal/';
import { takeWhile } from 'rxjs/operators';
import { ValidationService } from 'libs/shared/common-ui/src/lib/services';
import * as _ from 'lodash';

@Directive()
export class PrimaryNumberAfterCheckoutBase extends OnDestroyCleanup implements OnInit {
    orderId: string;
    primaryPhone: any;
    number: string;
    currentEmergencyAddress = new EmergencyAddress();
    additionalNumbers: any;
    companyNumbers: CompanyNumber[] = [];
    modalRef: BsModalRef;
    userIsVoiplyMember = false
    updatingOrder: boolean;
    coolDownPeriodOn = false;
    showE911 = true;
    bsModalRef: BsModalRef;
    canshowCompanyNumber = false;

    @Select(AppStateSelectors.orderId) orderId$: Observable<string>;
    @Select(AppStateSelectors.userIsVoiplyMember) userIsVoiplyMember$: Observable<boolean>;
    @Select(AppStateSelectors.primaryPhone) primaryPhone$: Observable<string>;
    @Select(AppStateSelectors.isOrderDisabled) isOrderDisabled$: Observable<boolean>;
    @Select(AppStateSelectors.shippingAddress) shippingAddress$: Observable<any>;
    @Select(AppStateSelectors.checkoutDetails) checkoutDetail$: Observable<any>;
    @Select(AppStateSelectors.additionalNumbers) additionalNumbers$: Observable<any>;
    @Select(AppStateSelectors.companyNumbers) companyNumbers$: Observable<any>;
    @Select(AppStateSelectors.updatingPhoneSystem) updatingPhoneSystem$: Observable<boolean>
    @Select(AppStateSelectors.cartItems) cartItems$: Observable<any>;



    constructor(private orderService: OrderService, private toastr: ToastrService, public modalService: BsModalService, private validateService: ValidationService) {
        super();
        this.subscriptions$.add(this.companyNumbers$.subscribe((companyNumbers) => this.companyNumbers = companyNumbers))

        this.subscriptions$.add(this.checkoutDetail$.subscribe((checkoutDetail) => {
            if (checkoutDetail.shippingAddress.country !== 'US' && checkoutDetail.shippingAddress.country !== 'CA') {
                this.showE911 = false;
            }
            else if (checkoutDetail.emergencyAddress.name) {
                this.showE911 = true
                this.currentEmergencyAddress = checkoutDetail.emergencyAddress;
            }
            else {
                //subscribe to Shipping Address only when emergency Address is not available.
                this.subscriptions$.add(this.shippingAddress$.pipe(takeWhile(data => !checkoutDetail.emergencyAddress.name)).subscribe(async shippingAddress => {
                    if (shippingAddress) {
                        this.currentEmergencyAddress.name = shippingAddress.name;
                        this.currentEmergencyAddress.addressLine1 = shippingAddress.address;
                        this.currentEmergencyAddress.addressLine2 = "";
                        this.currentEmergencyAddress.state = shippingAddress.state;
                        this.currentEmergencyAddress.country = shippingAddress.country;
                        this.currentEmergencyAddress.city = shippingAddress.city;
                        this.currentEmergencyAddress.zip = shippingAddress.zip
                        this.emergencyAddressChange(this.currentEmergencyAddress);
                        // try {
                        //     await this.validateService.validateEmergencyAddress(this.currentEmergencyAddress);
                        // }
                        // catch (err) {
                        //     if (err.error.error.length) {
                        //         setTimeout(() => {
                        //             this.toastr.error('Please Update your E911 Address');
                        //         }, 2000);
                        //     }
                        // }
                    }
                }))
            }


        }));
        this.subscriptions$.add(this.orderId$.subscribe((orderId) => this.orderId = orderId));
        this.subscriptions$.add(this.updatingPhoneSystem$.subscribe((updatingOrder) => {
            this.updatingOrder = updatingOrder;
            if (updatingOrder)
                this.coolDownPeriodOn = true
            else {
                setTimeout(() => {
                    this.coolDownPeriodOn = false;
                }, 60 * 1000);
            }
        }));
        this.subscriptions$.add(this.primaryPhone$.subscribe((phoneNumber) => {

            this.primaryPhone = phoneNumber;
            this.number = this.primaryPhone.number;
        }));
        this.subscriptions$.add(this.userIsVoiplyMember$.subscribe((userIsVoiplyMember) => { this.userIsVoiplyMember = userIsVoiplyMember }))
        this.subscriptions$.add(this.additionalNumbers$.subscribe((additionalNumbers) => this.additionalNumbers = additionalNumbers));
        this.subscriptions$.add(this.cartItems$.subscribe(cartItems => {
            if (!(_.size(cartItems) > 0)) {
                this.canshowCompanyNumber = true;
                return;
            }
            _.each(cartItems, (cartItem) => {
                if (cartItem.type === CartItemType.Phone && cartItem.paid) {
                    this.canshowCompanyNumber = true;
                    //This return is to exit loop
                    return false;
                } else {
                    this.canshowCompanyNumber = false;
                }
            });
        }));
    }
    @Dispatch() onShippingAddressChange = (shippingAddress) => new ShippingAddressChangeAction(shippingAddress);
    @Dispatch() emergencyAddressChange = (emergencyAddress) => new EmergencyAddressChangeAction(emergencyAddress);
    @Dispatch() setPrimaryPhoneNumber = (phoneNumber) => new SetPrimaryPhoneNumber(phoneNumber);
    @Dispatch() updateAdditionalNumbers = (additionalNumbers) => new UpdateAdditionalNumbersAction(additionalNumbers);
    @Dispatch() updateCompanyNumber = (phoneNumber: CompanyNumber) => new UpdateCompanyNumberAction(phoneNumber);
    @Dispatch() removeCompanyNumber = (phoneNumber: CompanyNumber) => new RemoveCompanyNumberAction(phoneNumber);
    @Dispatch() addCompanyNumber = (phoneNumber: CompanyNumber) => new AddCompanyNumberAction(phoneNumber);

    ngOnInit() {

    }

    onE911AddressChanged(address: Address) {
        this.emergencyAddressChange(address);
    }

    onUpdateAdditionalNumbers(numbers: any) {
        this.updateAdditionalNumbers(numbers);
    }

    onAddtoCompanyNumber(numbers: any) {
        _.each(numbers, (value, key) => {
            if (key.length >= 10)
                this.addCompanyNumber({ number: key, isNew: false, systemSelected: false, isPrimaryNumber: false, name: 'Phone Number', callFlow: new CallFlow, isSelectedCallFlow: false })
        })
    }

    async updateOrderDetails() {
        if (!this.coolDownPeriodOn) {
            this.coolDownPeriodOn = true;
            this.updatingOrder = true
            await this.orderService.updateOrder(this.orderId, 'fast');
            this.updatingOrder = false
            setTimeout(() => {
                this.coolDownPeriodOn = false;
            }, 60 * 1000);
        }
    }

    updatePrimaryNumber() {
        this.setPrimaryPhoneNumber({ number: this.number, isNew: this.primaryPhone.isNew, systemSelected: this.primaryPhone.systemSelected });
    }
    onUpdatePrimaryNumberStatus(phoneNumber: CompanyNumber) {
        if (this.userIsVoiplyMember) {
            phoneNumber.isPrimaryNumber = true
            this.setPrimaryPhoneNumber({ number: phoneNumber.number, isNew: phoneNumber.isNew, systemSelected: phoneNumber.systemSelected });
            this.updateCompanyNumber(phoneNumber)
        }
        else
            this.toastr.error('You cannot Update your Primary Number. Please Contact Support')
    }
    onRemoveCompanyNumber(phoneNumber: CompanyNumber) {
        if (this.userIsVoiplyMember) {
            this.removeCompanyNumber(phoneNumber);
        }
        else
            this.toastr.error('You cannot Remove Number. Please Contact Support')
    }
    onUpdatePrimaryNumberName(phoneNumber: CompanyNumber) {
        const initialState = {
            companyNumber: phoneNumber
        };
        this.modalRef = this.modalService.show(CompanyNumberNameChangeModalComponent, { initialState, backdrop: 'static' });
        this.modalRef.content.nameChange.subscribe(
            result => {
                phoneNumber.name = result
                this.updateCompanyNumber(phoneNumber)
            }
        )
    }
    onUpdateSelectedCallFlowStatus(phoneNumber: CompanyNumber) {
        phoneNumber.isSelectedCallFlow = true;
        this.updateCompanyNumber(phoneNumber);
    }


}
