import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ControlValueAccessorAndValidator } from '@voiply/shared/common-ui';
import { WizardService } from 'libs/shared/common-ui/src/lib/services';


@Component({
  selector: 'voiply-select-phone-qty',
  templateUrl: './select-phone-qty.component.html',
  styleUrls: ['./select-phone-qty.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectPhoneQtyComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectPhoneQtyComponent),
      multi: true
    }
  ]
})
export class SelectPhoneQtyComponent extends ControlValueAccessorAndValidator implements OnInit, AfterViewInit {

  @ViewChild('range') range: ElementRef;
  @ViewChild('bubble') bubble: ElementRef;
  @ViewChild('dummy') dummy: ElementRef;

  constructor(public wizardService: WizardService) {
    super();
    this.form = new FormGroup({
      surveyEmail: new FormControl(null, { validators: [RxwebValidators.required(), RxwebValidators.email()], updateOn: 'blur' }),
      surveyZip: new FormControl('', { updateOn: 'blur' }),
    });
    this.form.valueChanges.subscribe(change => {
      this.wizardService.survey.email = change.surveyEmail;
      this.wizardService.survey.zipcode = change.surveyZip;
      this.wizardService.survey.responseSubmitted = (new Date()).toString()
    })
  }


  onPercentChange(percent: number) {
    this.bubble.nativeElement.innerHTML = percent;
    if (percent == 0) {
      this.bubble.nativeElement.style.left = 0;
      this.dummy.nativeElement.style.left = 0;
    }
    else {
      this.bubble.nativeElement.style.left = `${((100 * percent) / 15) - 2}%`;
      this.dummy.nativeElement.style.left = `${((100 * percent) / 15) - 2}%`;
    }

    //update number of employees
    this.wizardService.survey.numberOfEmployees = percent.toString();
  }

  ngOnInit(): void {

    this.form.controls['surveyEmail'].patchValue(this.wizardService.survey.email);
    this.form.controls['surveyZip'].patchValue(this.wizardService.survey.zipcode);

  }
  ngAfterViewInit() {
    this.onPercentChange(+this.wizardService.survey.numberOfEmployees);
  }



}


