<div class="bsModal">
  <div class="modal-header">
    <div class="d-flex flex-row align-items-center">
      <img style="max-width: 45px; max-height: 45px;" [src]="app.image" class="app-image-color">
      <h4 class="flex-grow-1" class="modal-title text-primary pl-3">{{app.title}}</h4>
    </div>
  </div>
  <div class="modal-body">
    <div>{{app.description}}</div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark px-4 py-2" data-dismiss="modal"
      (click)="this.bsModalRef.hide()">Cancel</button>
    <button type="button" class="btn bg-primary text-primary px-4 py-2" (click)="this.addToCart.emit(app);
        this.bsModalRef.hide();">Add
      to Cart
    </button>
  </div>
</div>