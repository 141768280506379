import { Component } from '@angular/core';
import { HeaderBase } from '../header.base';
import { AppStateSelectors, HeadingService } from '@voiply/shared/common-ui';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { Address } from 'cluster';
import { Select } from '@ngxs/store';

@Component({
  selector: 'voiply-header-business',
  templateUrl: './header-business.component.html',
  styleUrls: ['./../header.base.css']
})
export class HeaderBusinessComponent extends HeaderBase {

  chips = {
    payAnnually: false,
    promoCode: '',
    freeShipping: false,
  };

  title = "Work Anywhere with our Wi-Fi Voice and Video Solutions";
  constructor(private headingService: HeadingService) {
    super();
    if (this.headingService.reviews.length)
      this.title = _.filter(this.headingService.reviews, (review) => review.type === 'business')[0].title;

    this.subscriptions$.add(this.checkOutDetails$.subscribe((details) => {
      this.chips.payAnnually = details.payAnnually;
      this.chips.promoCode = details.promoCode;
    }));
  }
}

