<div class="card">
  <div class="card-body">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <h5 class="text-secondary font-weight-bold m-0"><i class="fas fa-user pr-3"></i> SELECT USERS TO RING
      </h5>
      <ng-template #popTemplate>{{RingGroupHelpText}}</ng-template>
      <i class="fas fa-question-circle fa-lg" [popover]="popTemplate" placement="auto" containerClass="popover-css"></i>
    </div>
    <div class="border-bottom my-3"></div>
    <div class="row">
      <div *ngFor="let user of users;let i = index" class="col-sm-6">
        <div class="form-check mt-2" style="padding-left: 0px">
          <label class="checkbox-container">
            <input class="form-check-input mt-1" id="user" tabindex="1" name="user" type="checkbox"
              [checked]="isUserSelected(user.id, i)" (click)="toggleUserSelection(user.id)"
              [disabled]="(users.length == 1 && isUserSelected(user.id, i))">
            <i class="fas fa-user mr-2 ml-2" style="color: #fb522c"></i>
            {{user.firstName}} {{user.lastName}}
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>