<!-- <div class="detail-form mb-3">

  <voiply-business-hours [callFlow]="callFlow" (configurationChanged)="updateBusinessHours($event)">
  </voiply-business-hours>

  <div class="border-bottom mt-3"></div>

  <div class="mt-2">
    <div class="btn-group btn-group-toggle" data-toggle="buttons">
      <label
        [ngClass]="{'btn btn-secondary btn-shadow-right': true, 'active': callFlow.ringCallFlow.ring.type == RING_TYPE.Simultaneous}"
        (click)="changeRingTypeSelection(RING_TYPE.Simultaneous)">
        <i *ngIf="callFlow.ringCallFlow.ring.type == RING_TYPE.Simultaneous" class="fas fa-check"></i>
        <input type="radio" name="ringOption" autocomplete="off"
          [checked]="callFlow.ringCallFlow.ring.type == RING_TYPE.Simultaneous">
        Simultaneous Ring
      </label>
      <label
        [ngClass]="{'btn btn-secondary btn-shadow-left': true, 'active': callFlow.ringCallFlow.ring.type == RING_TYPE.Sequential}"
        (click)="changeRingTypeSelection(RING_TYPE.Sequential)">
        <i *ngIf="callFlow.ringCallFlow.ring.type == RING_TYPE.Sequential" class="fas fa-check"></i>
        <input type="radio" name="ringOption" autocomplete="off"
          [checked]="callFlow.ringCallFlow.ring.type == RING_TYPE.Sequential">
        Sequential Ring
      </label>
    </div>

    <button type="button" class="ml-3 btn btn-light rounded-circle shadow-1" [popover]="popTemplate" placement="bottom"
      triggers="mouseenter:mouseleave">
      <i class="fas fa-info p-1"></i>
    </button>
    <ng-template #popTemplate>
      <ul class="list-group list-group-flush">
        <li class="list-group-item"><span class="font-italic">
            Simultaneous Ring</span> - Simultaneous ring rings several different phones at once. As soon as the call is
          answered, the ringing stops.</li>
        <li class="list-group-item"><span class="font-italic">
            Sequential Ring</span> - When sequential ring strategy selected, an inbound call will ring one phone, then
          another, and another, until the call is answered.</li>
      </ul>
    </ng-template>

    <div class="row">
      <div *ngFor="let user of users;let i = index" class="col-sm-6">
        <div class="form-check mt-2">

          <label class="checkbox-container">
            <input class="form-check-input mt-1" id="rememberMe1" tabindex="1" name="remeberMe" type="checkbox"
              [checked]="isUserSelected(user.id)" (click)="toggleUserSelection(user.id)" [disabled]="users.length == 1">
            <i class="fas fa-user mr-2 ml-2" style="color: #fb522c"></i>
            {{user.extension}} - {{user.firstName}} {{user.lastName}}
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div *ngIf="users.length == 0" class="col-12">
        No user available. Please add phone to cart to configure user details.
      </div>
    </div>
  </div>
  <div class="border-bottom mt-3"></div>
  <voiply-missedcalls-voicemail [voiceMailUser]="callFlow.ringCallFlow.voiceMailUser"
    [voicemailRingTimeoutCount]="callFlow.ringCallFlow.voicemailRingTimeoutCount" [users]="users"
    (updateVoiceMailUser)="onVoiceMailUserChanged($event)"
    (updateVoicemailRingTimeoutCount)="onvoicemailRingTimeoutCountChanged($event)"></voiply-missedcalls-voicemail> -->


<div class="row">
  <div class="col-12 col-md-6" style="padding-right:12px; padding-left:12px;">
    <voiply-business-hours [callFlow]="callFlow" (configurationChanged)="updateBusinessHours($event)"
      [businessHoursHelpText]="callFlowHelpText.businessHours">
    </voiply-business-hours>
  </div>
  <div class="col-12 col-md-6 pt-3 pt-md-0" style="padding-left:12px; padding-right:12px;">
    <div>
      <voiply-timezone [timeZone]="this.callFlow.ringCallFlow.timeZone"
        [timeZoneHelpText]="this.callFlowHelpText.timeZone" (timeZoneChange)=onTimeZoneChange($event)>
      </voiply-timezone>
    </div>

    <div class="mt-4">
      <voiply-ringgroup-user [users]="users" [ringGroupUser]="this.callFlow.ringCallFlow.ring.users"
        [RingGroupHelpText]="callFlowHelpText.ringGroupUser" (userSelectionChange)="toggleUserSelection($event)">
      </voiply-ringgroup-user>
    </div>
    <div class="mt-4">
      <voiply-missedcalls-voicemail [voiceMailUser]="callFlow.ringCallFlow.voiceMailUser"
        [voicemailRingTimeoutCount]="callFlow.ringCallFlow.voicemailRingTimeoutCount" [users]="users"
        [voicemailHelpText]="callFlowHelpText.voicemail" (updateVoiceMailUser)="onVoiceMailUserChanged($event)"
        (updateVoicemailRingTimeoutCount)="onvoicemailRingTimeoutCountChanged($event)"></voiply-missedcalls-voicemail>

    </div>
    <!-- <div class="mt-4">
      <voiply-sequential-ring [ringType]="callFlow.ringCallFlow.ring.type"
        [phoneRingOptionsHelpText]="callFlowHelpText.phoneRingOptions"
        (ringTypeChange)="changeRingTypeSelection($event)">
      </voiply-sequential-ring>
    </div> -->
  </div>
</div>