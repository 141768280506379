<!--containing in - Lib: product-configuration -->
<!--used by - Container: product-configuration-home, product-configuration-business, product-configuration-business-after-checkout -->
<!--Added for - Home, Business -->
<div class="d-flex flex-row align-items-center" *ngIf="phoneStatus.status !== 'Registered'">
  <div class=" d-flex flex-row align-items-center px-2 bg-primary text-primary" style="margin-right:1px;">
    <i class="fas fa-circle fa-xs pr-1" style="font-size: 6px;"></i>
    <div style="font-size: 12px;">
      OFFLINE
    </div>
  </div>
  <div class="bg-primary-gradient d-flex flex-row align-items-center px-2" style="height: 18px;"
    [popover]="'IP: ' + (phoneStatus.ip || 'N/A')" placement="auto" containerClass="popover-css" [outsideClick]="true">
    <i class="fas fa-question-circle fa-xs  text-primary "></i>
  </div>
</div>
<div class="d-flex flex-row align-items-center" *ngIf="phoneStatus.status == 'Registered'">
  <div class="d-flex flex-row align-items-center px-2 bg-success-light text-primary" style="margin-right:1px;">
    <i class="fas fa-circle fa-xs pr-1" style="font-size: 6px;"></i>
    <div style="font-size: 12px;">
      ONLINE
    </div>
  </div>
  <div class="bg-success d-flex flex-row align-items-center px-2" style="height: 18px;"
    [popover]="'IP: ' + (phoneStatus.ip || 'N/A')" placement="auto" containerClass="popover-css" [outsideClick]="true">
    <i class="fas fa-question-circle fa-xs  text-primary "></i>
  </div>
</div>
