<div style="position: relative;">
  <i *ngIf="_productConfigurationLength>0"
    class="d-none d-lg-block fal fa-long-arrow-right section-arrow d-none d-lg-block"
    style="position: absolute; left: -18px; top: 21px; font-size: 60px; color:#D3D4D5;z-index:1;"></i>
</div>
<div class=" p-3 card mt-3" *ngIf="_productConfigurationLength>0">
  <div class="card-header bg-white text-black" style="padding: 10px 20px!important; border:0px;">
    <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
      <div class="pb-2 pb-md-0">
        <span style="font-size:18px;">
          <h2 class="font-weight-bold">Manage Your Phones <span class=""
              style="font-size:medium;">({{_productConfigurationLength}} Total
              Products)</span></h2>
        </span>
      </div>
      <div class="d-flex flex-row mb-1 flex-fill flex-md-grow-0">
        <input type="text" class="inputClass align-self-center p-2 flex-fill" placeholder="    Search"
          [(ngModel)]="searchTerm">
        <i class="fa fa-search setMg" aria-hidden="true"></i>
      </div>
      <!-- <div class="d-flex flex-row flex-grow-1 flex-md-grow-0 flex-wrap flex-fill">
        <div class="search">
          <mat-form-field floatLabel="never" style="max-width: 125px;">
            <input matInput type="search" placeholder="Search" autocomplete="off" class="search-input"
              [(ngModel)]="searchTerm" style="
              background-color: #EEEEEE;
              border: none;
              border-radius: 5px ;
              outline: none;
              color: #8B8B8B;">
            <div matSuffix><i class="far fa-search"></i></div>
          </mat-form-field>
        </div>
        <div class="mx-3 my-2 border-left"></div>
        <div class="d-flex align-items-center flex-row">
          <span style="width: 94px;" class=""> Filter By</span>
          <div class="select-input">
            <i class="fas fa-caret-down  dropdown-img d-flex align-items-center text-color"></i>
            <select class="select-custom text-color form-control" [(ngModel)]="filterTerm">
              <option value="extension">Most Recent</option>
              <option value="firstName">First Name</option>
              <option value="Voiply Adapter"> Voiply Adapter</option>
              <option value="Wi-Fi Desk Phone">Wi-Fi Desk Phone</option>
              <option value="Wi-Fi Cordless Phone">Wi-Fi Cordless Phone</option>
              <option value="Voiply online app">Voiply online app</option>
            </select>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <div class="card-body pt-0 pb-3" style="border: 0px;">
    <!-- Phones Configuration -->
    <ng-container
      *ngIf="(phonesconfiguration | keyvalue | keyValueSorted:filterTerm| SearchPipe:searchTerm:searchableList) as results">
      <ng-container *ngIf="_phonesconfigurationLength>0 && (searchTerm.length>0 && results.length==0)">
        <p class="mt-3 mb-0"> No Phones Found with Term {{searchTerm}}</p>
      </ng-container>
      <ng-container *ngIf="results.length>0">
        <div class="d-flex flex-row pt-3 align-items-center" id="managePhones">
          <span class="text-black font-weight-bold">Manage
            Phones</span>
          <div class="flex-fill pl-3">
            <hr style="border: 1px dashed rgba(0, 0, 0, 0.125)" class="m-0">
          </div>
          <div>
            <a class=" d-flex pl-3  " (click)="isCollapsedPhones = !isCollapsedPhones"
              [attr.aria-expanded]="!isCollapsedPhones" aria-controls="collapsePhone">
              <div class="" [collapse]="isCollapsedPhones">
                <i class="fas fa-plus "></i>
              </div>
              <div class="" [collapse]="!isCollapsedPhones">
                <i class="fas fa-minus "></i>
              </div>
            </a>
          </div>
        </div>
        <div class="collapse multi-collapse background-color" [collapse]="!isCollapsedPhones" id="collapsePhone">
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-xl-3 pt-3"
              *ngFor="let cartItem of (results | paginate: { itemsPerPage: 8, currentPage: phonePageNumber, id:'phonesPagination'})">
              <div class="card" style="border: 0px;"
                [ngClass]="{ 'border-error':(submitted && validateCartConfiguration(cartItem.value))}">
                <div style=" position: relative;">
                  <!-- cartItem Image -->
                  <div class="d-flex align-items-center justify-content-center" style="height:180px;">
                    <img [src]="getItemImage(cartItem.value.itemId, cartItem.value.type)" alt="..." height="130px"
                      class="">
                  </div>
                  <!-- Validation Exclamation Mark -->
                  <div *ngIf="submitted && validateCartConfiguration(cartItem.value)"
                    style="position: absolute; left: 12px; top: 8px;">
                    <i class="fas fa-exclamation-circle" tooltip="Please enter details" style="color:#e2574c"
                      [ngClass]="{ 'is-invalid':true}"></i>
                  </div>

                  <!-- online-offline status aftercheckout -->
                  <voiply-product-configuration-phone-status [phoneStatus]="cartItem.value.phoneStatusDetail"
                    style="position:absolute; top:8px; left:12px;" *ngIf="cartItem.value.phoneStatusDetail">
                  </voiply-product-configuration-phone-status>
                  <!-- Delete Cart Item -->
                  <div style="position: absolute; right: 12px; top: 8px;">
                    <i class=" fal fa-trash-alt text-color  cursor-pointer"
                      (click)="onRemoveCartItem(cartItem.key, cartItem.value)"></i>
                  </div>
                  <!-- Arrow for edit help if cart not valid -->
                  <div *ngIf="submitted && validateCartConfiguration(cartItem.value)">
                    <i class="fal fa-long-arrow-down"
                      style="position: absolute; right: 21px; bottom: 10px; font-size: 26px; color:#e2574c"></i>
                  </div>
                </div>

                <!-- Heading and Edit button -->
                <div class="d-flex flex-row">
                  <div class="btn-group flex-fill" dropdown style="width: 77%;">
                    <button class="btn text-primary py-2 mb-0 font-weight-bold"
                      [ngClass]="{ 'error':(submitted && validateCartConfiguration(cartItem.value))}"
                      style="margin-right:-1px; width: 99%;background-color:#404040;" type="button" dropdownToggle
                      type="button" aria-controls="dropdown-basic" id="dropdown-context-lable">
                      <div class="d-flex flex-row justify-content-between">
                        <span class="text-truncate"> {{cartItem.value.heading}}</span>
                        <span class="dropdown-toggle"></span>
                      </div>
                    </button>

                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu w-100" role="menu"
                      aria-labelledby="button-basic">
                      <ng-container *ngFor="let phone of phones; let i of index">
                        <li role="menuitem"
                          *ngIf="this.phoneAvailableInCountry(phone)&&phone.isActive&&!phone.outOfStock"><button
                            class="dropdown-item w-100 px-2"
                            (click)="onCartItemdropdownOptionChange(cartItem.key, phone.featureId)">
                            {{phone.heading}}
                          </button>
                        </li>
                      </ng-container>
                    </ul>
                  </div>
                  <button style="width:56px; background-color:#1A1A1A;"
                    class="btn rounded-0 text-primary p-0 cursor-pointer" (click)="openBusinessModal(cartItem.key)"
                    [ngClass]="{ 'error':(submitted && validateCartConfiguration(cartItem.value))}">
                    <div class="bg-none d-flex align-items-center text-black text-secondary"
                      style="width: 100%; margin:auto;">
                      <i class="fas fa-pencil-alt" style="font-size: 18px; padding:5px; color:white;margin:auto;"></i>
                    </div>
                  </button>
                </div>
              </div>

              <div class="d-flex flex-column mt-3 border" style="border-radius:3px;">

                <div class="d-flex flex-row" style="height: 40px;">
                  <div class=" d-flex flex-row ml-2 mr-2 align-self-center" style="width: 75%;">
                    <div class="mr-2 align-self-center">
                      <i class="fa fa-user align-self-center" aria-hidden="true" style="color:#F63B0B"></i>
                    </div>
                    <div class="d-flex fill-height-or-more flex-column text-truncate" style="height: auto;">
                      <!-- <span class="align-self-center">{{data.username}}</span> -->
                      <input type="text" class=" form-control bg-transparent"
                        [ngModel]="cartItem.value.configuration.firstName + ' ' + cartItem.value.configuration.lastName"
                        placeholder="User Name" disabled style="border: 0px ;">
                    </div>
                  </div>
                  <div class="d-flex flex-column flex-fill border-left align-items-center justify-content-center"
                    style="background-color: #F9F9F9;">
                    <div>
                      <span style="font-size: smaller;">{{cartItem.value.configuration.extension}}</span>
                    </div>
                  </div>
                </div>

                <div class="d-flex flex-row border-top" style="height: 40px;">
                  <div class=" d-flex flex-row ml-2 mr-2 align-self-center" style="width: 75%;">
                    <div class="mr-2 align-self-center">
                      <i class="fas fa-envelope align-self-center" aria-hidden="true" style="color:#F63B0B"></i>
                    </div>
                    <div class="d-flex fill-height-or-more flex-column text-truncate" style="height: auto;">
                      <!-- <span class="align-self-center">{{data.email}}</span> -->
                      <input type="text" class="form-control bg-transparent" style="border:0px;"
                        [ngModel]="cartItem.value.configuration.email" placeholder="E-mail" disabled>
                    </div>
                  </div>
                </div>

                <!-- <div class="pt-2 bg-none">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text rounded-0" style="width: 45px;">
                        <i class="fas fa-user text-secondary" style="margin: auto;"></i>
                      </div>
                    </div>
                    <input type="text" class=" form-control"
                      [ngModel]="cartItem.value.configuration.firstName + ' ' + cartItem.value.configuration.lastName"
                      placeholder="User Name" disabled>
                  </div>
                </div> -->

                <!-- <div class="pt-2">

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text rounded-0 text-secondary font-weight-bold"
                        style="font-size: 12px; width: 45px;">
                        EXT
                      </div>
                    </div>
                    <input type="text" class="form-control" [ngModel]="cartItem.value.configuration.extension"
                      placeholder="101" disabled>
                  </div>
                </div> -->
                <!-- <div class="pt-2">

                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text rounded-0" style="width: 45px;">
                        <i class="fas fa-envelope text-secondary" style="margin: auto;"></i>
                      </div>
                    </div>
                    <input type="text" class="form-control" [ngModel]="cartItem.value.configuration.email"
                      placeholder="E-mail" disabled>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div [hidden]="_phonesconfigurationLength<9" class="pt-3">
            <voiply-pagination (pageChange)="phonePageChange($event);scrollTo('managePhones');" id="phonesPagination">
            </voiply-pagination>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <!-- Apps Configuration -->
    <ng-container
      *ngIf="(appsconfiguration | keyvalue | keyValueSorted:filterTerm | SearchPipe:searchTerm:searchableList) as results">
      <ng-container *ngIf="_appsconfigurationLength>0 && (searchTerm.length>0 && results.length==0)">
        <p class="mt-3 mb-0"> No Apps Found with Term {{searchTerm}}</p>
      </ng-container>
      <ng-container *ngIf="results.length>0">
        <div class="d-flex flex-row align-items-center pt-3">
          <span class="text-black font-weight-bold">Manage Apps</span>
          <div class="flex-fill pl-3">
            <hr style="border: 1px dashed rgba(0, 0, 0, 0.125)" class="m-0">
          </div>
          <div>
            <a class=" d-flex pl-3" (click)="isCollapsedApps = !isCollapsedApps" [attr.aria-expanded]="!isCollapsedApps"
              aria-controls="collapseApps">
              <div class="" [collapse]="isCollapsedApps">
                <i class="fas fa-plus "></i>
              </div>
              <div class="" [collapse]="!isCollapsedApps">
                <i class="fas fa-minus "></i>
              </div>
            </a>
          </div>
        </div>
        <div class="collapse multi-collapse background-color" [collapse]="!isCollapsedApps" id="collapseApps">
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-xl-3 pt-3"
              *ngFor="let cartItem of (results | paginate: { itemsPerPage: 4, currentPage: appPageNumber,id:'appsPagination' }) ; let i = index; ">
              <div class="card rounded-0" style="border:0px;"
                [ngClass]="{ 'border-error':(submitted && validateCartConfiguration(cartItem.value))}">
                <div style="position: relative;">
    <!-- cartItem Image -->
    <div class="d-flex align-items-center justify-content-center" style="height: 180px;">
                    <img [src]="getItemImage(cartItem.value.itemId, cartItem.value.type)" alt="..."
                      style="height:100px; width: 100px;" >
                  </div>
    <!-- Validation Exclamation Mark -->
    <div style="position: absolute; left: 12px; top: 8px;">
                    <i *ngIf="submitted && validateCartConfiguration(cartItem.value)" class="fas fa-exclamation-circle"
                      tooltip="Please enter details" style="color:#e2574c" [ngClass]="{ 'is-invalid':true}"></i>
                  </div>
    <!-- Delete Cart Item -->
    <div style="position: absolute; right: 12px; top: 8px;">
                    <i class=" fal fa-trash-alt text-color  cursor-pointer "
                      (click)="onRemoveCartItem(cartItem.key, cartItem.value)"></i>
                  </div>
    <!-- Arrow for edit help if cart not valid -->
    <div *ngIf="submitted && validateCartConfiguration(cartItem.value)">
                    <i class="fal fa-long-arrow-down"
                      style="position: absolute; right: 21px; bottom: 10px; font-size: 26px; color:#e2574c"></i>
                  </div>
                </div>
                <!-- Heading and Edit button -->
                <div class="d-flex flex-row">
                  <p class="text-primary text-center py-2 m-0 font-weight-bold flex-fill" style="margin-right:2px;background-color:#404040;"
                    [ngClass]="{ 'error':(submitted && validateCartConfiguration(cartItem.value)) }">
                    {{cartItem.value.heading}}
                  </p>
                  <!-- <button style="width:56px;" class="btn rounded-0 text-primary p-0"
                    (click)="openBusinessModal(cartItem.key)"
                    [ngClass]="{ 'error':(submitted && validateCartConfiguration(cartItem.value)), 'bg-primary-gradient': !(submitted && validateCartConfiguration(cartItem.value))}">
                    <div class="bg-white rounded-circle d-flex align-items-center text-black text-secondary"
                      style="width: 21px; margin:auto;">
                      <i class="fas fa-pencil-alt" style="font-size: 11px; padding:5px;"></i>
                    </div>
                  </button> -->

                  <button style="width:56px; background-color:#1A1A1A;"
                    class="btn rounded-0 text-primary p-0 cursor-pointer" (click)="openBusinessModal(cartItem.key)"
                    [ngClass]="{ 'error':(submitted && validateCartConfiguration(cartItem.value))}">
                    <div class="bg-none d-flex align-items-center text-black text-secondary"
                      style="width: 100%; margin:auto;">
                      <i class="fas fa-pencil-alt" style="font-size: 18px; padding:5px; color:white;margin:auto;"></i>
                    </div>
                  </button>

                </div>

              </div>
            </div>
          </div>
          <div [hidden]="_appsconfigurationLength<5 " class="pt-3">
            <voiply-pagination (pageChange)="appPageChange($event)" id="appsPagination">
            </voiply-pagination>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>