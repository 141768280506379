import { createSelector } from '@ngxs/store';
import { AppState, AppStateModel } from './app.state';
import { CartItemType, CallFlowType, CallFlowList } from '@voiply/shared/model';
import * as _ from 'lodash';

class AppSelectors {

    /// ORDER ID
    get orderId() {
        return createSelector([this.getOrderId], (record) => record);
    }
    private get getOrderId() {
        return createSelector([AppState], (state: AppStateModel) => state.orderId);
    }

    get userIsVoiplyMember() {
        return createSelector([AppState], (state: AppStateModel) => state.userIsVoiplyMember);
    }
    //Current Location
    get currentLocation() {
        return createSelector([this.getCurrentLocation], (currentLocation) => currentLocation);
    }
    private get getCurrentLocation() {
        return createSelector([AppState], (state: AppStateModel) => state.currentLocation);
    }

    /// Primary Phone Number
    get primaryPhoneNumber() {
        return createSelector([this.primaryPhone], (primaryPhone) => primaryPhone.number);
    }
    get primaryPhone() {
        return createSelector([this.getPrimaryPhone], (record) => record);
    }
    private get getPrimaryPhone() {
        return createSelector([AppState], (state: AppStateModel) => state.primaryNumber);
    }

    get companyNumbers() {
        return createSelector([this.getCompanyNumbers], (companyNumbers) => companyNumbers);
    }
    private get getCompanyNumbers() {
        return createSelector([AppState], (state: AppStateModel) => state.companyNumbers);
    }

    /// Voiply Team Member
    get isUserVoiplyMember() {
        return createSelector([this.getUserifVoiplyMember], (record) => record);
    }
    private get getUserifVoiplyMember() {
        return createSelector([AppState], (state: AppStateModel) => state.userIsVoiplyMember);
    }

    /// Survey
    get surveyBanner() {
        return createSelector([this.getSurveyBanner], (surveyBanner) => surveyBanner);
    }
    private get getSurveyBanner() {
        return createSelector([AppState], (state: AppStateModel) => state.surveyBanner);
    }
    get survey() {
        return createSelector([this.getSurvey], (survey) => survey);
    }
    private get getSurvey() {
        return createSelector([AppState], (state: AppStateModel) => state.survey);
    }

    // Generic App Forms
    get doValidate() {
        return createSelector([this.getDoValidate], (doValidate) => doValidate);
    }
    private get getDoValidate() {
        return createSelector([AppState], (state: AppStateModel) => state.doValidate);
    }

    // phones
    get phones() {
        return createSelector([this.getPhones], (phones) => phones);
    }
    private get getPhones() {
        return createSelector([AppState], (state: AppStateModel) => state.phones);
    }

    // apps
    get apps() {
        return createSelector([this.getapps], (apps) => apps);
    }
    private get getapps() {
        return createSelector([AppState], (state: AppStateModel) => state.apps);
    }

    // Cart Items
    get cartItems() {
        return createSelector([this.getCartItems], (cartItems) => cartItems);
    }
    private get getCartItems() {
        return createSelector([AppState], (state: AppStateModel) => state.cartItems);
    }

    //Promo code
    get promoCodeApplied() {
        return createSelector([this.getPromoCodeApplied], (promoCodeApplied) => promoCodeApplied);
    }
    private get getPromoCodeApplied() {
        return createSelector([AppState], (state: AppStateModel) => state.promoCodeApplied);
    }

    // Checkout details
    get checkoutDetails() {
        return createSelector([this.getCheckoutDetails], (checkoutDetails) => checkoutDetails);
    }
    get shippingAddress() {
        return createSelector([this.getCheckoutDetails], (checkoutDetails) => checkoutDetails.shippingAddress);
    }

    get billingAddress() {
        return createSelector([this.getCheckoutDetails], (checkoutDetails) => checkoutDetails.billingAddress);
    }
    private get getCheckoutDetails() {
        return createSelector([AppState], (state: AppStateModel) => state.checkoutDetail);
    }

    get cartHasHardware() {
        return createSelector([this.getCartItems], (cartItems) => {
            let hasHardware = false;
            // tslint:disable-next-line: forin
            for (const key in cartItems) {
                hasHardware = cartItems[key].type === CartItemType.Phone && (cartItems[key].itemId !== 4 && cartItems[key].itemId !== 11);
                if (hasHardware)
                    return true;
            }

            return hasHardware;
        })
    }

    get cartHasHardwareAfterCheckout() {
        return createSelector([this.getCartItems], (cartItems) => {
            let hasHardware = false;
            // tslint:disable-next-line: forin
            for (const key in cartItems) {
                hasHardware = cartItems[key].type === CartItemType.Phone && (cartItems[key].itemId !== 4 && cartItems[key].itemId !== 11 && cartItems[key].heading!=='Voiply Extra Line') && cartItems[key].paid;
                if (hasHardware)
                    return true;
            }

            return hasHardware;
        })
    }

    get showVoiplyOnlineApp() {
        return createSelector([this.getCartItems], (cartItems) => {
            let showVoiplyOnlineApp = false;
            _.each(cartItems, (cartItem) => {
                if (cartItem.type === CartItemType.Phone) {
                    showVoiplyOnlineApp = true;
                    //This return is to exit loop
                    return false;
                } else {
                    showVoiplyOnlineApp = false;
                }
            });
            return showVoiplyOnlineApp;
        })
    }
    get updateShipping() {
        return createSelector([this.getIfUpdateShipping], (updateShipping) => updateShipping);
    }
    private get getIfUpdateShipping() {
        return createSelector([AppState], (state: AppStateModel) => state.updateShipping);
    }

    //Call FLows List
    get callFlowsList() {
        return createSelector([this.getCallFlowsList], (callFlowsList) => callFlowsList);
    }
    private get getCallFlowsList() {
        return createSelector([AppState], (state: AppStateModel) => state.callFlowsList);
    }

    //Selected Call FLow
    get callFlow() {
        return createSelector([this.getCallFlow], (callFlow) => callFlow);
    }
    private get getCallFlow() {
        return createSelector([AppState], (state: AppStateModel) => state.callFlow);
    }

    //Help Text
    get CallFlowHelpText() {
        return createSelector([this.getCallFlowHelpText], (callFLowHelpText) => callFLowHelpText);
    }

    private get getCallFlowHelpText() {
        return createSelector([AppState], (state: AppStateModel) => state.helpText.callFlows);
    }

    //User per cartItem
    get users() {
        return createSelector([this.getCartItems], (cartItems) => {
            const users = [];

            _.each(cartItems, (cartItem) => {
                if (cartItem.type === CartItemType.Phone) {
                    const config = cartItem.configuration;

                    // Generate Users array
                    users.push({ id: config.userId, firstName: config.firstName, lastName: config.lastName, extension: config.extension, email: config.email });
                }
            });

            return users;
        })
    }
    private get getUsers() {
        return createSelector([AppState], (state: AppStateModel) => (state.cartItems || {}).users);
    }

    //Selected Call FLow
    get internetProviders() {
        return createSelector([this.getInternetProviders], (internetProvider) => internetProvider);
    }
    private get getInternetProviders() {
        return createSelector([AppState], (state: AppStateModel) => state.checkoutDetail.internetProvider);
    }

    //calculatingTotal
    get calculatingTotal() {
        return createSelector([this.getCalculatingTotal], (calculatingTotal) => calculatingTotal);
    }
    private get getCalculatingTotal() {
        return createSelector([AppState], (state: AppStateModel) => state.calculatingTotal);
    }
    //cardProcessing
    get cardProcess() {
        return createSelector([this.getcardProcess], (cardProcess) => cardProcess);
    }
    private get getcardProcess() {
        return createSelector([AppState], (state: AppStateModel) => state.cardProcess);
    }

    //Shipment
    get shipment() {
        return createSelector([this.getShipment], (shipment) => shipment);
    }
    private get getShipment() {
        return createSelector([AppState], (state: AppStateModel) => state.shipment);
    }

    //First Promoter
    get firstPromoter() {
        return createSelector([this.getFirstPromoter], (firstPromoter) => firstPromoter);
    }
    private get getFirstPromoter() {
        return createSelector([AppState], (state: AppStateModel) => state.firstPromoter);
    }

    //Number Porting
    get numberPorting() {
        return createSelector([this.getNumberPorting], (numberPorting) => numberPorting);
    }
    private get getNumberPorting() {
        return createSelector([AppState], (state: AppStateModel) => state.numberPorting);
    }

    //Addtional Numbers
    get additionalNumbers() {
        return createSelector([this.getAdditionalNumbers], (additionalNumbers) => additionalNumbers);
    }
    private get getAdditionalNumbers() {
        return createSelector([AppState], (state: AppStateModel) => state.additionalNumbers)
    }

    //updatingOrder
    get updatingPhoneSystem() {
        return createSelector([this.getUpdatingPhoneSystem], (updatingOrder) => updatingOrder);
    }

    private get getUpdatingPhoneSystem() {
        return createSelector([AppState], (state: AppStateModel) => state.updatingOrder);
    }

    // system features
    get companyFeatures() {
        return createSelector([AppState], (state: AppStateModel) => state.systemFeatures);
    }

    //validations
    get checkAllFormsAreValid() {
        return createSelector([this.getCheckoutDetails, this.getPrimaryPhone], (checkoutDetail, primaryNumber) => {
            return checkoutDetail.isValid && primaryNumber.isValid;
        });
    }

    get checkCallFlowIsSelected() {
        return createSelector([this.getCartItems, this.callFlow, this.callFlowsList], (cartItems, callFlow, callFlowsList) => {
            if (_.some(cartItems, (item) => item.type === CartItemType.Phone) && callFlow.type === CallFlowType.None && callFlowsList.length > 0) {
                return false;
            }

            return true;
        });
    }

    //isOrderDisabled
    get isOrderDisabled() {
        return createSelector([this.getIfOrderIsDisabled], (internetProvider) => internetProvider);
    }
    private get getIfOrderIsDisabled() {
        return createSelector([AppState], (state: AppStateModel) => state.isOrderDisabled);
    }

    //StripeCardDeatils
    get cardDetails() {
        return createSelector([this.getCardDetails], (card) => card);
    }
    private get getCardDetails() {
        return createSelector([AppState], (state: AppStateModel) => state.card);
    }

    //PayAnnually
    get isPayAnually() {
        return createSelector([this.getisPayAnually], (isPayAnually) => isPayAnually);
    }
    private get getisPayAnually() {
        return createSelector([AppState], (state: AppStateModel) => state.checkoutDetail.payAnnually);
    }
    //Monthly Total with Annual Pre-pay
    get monthlyTotalAnnualPrePay() {
        return createSelector([this.getMonthlyTotalAnnualPrePay], (monthlyTotalAnnualPrePay) => monthlyTotalAnnualPrePay);
    }
    private get getMonthlyTotalAnnualPrePay() {
        return createSelector([AppState], (state: AppStateModel) => state.systemFeatures.subheader);
    }

    get subscription() {
        return createSelector([this.getSubscription], (subscription) => subscription);
    }

    private get getSubscription() {
        return createSelector([AppState], (state: AppStateModel) => state.subscription);
    }

    get customer() {
        return createSelector([this.getCustomer], (customer) => customer);
    }

    private get getCustomer() {
        return createSelector([AppState], (state: AppStateModel) => state.customer);
    }

    get orderedMorePhones() {
        return createSelector([this.getOrderedMorePhones], (orderedMorePhones) => orderedMorePhones);
    }
    private get getOrderedMorePhones() {
        return createSelector([AppState], (state: AppStateModel) => state.orderedMorePhones);
    }

    get metadata() {
        return createSelector([this.getmetadata], (metadata) => metadata);
    }
    private get getmetadata() {
        return createSelector([AppState], (state: AppStateModel) => state.metadata);
    }
}
export const AppStateSelectors = new AppSelectors();