<div class="card">
  <div class="card-body">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <h5 class="text-secondary font-weight-bold m-0"><i class="fas fa-user pr-3"></i> SELECT USER TO RING </h5>
      <i class="fas fa-question-circle fa-lg" *ngIf="ringOneUserHelpText" [popover]="ringOneUserHelpText"
        placement="auto" containerClass="popover-css" [outsideClick]="true"></i>
    </div>
    <div class="border-bottom my-3"></div>

    <div class="select-input">
      <i class="fas fa-caret-down  dropdown-img d-flex align-items-center"></i>
      <select class="select-custom form-control text-secondary font-weight-bold"
        (change)="toggleUserSelectionRingOne($event.target.value)" [ngModel]="ringOneUser">
        <option *ngIf="!ringOneUser" value=""> Select a User
        </option>
        <option *ngFor="let user of users;let i=index" [value]="user.id"> {{user.extension}} - {{user.firstName}}
          {{user.lastName}}
        </option>
      </select>
    </div>

  </div>
</div>