import { Component, Inject, OnInit } from '@angular/core';
import { APPSCONST, AudioService, CrispService, ENVIRONMENT, IEnvironment } from '@voiply/shared/common-ui';
import { App, CartItem, OnlineFax } from '@voiply/shared/model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ProductBase } from '../product.base';
import * as _ from 'lodash';
import { debug } from 'logrocket';

@Component({
  selector: 'voiply-apps-business',
  templateUrl: './apps-business.component.html',
  styleUrls: ['./apps-business.component.css']
})
export class AppsBusinessComponent extends ProductBase {

  modalRef: BsModalRef;
  showapps = 4;
  selectedAppsInCart=[
    {
      "heading": APPSCONST.ONLINEFAX,
      "isSelected": false,
    },
    {
      "heading": APPSCONST.CONFERENCING,
      "isSelected": false,
    },
    {
      "heading": APPSCONST.SPAMCALLBOT,
      "isSelected": false,
    },
    {
      "heading": APPSCONST.CALLRECORDING,
      "isSelected": false,
    },
  ]
  apps: App[]

  constructor(@Inject(ENVIRONMENT) environment: IEnvironment, toastr: ToastrService, crispService: CrispService, audioService: AudioService,private modalService: BsModalService) {
    super(environment, toastr, crispService, audioService);
    
  }

  ngOnInit(): void {
    this.apps$.subscribe((apps) => this.apps = _.filter(apps, (app) => app.isActive));
    this.subscriptions$.add(this.cartItems$.subscribe((items) => {
        this.cartItems = items
        this.findAppInCartItems();
      })
    );
  }


  clickedToggle(index,app: App){
    
    if(this.selectedAppsInCart[app.appId-1].isSelected===false){
      this.onAddAppToCart(app);
      this.selectedAppsInCart[app.appId-1].isSelected=true;
    }
    else{
      this.selectedAppsInCart[app.appId-1].isSelected=false;
      console.log(this.cartItems)
      for(let i of Object.keys(this.cartItems)){
        if(this.cartItems[i].itemId==app.appId){
          this.onRemoveCartItem(i);
          break;
        }
      }
    }
    
  }

  findAppInCartItems(){
    for(let i=0;i<this.selectedAppsInCart.length;i++){
      this.selectedAppsInCart[i].isSelected=false;
    }

    for(let i of Object.keys(this.cartItems)){
      if(this.cartItems[i].type=="app"){
        this.selectedAppsInCart[this.cartItems[i].itemId-1].isSelected=true;
      }
    }
    
  }

  appAvailableInCountry(app) {
    if (app.onlyAvailableInCountry.length) {
      if (_.includes(app.onlyAvailableInCountry, this.selectedCountry)) {
        return true
      } else return false
    } else return true
  }
  

  
}
