import { Injectable, Inject, EventEmitter } from '@angular/core';
import { IEnvironment } from '../environment.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable, of, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Address, ShippingOptions, CheckoutDetail } from '@voiply/shared/model';
import * as _ from 'lodash';
import spacetime from 'spacetime';
import { ENVIRONMENT } from '../constants';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ShippingService extends BaseService {
  shippingAddress: Address;
  orderId: string;
  checkoutDetail: CheckoutDetail;
  shippingOptions: ShippingOptions[] = [];
  // private readonly _shippingOptions: BehaviorSubject<ShippingOptions[]> = new BehaviorSubject<ShippingOptions[]>([]);
  // public get shippingOptions$(): Observable<ShippingOptions[]> { return this._shippingOptions.asObservable(); }
  // private set shippingOptions(value: ShippingOptions[]) { this._shippingOptions.next(Object.assign([], value)); }
  selectShipping = new EventEmitter<any>();

  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {
    super();
  }

  getShippingOptionsAsync(address = null, isShippingSameAsBilling = false): Observable<any> {

    // if (location.origin.indexOf('localhost') > 0) {
    //   //   if (this.shippingAddress.country == "CA")
    //   //     return of({
    //   //       quote_results: {
    //   //         shipping_amount: 14.99
    //   //       }
    //   //     });
    //   //   else
    //   return of({
    //     overnight: 29.99,
    //     overnight_label: "UPS_1DY",
    //     twoday: 19.99,
    //     twoday_label: "UPS_2DY",
    //     standard: 9.99,
    //     standard_label: "UPS_GRD"
    //   });
    // }

    return this.http.get(`${this.environment.rootApi}/shipping-master?code=WA50wkpFvIfFufn36a4pkFFBmKytO97gaDX5JezhoIjsQMYSTxlOvg==&order_id=${this.orderId}`).pipe(catchError(this.handleError));
    //  return this.http.get(`${this.environment.rootApi}/shipping-teledynamics?code=WA50wkpFvIfFufn36a4pkFFBmKytO97gaDX5JezhoIjsQMYSTxlOvg==&order_id=${this.orderId}`).pipe(catchError(this.handleError));
  }

  fetchShippingOptionsBusiness(orderId, address = null, isShippingSameAsBilling = false) {
    this.orderId = orderId;

    if (!this.checkoutDetail.billingAddress.zip) {
      this.shippingOptions = this.getDefaultShippingOptionsBusiness();
      this.selectShipping.emit();
    }

    this.getShippingOptionsAsync(address, isShippingSameAsBilling).subscribe((result: any) => {

      this.shippingOptions = [];

      if (this.shippingAddress.country == "CA") {
        result = ((result || {}).quote_results || {}).shipping_amount;
        if (result == null || result == '0.00') {
          this.shippingOptions = this.getDefaultShippingOptionsBusiness();
          this.selectShipping.emit();
        }
        else {
          this.shippingOptions.push({
            charges: +result,
            displayDate: "Charges",
            displayTitle: "No-Rush Shipping",
            id: "standard",
            label: 'UPS_GRD'
          });
          this.selectShipping.emit();
        }
      }
      else if (this.shippingAddress.country == "US") {
        result = (result || {}).response;
        if (_.isEmpty(result) || result == null) {
          this.shippingOptions = this.getDefaultShippingOptionsBusiness();
          this.selectShipping.emit();
          return;
        }

        // const dateToCheck = moment.tz("America/New_York").hour(15).minutes(0).format();
        // const currentDate = moment.tz("America/New_York").format();
        // const daysToAdd = moment(currentDate).isBefore(dateToCheck) ? 0 : 1;

        const dateToCheck = spacetime(Date.now(), 'America/New_York').hour(15).minute(0);
        const currentDate = spacetime(Date.now(), 'America/New_York');
        const daysToAdd = currentDate.isBefore(dateToCheck) ? 0 : 1;

        // Add exact days
        if (result.overnight)
          this.shippingOptions.push({
            charges: +result.overnight.toFixed(2),
            displayDate: this.findNextShippingDateByDays(1 + daysToAdd).format('{day}, {month-short} {date}').toString(),
            displayTitle: "One-Day Shipping",
            id: "overnight",
            label: result.overnight_label
          });

        if (result.twoday)
          this.shippingOptions.push({
            charges: +result.twoday.toFixed(2),
            displayDate: this.findNextShippingDateByDays(2 + daysToAdd).format('{day}, {month-short} {date}').toString(),
            displayTitle: "Two-Day Shipping",
            id: "twoday",
            label: result.twoday_label
          });

        if (result.standard)
          this.shippingOptions.push({
            charges: +result.standard.toFixed(2),
            displayDate: this.findNextShippingDateByDays(3 + daysToAdd).format('{day}, {month-short} {date}').toString(),
            displayTitle: "No-Rush Shipping",
            id: "standard",
            label: result.standard_label
          });
        this.selectShipping.emit();
      }
      else {
        //International Country

        result = (result || {}).response;
        if (result == null || result == '0.00') {
          this.shippingOptions = this.getDefaultShippingOptionsBusiness();
          this.selectShipping.emit();
        }
        else {
          let s = spacetime.now();


          if (result.priority) {

            let dateDiff = spacetime(result.priority.deliverydate).since(s).diff;
            let daysToAdd = dateDiff.days + 1;

            this.shippingOptions.push({
              charges: +result.priority.charges.toFixed(2),
              displayDate: this.findNextShippingDateByDays(daysToAdd).format('{day}, {month-short} {date}').toString(),
              displayTitle: result.priority.label,
              id: "overnight",
              label: result.priority.label
            });
          }
          if (result.economy) {
            let dateDiff = spacetime(result.economy.deliverydate).since(s).diff;
            let daysToAdd = dateDiff.days + 1;

            this.shippingOptions.push({
              charges: +result.economy.charges.toFixed(2),
              displayDate: this.findNextShippingDateByDays(daysToAdd).format('{day}, {month-short} {date}').toString(),
              displayTitle: result.economy.label,
              id: "standard",
              label: result.economy.label
            });
          }
          this.selectShipping.emit();
        }
      }
    }, () => {
      this.shippingOptions = [];
      this.shippingOptions = this.getDefaultShippingOptionsBusiness();
      this.selectShipping.emit();
    });

  }
  public getDefaultShippingOptionsBusiness(): ShippingOptions[] {
    const defaultShippingOptions: ShippingOptions[] = [];

    if (this.shippingAddress.country == "CA") {
      defaultShippingOptions.push({
        charges: 14.99,
        displayDate: "Charges",
        displayTitle: "No-Rush Shipping",
        id: "standard",
        label: 'UPS_GRD'
      });
    }
    else if (this.shippingAddress.country == "US") {
      //TODO: moment timezone is replced with spacetime library as moment library is very big
      // const dateToCheck = moment.tz("America/New_York").hour(15).minutes(0).format();
      // const currentDate = moment.tz("America/New_York").format();
      // const daysToAdd = moment(currentDate).isBefore(dateToCheck) ? 0 : 1;

      const dateToCheck = spacetime(Date.now(), 'America/New_York').hour(15).minute(0);
      const currentDate = spacetime(Date.now(), 'America/New_York');
      const daysToAdd = currentDate.isBefore(dateToCheck) ? 0 : 1;

      defaultShippingOptions.push({
        charges: 29.99,
        displayDate: this.findNextShippingDateByDays(1 + daysToAdd).format('{day}, {month-short} {date}').toString(),
        displayTitle: "One-Day Shipping",
        id: "overnight",
        label: 'UPS_1DY'
      });

      defaultShippingOptions.push({
        charges: 19.99,
        displayDate: this.findNextShippingDateByDays(2 + daysToAdd).format('{day}, {month-short} {date}').toString(),
        displayTitle: "Two-Day Shipping",
        id: "twoday",
        label: 'UPS_2DY'
      });

      defaultShippingOptions.push({
        charges: 9.99,
        displayDate: this.findNextShippingDateByDays(3 + daysToAdd).format('{day}, {month-short} {date}').toString(),
        displayTitle: "No-Rush Shipping",
        id: "standard",
        label: 'UPS_GRD'
      });
    }
    else {
      defaultShippingOptions.push({
        charges: 34.99,
        displayDate: "Charges",
        displayTitle: "No-Rush Shipping",
        id: "standard",
        label: 'ECONOMY'
      });
    }
    return defaultShippingOptions;
  }


  fetchShippingOptionsHome(orderId, address = null, isShippingSameAsBilling = false) {
    this.orderId = orderId;
    //
    //if (!this.checkoutDetail.billingAddress.zip) {
      this.shippingOptions = this.getDefaultShippingOptionsHome();
      this.selectShipping.emit();
    //}

    /*Removed call of shipping-master for res site because we have to return only default value */

    // this.getShippingOptionsAsync(address, isShippingSameAsBilling).subscribe((result: any) => {

    //   this.shippingOptions = [];

    //   if (this.shippingAddress.country == "CA") {
    //     result = ((result || {}).quote_results || {}).shipping_amount;
    //     if (result == null || result == '0.00') {
    //       this.shippingOptions = this.getDefaultShippingOptionsHome();
    //       this.selectShipping.emit();
    //     }
    //     else {
    //       this.shippingOptions.push({
    //         charges: +result,
    //         displayDate: "Charges",
    //         displayTitle: "No-Rush Shipping",
    //         id: "standard",
    //         label: 'UPS_GRD'
    //       });
    //       this.selectShipping.emit();
    //     }
    //   }
    //   else if (this.shippingAddress.country == "US") {
    //     result = (result || {}).response;
    //     if (_.isEmpty(result) || result == null) {
    //       this.shippingOptions = this.getDefaultShippingOptionsHome();
    //       this.selectShipping.emit();
    //       return;
    //     }

    //     // const dateToCheck = moment.tz("America/New_York").hour(15).minutes(0).format();
    //     // const currentDate = moment.tz("America/New_York").format();
    //     // const daysToAdd = moment(currentDate).isBefore(dateToCheck) ? 0 : 1;

    //     const dateToCheck = spacetime(Date.now(), 'America/New_York').hour(15).minute(0);
    //     const currentDate = spacetime(Date.now(), 'America/New_York');
    //     const daysToAdd = currentDate.isBefore(dateToCheck) ? 0 : 1;

    //     // Add exact days
    //     if (result.overnight)
    //       this.shippingOptions.push({
    //         charges: +result.overnight.toFixed(2),
    //         displayDate: this.findNextShippingDateByDays(1 + daysToAdd).format('{day}, {month-short} {date}').toString(),
    //         displayTitle: "One-Day Shipping",
    //         id: "overnight",
    //         label: result.overnight_label
    //       });

    //     if (result.twoday)
    //       this.shippingOptions.push({
    //         charges: +result.twoday.toFixed(2),
    //         displayDate: this.findNextShippingDateByDays(2 + daysToAdd).format('{day}, {month-short} {date}').toString(),
    //         displayTitle: "Two-Day Shipping",
    //         id: "twoday",
    //         label: result.twoday_label
    //       });

    //     if (result.standard)
    //       this.shippingOptions.push({
    //         charges: 0,
    //         displayDate: this.findNextShippingDateByDays(3 + daysToAdd).format('{day}, {month-short} {date}').toString(),
    //         displayTitle: "No-Rush Shipping",
    //         id: "standard",
    //         label: result.standard_label
    //       });
    //     this.selectShipping.emit();
    //   }
    //   else {
    //     //International Country

    //     result = (result || {}).response;
    //     if (result == null || result == '0.00') {
    //       this.shippingOptions = this.getDefaultShippingOptionsHome();
    //       this.selectShipping.emit();
    //     }
    //     else {
    //       let s = spacetime.now();


    //       if (result.priority) {

    //         let dateDiff = spacetime(result.priority.deliverydate).since(s).diff;
    //         let daysToAdd = dateDiff.days + 1;

    //         this.shippingOptions.push({
    //           charges: +result.priority.charges.toFixed(2),
    //           displayDate: this.findNextShippingDateByDays(daysToAdd).format('{day}, {month-short} {date}').toString(),
    //           displayTitle: result.priority.label,
    //           id: "overnight",
    //           label: result.priority.label
    //         });
    //       }
    //       if (result.economy) {
    //         let dateDiff = spacetime(result.economy.deliverydate).since(s).diff;
    //         let daysToAdd = dateDiff.days + 1;

    //         this.shippingOptions.push({
    //           charges: +result.economy.charges.toFixed(2),
    //           displayDate: this.findNextShippingDateByDays(daysToAdd).format('{day}, {month-short} {date}').toString(),
    //           displayTitle: result.economy.label,
    //           id: "standard",
    //           label: result.economy.label
    //         });
    //       }
    //       this.selectShipping.emit();
    //     }
    //   }
    // }, () => {
    //   this.shippingOptions = [];
    //   this.shippingOptions = this.getDefaultShippingOptionsHome();
    //   this.selectShipping.emit();
    // });
    //
  }
  public getDefaultShippingOptionsHome(): ShippingOptions[] {
    const defaultShippingOptions: ShippingOptions[] = [];

    if (this.shippingAddress.country == "CA") {
      defaultShippingOptions.push({
        charges: 14.99,
        displayDate: "Charges",
        displayTitle: "No-Rush Shipping",
        id: "standard",
        label: 'UPS_GRD'
      });
    }
    else if (this.shippingAddress.country == "US") {
      //TODO: moment timezone is replced with spacetime library as moment library is very big
      // const dateToCheck = moment.tz("America/New_York").hour(15).minutes(0).format();
      // const currentDate = moment.tz("America/New_York").format();
      // const daysToAdd = moment(currentDate).isBefore(dateToCheck) ? 0 : 1;

      const dateToCheck = spacetime(Date.now(), 'America/New_York').hour(15).minute(0);
      const currentDate = spacetime(Date.now(), 'America/New_York');
      const daysToAdd = currentDate.isBefore(dateToCheck) ? 0 : 1;

      defaultShippingOptions.push({
        charges: 29.99,
        displayDate: this.findNextShippingDateByDays(1 + daysToAdd).format('{day}, {month-short} {date}').toString(),
        displayTitle: "One-Day Shipping",
        id: "overnight",
        label: 'UPS_1DY'
      });

      defaultShippingOptions.push({
        charges: 19.99,
        displayDate: this.findNextShippingDateByDays(2 + daysToAdd).format('{day}, {month-short} {date}').toString(),
        displayTitle: "Two-Day Shipping",
        id: "twoday",
        label: 'UPS_2DY'
      });

      defaultShippingOptions.push({
        charges: 0,
        displayDate: this.findNextShippingDateByDays(3 + daysToAdd).format('{day}, {month-short} {date}').toString(),
        displayTitle: "No-Rush Shipping",
        id: "standard",
        label: 'UPS_GRD'
      });
    }
    else {
      defaultShippingOptions.push({
        charges: 34.99,
        displayDate: "Charges",
        displayTitle: "No-Rush Shipping",
        id: "standard",
        label: 'ECONOMY'
      });
    }
    return defaultShippingOptions;
  }


  //#region 'Protected Methods'
  protected findNextShippingDateByDays(days) {
    let timezone = spacetime.now().timezone().name;
    let date = spacetime(Date.now(), timezone);
    while (days > 0) {
      // decrease "days" only if it's a weekday.
      date = date.add(1, 'day');
      if (date.day() !== 0 && date.day() !== 6) {
        days -= 1;
      }
    }
    return date;
  }
  //#endregion
}
