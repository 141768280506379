<div class="row" *ngIf="apps">
  <div class="col-lg-3 col-sm-6 col-12" *ngFor="let app of apps; let i = index;">
    <ng-container *ngIf="i<showapps && (appAvailableInCountry(app))">
      <div class="card mt-4">
        <div style="position: absolute; right:8px; top:8px;">
          <button type="button" class="btn rounded-0 shadow-1 bg-primary text-secondary" (click)="onAddToCart(app)"><i
              class="fas fa-plus" style="color: white;"></i></button>
        </div>
        <div
          class="d-flex flex-column justify-content-between align-items-center px-4 pt-2 pb-4 cursor-pointer"
          (click)="onOpenModalDialog(i) ">
          <img [src]="app.image" style="height: 90px; width: 90px;" class="">
          <h6 class="text-secondary m-0 font-weight-bold">{{app.title.toUpperCase()}}</h6>
        </div>
      </div>

      <div class="bg-black py-1 border"></div>
    </ng-container>
  </div>
</div>

<div class=" d-flex flex-row justify-content-center align-items-center mt-3 border-x-2 cursor-pointer"
  *ngIf="apps?.length>4 &&showapps<5" style="background-color: #dadadb;" (click)="showMoreApps()">
  <h6 class="text-secondary m-0 py-2 d-flex flex-row align-items-center font-weight-bold">
    SHOW MORE APPS
  </h6>
</div>
<div class=" d-flex flex-row justify-content-center align-items-center mt-3 border-x-2 cursor-pointer"
  *ngIf="apps?.length>4&&apps?.length<showapps+1" style="background-color: #dadadb;" (click)="this.showapps=4">
  <h6 class="text-secondary m-0 py-2 d-flex flex-row align-items-center font-weight-bold">
    HIDE MORE APPS
  </h6>
</div>

<div class="modal fade" bsModal #moreAppsModal="bs-modal" tabindex="-1" role="dialog"
  aria-labelledby="dialog-static-name">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex flex-row align-items-center">
          <h4 class="flex-grow-1" class="modal-title text-primary pl-3">Apps</h4>
        </div>
        <button type="button" class="close pull-right" aria-label="Close" (click)="moreAppsModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-4 col-sm-6 col-12" *ngFor="let app of apps; let i = index;">
            <div class="card ">
              <div style="position: absolute; right:8px; top:8px;">
                <button type="button" class="btn rounded-0 shadow-1 bg-white text-secondary"
                  (click)="onAddToCart(app)"><i class="fas fa-plus"></i></button>
              </div>
              <div
                class="d-flex flex-column justify-content-between align-items-center px-4 pt-2 pb-4 bg-primary-gradient">
                <img [src]="app.image" style="height: 70px;" class="mb-2" class="app-image-color">
                <h6 class="text-primary m-0 font-weight-bold">{{app.title.toUpperCase()}}</h6>
              </div>
            </div>

            <div class="bg-black py-1 border"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>