import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { App } from '@voiply/shared/model';
import { BsModalRef } from 'ngx-bootstrap/modal/';

@Component({
  selector: 'voiply-show-app-details-modal',
  templateUrl: './show-app-details-modal.component.html',
  styleUrls: ['./show-app-details-modal.component.scss']
})
export class ShowAppDetailsModalComponent {
  @Input()
  app: App;
  @Output() addToCart = new EventEmitter<App>();
  constructor(public bsModalRef: BsModalRef) { }

}
