import { Component, Inject, OnInit } from '@angular/core';
import { AudioService, CrispService, ENVIRONMENT, IEnvironment } from '@voiply/shared/common-ui';
import { ToastrService } from 'ngx-toastr';
import { ProductBase } from '../product.base';

@Component({
  selector: 'voiply-phone-business',
  templateUrl: './phone-business.component.html',
  styleUrls: ['./phone-business.component.scss']
})
export class PhoneBusinessComponent extends ProductBase {
  isCollapsed = true;
  constructor(@Inject(ENVIRONMENT) environment: IEnvironment, toastr: ToastrService, crispService: CrispService, audioService: AudioService) {
    super(environment, toastr, crispService, audioService);
  }

}
