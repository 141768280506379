import { Component, OnInit, Inject } from '@angular/core';
import { ProductBase } from '../product.base';
import { ENVIRONMENT, IEnvironment, CrispService, AudioService } from '@voiply/shared/common-ui';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'voiply-product-business-after-checkout',
  templateUrl: './product-business-after-checkout.component.html',
  styleUrls: ['./product-business-after-checkout.component.css']
})
export class ProductBusinessAfterCheckoutComponent extends ProductBase {
  constructor(@Inject(ENVIRONMENT) environment: IEnvironment, toastr: ToastrService, crispService: CrispService, audioService: AudioService) {
    super(environment, toastr, crispService, audioService);
  }
}
