<div class="bsModal">
  <div class="modal-content">
    <div class="modal-body">
      <p class="m-0">{{title}}</p>
    </div>
    <div class="numb-footer py-3">
      <button type="button" class="btn-close btn" (click)="decline();">{{leftButton}}</button>
      <button type="button" class="btn-update btn btn-primary" (click)="confirm()">{{rightButton}}</button>
    </div>
  </div>
</div>