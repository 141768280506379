import { Injectable } from '@angular/core';
import { Survey } from '@voiply/shared/model';

@Injectable({
  providedIn: 'root'
})
export class WizardService {
  survey: Survey = { email: '', numberOfEmployees: '1', zipcode: '', responseSubmitted: '' };
  constructor() { }
}
