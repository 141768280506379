import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CrispService, QrCodeService } from '@voiply/shared/common-ui';
import { ToastrService } from 'ngx-toastr';
import { HeaderOrderTrackingBase } from "../header-order.tracking.base";

@Component({
  selector: 'voiply-header-order-tracking-business',
  templateUrl: './header-order-tracking-business.component.html',
  styleUrls: ['./header-order-tracking-business.component.css']
})
export class HeaderOrderTrackingBusinessComponent extends HeaderOrderTrackingBase {

  constructor(crispService: CrispService, toastr: ToastrService, qrCodeService: QrCodeService, domSanitizer: DomSanitizer) {
    super(crispService,toastr,qrCodeService,domSanitizer);
  }


}
