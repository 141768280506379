import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'voiply-budget-per-week',
  templateUrl: './budget-per-week.component.html',
  styleUrls: ['./budget-per-week.component.scss']
})
export class BudgetPerWeekComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() selectedBudget: number;
  @Input() budgetHelpText: string;

  @Output() changeBudget = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const allRangesBudget = document.querySelectorAll('.change-budget');
    allRangesBudget.forEach(wrap => {
      const range = wrap.querySelector('.range-budget');
      const bubble = wrap.querySelector('.bubble-budget');
      range.addEventListener("input", () => {
        this.setBubble(range, bubble);
      });
      this.setBubble(range, bubble, this.selectedBudget);
    });
  }

  setBubble(range, bubble, defaultvalue?) {
    let val;
    if (defaultvalue) {
      val = defaultvalue
    } else {
      val = range.value;
    }
    const min = range.min ? range.min : 1;
    const max = range.max ? range.max : 100;
    const newVal = Number(((val - min) * 100) / (max - min));
    bubble.innerHTML = '$' + Math.floor(parseInt(val, 10));
    // Sorta magic numbers based on size of the native UI thumb
    bubble.style.left = `calc(${newVal}% + (${6 - newVal * 0.15}px))`;
  }

  ngOnDestroy() {
    const allRangesBudget = document.querySelectorAll('.change-budget');
    allRangesBudget.forEach(wrap => {
      const range = wrap.querySelector('.range-budget');
      range.removeEventListener("input", () => {
      });
    });
  }
}
