import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'voiply-intro-model',
  templateUrl: './intro-model.component.html',
  styleUrls: ['./intro-model.component.scss']
})
export class IntroModelComponent implements OnInit {

  @Input() introText = '';
  @Output() introChange = new EventEmitter<string>();
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }


}
