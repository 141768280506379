import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'voiply-timeout-options',
  templateUrl: './timeout-options.component.html',
  styleUrls: ['./timeout-options.component.scss']
})
export class TimeoutOptionsComponent {


  @Input()
  timeoutOptionHelpText;
  @Input()
  timeoutOptions: { timeoutEnable: boolean, timeoutValue: number }
  @Output()
  timeoutOptionsChange = new EventEmitter<any>();



  constructor() {
  }


  timeoutEnableChange(value) {
    this.timeoutOptions.timeoutEnable = value;
    this.timeoutOptionsChange.emit(this.timeoutOptions);
  }
  timeoutValue(value) {
    this.timeoutOptions.timeoutValue = value;
    this.timeoutOptionsChange.emit(this.timeoutOptions);
  }

}
