import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'voiply-footer-top',
  templateUrl: './footer-top.component.html',
  styleUrls: ['./footer-top.component.scss']
})
export class FooterTopComponent implements OnInit {
  @Input() title: string;
  @Input() content: string;
  @Input() buttonText: string;
  @Input() logo: string;
  shareUrl: string;
  @Output()
  navigatePage = new EventEmitter();
  constructor() { }

  text=["Unlimited Calling to 50+ Countries","HD Voice","99.9999% Uptime","Voicemail to Email"]
  ngOnInit() {
    this.shareUrl = location.origin;
  }
  

}
