<div class="card">
  <div class="position-relative human-attendant-tabs">
    <mat-tab-group animationDuration="0ms" #tabs (selectedTabChange)="tabChanged($event)">
      <mat-tab *ngFor="let questionAnswer of questionsAnswers; let tabIndex = index">
        <ng-template mat-tab-label>{{ questionAnswer.tab }}
          <i class="fas fa-pencil-alt ml-2" (click)="onEditLabel(questionAnswer.tab)"
            *ngIf="selectedTabIndex===tabIndex" style="font-size: 11px;"></i>
        </ng-template>
        <div>
          <ng-container *ngIf="this.questionAnswer?.tabValue">
            <ng-container *ngTemplateOutlet="questionAnswerTemplate ;context:{questionAnswer:questionAnswer}">
            </ng-container>
          </ng-container>
        </div>
      </mat-tab>
    </mat-tab-group>

    <button class="btn btn-light position-absolute border-bottom border-left" (click)="addNewTab()"
      style="top: 0;right: 0;height: 48px;width: 48px;"> <i class="fas fa-plus"></i></button>
  </div>

</div>

<!-- task:1001-humanattendant this section is for the top most question Answers -->
<ng-template #questionAnswerTemplate let-questionAnswer="questionAnswer">
  <div class="card-body " style="background-color: #F9F9F9;">
    <div class="questions-and-answers">
      <div class="intro d-flex flex-column border bg-white">
        <div class="d-flex flex-row align-items-center justify-content-between border-bottom px-3 py-2">
          <div class="d-flex flex-row align-items-center ">
            <i class="fas fa-bookmark text-secondary mr-3"></i>
            <span class=""> {{questionAnswer.tabValue.intro.agentResponse}} </span>
          </div>
          <div>
            <button class="btn border btn-light ml-3 p-1" [matMenuTriggerFor]="labelOptions"><i
                class="d-flex fas fa-ellipsis-h "></i></button>
            <mat-menu #labelOptions="matMenu">
              <div style="width: 120px;">
                <div class="d-flex flex-column" style="color: #A1A1A1;">
                  <div class="py-1 px-2"> ACTIONS</div>
                  <button mat-menu-item class="d-flex align-items-center btn " style="color: #A1A1A1;">
                    <div style="width: 20px;height: 20px;background-color: #F9F9F9;"
                      class="mr-2 d-flex align-items-center justify-content-center"><i class="far fa-circle "></i></div>
                    <span class="font-weight-bold" style="font-size: 14px;">
                      NEW</span>
                  </button>
                  <button mat-menu-item class="d-flex align-items-center btn" style="color: #A1A1A1;"
                    (click)="onEditInto(questionAnswer.tabValue.intro.agentResponse)">
                    <div style="width: 20px;height: 20px;background-color: #F9F9F9;"
                      class="mr-2 d-flex align-items-center justify-content-center"><i class="fal fa-edit "></i></div>
                    <span class="font-weight-bold" style="font-size: 14px;">
                      EDIT</span>
                  </button>
                  <button mat-menu-item class="d-flex align-items-center btn" style="color: #A1A1A1;">
                    <div style="width: 20px;height: 20px;background-color: #F9F9F9;"
                      class="mr-2 d-flex align-items-center justify-content-center"><i class="fal fa-copy "></i></div>
                    <span class="font-weight-bold" style="font-size: 14px;">
                      COPY</span>
                  </button>
                  <button mat-menu-item class="d-flex align-items-center btn" style="color: #A1A1A1;">
                    <div style="width: 20px;height: 20px;background-color: #F9F9F9;"
                      class="mr-2 d-flex align-items-center justify-content-center"><i class="fal fa-clipboard "></i>
                    </div><span class="font-weight-bold" style="font-size: 14px;">
                      PASTE</span>
                  </button>

                </div>
                <div class="border-top mt-2"></div>
                <button mat-menu-item class="btn p-2 d-flex align-items-center">
                  <div style="width: 20px;height: 20px;"
                    class="mr-2 d-flex align-items-center bg-primary justify-content-center">
                    <i class="fal fa-times text-primary"></i>
                  </div> <span class="font-weight-bold text-secondary"> DELETE</span>
                </button>
              </div>

            </mat-menu>
          </div>
        </div>
        <div class="d-flex flex-row align-items-center px-3 pb-2">
          <div class="d-flex flex-row flex-wrap" *ngFor="let keyword of questionAnswer.tabValue.intro.keywords ">
            <div class="parallelogram mr-3 mt-2">
              <div class="parallelogram-text"> # {{keyword.toUpperCase()}}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- task:1001-humanattendant if Emptytab show, add your first callflow UI -->
      <ng-container *ngIf="questionAnswer.tabValue.questions.length===0">
        <button class="d-flex btn w-100 text-secondary align-items-center justify-content-center p-3 mt-3"
          style="background-color: #F9DED7;">
          <h5 class="font-weight-bold m-0">ADD YOUR FIRST CALL FLOW</h5>
        </button>
      </ng-container>

      <!-- task:1001-humanattendant Loop Through All the questionAnswer CallFlow -->
      <ng-container *ngFor="let questions of questionAnswer.tabValue.questions; let questionsIndex=index">

        <div class="d-flex flex-column border bg-white mt-3">
          <div class="px-3 pb-2 border-bottom">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="px-2 py-1 text-primary mr-3 bg-primary-gradient mr-3 mt-2" style="font-size: 12px;">
                  {{this.getAlphabets(questionsIndex)}}</div>
                <span class="mr-4 mt-2 text-secondary font-weight-bold d-flex" style="font-size: 14px;">
                  {{this.questions.label.toUpperCase()}}
                </span>
                <div class="d-flex flex-row flex-wrap" *ngFor="let keyword of this.questions.keywords ">
                  <div class="parallelogram mr-3 mt-2">
                    <div class="parallelogram-text"> # {{keyword.toUpperCase()}}</div>
                  </div>
                </div>
              </div>
              <div class="mt-2 d-flex align-items-center">
                <div class="ml-3 d-flex">
                  <button class="btn p-1 text-color" [disabled]="questionsIndex===0"
                    (click)="shiftHumanAttendantCallFlow(questionsIndex,'up')">
                    <i class="fas fa-long-arrow-up"></i></button>
                  <button class="btn p-1 text-color"
                    [disabled]="questionAnswer.tabValue.questions.length===questionsIndex+1"
                    (click)="shiftHumanAttendantCallFlow(questionsIndex,'down')">
                    <i class="fas fa-long-arrow-down"></i></button>
                </div>
                <button class="btn border btn-light ml-3 p-1 h-100" [matMenuTriggerFor]="labelOptions"><i
                    class="d-flex fas fa-ellipsis-h "></i></button>
                <!-- task:1001-humanattendant Menu options for followup-->
                <mat-menu #labelOptions="matMenu">
                  <div style="width: 120px;">
                    <div class="d-flex flex-column" style="color: #A1A1A1;">
                      <div class="py-1 px-2"> ACTIONS</div>
                      <button mat-menu-item class="d-flex align-items-center btn " style="color: #A1A1A1;"
                        (click)="onAddNewQuestion(questionsIndex)">
                        <div style="width: 20px;height: 20px;background-color: #F9F9F9;"
                          class="mr-2 d-flex align-items-center justify-content-center"><i class="far fa-circle "></i>
                        </div>
                        <span class="font-weight-bold" style="font-size: 14px;">
                          NEW</span>
                      </button>
                      <button mat-menu-item class="d-flex align-items-center btn" style="color: #A1A1A1;">
                        <div style="width: 20px;height: 20px;background-color: #F9F9F9;"
                          class="mr-2 d-flex align-items-center justify-content-center"><i class="fal fa-edit "></i>
                        </div>
                        <span class="font-weight-bold" style="font-size: 14px;">
                          EDIT</span>
                      </button>
                      <button mat-menu-item class="d-flex align-items-center btn" style="color: #A1A1A1;">
                        <div style="width: 20px;height: 20px;background-color: #F9F9F9;"
                          class="mr-2 d-flex align-items-center justify-content-center"><i class="fal fa-copy "></i>
                        </div>
                        <span class="font-weight-bold" style="font-size: 14px;">
                          COPY</span>
                      </button>
                      <button mat-menu-item class="d-flex align-items-center btn" style="color: #A1A1A1;">
                        <div style="width: 20px;height: 20px;background-color: #F9F9F9;"
                          class="mr-2 d-flex align-items-center justify-content-center"><i
                            class="fal fa-clipboard "></i>
                        </div><span class="font-weight-bold" style="font-size: 14px;">
                          PASTE</span>
                      </button>

                    </div>
                    <div class="border-top mt-2"></div>
                    <button mat-menu-item class="btn p-2 d-flex align-items-center"
                      (click)="onDeleteFlow(questionsIndex)">
                      <div style="width: 20px;height: 20px;"
                        class="mr-2 d-flex align-items-center bg-primary justify-content-center">
                        <i class="fal fa-times text-primary"></i>
                      </div> <span class="font-weight-bold text-secondary"> DELETE</span>
                    </button>
                  </div>

                </mat-menu>
              </div>
            </div>
          </div>

          <ng-container *ngTemplateOutlet="followUpTemplate;context:{followUp:questions.followUp}"></ng-container>

        </div>
      </ng-container>
    </div>
  </div>


</ng-template>

<!-- task:1001-humanattendant this loop is for the Recursive followups -->
<ng-template #followUpTemplate let-followUps="followUp">
  <ng-container *ngFor="let followUp of followUps let first = first ;let last = last;">
    <div class="px-4 py-3" [ngClass]="!first?'border-top ':''">
      <div class="d-flex flex-column flex-md-row align-items-md-center">

        <div class="mr-3 input-box mb-2 mb-md-0" *ngIf="followUp.input==='text'">
          TEXT
        </div>

        <div class="mr-3 input-box mb-2 mb-md-0" *ngIf="followUp.input==='date'">
          DATE
        </div>
        <div class="mr-3 input-box mb-2 mb-md-0" *ngIf="followUp.input==='phone'">
          PHONE
        </div>
        <div class="mr-3 input-box mb-2 mb-md-0" *ngIf="followUp.actionsAllowed==='select'">
          CHOICE
        </div>
        <div class="d-flex flex-column">
          <div>{{followUp.agentResponse}}</div>
          <ng-container class="mt-3" *ngIf="followUp.options?.length">
            <div class="d-flex flex-row align-items-center flex-wrap">
              <div class="d-flex flex-row flex-wrap" *ngFor="let option of followUp.options">
                <div class="parallelogram mr-3 mt-2">
                  <div class="parallelogram-text"> # {{option.optionKey?.toUpperCase()}}</div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <ng-container *ngIf="followUp.options?.length>0">
        <div class="">
          <ng-container *ngFor="let option of followUp.options; let i = index; let first = first ;let last = last;">
            <ng-container *ngIf="option.followUp">
              <div class="d-flex flex-row mt-3">
                <div [ngStyle]="{'background-color':!first? '#E3E3E3':''}">

                  <div
                    style="background-color:#909090;width: 80px;text-align: center;font-size: 12px;border-radius:8px 0 0 0;color: white;">
                    IF
                  </div>
                </div>
                <div
                  style="background-color:#E3E3E3;padding:0 12px;min-width: 80px;text-align: center;font-size: 12px;border-radius:0 0 8px 0;">
                  {{option.optionKey}}</div>
              </div>
              <div class="d-flex flex-column" style="border-left: 10px solid #e3e3e3;overflow-x: auto;">
                <ng-container *ngTemplateOutlet="followUpTemplate;context:{followUp:option.followUp}"></ng-container>
              </div>

            </ng-container>
            <div class="d-flex mt-1" *ngIf="last&&findFollowUpExist(followUp.options)">
              <div style="width:10px; height:10px;background-color: #909090;" class="mr-1"></div>
              <div style="width:80px;background-color: #E3E3E3;border-radius:0 0 8px 0;"></div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>