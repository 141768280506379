import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ENVIRONMENT, MESSAGES } from '../constants';
import { Subject, Observable, of } from 'rxjs';
import { catchError, buffer, debounceTime, concatMap } from 'rxjs/operators';
import * as Model from '@voiply/shared/model';
import { DeepMergeService } from './deep-merge.service';
import { BaseService } from './base.service';
import { UpdateOrderAction, CalculatingTotalAction, UpdateShippingOptions, CardProcessAction } from '../+state/app.actions';
import { EventType } from '@voiply/shared/model';
import { IEnvironment } from '../environment.interface';
import { SignalRService } from './signal-r.service';
import { ToastrService } from 'ngx-toastr';
import { ShippingService } from './shipping.service';


@Injectable({
  providedIn: 'root'
})
export class OrderService extends BaseService {
  @Dispatch() updateOrderState = (orderData: any) => new UpdateOrderAction(orderData);
  @Dispatch() calculatingTotal = (isCalculating: boolean) => new CalculatingTotalAction(isCalculating);
  @Dispatch() updateShippingOptions = (fetchOptions: boolean) => new UpdateShippingOptions(fetchOptions);
  @Dispatch() cardProcess = (cardProcess: { isCardProcessing: boolean }) => new CardProcessAction(cardProcess);

  constructor(private http: HttpClient,
    private mergeService: DeepMergeService,
    @Inject(ENVIRONMENT) private environment: IEnvironment,
    private toastr: ToastrService,
    private shippingService: ShippingService) {

    super();

    // Buffered queue to hold all the messages which comes 1000 miliseconds apart and then process them at once.
    this.processMessage$.pipe(buffer(this.processMessage$.pipe(debounceTime(1000))), concatMap((async (messages: Model.EventMessage[]) => {
      console.log('[Order Service] Processing messages :', messages.length);

      const finalChanges: Model.EventMessage | any = {};
      const eventNames: any = [];

      // Merge all messages into one
      messages.forEach((message: Model.EventMessage) => {
        this.mergeService.merge(finalChanges, message.changes);
        // requireMoneyCalculation = this.moneyCalculationsRequired(message);   // TODO:VINIT Handle This
      });

      for (let i = 0; i < messages.length; i++) {
        eventNames[i] = messages[i].eventName;
      }

      //TODO:Temporary added this, so that orderid that were created for the testing purpose, could be removed from db having this flag.
      if (location.origin.indexOf("dev") > -1 || location.origin.indexOf("localhost") > -1)
        finalChanges.isTestOrder = true;


      //Show total calculation loading here
      if (this.moneyCalculationsRequired(eventNames)) {
        this.calculatingTotal(true);
      }

      const orderId = messages[0].orderId;
      // Save to ComosDB
      const order = await this.saveOrderOnServerAsync(orderId, finalChanges, eventNames, messages[0].signalRUserId);
      console.log('[Order Service] Changes :', finalChanges, ', server order: ', order);
      if (finalChanges.card) {
        //Card Process is the event which is called on Pay Now
        //we make cardProcessing False if card changes are stored in cosmos to show auth login pop up
        this.cardProcess({ isCardProcessing: false });
      }

      if (order) {
        if (finalChanges)
          if (((finalChanges.checkoutDetail || {}).shippingAddress || {}).zip ||
            ((finalChanges.checkoutDetail || {}).shippingAddress || {}).state ||
            ((finalChanges.checkoutDetail || {}).shippingAddress || {}).country) {
            this.updateShippingOptions(true);
          }
        this.updateOrderState(order);
      }

      //fetch shipping options when new cart item is added.
      if (messages.filter(item => item.eventName == EventType.Survey || item.eventName == EventType.CartItemPhone).length > 0)
        this.updateShippingOptions(true);

      this.calculatingTotal(false);

      // this.saveOrderOnServerAsync(messages[0].orderId, finalChanges, messages[0].eventName).then(order => {
      //   console.log('[Order Service] Changes :', finalChanges, ', server order: ', order);
      //   this.updateOrderState(order);
      //   this.calculatingTotal(false);
      // });
      // Share with others using SignalR
      // TODO:VINIT Add SignalR Here.
    }))).subscribe();
  }

  /// Order related API

  async getOrderAsync(orderId): Promise<any> {
    const response: any = await this.http.get(`${this.environment.rootApi}/order/${orderId}`).pipe(catchError(this.handleError)).toPromise();
    if (response) {
      return response.data;
    }
    else return response;
  }

  private saveOrderOnServerAsync(orderId: string, changes: any, eventName: Model.EventType[], signalRUserId: string): Promise<any> {
   //  return this.http.post(`http://localhost:7071/api/order-new/${orderId}?systemType=${this.environment.systemType}`, { changes, orderId, eventName, signalRUserId })
    return this.http.post(`${this.environment.rootApi}/order-new/${orderId}?systemType=${this.environment.systemType}`, { changes, orderId, eventName, signalRUserId })
      .pipe(catchError((error) => {
        if (((error || {}).error || {}).isOrderDisabled) //if order is disabled
          this.toastr.warning(MESSAGES.ORDER_DISABLED_MESSAGE);
        if (error.status === 400) {
          this.toastr.warning('Something Went Wrong. Please Try Again Later');
        }
        console.error('ERROR HTTP Call', error);
        return of(null);
      })).toPromise();


  }

  postAddMorePhoneTemplate(data: Model.IAddMorePhoneTemplate): Observable<any> {
    return this.http.post(`${this.environment.rootApi}/add-more-phone`, data);
  }
  postNewFaxTemplate(data): Observable<any> {
    return this.http.post(`${this.environment.rootApi}/send-fax-mail`, data);
  }

  postExtraLineUpgradeTemplate(data: Model.IAddMorePhoneTemplate): Observable<any>{
    return this.http.post(`${this.environment.rootApi}/upgrade-extraline`, data);
  }
  // #region 'Queue Functionality'

  shareOrderViaEmail(data: any ): Observable<any>{
    return this.http.post(`${this.environment.rootApi}/share-order-email`,data);
  }

  shareOrderViaSMS(data: any ): Observable<any>{
    return this.http.post(`${this.environment.rootApi}/sms-send`,data);
  }

  private readonly _processMessage: Subject<Model.EventMessage> = new Subject<Model.EventMessage>();
  private get processMessage$(): Observable<Model.EventMessage> { return this._processMessage.asObservable(); }

  saveOrderChanges(changes: Model.EventMessage) {
    //Show total calculation loading here
    if (this.moneyCalculationsRequired([changes.eventName])) {
      this.calculatingTotal(true);
    }
    //Card Process is the event which is called on Pay Now
    //This is done to make sure card Process gets completed and then only auth Login is shown.
    if (this.cardProcessingEvent([changes.eventName])) {
      this.cardProcess({ isCardProcessing: true });
    }
    this._processMessage.next(changes);
  }

  private moneyCalculationsRequired(eventNames) {
    // See if we need money calculation to be done
    for (let i = 0; i < eventNames.length; i++) {
      if (eventNames[i] === EventType.ApplyPromoCode || eventNames[i] === EventType.Survey ||
        eventNames[i] === EventType.RemovePromoCode || eventNames[i] === EventType.PaymentDuration || eventNames[i] === EventType.CartItemPhone || eventNames[i] === EventType.CartItemApp || eventNames[i] === EventType.ProtectionPlanStatus || eventNames[i] === EventType.UpgradeToBusiness || eventNames[i] === EventType.UpgradeToHome) {
        return true;
      }
    }

  }
  private cardProcessingEvent(eventNames) {
    for (let i = 0; i < eventNames.length; i++) {
      if (eventNames[i] === EventType.Payment) {
        return true;
      }
    }
  }

  async updateOrder(orderId, type?) {
    try {
      await this.updateOrderInPhoneSystem(orderId, type);
      this.toastr.success('We\'re updating your phone system. Changes can take 3-5 minutes to appear.');
     // this.toastr.success(' Please click on Apply Changes to update your phone system');
    } catch (Error) {
      this.toastr.error('Oops! We could not update your phone system.');
    }
  }

  updateOrderInPhoneSystem(orderId, type): Promise<any> {
    if (type)
      return this.http.get(`https://online-checkout-create.azurewebsites.net/api/orchestrators/create?order_id=${orderId}&type=${type}`).toPromise();
    else
      return this.http.get(`https://online-checkout-create.azurewebsites.net/api/orchestrators/create?order_id=${orderId}`).toPromise();
  }
  // #endregion

}
