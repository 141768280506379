import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProductConfigurationBase } from '../../../container/product-configuration.base';
import { FormBase, UpdateCartItemConfigurationAction, OrderService } from '@voiply/shared/common-ui';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { phoneNumberValidator } from '@voiply/shared/common-ui';
import { CartItemType } from '@voiply/shared/model';
import * as _ from 'lodash';
import { FormControl, FormGroup } from '@angular/forms';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ToastrService } from 'ngx-toastr';
import { HomeConfigurationBase } from '../home-configuration.base';


@Component({
  selector: 'voiply-home-configuration-modal',
  templateUrl: './home-configuration-modal.component.html',
  styleUrls: ['./home-configuration-modal.component.scss']
})
export class HomeConfigurationModalComponent extends HomeConfigurationBase {

 
  constructor(public bsModalRef: BsModalRef, toastr: ToastrService, orderService: OrderService) {
    super(toastr, orderService);
  }
  updateCartItemConfiguration(key, productConfig) {
    this.bsModalRef.hide()
    this.updateCartItemConfigurationAction(key, productConfig);
    if (this.value.paid) {
      this.orderService.updateOrder(this.orderId)
    }
  }

  onClick(btnId){
    console.log(this.form)
    if(btnId=='1' && this.toggle==true){
      this.toggle=!this.toggle;
      this.form.controls[this.key].get("isVoicemailToEmailEnable").setValue(this.toggle);
      return;
    }
    if(btnId=='2' && this.toggle==false){
      this.toggle=!this.toggle;
      this.form.controls[this.key].get("isVoicemailToEmailEnable").setValue(this.toggle);
      return;
    }
  }
}
