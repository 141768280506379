import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LayoutService } from 'libs/shared/common-ui/src/lib/services';

@Component({
  selector: 'voiply-root-page',
  templateUrl: './root-page.component.html',
  styles: []
})
export class RootPageComponent {
  isShowWizard=true;
  isShowProductBusiness:boolean;
  queryParams;
  paraZipCode;
  constructor( private layoutService: LayoutService,private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
        this.queryParams = params;
      }
    );
    if(this.queryParams.da=="Online Fax"){
      this.isShowWizard=false;
    }
    this.layoutService.isClickedWizardSaveBtn.subscribe((toggle)=>{ this.isShowProductBusiness=toggle;});
    if(this.queryParams.z){
      this.paraZipCode=this.queryParams.z;
    }
    this.doAutoScroll();
  }

  doAutoScroll(){
    if(this.queryParams.checkout || this.queryParams.faq || this.queryParams.international || this.queryParams.addOn || this.queryParams.numbers){
      let className;
      if(this.queryParams.checkout){
        className="checkoutScroll";
      }
      else if(this.queryParams.faq){
        className="faqScroll";
      }
      else if(this.queryParams.international){
        className="internationalScroll"
      }
      else if(this.queryParams.addOn){
        className="addOnScroll"
      }
      else if(this.queryParams.numbers){
        className="wizardScroll"
      }
      else{
        return;
      }
      setTimeout(() => {
        const section = document.getElementsByClassName(className);
        // console.log("section =", section[0].parentElement.scrollIntoView());
        if (section !== undefined && section.length > 0)
          window.scrollTo({
            top: section[0].getBoundingClientRect().top + window.scrollY,
            left: window.pageXOffset,
            behavior: 'smooth'
          });
      }, 1000);
    }
  }
}
