import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'voiply-max-price-per-week',
  templateUrl: './max-price-per-week.component.html',
  styleUrls: ['./max-price-per-week.component.scss']
})
export class MaxPricePerWeekComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() maxPricePerCall: number;
  @Input() maxPriceHelpText: string;
  @Output() changeMaxPricePerCall = new EventEmitter<number>();
  constructor() { }

  ngOnInit() {
  }



  ngAfterViewInit() {
    const allRangesMaxPrice = document.querySelectorAll('.change-max-price-per-call');
    allRangesMaxPrice.forEach(wrap => {
      const range = wrap.querySelector('.range-max-price');
      const bubble = wrap.querySelector('.bubble-max-price');
      range.addEventListener("input", () => {
        this.setBubble(range, bubble);
      });
      this.setBubble(range, bubble, this.maxPricePerCall * 100);
    });
  }

  setBubble(range, bubble, defaultvalue?) {
    let val;
    if (defaultvalue) {
      val = defaultvalue
    } else {
      val = range.value;
    }
    const min = range.min ? range.min : 1;
    const max = range.max ? range.max : 100;
    const newVal = Number(((val - min) * 100) / (max - min));
    bubble.innerHTML = '$' + (parseInt(val, 10) / 100).toFixed(2);
    // Sorta magic numbers based on size of the native UI thumb
    bubble.style.left = `calc(${newVal}% + (${6 - newVal * 0.15}px))`;
  }

  ngOnDestroy() {
    const allRangesMaxPrice = document.querySelectorAll('.change-max-price-per-call');
    allRangesMaxPrice.forEach(wrap => {
      const range = wrap.querySelector('.range-max-price');
      range.removeEventListener("input", () => {
      });
    });
  }
}
