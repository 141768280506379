import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal/';
import { Phone } from '@voiply/shared/model';

@Component({
  selector: 'voiply-add-to-cart-confirm-modal',
  templateUrl: './add-to-cart-confirm-modal.component.html',
  styleUrls: ['./add-to-cart-confirm-modal.component.scss']
})
export class AddToCartConfirmModalComponent implements OnInit {
  @Input() title: string;
  @Input() rightButton = 'Add To Cart';
  @Input() leftButton = 'Cancel'
  @Output() rightButtonClicked = new EventEmitter<Phone>();
  @Output() leftButtonClicked = new EventEmitter<Phone>();
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  confirm(): void {
    this.rightButtonClicked.emit();
    this.bsModalRef.hide();
  }

  decline(): void {
    this.leftButtonClicked.emit();
    this.bsModalRef.hide();
  }
}
