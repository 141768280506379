import { Component, OnInit, Input } from '@angular/core';
import { FaqService } from '../../services/faq.service';
import { OnDestroyCleanup } from '@voiply/shared/common-ui';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CallFlowType } from '@voiply/shared/model';

@Component({
  selector: 'voiply-callflow-faqs',
  templateUrl: './callflow-faqs.component.html',
  styleUrls: ['./callflow-faqs.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        height: '0px',
        opacity: 0,
        overflow: 'hidden'
      })),
      state('out', style({
        height: '*',
        opacity: 1,
        overflow: 'hidden'
      })),
      transition('in => out', animate('400ms')),
      transition('out => in', animate('400ms'))
    ]),
    trigger('childAnimation', [
      state('in', style({
        transform: 'rotate(0deg)'
      })),
      state('out', style({
        transform: 'rotate(180deg)'
      })),
      transition('in => out', animate('400ms', style({ transform: 'rotate(180deg)' }))),
      transition('out => in', animate('400ms', style({ transform: 'rotate(0deg)' })))
    ]),

  ]
})
export class CallFlowFaqsComponent extends OnDestroyCleanup implements OnInit {
  @Input() faqType: CallFlowType;
  faqs: { title: string, content: string }[] = [];
  menuState = "in";
  constructor(private service: FaqService) {
    super();
  }

  ngOnInit(): void {
    switch (this.faqType) {
      case CallFlowType.RingOne:
        this.subscriptions$.add(this.service.getFaqs().subscribe(data => this.faqs = data.RingOne));
        break;
      case CallFlowType.RingGroup:
        this.subscriptions$.add(this.service.getFaqs().subscribe(data => this.faqs = data.RingGroup));
        break;
      case CallFlowType.AutoAttendant:
        this.subscriptions$.add(this.service.getFaqs().subscribe(data => this.faqs = data.AutoAttendant));
        break;
      case CallFlowType.NightTimeAttendant:
        this.subscriptions$.add(this.service.getFaqs().subscribe(data => this.faqs = data.NightTimeAttendant));
        break;
    }
  }

  toggleMenu() {
    if (this.menuState === 'in') {
      this.menuState = "out"
    }
    else {
      this.menuState = "in"
    }
  }
}