<div class="my-3" *ngIf="canshowCompanyNumber">
  <common-ui-section-header imagePath="/assets/images/dialer.png" title="Company Numbers" id="companyNumbers">
  </common-ui-section-header>
  <div class="mt-3">
    <div class="d-flex justify-content-center flex-wrap flex-lg-nowrap">
      <div class="flex-grow-1 pb-2 pr-0"
        [ngClass]="{'pb-lg-0 pr-lg-2':userIsVoiplyMember$ | async,'pb-md-0 pr-md-2':!(userIsVoiplyMember$ | async)}">
        <div class="input-group h-100">
          <input type="text" class="form-control h-100 primary-number-input" mask="(000) 000-0000"
            *ngIf="userIsVoiplyMember$ | async" [(ngModel)]="number" (focusout)="updatePrimaryNumber()">
          <input type="text" class="form-control h-100 primary-number-input" mask="(000) 000-0000"
            *ngIf="!(userIsVoiplyMember$ | async)" [(ngModel)]="number" disabled>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-center flex-nowrap">
        <div class="" *ngIf="showE911">
          <voiply-e911 [emergencyAddress]="currentEmergencyAddress"
            (updateEmergencyAddress)="onE911AddressChanged($event)"></voiply-e911>
        </div>
        <!-- <div class="pl-1 pl-sm-2">
          <button class="btn bg-primary text-primary w-100 h-100 py-3" (click)="addMorePhonesComponent()">
            <i class="fas fa-user-plus d-none d-md-block"></i>
            <h5 class="m-0 button-heading">ORDER MORE</h5>
          </button>
        </div> -->
        <div class="pl-1 pl-sm-2">
          <voiply-apply-changes *ngIf="!(isOrderDisabled$ | async)" [updatingOrder]="updatingOrder"
            [coolDownPeriodOn]="coolDownPeriodOn" (updateOrder)="updateOrderDetails()">
          </voiply-apply-changes>
        </div>
        <div class="pl-1 pl-sm-2" *ngIf="userIsVoiplyMember$ | async">
          <voiply-more-numbers (updateNumbers)="onUpdateAdditionalNumbers($event)" [showAddtoCompanyNumber]="true"
            (addToCompanyNumber)="onAddtoCompanyNumber($event)" [additionalNumbers]="additionalNumbers"
            [updatingOrder]="updatingOrder" [coolDownPeriodOn]="coolDownPeriodOn"></voiply-more-numbers>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-3" *ngIf="companyNumbers.length">
    <div class="d-flex flex-row flex-wrap" style="border-left: 10px solid #fe330a">
      <ng-container *ngFor="let companyNumber of companyNumbers">
        <div
          class="bg-white p-2 d-flex flex-column border border-left-0 justify-content-between flex-grow-1 flex-md-grow-0"
          style="min-width: 25%;">
          <div class="d-flex justify-content-between align-items-center">
            <div class="text-secondary d-flex">
              <div style="font-size: 14px;">{{companyNumber.name}}</div><i
                class="fas fa-pen text-secondary pl-2 align-self-center cursor-pointer"
                (click)="onUpdatePrimaryNumberName(companyNumber)" style="font-size: 12px;"></i>
            </div>
            <div class="d-flex">
              <button class="btn py-0 px-2 d-flex align-items-center justify-content-between ml-4" style="width: 70px;"
                [ngClass]="{'bg-primary text-primary':companyNumber.isSelectedCallFlow,'btn-outline-secondary':!companyNumber.isSelectedCallFlow}"
                (click)="onUpdateSelectedCallFlowStatus(companyNumber)">
                <img *ngIf="!companyNumber.isSelectedCallFlow" src="assets/images/call-scenarios-alt.png" alt=""
                  style="width: 18px;">
                <img *ngIf="companyNumber.isSelectedCallFlow" src="assets/images/call-scenarios.png" alt=""
                  style="width: 18px;">
                <span style="font-size: 12px;">EDIT</span></button>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex">
              <button class="btn p-0 d-flex align-items-center" *ngIf=" !companyNumber.isInternational"
                (click)="onUpdatePrimaryNumberStatus(companyNumber)"> <img *ngIf="companyNumber.isPrimaryNumber"
                  src="assets/images/crown-alt.png" alt="" style="width: 20px;">
                <img *ngIf="!companyNumber.isPrimaryNumber " popover="Click to set as Main Company Number"
                  containerClass="popover-css" triggers="mouseenter:mouseleave" src="assets/images/crown.png" alt=""
                  style="width: 20px;">
              </button>
              <span class="pl-2"
                *ngIf="companyNumber.isInternational">{{sanitizeInternationalNumber(companyNumber)}}</span>
              <span class="pl-2" *ngIf="!companyNumber.isInternational">
                {{companyNumber.number|mask:'(000)000-0000'}}</span></div>
            <div *ngIf="userIsVoiplyMember$ | async"><button class="btn p-0"
                (click)="onRemoveCompanyNumber(companyNumber)"><i
                  class="fas fa-times-circle text-secondary"></i></button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>