import { Component, OnInit, EventEmitter, Output, Input, Inject } from '@angular/core';
import { DEFAULT_GREETINGS, AudioService, IEnvironment, ENVIRONMENT } from '@voiply/shared/common-ui';
import { v4 as uuid } from 'uuid';
import { CallFlowType, CallFlow, Users, RingType, AutoAttendantType, Gender, AudioRecording, HumanAttendant, OutsideBusinessHoursType, App } from '@voiply/shared/model';
import { AudioTemplateService } from 'libs/shared/common-ui/src/lib/services/audio-template.service';
import * as _ from 'lodash';
import { DeepMergeService } from 'libs/shared/common-ui/src/lib/services';

@Component({
  selector: 'voiply-callflow-list',
  templateUrl: './callflow-list.component.html',
  styleUrls: ['./callflow-list.component.css']
})
export class CallflowListComponent implements OnInit {

  @Output() callConfigurationChanged = new EventEmitter<any>();
  @Input() apps: App[] = [];
  @Input() callFlowsList: any = [];
  @Input() users: Users[];
  @Input() callFlow: any;

  CallFlow_Type: typeof CallFlowType = CallFlowType;



  title: string = '';
  subTitle: string = 'SELECT';
  videoUniqueId: string = '';
  selected: boolean = false;
  selectedSubTitle: string = 'SKIP CONFIG'

  audioTemplates: any = null;
  constructor(
    private audioService: AudioService,
    private audioTemplateService: AudioTemplateService,
    private deepMergeService: DeepMergeService,
    @Inject(ENVIRONMENT) private environment: IEnvironment) {
    //  if (this.callFlow == undefined) {
    //     this.selected = false;
    //   }
    //   else if (this.callFlow.type != CallFlowType.None)
    //     this.selected = true;
  }

  ngOnInit() {
    this.audioService.getAudioTemplates().subscribe((value) => {
      this.audioTemplates = value;
    });
  }

  changeSelectedTab(type: CallFlowType, selected: boolean): void {
    let callFlow: CallFlow = {
      type: type,
      skipConfig: !selected,
      ringCallFlow: { ...this.getBlankRingCallFlow() },
      autoAttendantOptions: {},
      autoAttendantRingCallFlow: { ... this.callFlow?.autoAttendantRingCallFlow } || null,
      humanAttendant: { ...this.callFlow?.humanAttendant } || null
    };
    console.log('Change tab', type);
    const ringCallFlow = this.getBlankRingCallFlow();
    switch (type) {
      case CallFlowType.RingOne:
        callFlow.ringCallFlow = { ...ringCallFlow, ...this.getDefaultRingOneCallFlow(), };
        this.deepMergeService.merge(this.callFlow, callFlow);
        break;
      case CallFlowType.RingGroup:
        callFlow.ringCallFlow = { ...ringCallFlow, ...this.getDefaultRingGroupCallFlow() };
        this.deepMergeService.merge(this.callFlow, callFlow);
        break;
      case CallFlowType.AutoAttendant:
        callFlow = { ...callFlow, ...this.getDefaultAutoAttendantCallFlow(), ...this.getDefaultAutoAttendantRingCallFlow() };
        this.deepMergeService.merge(this.callFlow, callFlow);
        break;
      case CallFlowType.NightTimeAttendant:
        callFlow = {
          ...callFlow, ...this.getDefaultAutoAttendantCallFlow(),
          ringCallFlow: { ...this.getDefaultRingGroupCallFlow() }, ...this.getDefaultAutoAttendantRingCallFlow()
        };
        this.deepMergeService.merge(this.callFlow, callFlow);
        break;
      case CallFlowType.HumanAttendant:
        if (!this.callFlow.humanAttendant) {
          callFlow = {
            ...callFlow, humanAttendant: new HumanAttendant()
          }
        }
        this.deepMergeService.merge(this.callFlow, callFlow);
        break;
    }
    this.callConfigurationChanged.emit(this.callFlow);
  }
  getBlankRingCallFlow() {
    const ringCallFlow: any = {
      ringOneUser: null,
      voiceMailUser: null,
      voicemailRingTimeoutCount: null,
      ring: null,
      autoAttendantOptions: null
    };
    return ringCallFlow;
  }
  getDefaultRingOneCallFlow() {
    const userId = this.users['0'].id;
    const changes: any = {
      ringOneUser: (this.callFlow.ringCallFlow || {}).ringOneUser || userId,
      voiceMailUser: (this.callFlow.ringCallFlow || {}).voiceMailUser || userId,
      voicemailRingTimeoutCount: (this.callFlow.ringCallFlow || {}).voicemailRingTimeoutCount || 3,
      timeZone: ((this.callFlow || {}).ringCallFlow || {}).timeZone || 'est',
      callForward: ((this.callFlow || {}).ringCallFlow || {}).callForward || false,
      callForwardNumbers: ((this.callFlow || {}).ringCallFlow || {}).callForwardNumbers || [],
      opens24_7: typeof (((this.callFlow || {}).ringCallFlow || {}).opens24_7) === 'boolean' ? ((this.callFlow || {}).ringCallFlow || {}).opens24_7 : true,
      ring: {
        type: (((this.callFlow || {}).ringCallFlow || {}).ring || {}).type || RingType.Simultaneous,
        users: (((this.callFlow || {}).ringCallFlow || {}).ring || {}).users || {}
      },

      //   ringOneUser: this.masterCache.ringOneUser || userId,
      //   voiceMailUser: this.masterCache.voiceMailUser || userId,
      //   voicemailRingTimeoutCount: this.masterCache.voicemailRingTimeoutCount || 3
    };
    return changes;
  }

  getDefaultRingGroupCallFlow() {
    const userId = this.users['0'].id;
    const changes: any = {
      //   ring: {
      //     type: ((this.masterCache || {}).ring || {}).type || Models.RingType.Simultaneous,
      //     users: ((this.masterCache || {}).ring || {}).users || {}
      //   },
      //   voiceMailUser: this.masterCache.voiceMailUser || userId,
      //   voicemailRingTimeoutCount: this.masterCache.voicemailRingTimeoutCount || 3
      // };
      // if (Object.keys(changes.ring.users).length == 0) {
      //   changes.ring.users[userId] = true;
      ring: {
        type: (((this.callFlow || {}).ringCallFlow || {}).ring || {}).type || RingType.Simultaneous,
        users: (((this.callFlow || {}).ringCallFlow || {}).ring || {}).users || {}
      },
      voiceMailUser: ((this.callFlow || {}).ringCallFlow || {}).voiceMailUser || userId,
      voicemailRingTimeoutCount: ((this.callFlow || {}).ringCallFlow || {}).voicemailRingTimeoutCount || 3,
      timeZone: ((this.callFlow || {}).ringCallFlow || {}).timeZone || 'est',
      opens24_7: typeof (((this.callFlow || {}).ringCallFlow || {}).opens24_7) === 'boolean' ? ((this.callFlow || {}).ringCallFlow || {}).opens24_7 : true,
      ringOneUser: ((this.callFlow || {}).ringCallFlow || {}).ringOneUser || userId,
    };
    if (Object.keys(changes.ring.users).length == 0) {
      changes.ring.users[userId] = true;
    }
    return changes;
  }

  getDefaultAutoAttendantCallFlow() {

    const userId = this.users['0'].id;
    const changes: any = {
      autoAttendantOptions: (this.callFlow || {}).autoAttendantOptions || {},
    };
    if (Object.keys(changes.autoAttendantOptions).length > 0)
      return changes;

    // Generate file for blob.
    const blobId1 = uuid() + ".wav";
    this.audioService.generateAudioFileFromText(DEFAULT_GREETINGS.greeting, Gender.Male, blobId1);

    // Auto Attendant options
    const optionHyphen: any = {}; // new Models.AutoAttendantOption();
    optionHyphen.type = AutoAttendantType.Greeting;
    optionHyphen.recording = new AudioRecording();
    optionHyphen.recording.selectedGender = Gender.Male;
    optionHyphen.recording.uploadRecording = false;
    optionHyphen.recording.audioText = DEFAULT_GREETINGS.greeting;
    optionHyphen.recording.blobFileName = `https://onlinecheckout.blob.core.windows.net/recordings/${blobId1}`;
    changes.autoAttendantOptions["-"] = optionHyphen;

    const optionOne: any = {};
    optionOne.type = AutoAttendantType.RingOne;
    optionOne.ringOneUser = userId;
    changes.autoAttendantOptions["1"] = optionOne;

    const optionTwo: any = {};
    optionTwo.type = AutoAttendantType.RingOne;
    optionTwo.ringOneUser = userId;
    changes.autoAttendantOptions["2"] = optionTwo;

    const optionThree: any = {};
    optionThree.type = AutoAttendantType.RingOne;
    optionThree.ringOneUser = userId;
    changes.autoAttendantOptions["3"] = optionThree;

    const blobId2 = uuid() + ".wav";

    let audioText = DEFAULT_GREETINGS.announcement;


    if (this.audioTemplates)
      audioText = _.filter(this.audioTemplates, (item) => _.includes(item.category.toLowerCase().split(","), AutoAttendantType.Announcements.toLowerCase()))[0].text;
    audioText = this.audioTemplateService.replacePlaceholders(audioText, "root");
    this.audioService.generateAudioFileFromText(audioText, Gender.Male, blobId2);

    const optionFour: any = {};
    optionFour.type = AutoAttendantType.Announcements;
    optionFour.recording = new AudioRecording();
    optionFour.recording.selectedGender = Gender.Male;
    optionFour.recording.uploadRecording = false;
    optionFour.recording.audioText = audioText;
    optionFour.recording.blobFileName = `https://onlinecheckout.blob.core.windows.net/recordings/${blobId2}`;
    changes.autoAttendantOptions["4"] = optionFour;

    return changes;
  }


  getDefaultAutoAttendantRingCallFlow() {
    const changes = {
      autoAttendantRingCallFlow: {
        opens24_7: true,
        timeZone: 'est',
        businessHours: {},
        outsideBusinessHours: {
          type: OutsideBusinessHoursType.RingOne,
          ringOneUser: this.users['0'].id,
          voiceMailUser: this.users['0'].id,
          voicemailRingTimeoutCount: 4,
          ringType: RingType.Simultaneous,
          users: { [this.users[0].id]: true },
          app: this.apps[0].title,
          voicemailRecording: new AudioRecording(),
          announcementRecording: new AudioRecording()
        }
      }
    }
    const voicemailAudioText = _.filter(this.audioTemplates, (item) => _.includes(item.category.toLowerCase().split(","), OutsideBusinessHoursType.VoiceMail.toLowerCase()))[0].text;
    const voicemailblobId = uuid() + ".wav";
    changes.autoAttendantRingCallFlow.outsideBusinessHours.voicemailRecording.audioText = voicemailAudioText;
    this.audioService.generateAudioFileFromText(voicemailAudioText, Gender.Male, voicemailblobId);
    changes.autoAttendantRingCallFlow.outsideBusinessHours.voicemailRecording.blobFileName = `${this.environment.blobStoragePath}${voicemailblobId}`

    const announcementAudioText = _.filter(this.audioTemplates, (item) => _.includes(item.category.toLowerCase().split(","), OutsideBusinessHoursType.Announcements.toLowerCase()))[0].text;
    const announcementblobId = uuid() + ".wav";
    changes.autoAttendantRingCallFlow.outsideBusinessHours.announcementRecording.audioText = announcementAudioText;
    this.audioService.generateAudioFileFromText(announcementAudioText, Gender.Male, announcementblobId);
    changes.autoAttendantRingCallFlow.outsideBusinessHours.announcementRecording.blobFileName = `${this.environment.blobStoragePath}${announcementblobId}`
    return changes;
  }
}
