<div class=" d-flex mt-3">
    <common-ui-section-header imagePath="/assets/images/question-sign.png" title="Frequently Asked Questions (FAQ)" id="faq">
    </common-ui-section-header>
    <div class="flex-grow-1 d-flex justify-content-end bg-secondary">
        <a id="faq-expand-button" class=" d-flex align-items-center pr-3 pl-3  " (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="multiCollapseExample1">
            <div class="text-primary" [collapse]="isCollapsed">
                <i class="fas fa-plus text-primary"></i>
            </div>
            <div class="text-primary" [collapse]="!isCollapsed">
                <i class="fas fa-minus text-primary"></i>
            </div>
        </a>
    </div>
</div>
<div class="">
    <div class="collapse multi-collapse background-color" [collapse]="!isCollapsed" id="multiCollapseExample1">
        <div class="px-4 py-2" style="background:#ffffff46;">
            <voiply-faq-item *ngFor="let faq of faqs" [faq]="faq"></voiply-faq-item>
        </div>
    </div>
</div>