import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'voiply-label-model',
  templateUrl: './label-model.component.html',
  styleUrls: ['./label-model.component.scss']
})
export class LabelModelComponent implements OnInit {

  @Input() label;
  @Output() nameChange = new EventEmitter<string>();
  name = ""
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.name = this.label;
  }

}
