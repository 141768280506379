<!-- <voiply-header-business></voiply-header-business>
<voiply-header-survey-business></voiply-header-survey-business>
<div class="container">
    <voiply-primary-number-business></voiply-primary-number-business>
    <voiply-configure-call-flow></voiply-configure-call-flow>
    <voiply-product-business></voiply-product-business>
    <voiply-checkout-business></voiply-checkout-business>
    <voiply-speed-test></voiply-speed-test>
    <voiply-testimonial></voiply-testimonial>
    <voiply-company-features-business></voiply-company-features-business>
    <voiply-all-faqs></voiply-all-faqs>
</div>
<voiply-footer-business></voiply-footer-business>
<voiply-cookie-concent></voiply-cookie-concent> -->
 
<div class="container-xl bg-white shadow">
    <voiply-header-business></voiply-header-business>
    <div class="container bg-light-grey pt-3">

       <voiply-intro-business></voiply-intro-business>
        <voiply-wizard *ngIf="isShowWizard" [paraZipCode]="paraZipCode"></voiply-wizard>
        <voiply-product-business ></voiply-product-business> 
        <voiply-company-features-business *ngIf="isShowWizard"></voiply-company-features-business>
        <voiply-checkout-business [isShowShareOrder]="isShowWizard"></voiply-checkout-business>
        <voiply-testimonial-new></voiply-testimonial-new>
        <voiply-International-call-feature-home></voiply-International-call-feature-home>
        <voiply-all-faqs-new></voiply-all-faqs-new>
       <!--  -->
        <!-- <voiply-footer-business></voiply-footer-business> -->
        <voiply-cookie-concent></voiply-cookie-concent>
        
    </div>
    <voiply-footer-business></voiply-footer-business>
</div>