import { Component, OnInit } from '@angular/core';
import { CompanyFeatures } from '../company-features.base';
import { CompanyFeaturesService } from '../../services/company-features.service';

@Component({
  selector: 'voiply-company-features-business',
  templateUrl: './company-features-business.component.html',
  styleUrls: ['./company-features-business.component.scss']
})
export class CompanyFeaturesBusinessComponent extends CompanyFeatures {

  constructor(service: CompanyFeaturesService) {
    super(service);
  }
  onShowAllFeatures() {
    this.features.features = this.features.features.filter(f => f.title != 'Fax to Email');
    this.featuremodel.openFeatures();
  }
  //* text to display in extra line card
  extralineText = [
    {
      id: 1,
      heading: `Desktop`,
      discription: `Take business calls on your PC or Mac.`,

    },
    {
      id: 2,
      heading: `Mobile`,
      discription: `Be available when you're on the move.`,
    },
    {
      id: 3,
      heading: `SMS`,
      discription: `Send and receive business text messages.`,
    },
  ]

}
