<div class="">
  <div *ngIf="(userIsVoiplyMember$ |async)">
    <voiply-app [apps$]="apps$" [selectedCountry]="selectedCountry" (addToCart)="onAddAppToCart($event)"></voiply-app>
  </div>

  <voiply-product-configuration-business-after-checkout [productConfigurations$]="cartItems$" [extDisabled]=true
    [userIsVoiplyMember]="userIsVoiplyMember$ | async" (removeCartItem)="onRemoveCartItem($event)"
    (updateCartItemConfiguration)="onUpdateCartItemConfiguration($event)" [phones]="(phones$ | async)"
    [apps]="(apps$ | async)">
  </voiply-product-configuration-business-after-checkout>
</div>