import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CallFlow, DayOfWeek } from '@voiply/shared/model';
import * as _ from 'lodash';

@Component({
  selector: 'voiply-business-hours',
  templateUrl: './business-hours.component.html',
  styleUrls: ['./business-hours.component.css']
})
export class BusinessHoursComponent implements OnInit {

  @Output() configurationChanged = new EventEmitter<any>();
  @Input() callFlow: CallFlow;
  @Input() businessHoursHelpText: string;

  public selectedDay: number = 1;
  public selectedFromTime: string = '09:00:00';
  public selectedToTime: string = '17:00:00';
  DAY_OF_WEEK: typeof DayOfWeek = DayOfWeek;
  isEmpty = true;
  constructor() { }

  ngOnInit() {
    _.each(this.callFlow.ringCallFlow.businessHours, (value) => {
      if (value) {
        this.isEmpty = false;
        return false
      }
    });
  }

  addBusinessHour() {
    this.isEmpty = false;
    let changes = this.callFlow.ringCallFlow;
    //if (!_.find(this.callFlow.ringCallFlow.businessHours, { day: this.selectedDay })) {
    if (!changes.businessHours)
      changes.businessHours = {};
    changes.opens24_7 = false;
    changes.businessHours[this.selectedDay] = { fromHour: this.selectedFromTime, toHour: this.selectedToTime };
    this.updateConfiguration(changes);
    //}
    this.selectedDay = this.selectedDay + 1;
    if (this.selectedDay > 6)
      this.selectedDay = 0;

  }
  onOpen24_7Change(value) {
    this.callFlow.ringCallFlow.opens24_7 = value;
    this.updateConfiguration(this.callFlow.ringCallFlow);
  }
  updateConfiguration(changes) {
    this.configurationChanged.emit(changes);
  }
  isEmptyObject(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop) && obj[prop] != null) {
        return false;
      }
    }
    return true;
  }

  getObjectKeys(obj) {
    if (obj == null)
      return [];
    return Object.keys(obj);
  }

  findKeyInObject(key) {
    return this.callFlow.ringCallFlow.businessHours.hasOwnProperty(key)
  }

  deleteBusinessHour(day) {
    //const changes = _.filter(this.callFlow.ringCallFlow.businessHours, function (x) { return x.day != day; });
    const changes = this.callFlow.ringCallFlow
    const businessHours = changes.businessHours;
    businessHours[day] = null;
    this.isEmpty = true;
    _.each(changes.businessHours, (value) => {
      if (value) {
        this.isEmpty = false;
        return false
      }
    });
    if (this.isEmpty)
      changes.opens24_7 = true;

    this.updateConfiguration(changes);
  }
}
