import { v4 as uuid } from 'uuid';


export class HumanAttendant {

    businessCategory = '';
    budgetPerWeek = 15;
    maxPricePerCall = 1.75;
    questionsAnswers = [new QuestionsAnswers()];
    selectedCategory = "QA"
}

export class QuestionsAnswers {
    tab = 'Empty Tab'
    tabValue = {
        intro: {
            "id": uuid(),
            "label": "Intro",
            "agentResponse": "This is your Intro Section",
            "keywords": [
                "Intro"
            ]
        },
        questions: [
            // {
            //     "id": uuid(),
            //     "label": "Air Condition Flow",
            //     "agentResponse": "That's great, we install air conditioners in the Greater Pittsburgh area",
            //     "keywords": [
            //         "Air Conditioner",
            //         "New Ac",
            //         "Tune-up"
            //     ],
            //     "labelEnabled": true,
            //     "followUpQuestionsEnabled": true,
            //     "followUp": [
            //         {
            //             "id": uuid(),
            //             "agentResponse": "I can certainly assist you with that. I just have a few questions for you, if that's alright. May I have your first name?",
            //             "type": "firstName",
            //             "activeQuestion": true,
            //             "options": [],
            //             "input": "text",
            //             "actionsAllowed": ""
            //         },
            //         {
            //             "id": uuid(),
            //             "agentResponse": "May I have the spelling of your last name?",
            //             "type": "lastName",
            //             "activeQuestion": true,
            //             "options": []
            //         },
            //         {
            //             "id": uuid(),
            //             "agentResponse": "Is this an emergency?",
            //             "type": "choice",
            //             "activeQuestion": true,
            //             "options": [{
            //                 "text": "Yes"
            //             }, {
            //                 "text": "No"
            //             }]
            //         }
            //     ],
            //     "callTransferEnabled": true,
            //     "callTransfer": [
            //         new CallTransfer()
            //     ],
            //     "scheduleAppointmentEnabled": false
            // },
            // {
            //     "id": uuid(),
            //     "label": "Business Flow",
            //     "agentResponse": "That's great, we install air conditioners in the Greater Pittsburgh area",
            //     "keywords": [
            //         "Billing",

            //     ],
            //     "labelEnabled": true,
            //     "followUpQuestionsEnabled": true,
            //     "followUp": [
            //         {
            //             "id": uuid(),
            //             "agentResponse": "I can certainly assist you with that. I just have a few questions for you, if that's alright. May I have your first name?",
            //             "type": "firstName",
            //             "activeQuestion": true,
            //             "options": []
            //         },
            //         {
            //             "id": uuid(),
            //             "agentResponse": "May I have the spelling of your last name?",
            //             "type": "lastName",
            //             "activeQuestion": true,
            //             "options": []
            //         },
            //         {
            //             "id": uuid(),
            //             "agentResponse": "May I have the spelling of your last name?",
            //             "type": "choice",
            //             "activeQuestion": true,
            //             "options": [{
            //                 "text": "Yes"
            //             }, {
            //                 "text": "No"
            //             }]
            //         }
            //     ],
            //     "callTransferEnabled": true,
            //     "callTransfer": [
            //         new CallTransfer()
            //     ],
            //     "scheduleAppointmentEnabled": false
            // }
        ]
    }
}

export class FollowUp {
    "id" = uuid();
    "agentResponse" = "Would you like  a quote?";
    "options" = [
        {
            "optionKey": "yes"
        }
    ];
    "activeQuestion" = false;
    "type": '';
    "input": "";
    "actionsAllowed": '';
}

export class CallTransfer {
    "id" = uuid();
    "activeCallTransfer" = true;
    "name" = "Alex M.";
    "phone" = "1111111111";
    "holdText" = "HI this is a random Hold Text";
    "transferText" = "HI this is a random Transfer Text";
    "mergeText" = "HI this is a random Merge Text";
}