<!-- <div class=" mt-3" id="phonesAndApps">
  <common-ui-section-header imagePath="/assets/images/dialer.png" title="Select Phones & Apps"
    rightSideButton="USE MY VOIP PHONE" (rightSideButtonClick)="bringYourOwnDevice()">
  </common-ui-section-header>
  <div class="flex-grow-1 d-flex justify-content-end bg-secondary">
    <a class=" d-flex align-items-center pr-3 pl-3  " (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed" aria-controls="multiCollapseExample1">
      <div class="text-primary" [collapse]="isCollapsed">
        <i class="fas fa-plus text-primary"></i>
      </div>
      <div class="text-primary" [collapse]="!isCollapsed">
        <i class="fas fa-minus text-primary"></i>
      </div>
    </a>
  </div>
</div> -->
<div class="">
  <!-- <div class="collapse multi-collapse background-color" [collapse]="!isCollapsed" id="multiCollapseExample1">
    <voiply-phone [phones$]="phones$" [selectedCountry]="selectedCountry" (addToCart)="onAddPhoneToCart($event)">
    </voiply-phone>
    <voiply-app [apps$]="apps$" [selectedCountry]="selectedCountry" (addToCart)="onAddAppToCart($event)"></voiply-app>
  </div> -->
  <voiply-product-configuration-business [productConfigurations$]="cartItems$" [phones]="(phones$ | async)"
    [extDisabled]=false [apps]="(apps$ | async)" (removeCartItem)="onRemoveCartItem($event)"
    (updateCartItemConfiguration)="onUpdateCartItemConfiguration($event)">
  </voiply-product-configuration-business>
</div>

<!-- <voiply-voiply-wizard-step-second [phones]="phones" [CartItems]="cartItems$ | async"></voiply-voiply-wizard-step-second> -->