<div class="position-relative rich-text-box">
    <div #richTextBox class="form-control position-relative" style="height: 120px;overflow:auto;" contenteditable="true"
        (blur)="updateOnBlur($event.target.innerText)">

    </div>
    <button [matMenuTriggerFor]="tagMenu" class="position-absolute btn input-box" style="bottom: 0; right: 0;">
        ADD TAG
    </button>
    <mat-menu #tagMenu="matMenu">
        <button class="btn" mat-menu-item *ngFor="let tag of tags" (click)="onAddTag(tag)">{{tag}}</button>
    </mat-menu>
</div>