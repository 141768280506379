import { Component, OnInit } from '@angular/core';
import * as LogRocket from 'logrocket';
import { version } from '../../../../package.json';

@Component({
  selector: 'voiply-business-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  childComponentLoaded = false;
  public version: string = version;
  constructor() {
    LogRocket.init('uyjcld/online-checkout', ({
      console: {
        shouldAggregateConsoleErrors: true
      }
    }) as any);
    console.log('VERSION:', version);
  }
}