<!-- <div class="container d-flex my-3">
  <common-ui-section-header imagePath="/assets/images/question-sign.png" title="Frequently Asked Questions (FAQ)">
  </common-ui-section-header>

  <div class="flex-grow-1 d-flex justify-content-end bg-secondary">
    <a class=" d-flex align-items-center pr-3 pl-3  " (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed" aria-controls="multiCollapseExample1">
      <div class="text-primary" [collapse]="isCollapsed">
        <i class="fas fa-plus text-primary"></i>
      </div>
      <div class="text-primary" [collapse]="!isCollapsed">
        <i class="fas fa-minus text-primary"></i>
      </div>
    </a>
  </div>
</div>

<div class="container">
  <div class="collapse multi-collapse background-color" [collapse]="!isCollapsed" id="multiCollapseExample1">
    <div *ngFor="let faq of faqs">
      <div class="row my-3 align-items-center">
        <div class="col-auto">
          <h5 class="font-weight-bold m-0">Q. {{ faq.title }}</h5>
        </div>
        <div class="col">
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="mb-3">{{ faq.content }}</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col pt-3">
        <hr />
      </div>
      <div class="col-auto">
        <button class="btn bg-primary text-primary">All FAQ</button>
      </div>
    </div>
  </div>
</div> -->

<div class="bg-primary-gradient">
  <a (click)="toggleMenu()">
    <div class="d-flex flex-row align-items-center justify-content-between text-primary ">
      <div class="pl-4 "><i class="fas fa-chevron-down fa-lg" [@childAnimation]="menuState"></i></div>
      <div class="py-3 text-center"><span> CALL SCENARIO QUESTIONS? </span> <span class="font-weight-bold">SEE OUR FAQ</span>
      </div>
      <div class="pr-4"><i class="fas fa-chevron-down fa-lg" [@childAnimation]="menuState"></i></div>
    </div>
  </a>
</div>
<div class="" [@slideInOut]="menuState">
  <div class="px-4 py-2" style="background:#ffffff46;">
    <voiply-faq-item *ngFor="let faq of faqs" [faq]="faq"></voiply-faq-item>
  </div>
</div>
