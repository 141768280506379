import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CallFlow, Users } from '@voiply/shared/model';

@Component({
  selector: 'voiply-missedcalls-voicemail',
  templateUrl: './missedcalls-voicemail.component.html',
  styleUrls: ['./missedcalls-voicemail.component.css']
})
export class MissedcallsVoicemailComponent implements OnInit {


  @Input() users: Users[];
  //@Input() callFlow: CallFlow;
  @Input() voicemailHelpText: string;
  @Input() voiceMailUser: string;
  @Input() voicemailRingTimeoutCount: number;

  @Output() updateVoiceMailUser = new EventEmitter<any>();
  @Output() updateVoicemailRingTimeoutCount = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {

  }
  onVoiceMailSelectionChanged(userId) {
    const changes: any = userId;
    this.updateVoiceMailUser.emit(changes);
  }

  onvoicemailRingTimeoutCountChanged(ringCount) {
    const changes: any = parseInt(ringCount);
    this.updateVoicemailRingTimeoutCount.emit(changes);
  }

}
