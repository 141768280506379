<!-- <div class=""> OLD2
  <common-ui-section-header imagePath="/assets/images/dial.png" title="Popular VoIP Features">
  </common-ui-section-header>


  <div class="card w-100 mt-3">
    <div class="row h-100 align-items-center">
      <div class="col-md-3 col-lg-3 py-3">
        <div class="text-center"> <img src="/assets/images/nomorobo.png" alt="" style="height:120px;">
        </div>
      </div>
      <div class="col-md-9 col-lg-3">
        <div class="text-center text-md-left">
          <h2>Spam Call Bot</h2>
          <p class="m-0">Uses Nomorobo which has stopped over <strong>1,083,862,796</strong> robocalls.</p>
        </div>
      </div>
      <div class=" col-md-12 col-lg-6 padding">
        <ul class="fa-ul">
          <li><span class="fa-li"><i class="fas fa-circle"></i></span>Add our Spam Call app to activate.</li>
          <li><span class="fa-li"><i class="fas fa-circle"></i></span>Block additional numbers easily online.</li>
          <li><span class="fa-li"><i class="fas fa-circle"></i></span>Send spam callers to talk with our Harold bot.
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="card w-100 my-3 card-background-color">
    <div class="row h-100 align-items-center p-3">

      <div class=" col-md-12 col-lg-4 text-center order-last order-lg-first">
        <div class="">

          <div class="video-styles">
            <voiply-wistia-player [videoId]=videoId style="height:100%;left:0;top:0;width:100%;"></voiply-wistia-player>
          </div>

        </div>
      </div>
      <div class="col-md-9 col-lg-4 offset-lg-1">
        <div class="text-center text-md-left text-lg-right">
          <h2>Online Fax</h2>
          <p>Send and receive faxes online using your phone, tablet or laptop.</p>
        </div>
      </div>

      <div class="col-md-3 order-first order-lg-last  col-lg-3">
        <div class="px-3 text-lg-center text-md-left text-center pb-3">
          <img src="/assets/images/fax1.png" alt="" style="width:123px; height:120px;"></div>
      </div>

    </div>
  </div> -->
<!-- Oldest
  <div class="card w-100 mb-5 container d-flex h-100">
    <div class="row w-100 justify-content-center align-self-center">
      <div class="row w-100 align-items-center">
        <div class="col-md-6 col-lg-3 text-center py-3 "><img src="/assets/images/world.png" alt=""
            style="width:123px; height:120px;">
        </div>
        <div class="col-md-6 col-lg-3 padding">
          <div class="text-center text-md-left">
            <h3>International calls</h3>
            <h5>Enter a number to see rates.</h5>
            <voiply-international-callrates (getInternationalCallRates)="getInternationalCallRates($event)"
              [currentRate]="currentRate" [loading]="loading" [country]="country"></voiply-international-callrates>
          </div>
        </div>
        <div class=" col-md-12 col-lg-6">
          <ul class="fa-ul">
            <li><span class="fa-li"><i class="fas fa-circle"></i></span>Unlimited calling in US, Canada and 50+
              countries
              landlines.</li>
            <li><span class="fa-li"><i class="fas fa-circle"></i></span>Low international rates across the board.</li>
            <li><span class="fa-li"><i class="fas fa-circle"></i></span>International numbers available starting at
              $4.95/mo.</li>
          </ul>
        </div>
      </div>
    </div>
  </div> -->


<!-- <div id="internationalRates" class="card w-100 mb-3 card-background-color "> OLD 2 Continue
    <div class="row h-100 align-items-center">
      <div class="col-md-3 col-lg-3 py-3 ">
        <div class="text-center"> <img src="/assets/images/world.png" alt="" style="width:120px; height:120px;"></div>
      </div>
      <div class="col-md-9 col-lg-3">
        <div class="text-center text-md-left">
          <h2>International calls</h2>
          <p>Enter a number to see rates.</p>
          <div class="m-0">
            <div class="d-flex flex-column">
              <voiply-international-callrates (getInternationalCallRates)="getInternationalCallRates($event)"
                [currentRate]="currentRate" [loading]="loading" [country]="country"></voiply-international-callrates>
            </div>
          </div>
        </div>
      </div>
      <div class=" col-md-12 col-lg-6 padding">
        <ul class="fa-ul">
          <li><span class="fa-li"><i class="fas fa-circle"></i></span>Unlimited calling in US, Canada and 50+ countries
            landlines.</li>
          <li><span class="fa-li"><i class="fas fa-circle"></i></span>Low international rates across the board.</li>
          <li><span class="fa-li"><i class="fas fa-circle"></i></span>International numbers available starting at
            $4.95/mo.</li>
        </ul>
      </div>
    </div>
  </div>

  <button class="btn w-100 bg-primary text-primary py-3 button-radius" (click)="onShowAllFeatures()">
    <h4 class="m-0 font-weight-bold">See All Our Features</h4>
  </button>
  <ng-container *ngIf="features">
    <common-ui-features-model [features]="features.features"></common-ui-features-model>
  </ng-container>
</div> -->


<div style="position: relative;">
    <i class="d-none d-lg-block fal fa-long-arrow-right section-arrow d-none d-lg-block"
        style="position: absolute; left: -18px; top: 50px; font-size: 60px; color:#D3D4D5;z-index:1;"></i>
</div>
<!-- * extraline section -->
<div class="card mt-3 px-lg-4 py-5" style="border-radius: 0% !important; border: 1px solid #ececec;">
    <!-- * top section -->
    <div class="card-header border-0 bg-white">
        <!-- * left arrow -->
        <!-- <div style="position: relative;" class="d-none d-lg-block">
          <i class="fal fa-long-arrow-right section-arrow d-none d-lg-block" style="position: absolute; left: -60px; top: -10px; font-size: 60px; color:#D3D4D5"></i>
      </div> -->
        <div class="header-width">
            <div class="d-flex flex-column flex-sm-row flex-lg-column flex-xl-row justify-content-between">
                <!-- * title -->
                <div class="d-flex align-items-center heading-font">
                    <div class="font-weight-bolder h2 my-auto">
                        Work from Home, your Business
                        <br class="d-none d-md-block">or Anywhere with our Apps
                        <!-- <span class="px-2">|</span> -->
                    </div>
                    <!-- <span class="text-secondary font-weight-bolder h4 my-auto">FREE</span>
                  <span class="text-muted font-weight-bolder h4 pl-2 my-auto" style="text-decoration: line-through; opacity: 0.4;">{{5 | currency}}/mo</span> -->
                </div>
                <!-- * google play / apple app buttons with the store links  -->
                <!-- <div class="align-self-end align-self-lg-center align-self-xl-start pt-2 pt-sm-0 pt-lg-2 pt-xl-0" style="width: 280px;">
                  <span class="d-flex justify-content-lg-start justify-content-end">
                      <a href="https://apps.apple.com/us/app/voiply-online/id1555541262">
                          <img class="img-fluid pr-2"  src="assets/images/app-store/apple-app.png" alt="" style="height: 2rem;">
                      </a>
                      <a href="https://play.google.com/store/apps/details?id=com.voiply.online.android">
                          <img class="img-fluid" src="assets/images/app-store/google-play.png" alt="" style="height: 2rem;">
                      </a>
                </span>
              </div> -->
            </div>
        </div>
    </div>
    <!--section body -->
    <div class="card-body">
        <div class="rounded-lg pt-4">
            <!-- * image to display in mobile view -->
            <div class=" d-block d-lg-none mb-3">
                <div class="d-flex justify-content-center">
                    <img class="img-fluid" style="height: 300px; object-fit: contain;"
                        src="assets/images/voiply-icon.png" alt="">
                </div>
            </div>
            <!-- content -->
            <div class="row d-flex flex-column justify-content-between">
                <div class="col-lg-7">
                    <div class="d-flex flex-fill px-2 px-sm-4 pr-lg-0 pb-2"
                        *ngFor="let feature of extralineText; let i = index">

                        <div class="d-flex flex-column flex-fill">
                            <div class="d-flex flex-column flex-fill justify-content-center bg-light-grey changeWidthDiv"
                                id="last-try">
                                <div class="d-flex flex-row align-items-center" style="margin-right: 1rem;">
                                    <svg class="ml-3" style="height: 20px;" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 23.477 23.477">
                                        <path id="Select_Dropdown" data-name="Select Dropdown"
                                            d="M10.626.326c.751-.751,2.21-.122,2.438,1.05L15.547,14.16a1.138,1.138,0,0,1-1.388,1.388L1.376,13.064C.2,12.837-.425,11.377.326,10.626Z"
                                            transform="translate(0.738 11.739) rotate(-45)" fill="#f63b0b"
                                            stroke="#f63b0b" stroke-width="1" />
                                    </svg>
                                    <div class="p-2" style="color: #F63B0B;">
                                        <h4 class="mb-0" style="font-weight: bolder;">{{feature.heading}}</h4>
                                    </div>
                                </div>
                                <div class="d-flex flex-column ml-3 flex-fill">
                                    <span>{{feature.discription}}</span>
                                </div>

                            </div>
                            <!-- * seperator line -->
                            <!-- <div *ngIf="i != extralineText.length - 1" class="pt-1 mt-2" style="background-image: linear-gradient(to right, #e4e4e4, #f9f9f9);margin-left: 35px;">
                          </div> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 d-none d-lg-block">
                    <div class="position-relative">
                        <!-- * image in web view  -->
                        <div class="image-container" style="position: absolute;">
                            <img class="img-style"  src="/assets/images/voiply-icon.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Added Arrow  -->
<div style="position: relative;">
    <i class="d-none d-lg-block fal fa-long-arrow-right section-arrow d-none d-lg-block"
        style="position: absolute; left: -18px; top: 66px; font-size: 60px; color:#D3D4D5;z-index:1;"></i>
</div>
<!-- * company feature section -->
<div class=" d-flex mt-3 card px-lg-4 py-5" style="border-radius: 0% !important; border: 1px solid #ececec;"
    id="comapany-features">
    <div class="card-header bg-white border-0">
        <!-- <div style="position: relative;">
            <i class="d-none d-lg-block fal fa-long-arrow-right section-arrow d-none d-lg-block" style="position: absolute; left: -60px; top: -10px; font-size: 60px; color:#D3D4D5"></i>
        </div> -->
        <div class="d-flex justify-content-between">
            <div class="h2 font-weight-bolder heading-font">Voiply Features</div>
            <div class="btn btn-secondary font-weight-bolder border-0 h4 rounded d-none d-lg-block"
                style="background-color: #F9DED7;" (click)="onShowAllFeatures()">See All 20+ Features</div>
        </div>
    </div>
    <div class="card-body row">
        <!-- 1st -->
        <div class="col-sm-6 col-md-4 col-lg-3 mb-4">
            <div
                class="bg-light-grey text-center d-flex flex-column align-items-center justify-content-between rounded-lg h-100 p-3">
                <div class="pt-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="90%" height="60" viewBox="0 0 150 86.315">
                        <g id="Group_1039" data-name="Group 1039" transform="translate(-221.025 -2956.417)">
                            <g id="Group_771" data-name="Group 771" transform="translate(221.025 2956.417)">
                                <g id="telephone-call" transform="translate(0 2.907)">
                                    <path id="Path_1022" data-name="Path 1022"
                                        d="M2.492,77.557C.884,73.215-.28,68.78.071,64.068a11.022,11.022,0,0,1,3.437-7.409c2.31-2.18,4.49-4.472,6.763-6.689,2.956-2.919,6.67-2.9,9.627,0,1.829,1.792,3.64,3.622,5.451,5.451,1.755,1.755,3.529,3.511,5.285,5.285,3.086,3.123,3.1,6.763.018,9.867-2.217,2.217-4.416,4.453-6.67,6.633a1.356,1.356,0,0,0-.333,1.774,39.615,39.615,0,0,0,6.024,9.664A69.207,69.207,0,0,0,46.5,103.961c1.4.868,2.938,1.515,4.4,2.31a1.347,1.347,0,0,0,1.866-.351c2.2-2.273,4.453-4.508,6.707-6.744,2.956-2.919,6.652-2.938,9.608,0q5.432,5.377,10.809,10.809c3.012,3.03,2.993,6.744-.037,9.793-2.051,2.069-4.213,4.028-6.135,6.19-2.809,3.141-6.356,4.176-10.384,3.954-5.876-.314-11.29-2.273-16.519-4.8a89.888,89.888,0,0,1-29.8-23.319A82.172,82.172,0,0,1,2.492,77.557Z"
                                        transform="translate(-0.008 -47.787)" fill="#f9d1c6" />
                                </g>
                                <path id="Rectangle_17021" data-name="Rectangle 17021"
                                    d="M0,0H4.363a5,5,0,0,1,5,5V24.426a5,5,0,0,1-5,5H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                                    transform="translate(8.46 6.62) rotate(-45)" fill="#f2ad9a" />
                                <path id="Path_1023" data-name="Path 1023"
                                    d="M0,0H2.675a6.391,6.391,0,0,1,6.7,6.318V22.369a6.688,6.688,0,0,1-6.688,6.688H.01Z"
                                    transform="matrix(0.656, -0.755, 0.755, 0.656, 57.759, 55.996)" fill="#f2ad9a" />
                            </g>
                            <g id="Group_772" data-name="Group 772" transform="translate(304.098 2956.417)">
                                <g id="telephone-call-2" data-name="telephone-call" transform="translate(0 2.907)">
                                    <path id="Path_1022-2" data-name="Path 1022"
                                        d="M2.492,77.557C.884,73.215-.28,68.78.071,64.068a11.022,11.022,0,0,1,3.437-7.409c2.31-2.18,4.49-4.472,6.763-6.689,2.956-2.919,6.67-2.9,9.627,0,1.829,1.792,3.64,3.622,5.451,5.451,1.755,1.755,3.529,3.511,5.285,5.285,3.086,3.123,3.1,6.763.018,9.867-2.217,2.217-4.416,4.453-6.67,6.633a1.356,1.356,0,0,0-.333,1.774,39.615,39.615,0,0,0,6.024,9.664A69.207,69.207,0,0,0,46.5,103.961c1.4.868,2.938,1.515,4.4,2.31a1.347,1.347,0,0,0,1.866-.351c2.2-2.273,4.453-4.508,6.707-6.744,2.956-2.919,6.652-2.938,9.608,0q5.432,5.377,10.809,10.809c3.012,3.03,2.993,6.744-.037,9.793-2.051,2.069-4.213,4.028-6.135,6.19-2.809,3.141-6.356,4.176-10.384,3.954-5.876-.314-11.29-2.273-16.519-4.8a89.888,89.888,0,0,1-29.8-23.319A82.172,82.172,0,0,1,2.492,77.557Z"
                                        transform="translate(-0.008 -47.787)" fill="#f63b0b" />
                                </g>
                                <path id="Rectangle_17022" data-name="Rectangle 17022"
                                    d="M0,0H4.363a5,5,0,0,1,5,5V24.426a5,5,0,0,1-5,5H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                                    transform="translate(8.829 6.62) rotate(-45)" fill="#d93207" />
                                <path id="Path_1024" data-name="Path 1024"
                                    d="M0,0H2.675a6.391,6.391,0,0,1,6.7,6.318V22.369a6.688,6.688,0,0,1-6.688,6.688H.01Z"
                                    transform="matrix(0.656, -0.755, 0.755, 0.656, 58.129, 55.996)" fill="#d93207" />
                            </g>
                        </g>
                    </svg>

                </div>
                <span class="font-weight-bolder h5 pt-4">Ring Group</span>
                <span class="pt-2 text-muted">Allow team members to answer calls in turns</span>
            </div>
        </div>
        <!-- 2nd -->
        <div class="col-sm-6 col-md-4 col-lg-3 mb-4">
            <div
                class="bg-light-grey text-center d-flex flex-column align-items-center justify-content-between rounded-lg h-100 p-3">
                <div class="pt-4">
                    <svg xmlns="http://www.w3.org/2000/svg" height="60" viewBox="0 0 87.365 115.63">
                        <g id="Group_1040" data-name="Group 1040" transform="translate(-617.585 -2927.068)">
                            <g id="Group_82" data-name="Group 82" transform="translate(617.585 2927.068)">
                                <ellipse id="Ellipse_3" data-name="Ellipse 3" cx="25.705" cy="33.265" rx="25.705"
                                    ry="33.265" transform="translate(20.773 23.579)" fill="#fff" />
                                <g id="roboto-logo" transform="translate(0 0)">
                                    <path id="Robo.to"
                                        d="M106.132,46.16a4.161,4.161,0,1,1,4.163,4.2A4.178,4.178,0,0,1,106.132,46.16ZM93.183,10.413h-.034v5.752h5.28V10.581a5.554,5.554,0,1,0-5.245-.168ZM81.63,50.355a4.2,4.2,0,1,0-4.163-4.2A4.179,4.179,0,0,0,81.63,50.355ZM95.957,115.63a14.488,14.488,0,0,0,13.607-9.636H82.349A14.489,14.489,0,0,0,95.957,115.63ZM52.57,98.582a5.582,5.582,0,0,1,3.152-5.026V69.765a8.821,8.821,0,0,1,8.2-8.828V49.424s1.114-20.982,18.424-27.509a43.772,43.772,0,0,1,13.9-2.869,43.744,43.744,0,0,1,13.9,2.868c17.313,6.527,18.429,27.509,18.429,27.509V60.935a8.824,8.824,0,0,1,8.2,8.829V93.556a5.547,5.547,0,1,1-4.8,0v-15.9a8.707,8.707,0,0,1-3.4.936v5.528c.031.447.068.89.068,1.348,0,.484-.031.964-.068,1.443v.128h-.006a19.212,19.212,0,0,1-16.941,17.593l.008.126H80.858l.01-.126A19.21,19.21,0,0,1,63.93,87.034h-.005v-.128c-.034-.479-.068-.958-.068-1.443,0-.457.034-.9.068-1.348V78.589a8.684,8.684,0,0,1-3.408-.937V93.558a5.574,5.574,0,1,1-7.946,5.023ZM105.512,69.32a2.671,2.671,0,1,0-2.67,2.7A2.682,2.682,0,0,0,105.512,69.32Zm6.574,0a2.671,2.671,0,1,0-2.67,2.7A2.682,2.682,0,0,0,112.086,69.32Zm6.577,0a2.671,2.671,0,1,0-2.67,2.7A2.682,2.682,0,0,0,118.663,69.32ZM73.769,46.937c0,7.149,5.549,7.616,5.549,7.616,1.931.615,6.012.31,7.858,0,1.7-.289,7.935-.833,8.927-.921.038,0,.1.01.148.012s.112-.009.147-.012c.994.087,7.227.632,8.928.921,1.85.31,5.927.615,7.859,0,0,0,5.547-.466,5.547-7.616,0,0,0-7.927-5.547-12.745-5.38-4.673-13.466-5.687-16.64-5.747V28.44c-.045,0-.1,0-.148,0s-.1,0-.147,0-.1,0-.148,0-.1,0-.147,0v.005c-3.17.06-11.259,1.074-16.638,5.747C73.769,39.01,73.769,46.937,73.769,46.937Z"
                                        transform="translate(-52.57)" fill="#f63b0b" />
                                </g>
                            </g>
                        </g>
                    </svg>

                </div>
                <span class="font-weight-bolder h5 pt-4">Auto Attendant</span>
                <span class="pt-2 text-muted">Automatically transfer calls without a receptionist</span>
            </div>
        </div>

        <!-- 3rd -->
        <div class="col-sm-6 col-md-4 col-lg-3 mb-4">
            <div
                class="bg-light-grey text-center d-flex flex-column align-items-center justify-content-between rounded-lg h-100 p-3">
                <div class="pt-4">
                    <svg xmlns="http://www.w3.org/2000/svg" height="60" viewBox="0 0 117.604 110.081">
                        <g id="Group_1045" data-name="Group 1045" transform="translate(-960.761 -2932.355)">
                            <path id="Path_1723" data-name="Path 1723"
                                d="M65.2,19.573A52.369,52.369,0,0,0,22.316,41.912a25.632,25.632,0,0,1,5.73,5.3A44.717,44.717,0,0,1,59.819,27.6l5.38,9.1,5.38-9.1a44.786,44.786,0,0,1,39,39l-9.106,5.381,9.106,5.381a44.735,44.735,0,0,1-20.5,32.355,13.605,13.605,0,0,1,1.171,4.941,13.091,13.091,0,0,1-.332,3.516A52.394,52.394,0,0,0,65.2,19.573Zm13.373,86.3c-12.08-9.709-15.458-4.936-20.94.544-3.827,3.828-13.511-4.166-21.872-12.528S19.407,75.848,23.233,72.02c5.482-5.481,10.253-8.861.541-20.938s-16.18-2.806-21.489,2.5c-6.13,6.128-.323,28.964,22.246,51.537S69.94,133.5,76.066,127.371C81.375,122.061,90.653,115.59,78.573,105.877Zm-36.792-25.7c0-10.738,12.571-12.651,12.571-16.841a2.873,2.873,0,0,0-3.133-2.887c-2.767,0-4.311,3.091-4.311,3.091L41.62,60a11.218,11.218,0,0,1,10.251-6.264c4.759,0,9.925,2.726,9.925,8.909,0,9.072-11.716,10.821-11.919,14.564H62.244v6.1H42.107A17.771,17.771,0,0,1,41.781,80.177Zm23.437-8.216L76.447,54.224h8.705V70.375h3.457v5.9H85.152v7.039H78.114V76.272h-12.9V71.962Zm12.9-1.587V63.866a25.971,25.971,0,0,1,.244-3.255h-.081a15.438,15.438,0,0,1-1.424,3.011L72.46,70.293v.081Z"
                                transform="translate(960.761 2912.782)" fill="#f9d1c6" />
                            <path id="Path_1723-2" data-name="Path 1723"
                                d="M60.648,19.573A52.369,52.369,0,0,0,17.764,41.912a25.632,25.632,0,0,1,5.73,5.3A44.717,44.717,0,0,1,55.267,27.6l5.38,9.1,5.38-9.1a44.786,44.786,0,0,1,39,39L95.92,71.976l9.106,5.381a44.735,44.735,0,0,1-20.5,32.355,13.605,13.605,0,0,1,1.171,4.941,13.091,13.091,0,0,1-.332,3.516A52.394,52.394,0,0,0,60.648,19.573Zm-23.419,60.6c0-10.738,12.571-12.651,12.571-16.841a2.873,2.873,0,0,0-3.133-2.887c-2.767,0-4.311,3.091-4.311,3.091L37.068,60a11.218,11.218,0,0,1,10.251-6.264c4.759,0,9.925,2.726,9.925,8.909,0,9.072-11.716,10.821-11.919,14.564H57.692v6.1H37.555A17.771,17.771,0,0,1,37.229,80.177Zm23.437-8.216L71.895,54.224H80.6V70.375h3.457v5.9H80.6v7.039H73.562V76.272h-12.9V71.962Zm12.9-1.587V63.866a25.971,25.971,0,0,1,.244-3.255h-.081A15.438,15.438,0,0,1,72.3,63.621l-4.392,6.672v.081Z"
                                transform="translate(965.313 2912.782)" fill="#f63b0b" />
                        </g>
                    </svg>


                </div>
                <span class="font-weight-bolder h5 pt-4">Business Hours</span>
                <span class="pt-2 text-muted">Specify how calls are routed during business hours</span>
            </div>
        </div>
        <!-- 4th -->
        <div class="col-sm-6 col-md-4 col-lg-3 mb-4">
            <div
                class="bg-light-grey text-center d-flex flex-column align-items-center justify-content-between rounded-lg h-100 p-3">
                <div class="pt-4">
                    <svg xmlns="http://www.w3.org/2000/svg" height="60" viewBox="0 0 122.834 104.411">
                        <g id="Group_1046" data-name="Group 1046" transform="translate(0 0)">
                            <path id="Path_1724" data-name="Path 1724"
                                d="M148.7,80.178c11.609,0,21.021-11.431,21.021-25.532S166.626,29.114,148.7,29.114s-21.021,11.431-21.021,25.532S137.087,80.178,148.7,80.178Z"
                                transform="translate(-87.279 -29.114)" fill="#f63b0b" />
                            <path id="Path_1725" data-name="Path 1725"
                                d="M147.991,232.69c-.389-24.564-3.6-31.564-28.146-35.994,0,0-3.456,4.4-11.511,4.4s-11.51-4.4-11.51-4.4c-24.282,4.382-27.685,11.278-28.133,35.2-.036,1.953-.053,2.056-.06,1.829,0,.425,0,1.211,0,2.581,0,0,5.845,11.782,39.7,11.782s39.7-11.782,39.7-11.782c0-.88,0-1.493,0-1.909A15.441,15.441,0,0,1,147.991,232.69Z"
                                transform="translate(-46.916 -143.673)" fill="#f63b0b" />
                            <path id="Path_1726" data-name="Path 1726"
                                d="M269.483,86.491c9.429,0,17.073-9.284,17.073-20.736s-2.51-20.737-17.073-20.737a24.343,24.343,0,0,0-6.371.756c3.364,6.2,3.825,13.732,3.825,19.744A35.01,35.01,0,0,1,261.694,84.2,14.623,14.623,0,0,0,269.483,86.491Z"
                                transform="translate(-178.895 -39.986)" fill="#f9d1c6" />
                            <path id="Path_1727" data-name="Path 1727"
                                d="M316.812,210.359c-.316-19.951-2.922-25.635-22.86-29.234,0,0-2.807,3.577-9.349,3.577-.271,0-.533-.008-.791-.02a26.23,26.23,0,0,1,10.722,8.3c4.814,6.6,5.921,15.468,6.149,28.5,13.414-2.651,16.164-8.186,16.164-8.186,0-.721,0-1.216,0-1.554A11.785,11.785,0,0,1,316.812,210.359Z"
                                transform="translate(-194.014 -133.029)" fill="#f9d1c6" />
                            <path id="Path_1728" data-name="Path 1728"
                                d="M65.027,86.492a14.63,14.63,0,0,0,7.79-2.286,35.01,35.01,0,0,1-5.243-18.687c0-6.012.461-13.544,3.824-19.744a24.337,24.337,0,0,0-6.371-.756c-14.563,0-17.072,9.284-17.072,20.737S55.6,86.492,65.027,86.492Z"
                                transform="translate(-32.782 -39.987)" fill="#f9d1c6" />
                            <path id="Path_1729" data-name="Path 1729"
                                d="M33.036,184.681c-.258.012-.52.02-.791.02-6.542,0-9.349-3.577-9.349-3.577C2.958,184.723.352,190.407.036,210.358A12.1,12.1,0,0,1,0,211.737c0,.338,0,.833,0,1.554,0,0,2.749,5.535,16.163,8.186.229-13.027,1.336-21.894,6.15-28.5A26.229,26.229,0,0,1,33.036,184.681Z"
                                transform="translate(0 -133.028)" fill="#f9d1c6" />
                        </g>
                    </svg>

                </div>
                <span class="font-weight-bolder h5 pt-4">Conferece Calling</span>
                <span class="pt-2 text-muted">Easily conference in callers with HD voice</span>
            </div>
        </div>

        <div class="col-sm-6 col-md-4 col-lg-3 mb-4">
            <div
                class=" text-center d-flex flex-column align-items-center justify-content-between rounded-lg bg-light-grey h-100 p-4">
                <div class="pt-4">
                    <svg xmlns="http://www.w3.org/2000/svg" height="60" style="object-fit: contain;"
                        viewBox="0 0 130.067 76.634">
                        <g id="Group_767" data-name="Group 767" transform="translate(-205.745 -3172.439)">
                            <g id="telephone_4_" data-name="telephone (4)" transform="translate(262.745 3172.439)">
                                <g id="Group_414" data-name="Group 414" transform="translate(3.978 7.334)">
                                    <rect id="Rectangle_17019" data-name="Rectangle 17019" width="6.715" height="13.124"
                                        transform="translate(40.439 0)" fill="#c4300a" />
                                    <rect id="Rectangle_17020" data-name="Rectangle 17020" width="6.715" height="13.124"
                                        transform="translate(18.171 0)" fill="#c4300a" />
                                    <path id="Path_977" data-name="Path 977"
                                        d="M87.611,369.62H32.074a4.887,4.887,0,0,0,0,9.773H87.757a4.822,4.822,0,0,0,4.887-4.887A5.06,5.06,0,0,0,87.611,369.62Z"
                                        transform="translate(-27.187 -332.093)" fill="#f63b0b" />
                                </g>
                                <g id="Group_415" data-name="Group 415" transform="translate(0 0)">
                                    <path id="Path_978" data-name="Path 978"
                                        d="M35.744,192.564H91.428a5.218,5.218,0,0,1,4.272,2.443,97.049,97.049,0,0,0-5.34-15.859A28.885,28.885,0,0,0,64.274,162.22H63.06a28.7,28.7,0,0,0-26.233,16.927,95.665,95.665,0,0,0-5.34,15.859A4.683,4.683,0,0,1,35.744,192.564Z"
                                        transform="translate(-26.88 -147.702)" fill="#f9d1c6" />
                                    <path id="Path_979" data-name="Path 979"
                                        d="M72.171,83.757a4.532,4.532,0,0,1-3.965,2.282H60.422A4.415,4.415,0,0,1,56,82.528a13.938,13.938,0,0,1,.922-6.862c-3.511-2.751-11.587-3.819-16.474-4.272a69.073,69.073,0,0,0-7.783,0c-4.887.454-12.963,1.522-16.474,4.272,0,0,1.522,3.819.922,7.023a4.426,4.426,0,0,1-4.418,3.35H5.06A4.562,4.562,0,0,1,1.1,83.757,9.232,9.232,0,0,1,.028,78.87c0-.307.146-.761.146-1.214,0,0,.146-10.827,24.711-14.031a68.64,68.64,0,0,1,10.066-.614h2.9a66.48,66.48,0,0,1,10.066.614c24.565,3.058,24.711,14.031,24.711,14.031a3.46,3.46,0,0,1,.146,1.214A5.91,5.91,0,0,1,72.171,83.757Z"
                                        transform="translate(0 -62.992)" fill="#f63b0b" />
                                </g>
                                <g id="Group_416" data-name="Group 416" transform="translate(0.027 16.785)">
                                    <path id="Path_980" data-name="Path 980"
                                        d="M1.255,179.71a4.409,4.409,0,0,0,3.965,2.136H13a4.377,4.377,0,0,0,4.36-3.438.712.712,0,0,0,.015-.176c-.015-.19-.044-.512-.044-.512a5.649,5.649,0,0,1-.059,2.75,4.4,4.4,0,0,1-4.418,3.511H5.22a5.039,5.039,0,0,1-4.111-2.136,7.8,7.8,0,0,1-.922-3.819A16.863,16.863,0,0,0,1.255,179.71Z"
                                        transform="translate(-0.187 -177.72)" fill="#c4300a" />
                                    <path id="Path_981" data-name="Path 981"
                                        d="M397.514,180.539a4.409,4.409,0,0,1-3.965,2.136h-7.783a4.4,4.4,0,0,1-4.4-3.5v-.015c0-.146.029-.351.029-.644v.044a5.591,5.591,0,0,0,.1,2.736,4.564,4.564,0,0,0,4.418,3.511H393.7a4.781,4.781,0,0,0,3.965-2.136,6.578,6.578,0,0,0,.863-3.95C398.084,179.486,397.821,180.071,397.514,180.539Z"
                                        transform="translate(-325.517 -178.403)" fill="#c4300a" />
                                </g>
                                <ellipse id="Ellipse_31" data-name="Ellipse 31" cx="12.655" cy="10.519" rx="12.655"
                                    ry="10.519" transform="translate(23.978 19.697)" fill="#f2f2f2" />
                                <ellipse id="Ellipse_32" data-name="Ellipse 32" cx="5.033" cy="4.272" rx="5.033"
                                    ry="4.272" transform="translate(31.6 25.944)" fill="#f63b0b" />
                                <g id="Group_417" data-name="Group 417" transform="translate(25.338 20.75)">
                                    <ellipse id="Ellipse_33" data-name="Ellipse 33" cx="1.99" cy="1.683" rx="1.99"
                                        ry="1.683" transform="translate(9.305 0)" fill="#f63b0b" />
                                    <ellipse id="Ellipse_34" data-name="Ellipse 34" cx="1.99" cy="1.683" rx="1.99"
                                        ry="1.683" transform="translate(9.305 15.713)" fill="#f63b0b" />
                                    <path id="Path_982" data-name="Path 982"
                                        d="M286.308,223.313a2.282,2.282,0,0,1-2.75,0,1.426,1.426,0,0,1,0-2.282,2.282,2.282,0,0,1,2.75,0A1.426,1.426,0,0,1,286.308,223.313Z"
                                        transform="translate(-266.923 -218.266)" fill="#f63b0b" />
                                    <path id="Path_983" data-name="Path 983"
                                        d="M195.608,299.445a2.282,2.282,0,0,1-2.751,0,1.426,1.426,0,0,1,0-2.282,2.3,2.3,0,0,1,2.751,0A1.426,1.426,0,0,1,195.608,299.445Z"
                                        transform="translate(-189.493 -283.264)" fill="#f63b0b" />
                                    <ellipse id="Ellipse_35" data-name="Ellipse 35" cx="1.99" cy="1.683" rx="1.99"
                                        ry="1.683" transform="translate(18.61 7.783)" fill="#f63b0b" />
                                    <ellipse id="Ellipse_36" data-name="Ellipse 36" cx="1.99" cy="1.683" rx="1.99"
                                        ry="1.683" transform="translate(0 7.783)" fill="#f63b0b" />
                                    <path id="Path_984" data-name="Path 984"
                                        d="M282.558,299.445a1.426,1.426,0,0,1,0-2.282,2.3,2.3,0,0,1,2.751,0,1.426,1.426,0,0,1,0,2.282A1.941,1.941,0,0,1,282.558,299.445Z"
                                        transform="translate(-266.069 -283.264)" fill="#f63b0b" />
                                    <path id="Path_985" data-name="Path 985"
                                        d="M191.758,223.313a1.426,1.426,0,0,1,0-2.282,2.282,2.282,0,0,1,2.751,0,1.426,1.426,0,0,1,0,2.282A1.94,1.94,0,0,1,191.758,223.313Z"
                                        transform="translate(-188.553 -218.266)" fill="#f63b0b" />
                                </g>
                            </g>
                            <g id="telephone_4_2" data-name="telephone (4)" transform="translate(205.745 3194.439)">
                                <g id="Group_414-2" data-name="Group 414" transform="translate(3.978 7.334)">
                                    <rect id="Rectangle_17019-2" data-name="Rectangle 17019" width="6.715"
                                        height="13.124" transform="translate(40.439 0)" fill="#c4300a" />
                                    <rect id="Rectangle_17020-2" data-name="Rectangle 17020" width="6.715"
                                        height="13.124" transform="translate(18.171 0)" fill="#c4300a" />
                                    <path id="Path_977-2" data-name="Path 977"
                                        d="M87.611,369.62H32.074a4.887,4.887,0,0,0,0,9.773H87.757a4.822,4.822,0,0,0,4.887-4.887A5.06,5.06,0,0,0,87.611,369.62Z"
                                        transform="translate(-27.187 -332.093)" fill="#f63b0b" />
                                </g>
                                <g id="Group_415-2" data-name="Group 415" transform="translate(0 0)">
                                    <path id="Path_978-2" data-name="Path 978"
                                        d="M35.744,192.564H91.428a5.218,5.218,0,0,1,4.272,2.443,97.049,97.049,0,0,0-5.34-15.859A28.885,28.885,0,0,0,64.274,162.22H63.06a28.7,28.7,0,0,0-26.233,16.927,95.665,95.665,0,0,0-5.34,15.859A4.683,4.683,0,0,1,35.744,192.564Z"
                                        transform="translate(-26.88 -147.702)" fill="#f9d1c6" />
                                    <path id="Path_979-2" data-name="Path 979"
                                        d="M72.171,83.757a4.532,4.532,0,0,1-3.965,2.282H60.422A4.415,4.415,0,0,1,56,82.528a13.938,13.938,0,0,1,.922-6.862c-3.511-2.751-11.587-3.819-16.474-4.272a69.073,69.073,0,0,0-7.783,0c-4.887.454-12.963,1.522-16.474,4.272,0,0,1.522,3.819.922,7.023a4.426,4.426,0,0,1-4.418,3.35H5.06A4.562,4.562,0,0,1,1.1,83.757,9.232,9.232,0,0,1,.028,78.87c0-.307.146-.761.146-1.214,0,0,.146-10.827,24.711-14.031a68.64,68.64,0,0,1,10.066-.614h2.9a66.48,66.48,0,0,1,10.066.614c24.565,3.058,24.711,14.031,24.711,14.031a3.46,3.46,0,0,1,.146,1.214A5.91,5.91,0,0,1,72.171,83.757Z"
                                        transform="translate(0 -62.992)" fill="#f63b0b" />
                                </g>
                                <g id="Group_416-2" data-name="Group 416" transform="translate(0.027 16.785)">
                                    <path id="Path_980-2" data-name="Path 980"
                                        d="M1.255,179.71a4.409,4.409,0,0,0,3.965,2.136H13a4.377,4.377,0,0,0,4.36-3.438.712.712,0,0,0,.015-.176c-.015-.19-.044-.512-.044-.512a5.649,5.649,0,0,1-.059,2.75,4.4,4.4,0,0,1-4.418,3.511H5.22a5.039,5.039,0,0,1-4.111-2.136,7.8,7.8,0,0,1-.922-3.819A16.863,16.863,0,0,0,1.255,179.71Z"
                                        transform="translate(-0.187 -177.72)" fill="#c4300a" />
                                    <path id="Path_981-2" data-name="Path 981"
                                        d="M397.514,180.539a4.409,4.409,0,0,1-3.965,2.136h-7.783a4.4,4.4,0,0,1-4.4-3.5v-.015c0-.146.029-.351.029-.644v.044a5.591,5.591,0,0,0,.1,2.736,4.564,4.564,0,0,0,4.418,3.511H393.7a4.781,4.781,0,0,0,3.965-2.136,6.578,6.578,0,0,0,.863-3.95C398.084,179.486,397.821,180.071,397.514,180.539Z"
                                        transform="translate(-325.517 -178.403)" fill="#c4300a" />
                                </g>
                                <ellipse id="Ellipse_31-2" data-name="Ellipse 31" cx="12.655" cy="10.519" rx="12.655"
                                    ry="10.519" transform="translate(23.978 19.697)" fill="#f2f2f2" />
                                <ellipse id="Ellipse_32-2" data-name="Ellipse 32" cx="5.033" cy="4.272" rx="5.033"
                                    ry="4.272" transform="translate(31.6 25.944)" fill="#f63b0b" />
                                <g id="Group_417-2" data-name="Group 417" transform="translate(25.338 20.75)">
                                    <ellipse id="Ellipse_33-2" data-name="Ellipse 33" cx="1.99" cy="1.683" rx="1.99"
                                        ry="1.683" transform="translate(9.305 0)" fill="#f63b0b" />
                                    <ellipse id="Ellipse_34-2" data-name="Ellipse 34" cx="1.99" cy="1.683" rx="1.99"
                                        ry="1.683" transform="translate(9.305 15.713)" fill="#f63b0b" />
                                    <path id="Path_982-2" data-name="Path 982"
                                        d="M286.308,223.313a2.282,2.282,0,0,1-2.75,0,1.426,1.426,0,0,1,0-2.282,2.282,2.282,0,0,1,2.75,0A1.426,1.426,0,0,1,286.308,223.313Z"
                                        transform="translate(-266.923 -218.266)" fill="#f63b0b" />
                                    <path id="Path_983-2" data-name="Path 983"
                                        d="M195.608,299.445a2.282,2.282,0,0,1-2.751,0,1.426,1.426,0,0,1,0-2.282,2.3,2.3,0,0,1,2.751,0A1.426,1.426,0,0,1,195.608,299.445Z"
                                        transform="translate(-189.493 -283.264)" fill="#f63b0b" />
                                    <ellipse id="Ellipse_35-2" data-name="Ellipse 35" cx="1.99" cy="1.683" rx="1.99"
                                        ry="1.683" transform="translate(18.61 7.783)" fill="#f63b0b" />
                                    <ellipse id="Ellipse_36-2" data-name="Ellipse 36" cx="1.99" cy="1.683" rx="1.99"
                                        ry="1.683" transform="translate(0 7.783)" fill="#f63b0b" />
                                    <path id="Path_984-2" data-name="Path 984"
                                        d="M282.558,299.445a1.426,1.426,0,0,1,0-2.282,2.3,2.3,0,0,1,2.751,0,1.426,1.426,0,0,1,0,2.282A1.941,1.941,0,0,1,282.558,299.445Z"
                                        transform="translate(-266.069 -283.264)" fill="#f63b0b" />
                                    <path id="Path_985-2" data-name="Path 985"
                                        d="M191.758,223.313a1.426,1.426,0,0,1,0-2.282,2.282,2.282,0,0,1,2.751,0,1.426,1.426,0,0,1,0,2.282A1.94,1.94,0,0,1,191.758,223.313Z"
                                        transform="translate(-188.553 -218.266)" fill="#f63b0b" />
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
                <span class="font-weight-bolder h5 pt-4">Simultaneous Ring</span>
                <span class="pt-2 text-muted">Ring on multiple devices at the same time.</span>
            </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3 mb-4">
            <div
                class="bg-light-grey text-center d-flex flex-column align-items-center justify-content-between rounded-lg h-100 p-4">
                <div class="pt-4">
                    <svg xmlns="http://www.w3.org/2000/svg" height="60" style="object-fit: contain;"
                        viewBox="0 0 94.956 86.37">
                        <g id="voicemail" transform="translate(0 1.582)">
                            <path id="Path_986" data-name="Path 986" d="M429.645,197.789l31.21,29.058V169.38Zm0,0"
                                transform="translate(-365.899 -145.596)" fill="#f9d1c6" />
                            <path id="Path_987" data-name="Path 987" d="M531.648,153.222l12.187-11.093H531.648Zm0,0"
                                transform="translate(-452.768 -122.389)" fill="#f63b0b" />
                            <path id="Path_988" data-name="Path 988" d="M38.4,153.222V142.129H26.211Zm0,0"
                                transform="translate(-22.322 -122.389)" fill="#f63b0b" />
                            <path id="Path_989" data-name="Path 989" d="M31.216,197.793,0,169.379v57.465Zm0,0"
                                transform="translate(0 -145.596)" fill="#f9d1c6" />
                            <path id="Path_990" data-name="Path 990"
                                d="M85,386.387l-10.22,9.3a2.8,2.8,0,0,1-3.774,0l-10.214-9.3-30.95,28.8h86.1Zm0,0"
                                transform="translate(-25.412 -330.406)" fill="#f9d1c6" />
                            <path id="Path_991" data-name="Path 991"
                                d="M217.164,154.562a3.644,3.644,0,1,0,3.643-3.644A3.647,3.647,0,0,0,217.164,154.562Zm0,0"
                                transform="translate(-184.944 -129.874)" fill="#f63b0b" />
                            <path id="Path_992" data-name="Path 992"
                                d="M368.141,150.918a3.643,3.643,0,1,0,3.643,3.644A3.648,3.648,0,0,0,368.141,150.918Zm0,0"
                                transform="translate(-310.416 -129.874)" fill="#f63b0b" />
                            <path id="Path_993" data-name="Path 993"
                                d="M197.731,1.6a3.187,3.187,0,0,0-3.184-3.183H149.324A3.187,3.187,0,0,0,146.14,1.6V35.937l25.8,23.48,25.8-23.48ZM182.182,33.94a9.249,9.249,0,0,1-8.5-12.9h-4.856a9.25,9.25,0,1,1-8.5-5.607h21.861a9.251,9.251,0,0,1,0,18.5Zm0,0"
                                transform="translate(-124.457 0)" fill="#f63b0b" />
                        </g>
                    </svg>
                </div>
                <span class="font-weight-bolder h5 pt-4">Voicemail to Email</span>
                <span class="pt-2 text-muted">Send voicemail to your email account.</span>
            </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3 mb-4">
            <div
                class="bg-light-grey text-center d-flex flex-column align-items-center justify-content-between rounded-lg h-100 p-4">
                <div class="pt-4">
                    <svg id="face-scan" xmlns="http://www.w3.org/2000/svg" height="60" style="object-fit: contain;"
                        viewBox="0 0 86.315 86.315">
                        <path id="Path_994" data-name="Path 994"
                            d="M180.575,230.948a13.674,13.674,0,0,1-11.153,5.765h-14.2v14a16.99,16.99,0,1,0,33.979,0V236.479A13.708,13.708,0,0,1,180.575,230.948Z"
                            transform="translate(-129.052 -192.014)" fill="#f63b0b" />
                        <path id="Path_995" data-name="Path 995"
                            d="M164.257,98.767V93.4a12.963,12.963,0,0,0-12.963-12.963H139.675a10.618,10.618,0,0,1-9.123-5.185,12.963,12.963,0,0,0-12.963,12.963V98.767a5.165,5.165,0,0,0,1.306,3.439h19.239a8.634,8.634,0,0,0,8.625-8.625h5.058a8.635,8.635,0,0,0,8.625,8.625h2.509a5.165,5.165,0,0,0,1.306-3.439Z"
                            transform="translate(-97.765 -62.564)" fill="#f63b0b" />
                        <path id="Path_996" data-name="Path 996"
                            d="M386.779,250.147v13.745h3.048a5.577,5.577,0,0,0,5.577-5.576v-2.591a5.577,5.577,0,0,0-5.577-5.577Z"
                            transform="translate(-321.574 -207.976)" fill="#f63b0b" />
                        <path id="Path_997" data-name="Path 997"
                            d="M82.685,263.892V250.147H79.637a5.577,5.577,0,0,0-5.577,5.577v2.591a5.577,5.577,0,0,0,5.577,5.577h3.048Z"
                            transform="translate(-61.575 -207.976)" fill="#f63b0b" />
                        <path id="Path_998" data-name="Path 998" d="M5.058,5.058H22.843V0H0V22.843H5.058Z"
                            fill="#f9d1c6" />
                        <path id="Path_999" data-name="Path 999" d="M60.25,60.25h5.057v5.057H60.25Z"
                            transform="translate(-50.093 -50.093)" fill="#f9d1c6" />
                        <path id="Path_1000" data-name="Path 1000" d="M376.5,0V5.058h17.786V22.843h5.057V0Z"
                            transform="translate(-313.028)" fill="#f9d1c6" />
                        <path id="Path_1001" data-name="Path 1001" d="M421.75,60.25h5.057v5.057H421.75Z"
                            transform="translate(-350.65 -50.093)" fill="#f9d1c6" />
                        <path id="Path_1002" data-name="Path 1002" d="M5.058,376.5H0v22.843H22.843v-5.057H5.058Z"
                            transform="translate(0 -313.028)" fill="#f9d1c6" />
                        <path id="Path_1003" data-name="Path 1003" d="M60.25,421.75h5.057v5.057H60.25Z"
                            transform="translate(-50.093 -350.65)" fill="#f9d1c6" />
                        <path id="Path_1004" data-name="Path 1004" d="M394.286,394.286H376.5v5.057h22.843V376.5h-5.057Z"
                            transform="translate(-313.028 -313.028)" fill="#f9d1c6" />
                        <path id="Path_1005" data-name="Path 1005" d="M421.75,421.75h5.057v5.057H421.75Z"
                            transform="translate(-350.65 -350.65)" fill="#f9d1c6" />
                    </svg>
                </div>
                <span class="font-weight-bolder h5 pt-4">Caller Name</span>
                <span class="pt-2 text-muted">Show the full caller id when ringing.</span>
            </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3 mb-4">
            <div
                class="bg-light-grey text-center d-flex flex-column align-items-center justify-content-between rounded-lg h-100 p-4">
                <div class="pt-4">
                    <svg xmlns="http://www.w3.org/2000/svg" height="60" style="object-fit: contain;"
                        viewBox="0 0 87.609 86.315">
                        <g id="emergency-call" transform="translate(0 -1.333)">
                            <g id="Group_418" data-name="Group 418" transform="translate(47.092 65.409)">
                                <path id="Path_1006" data-name="Path 1006"
                                    d="M284.847,403.988a14.48,14.48,0,0,1-4.523-.725,13.99,13.99,0,0,1-4.7-2.573,1.3,1.3,0,0,1,1.748-1.932c.238.213,5.772,5.028,13.522,1.095,3.116-1.581,4.317-4.664,5.589-7.927,2.221-5.7,4.739-12.158,18.2-9.3a1.3,1.3,0,1,1-.541,2.548c-7.689-1.631-10.277.289-11.128.919-2.055,1.524-3.05,4.078-4.1,6.781-1.328,3.409-2.834,7.273-6.837,9.3A15.842,15.842,0,0,1,284.847,403.988Z"
                                    transform="translate(-275.2 -381.925)" fill="#f9d1c6" />
                            </g>
                            <path id="Path_1007" data-name="Path 1007"
                                d="M242.668,56.3h-22.8a1.616,1.616,0,0,1-1.616-1.616v-22.8A24.412,24.412,0,1,1,242.668,56.3Z"
                                transform="translate(-180.908 -6.14)" fill="#f63b0b" />
                            <path id="Path_1008" data-name="Path 1008"
                                d="M337.37,7.473q-1.231,0-2.431.12a24.413,24.413,0,0,1-2.431,48.7h4.862a24.413,24.413,0,0,0,0-48.825Z"
                                transform="translate(-275.61 -6.14)" fill="#de370d" />
                            <path id="Path_1013" data-name="Path 1013"
                                d="M314.439,89.7h-5.329a.636.636,0,0,1-.636-.636V83.732a1.69,1.69,0,0,0-1.69-1.69h-4.612a1.69,1.69,0,0,0-1.69,1.69v5.329a.636.636,0,0,1-.636.636h-5.329a1.69,1.69,0,0,0-1.69,1.69V96a1.69,1.69,0,0,0,1.69,1.69h5.329a.636.636,0,0,1,.636.636v5.329a1.69,1.69,0,0,0,1.69,1.69h4.612a1.69,1.69,0,0,0,1.69-1.69V98.327a.636.636,0,0,1,.636-.636h5.329A1.69,1.69,0,0,0,316.13,96V91.388a1.69,1.69,0,0,0-1.69-1.69Z"
                                transform="translate(-242.717 -67.949)" fill="#f6f1f1" />
                            <g id="Group_423" data-name="Group 423" transform="translate(60.894 14.093)">
                                <path id="Path_1014" data-name="Path 1014"
                                    d="M357.551,83.732v5.329a.636.636,0,0,0,.636.636h3.172a.636.636,0,0,1-.636-.636V83.732a1.69,1.69,0,0,0-1.691-1.69H355.86A1.69,1.69,0,0,1,357.551,83.732Z"
                                    transform="translate(-355.86 -82.042)" fill="#eadfda" />
                                <path id="Path_1015" data-name="Path 1015"
                                    d="M357.551,174.131v5.329a1.69,1.69,0,0,1-1.69,1.69h3.172a1.69,1.69,0,0,0,1.691-1.69v-5.329a.636.636,0,0,1,.636-.636h-3.172A.636.636,0,0,0,357.551,174.131Z"
                                    transform="translate(-355.86 -157.846)" fill="#eadfda" />
                                <path id="Path_1016" data-name="Path 1016"
                                    d="M403.772,126.781H400.6a1.69,1.69,0,0,1,1.69,1.69v4.613a1.69,1.69,0,0,1-1.69,1.69h3.172a1.691,1.691,0,0,0,1.691-1.69v-4.613A1.691,1.691,0,0,0,403.772,126.781Z"
                                    transform="translate(-392.944 -119.125)" fill="#eadfda" />
                            </g>
                            <path id="Path_1017" data-name="Path 1017"
                                d="M41.959,151.766l-4.924,3.165a5.585,5.585,0,0,1-6.116-.025A30.471,30.471,0,0,1,17.225,133.6a5.585,5.585,0,0,1,2.517-5.573l4.925-3.165c-.822-.62,1.056-2.685.312-3.352a29.635,29.635,0,0,1-8.838-13.777c-.271-.9-3.163-.409-3.356-1.359l-3.678,2.364a19.771,19.771,0,0,0-7.67,24.027A118.45,118.45,0,0,0,25.12,169.612a19.77,19.77,0,0,0,25.042,3l3.678-2.364c-1.187-.9.81-3.43-.214-4.423a29.443,29.443,0,0,1-7.784-12.6C45.535,152.241,42.172,152.812,41.959,151.766Z"
                                transform="translate(0 -88.116)" fill="#f9d1c6" />
                            <path id="Path_1018" data-name="Path 1018"
                                d="M85.81,234.73a5.585,5.585,0,0,0-2.517,5.573A30.471,30.471,0,0,0,96.986,261.61a5.585,5.585,0,0,0,6.116.025l2.334-1.5a5.569,5.569,0,0,1-5.535-.348,30.47,30.47,0,0,1-13.694-21.306,5.585,5.585,0,0,1,2.517-5.573Z"
                                transform="translate(-68.982 -192.999)" fill="#f2ad9a" />
                            <path id="Path_1019" data-name="Path 1019"
                                d="M83.164,96.9l6.786,10.558a4.713,4.713,0,0,1-1.417,6.513L86.6,115.21A30.105,30.105,0,0,1,74.716,96.722l1.935-1.244A4.713,4.713,0,0,1,83.164,96.9Z"
                                transform="translate(-61.931 -78.465)" fill="#f2ad9a" />
                            <path id="Path_1020" data-name="Path 1020"
                                d="M253.647,362.156l6.786,10.558a4.713,4.713,0,0,1-1.417,6.513l-1.935,1.244A30.105,30.105,0,0,1,245.2,361.983l1.935-1.244A4.713,4.713,0,0,1,253.647,362.156Z"
                                transform="translate(-203.241 -298.336)" fill="#f2ad9a" />
                            <g id="Group_424" data-name="Group 424" transform="translate(49.106 80.891)">
                                <path id="Path_1021" data-name="Path 1021"
                                    d="M253.908,380.779l-3.755,2.413c.356-.2.709-.405,1.056-.628l5.613-3.607S256.1,379.372,253.908,380.779Z"
                                    transform="translate(-250.153 -378.957)" fill="#eadfda" />
                            </g>
                        </g>
                    </svg>
                </div>
                <span class="font-weight-bolder h5 pt-4">Enhanced 911</span>
                <span class="pt-2 text-muted">Dial 911 to speak with an emergency dispatcher.</span>
            </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3 mb-4 d-block d-lg-none">
            <div
                class="bg-light-grey text-center d-flex flex-column align-items-center justify-content-between rounded-lg h-100 p-4">
                <div class="pt-4">
                    <svg xmlns="http://www.w3.org/2000/svg" height="60" style="object-fit: contain;"
                        viewBox="0 0 126.561 63.602">
                        <g id="Group_425" data-name="Group 425" transform="translate(-1342.276 -3061.186)">
                            <g id="telephone-call" transform="translate(2227.268 2835.572)">
                                <path id="Path_1022" data-name="Path 1022"
                                    d="M1.865,70.044A23.762,23.762,0,0,1,.055,59.96a8.241,8.241,0,0,1,2.569-5.54c1.727-1.63,3.357-3.343,5.056-5a4.784,4.784,0,0,1,7.2,0c1.368,1.34,2.721,2.708,4.075,4.075,1.312,1.312,2.639,2.625,3.951,3.951,2.307,2.335,2.321,5.056.014,7.377-1.658,1.658-3.3,3.329-4.987,4.959a1.014,1.014,0,0,0-.249,1.326,29.617,29.617,0,0,0,4.5,7.225A51.742,51.742,0,0,0,34.771,89.785c1.05.649,2.2,1.133,3.288,1.727a1.007,1.007,0,0,0,1.4-.262c1.644-1.7,3.329-3.371,5.015-5.042a4.749,4.749,0,0,1,7.184,0q4.061,4.02,8.081,8.081c2.252,2.266,2.238,5.042-.028,7.322-1.533,1.547-3.15,3.012-4.586,4.628a9.1,9.1,0,0,1-7.764,2.956,32.257,32.257,0,0,1-12.35-3.592A67.2,67.2,0,0,1,12.723,88.169,61.435,61.435,0,0,1,1.865,70.044Z"
                                    transform="translate(-885 180)" fill="#f9d1c6" />
                            </g>
                            <path id="Rectangle_17021" data-name="Rectangle 17021"
                                d="M0,0H2A5,5,0,0,1,7,5V17a5,5,0,0,1-5,5H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                                transform="translate(1348.601 3066.136) rotate(-45)" fill="#f2ad9a" />
                            <path id="Path_1023" data-name="Path 1023"
                                d="M-.008.276h2A4.778,4.778,0,0,1,7,5V17a5,5,0,0,1-5,5H0Z"
                                transform="matrix(0.656, -0.755, 0.755, 0.656, 1385.255, 3102.864)" fill="#f2ad9a" />
                        </g>
                        <g id="Group_426" data-name="Group 426" transform="translate(-1354.891 -3061.186)">
                            <g id="telephone-call-2" data-name="telephone-call"
                                transform="translate(2301.991 2835.572)">
                                <path id="Path_1022-2" data-name="Path 1022"
                                    d="M1.865,70.044A23.762,23.762,0,0,1,.055,59.96a8.241,8.241,0,0,1,2.569-5.54c1.727-1.63,3.357-3.343,5.056-5a4.784,4.784,0,0,1,7.2,0c1.368,1.34,2.721,2.708,4.075,4.075,1.312,1.312,2.639,2.625,3.951,3.951,2.307,2.335,2.321,5.056.014,7.377-1.658,1.658-3.3,3.329-4.987,4.959a1.014,1.014,0,0,0-.249,1.326,29.617,29.617,0,0,0,4.5,7.225A51.742,51.742,0,0,0,34.771,89.785c1.05.649,2.2,1.133,3.288,1.727a1.007,1.007,0,0,0,1.4-.262c1.644-1.7,3.329-3.371,5.015-5.042a4.749,4.749,0,0,1,7.184,0q4.061,4.02,8.081,8.081c2.252,2.266,2.238,5.042-.028,7.322-1.533,1.547-3.15,3.012-4.586,4.628a9.1,9.1,0,0,1-7.764,2.956,32.257,32.257,0,0,1-12.35-3.592A67.2,67.2,0,0,1,12.723,88.169,61.435,61.435,0,0,1,1.865,70.044Z"
                                    transform="translate(-885 180)" fill="#f63b0b" />
                            </g>
                            <path id="Rectangle_17022" data-name="Rectangle 17022"
                                d="M0,0H2A5,5,0,0,1,7,5V17a5,5,0,0,1-5,5H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                                transform="translate(1423.601 3066.136) rotate(-45)" fill="#d93207" />
                            <path id="Path_1024" data-name="Path 1024"
                                d="M-.008.276h2A4.778,4.778,0,0,1,7,5V17a5,5,0,0,1-5,5H0Z"
                                transform="matrix(0.656, -0.755, 0.755, 0.656, 1460.255, 3102.864)" fill="#d93207" />
                        </g>
                    </svg>
                </div>
                <span class="font-weight-bolder h5 pt-2">Instant Second Line</span>
                <span class="pt-2 text-muted">Make and receive calls on your mobile phone using our app.</span>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-3 mb-lg-0 mb-4 d-block d-lg-none">
            <div class=" cursor-pointer d-flex align-items-center justify-content-center text-center rounded-lg h-100 p-4"
                style="background-color: #F9DED7;" (click)="onShowAllFeatures()">
                <span class="font-weight-bolder text-secondary">See All 20+ Features</span>
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="features">
    <common-ui-features-model [features]="features.features"></common-ui-features-model>
</ng-container>