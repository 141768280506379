<div class="container-xl bg-white shadow">
    <div id="footer">
        <div class="container">
            <div class="d-flex justify-content-between align-items-center">
                <div class="text-sm-left text-center my-3">
                    <img src="/assets/images/{{logo}}" style="height: 30px;" alt="" class=" align-top" />
                </div>
                <!-- <div class="col text-center text-lg-right d-none d-lg-block">
                    <button class="btn btn-outline-dark btn-sm my-3 mx-1 px-5 py-2 " *ngIf="buttonText" type="button" (click)="navigatePage.emit()">
                      {{ buttonText }}
                    </button>
                </div> -->
                <div class="d-none d-sm-block text-right my-3">
                    <a shareButton="whatsapp" [url]="shareUrl" title="Hey! I just ordered my Voiply phone system. Get yours at https://build.voiply.com">
                        <img class="socialIcons" src="/assets/images/social-whatsapp-circular-button.png" alt="" />
                    </a>
                    <a shareButton="google" [url]="shareUrl" title="Hey! I just ordered my Voiply phone system. Get yours at https://build.voiply.com">
                        <img class="socialIcons" src="/assets/images/social-googleplus-circular-button.png" alt="" /></a>
                    <a shareButton="linkedin" [url]="shareUrl" title="Hey! I just ordered my Voiply phone system. Get yours at https://build.voiply.com">
                        <img class="socialIcons" src="/assets/images/social-linkedin-circular-button.png" alt="" /></a>
                    <a shareButton="facebook" [url]="shareUrl" title="Hey! I just ordered my Voiply phone system. Get yours at https://build.voiply.com">
                        <img class="socialIcons" src="/assets/images/social-facebook-circular-button.png" alt="" /></a>
                    <a shareButton="twitter" [url]="shareUrl" title="Hey! I just ordered my Voiply phone system. Get yours at https://build.voiply.com">
                        <img class="socialIcons mr-0" src="/assets/images/social-twitter-circular-button.png" alt="" /></a>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid bg-primary-gradient">
        <div class="container ">
            <p class="m-0 text-center py-3" style="color: white;">
                <span class="pr-1">
          <i class="far fa-copyright">
          </i>
        </span> 2012-{{ currentYear }} VoIPly. All rights are reserved
            </p>

        </div>
    </div>
</div>
