import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'voiply-receptionist',
  templateUrl: './receptionist.component.html',
  styleUrls: ['./receptionist.component.scss']
})
export class ReceptionistComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
