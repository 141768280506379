<div class="card">
  <div class="card-body">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <h5 class="text-secondary font-weight-bold m-0"><i class="fas fa-globe-americas pr-3"></i> TIME ZONE </h5>
      <i class="fas fa-question-circle fa-lg" *ngIf="timeZoneHelpText" [popover]="timeZoneHelpText" placement="auto"
        containerClass="popover-css" [outsideClick]="true"></i>
    </div>
    <div class="border-bottom my-3"></div>

    <div class="select-input">
      <i class="fas fa-caret-down  dropdown-img d-flex align-items-center"></i>
      <select class="select-custom form-control text-secondary font-weight-bold"
        (change)="toggleTimeZone($event.target.value)" [ngModel]="timeZone">
        <option value="est">EST</option>
        <option value="mst">MST</option>
        <option value="cst">CST</option>
        <option value="pst">PST</option>
        <option value="hst">HST</option>
      </select>
    </div>

  </div>
</div>