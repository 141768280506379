import { Component, OnInit } from '@angular/core';
import { PrimaryNumberAfterCheckoutBase } from '../primary-number-after-checkout-base';
import { OrderService } from '@voiply/shared/common-ui';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal/';
import { ValidationService } from 'libs/shared/common-ui/src/lib/services';
import { AddMorePhonesBusinessComponent } from 'libs/checkout/src/lib/containers';

@Component({
  selector: 'voiply-primary-number-business-after-checkout',
  templateUrl: './primary-number-business-after-checkout.component.html',
  styleUrls: ['./primary-number-business-after-checkout.component.scss']
})
export class PrimaryNumberBusinessAfterCheckoutComponent extends PrimaryNumberAfterCheckoutBase {

  constructor(orderService: OrderService, toastr: ToastrService, modalService: BsModalService, validateService: ValidationService) {
    super(orderService, toastr, modalService, validateService);
  }


  sanitizeInternationalNumber(companyNumber) {
    return companyNumber.countryCode + ' ' + companyNumber.areaCode + ' ' + companyNumber.dialCode.substring(0, 3) + '-' + companyNumber.dialCode.substring(3, 7);
  }
}
