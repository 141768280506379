import { Component, Input, Output, EventEmitter, Inject, OnChanges, SimpleChanges } from '@angular/core';
import { Select } from '@ngxs/store';
import { IEnvironment, ENVIRONMENT, AppStateSelectors, OnDestroyCleanup } from '@voiply/shared/common-ui';
import { Address, CheckoutDetail, ShareOrder } from '@voiply/shared/model';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'voiply-share-order',
  templateUrl: './share-order.component.html',
  styleUrls: ['./share-order.component.css']
})
export class ShareOrderComponent {

  @Input()
  set orderId(value: string) {
    this._orderId = value;
    this.shareUrl = location.origin + "/" + value;
    this.printUrl = `${this.environment.rootApi}/order/${value}/print-new/{templateId}`;
  }

  @Output()
  shareOrder: EventEmitter<ShareOrder> = new EventEmitter();

  _orderId: string;
  shareUrl: string;
  printUrl: string;

  mobileNumber = '';
  email = '';

  @Input()
  set billingAddress(value: any) {
    this.email = value.email;
  }

  constructor(@Inject(ENVIRONMENT) private environment: IEnvironment,private toastr: ToastrService) {
  }



  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  shareViaSMS() {
    const sharing: ShareOrder = {
      emails: null,
      mobiles: {}
    };

    sharing.mobiles[this.mobileNumber] = (new Date()).toISOString();
    this.toastr.success("We have sent you an SMS");
    this.shareOrder.emit(sharing);
  }

  shareViaEmail() {
    const sharing: ShareOrder = {
      emails: {},
      mobiles: null
    };

    sharing.emails[this.email] = (new Date()).toISOString();
    this.toastr.success("We have sent you an email");
    this.shareOrder.emit(sharing);
  }

}
