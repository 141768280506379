<div class="" *ngIf="callFlowsList.length > 0">
  <div class="row" id="configure-call-flow" *ngIf="callFlow != undefined">

    <ng-container *ngFor="let flow of callFlowsList">
      <div class="col-md-3 col-sm-6 col-xs-12 pt-3">

        <span *ngIf="flow.videoId">
          <script src="https://fast.wistia.com/embed/medias/{{flow.videoId}}.jsonp" async></script>
        </span>
        <div
          [ngClass]="{'call-sec':this.callFlow.type!=CallFlow_Type.None && this.callFlow.type==flow.type && this.callFlow.skipConfig==false}">
          <div class="thumb">
            <div class="thumb-top">
              <img [popover]="flow.helpText" placement="auto" containerClass="popover-css" [outsideClick]="true"
                *ngIf="flow.helpText" src="assets/images/question-mark.png" style="width: 22px; position:absolute;right: -10px;
                  top: -10px;">
              <div class="d-flex flex-row justify-content-center align-items-center">
                <i class="fas fa-user text-secondary pr-1" *ngIf="flow.title==='BASIC'"></i>
                <i class="fas fa-users text-secondary pr-1" *ngIf="flow.title==='TEAM'"></i>
                <img class="pr-1 d-block d-md-none d-lg-block" src="assets/images/auto-attendant.png" style="width:21px"
                  *ngIf="flow.title==='AUTO ATTENDANT'">
                <img class="pr-1 d-block d-md-none d-lg-block icon-primary" src="assets/images/human-attendant.svg"
                  style="width:21px" *ngIf="flow.title==='HUMAN ATTENDANT'">
                <i class="fas fa-phone-office text-secondary pr-1" *ngIf="flow.title==='ADVANCED'"></i>
                <h6 class="py-3 m-0 font-weight-bold">{{flow.title}}</h6>
              </div>

            </div>
            <div class="thumb-body">
              <div class="thumb-img" *ngIf="flow.videoId">
                <div *ngIf='flow.videoId=="3ifkfut0yx"' style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/571628456?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=2003e4ca3c" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Ring One.mp4"></iframe></div>
                <div *ngIf='flow.videoId=="knylm3uepr"' style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/571628477?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=569f9a995c" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Ring Many.mp4"></iframe></div>
                <div *ngIf='flow.videoId=="t5artiobza"' style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/571628435?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=3e0abc6467" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Smart IVR.mp4"></iframe></div>
                <div *ngIf='flow.videoId=="pb551d022g"' style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/571628410?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=79a5a4a61a" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Night Time IVR.mp4"></iframe></div>
                <!-- <voiply-wistia-player [videoId]=flow.videoId style="height:100%;left:0;top:0;width:100%;">
                </voiply-wistia-player> -->
              </div>

              <!-- <div class="thumb-top">
                <h6 class="py-2 m-0">{{flow.title}}</h6>
              </div> -->
            </div>
            <div class="thumb-title" (click)="changeSelectedTab(flow.type, false)"
              *ngIf="(this.callFlow.type!=CallFlow_Type.None && this.callFlow.type==flow.type && this.callFlow.skipConfig==false) else other_content">
              <h5 class="text-center">
                <!-- <button class="thumb-title"  *ngIf="this.callFlow.type!=CallFlow_Type.None && this.callFlow.type==flow.type else other_content"
              (click)="changeSelectedTab(flow.type, false)">
                <input checked="" class="form-check-input mt-1" id="rememberMe1" name="remeberMe" tabindex="1"
                type="checkbox">Skip Config</button>
                <ng-template #other_content>
              <button class="thumb-title" *ngIf="this.callFlow.type==CallFlow_Type.None || (flow.type!=CallFlow_Type.None)" (click)="changeSelectedTab(flow.type, true)">Select</button>
            </ng-template> -->
                <!-- <label class="checkbox-container no-hover" appClickStopPropogation>
                <input checked="" class="form-check-input mt-1" id="rememberMe1" name="remeberMe" tabindex="1"
                  type="checkbox">
                {{selectedSubTitle}} skip config -->
                <!-- <span class="checkmark" style="background-color: #fff"></span>
              </label>  -->

                <label class="checkbox-container" appclickstoppropogation>{{selectedSubTitle}}
                  <input checked="" class="form-check-input mt-1" id="rememberMe1" name="remeberMe" tabindex="1"
                    type="checkbox">
                  <span class="checkmark"></span>
                </label>
              </h5>
            </div>
            <ng-template #other_content>
              <div class="thumb-title" (click)="changeSelectedTab(flow.type, true)">
                <h5>
                  <span class="font-weight-bold">{{subTitle}}</span>
                </h5>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <!-- 
      <tabset>
          <ng-container *ngFor="let flow of callFlowsList">
              <tab
              [customClass]="callFlowsList.length <= 2 ? 'col-sm-6 col-xs-12' : callFlowsList.length == 3 ? 'col-md-4 col-sm-6 col-xs-12' : 'col-md-3 col-sm-6 col-xs-12'"
              (selectTab)="changeSelectedTab(flow.type, true)" [active]="callFlow.type == flow.type && !callFlow.skipConfig">
           
              <ng-template tabHeading>
                <app-tab-header [title]="flow.title" subTitle="SELECT" selectedSubTitle="SKIP CONFIG"
                  [videoUniqueId]="flow.videoId" [selected]="callFlow.type == flow.type && !callFlow.skipConfig"
                  (onDeselectTab)="changeSelectedTab(flow.type, false)"></app-tab-header>
              </ng-template>
            </tab>
          </ng-container>
        </tabset> -->

    </ng-container>

  </div>
</div>