<div class="card">
  <div class="card-body">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <h5 class="text-secondary font-weight-bold m-0"><i class="fas fa-voicemail pr-3"></i> VOICEMAIL </h5>
      <i class="fas fa-question-circle fa-lg" *ngIf="voicemailHelpText" [popover]="voicemailHelpText" placement="auto"
        containerClass="popover-css" [outsideClick]="true"></i>
    </div>
    <div class="border-bottom my-3"></div>

    <div class="d-flex mt-3 align-items-center flex-wrap">
      <div class=" d-flex flex-row align-items-center flex-wrap flex-sm-nowrap flex-grow-1">
        <div class="flex-fill pr-sm-3">
          <div class="font-italic text-secondary">Recording</div>
          <div class="input-group">
            <div class="d-flex flex-fill">
              <div class="input-group-prepend">
                <div class="input-group-text bg-primary rounded-0">
                  <i class="fab fa-simplybuilt text-primary"></i>
                </div>
              </div>
              <div class="select-input">
                <i class="fas fa-caret-down dropdown-img d-flex align-items-center"></i>
                <select class="select-custom form-control text-secondary font-weight-bold" [(ngModel)]="voiceMailUser"
                  (change)="onVoiceMailSelectionChanged($event.target.value)"
                  style="min-width:150px; border-radius:0px;">
                  <option [value]="user.id" *ngFor="let user of users">{{user.firstName}} {{user.lastName}}</option>
                  <option disabled *ngIf="users.length == 0" selected>No user available</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-fill">
          <div class="font-italic text-secondary">Activate After</div>

          <div class="select-input  d-inline-block" style="min-width:100px;">
            <i class="fas fa-caret-down dropdown-img d-flex align-items-center"></i>
            <select class="select-custom form-control font-weight-bold text-secondary"
              [(ngModel)]="voicemailRingTimeoutCount"
              (change)="onvoicemailRingTimeoutCountChanged($event.target.value)">
              <option [value]="1">1 Ring</option>
              <option [value]="2">2 Rings</option>
              <option [value]="3">3 Rings</option>
              <option [value]="4" selected>4 Rings</option>
              <option [value]="5">5 Rings</option>
              <option [value]="6">6 Rings</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>