<div class="my-3" *ngIf="users.length > 0">

  <common-ui-section-header imagePath="/assets/images/call-scenarios.png" [title]="callScenariosTitle"
    id="callScenarios" [helpText]="callFlowHelpText.description">
  </common-ui-section-header>
  <voiply-callflow-list [callFlowsList]="callFlowsList" [users]="users" [callFlow]="callFlow" [apps]="apps"
    (callConfigurationChanged)="onCallConfigurationChanged($event)"></voiply-callflow-list>

  <div *ngIf="(callFlow.type != CALL_FLOW_TYPE.None) && !callFlow.skipConfig">

    <div *ngIf="callFlow.type == CALL_FLOW_TYPE.RingOne">
      <div class="thumb-detail2 p-4 mt-3 shadow">
        <!--<voiply-basic [callConfiguration]="callFlow.ringCallFlow" [users]="users" [callFlowType]="callFlow.type"
          (configurationChanged)="onConfigurationChanged($event)"></voiply-basic>  -->
        <voiply-basic [callFlow]="callFlow" [users]="users" (configurationChanged)="onCallConfigurationChanged($event)"
          [callFlowHelpText]="callFlowHelpText">
        </voiply-basic>
      </div>
      <voiply-callflow-faqs [faqType]="callFlow.type"></voiply-callflow-faqs>
    </div>

    <div *ngIf="callFlow.type == CALL_FLOW_TYPE.RingGroup">
      <div class="thumb-detail2 p-4 mt-3 shadow">
        <!-- <voiply-team [callConfiguration]="callFlow.ringCallFlow" [users]="users" [callFlowType]="callFlow.type"
          (configurationChanged)="onConfigurationChanged($event)"></voiply-team> -->
        <voiply-team [callFlow]="callFlow" [users]="users" (configurationChanged)="onCallConfigurationChanged($event)"
          [callFlowHelpText]="callFlowHelpText">
        </voiply-team>
      </div>
      <voiply-callflow-faqs [faqType]="callFlow.type"></voiply-callflow-faqs>
    </div>

    <div *ngIf="callFlow.type == CALL_FLOW_TYPE.AutoAttendant">
      <div class="thumb-detail2 p-4 mt-3 shadow">
        <!-- <app-after-hour [users]="users" [autoAttendantOptions]="callFlow.autoAttendantOptions"
          (optionChanged)="onAutoAttendentChanged($event)"></app-after-hour> -->
        <voiply-auto-attendant-master [users]="users" [callFlow]="callFlow" [callFlowHelpText]="callFlowHelpText"
          [apps]="apps" (configurationChanged)="onCallConfigurationChanged($event)" [selectedNumber]="selectedNumber">
        </voiply-auto-attendant-master>
      </div>
      <voiply-callflow-faqs [faqType]="callFlow.type"></voiply-callflow-faqs>
    </div>

    <div *ngIf="callFlow.type == CALL_FLOW_TYPE.NightTimeAttendant">
      <div class="thumb-detail2 p-4 mt-3 shadow">
        <!-- <voiply-team [callConfiguration]="callFlow.ringCallFlow" [users]="users" [callFlowType]="callFlow.type"
          (configurationChanged)="onConfigurationChanged($event)"></voiply-team> -->
        <voiply-team [callFlow]="callFlow" [users]="users" (configurationChanged)="onCallConfigurationChanged($event)"
          [callFlowHelpText]="callFlowHelpText">
        </voiply-team>
        <!-- <app-after-hour [users]="users" [autoAttendantOptions]="callFlow.autoAttendantOptions"
          (optionChanged)="onAutoAttendentChanged($event)"></app-after-hour> -->
        <div class="pt-4">
          <voiply-auto-attendant-master [users]="users" [callFlow]="callFlow" [callFlowHelpText]="callFlowHelpText"
            [apps]="apps" (configurationChanged)="onCallConfigurationChanged($event)" [selectedNumber]="selectedNumber">
          </voiply-auto-attendant-master>
        </div>
      </div>

      <voiply-callflow-faqs [faqType]="callFlow.type"></voiply-callflow-faqs>

    </div>

    <div *ngIf="callFlow.type == CALL_FLOW_TYPE.HumanAttendant">
      <div class="thumb-detail2 p-4 mt-3 shadow">
        <voiply-human-attendant [callFlow]="callFlow" [callFlowHelpText]="callFlowHelpText"
          (configurationChanged)="onCallConfigurationChanged($event)"></voiply-human-attendant>
      </div>
    </div>
  </div>


</div>